import { compose, createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { getUrlParam } from 'libs/utils'
import loggerMiddleware from '../middlewares/loggerMiddleware'
import filtersMiddleware from '../middlewares/filtersMiddleware'
import reducers, { initialStates } from '../reducers'

const extractFiltersFromUrl = () => {
  let filters = {}
  // extract filters from the url
  try {
    filters = getUrlParam('filters')
    filters = JSON.parse(unescape(filters)) || {}
  } catch (error) {
    // silently ignore
  }

  return filters
}

export let store;

const configureStore = (railsProps) => {
  const initialFilters = extractFiltersFromUrl()

  const initialState = {
    ...initialStates,
    page: { ...initialStates.page, ...railsProps},
    filters: { ...initialStates.filters, ...initialFilters }
  }

  const isDebug = getUrlParam('debug')
  const middlewares = [
    thunkMiddleware,
    filtersMiddleware(initialFilters)
  ]

  if (isDebug) {
    middlewares.push(loggerMiddleware)
  }

  const appliedMiddlewares = applyMiddleware(
    ...middlewares
  )

  const composedStore = compose(appliedMiddlewares)

  store = (
    composedStore(createStore)(reducers, initialState)
  )

  return store
}

export default configureStore
