/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { default as ReactDatePicker } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class CustomInput extends Component {
  render() {
    const { ...props } = this.props;
    return <input {...props} />;
  }
}

const DatePicker = (props) => {
  const dateFormat = window.CC_MOMENT_JS_DATE_FORMAT
  const { value, placeholder, required, readonly, options, schema } = props
  const { label, astrick } = options
  const { title } = schema
  const placeholderText = placeholder + (required && options.astrick ? '*' : '')
  const minDate = options.hasOwnProperty('minDate') ? (options.minDate !== null ? moment(options.minDate) : null) : moment()
  const maxDate = options.hasOwnProperty('maxDate') ? (options.maxDate !== null ? moment(options.maxDate) : null) : null
  return (
    <React.Fragment>
      {!label && (<label class="control-label">{title}{astrick ? '*' : null}</label>)}
      <div className="react-datepicker-container position-relative">
        <ReactDatePicker
          selected={value ? moment(value) : null}
          onChange={(date) => { props.onChange(date ? moment(date).format('YYYY-MM-DD') : undefined) }}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          minDate={minDate}
          maxDate={maxDate}
          readOnly={readonly}
          disabled={readonly}
          className={classNames('form-control datepicker', { 'disabled': readonly })}
          customInput={<CustomInput />}
        />
      </div>
    </React.Fragment>
  )
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  options: PropTypes.object,
  onChange: PropTypes.func,
  schema: PropTypes.object
}

export default DatePicker
