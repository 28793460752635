import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Error = ({ error, className }) => (
  <div className={classNames(className, 'text-danger text-center p-5')}>
    {error}
  </div>
)

Error.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string
}

Error.defaultProps = {
  className: ''
}

export default Error
