import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const EntityItem = ({ title, isSelected, onClickHandler, clientName, date, showTime }) => {
  const dateFormat = window.CC_MOMENT_JS_DATE_FORMAT

  return (
    <li className={isSelected ? 'active' : ''} role="presentation" onClick={onClickHandler}>
      <p className="ellipsis"> {title} </p>
      <p className="text-gray">
        {clientName &&
          <span className="mr-2">{clientName}</span>
        }
      </p>
      <p className="text-gray">{date ? moment(date).format(`${dateFormat}${showTime ? ', h:mm A' : ''}`) : ''}</p>
    </li>
  )
}

EntityItem.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClickHandler: PropTypes.func,
  clientName: PropTypes.string,
  showTime: PropTypes.bool,
  date: PropTypes.string
}

export default EntityItem
