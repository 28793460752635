import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker as DateRangePickerComponent } from 'react-dates'

const DateRangePicker = (props) => {
  const {
    // data
    formData = {},
    defaultSchema,
    placeholder,
    numberOfMonths,
    daySize,
    minimumNights,
    keyMap,
    title,
    readonly,
    showAstrick,
    inputToDisplay,
    focusedInput,
    isOutsideRange,
    label,
    // handlers
    handleDatesChange,
    handleFocusChange,
    astrick,
  } = props
  return (
    <React.Fragment>
      {!label && (<label>{title || placeholder}{astrick ? '*' : null}</label>)}
      <div className="cvc-daterange-picker">
        <div className="btn-group">
          <button type="button" className={classNames("dropdown-toggle dropdown-regular", { 'text-gray' : !inputToDisplay })} id="dropdownMenuButton" data-toggle="dropdown">
            {inputToDisplay || placeholder || 'Select...' }
          </button>
        </div>
        <DateRangePickerComponent
          startDateId="startDate"
          startDate={formData[keyMap.startDate] ? moment(formData[keyMap.startDate]) : defaultSchema.startDate}
          endDateId="endDate"
          endDate={formData[keyMap.endDate] ? moment(formData[keyMap.endDate]) : defaultSchema.endDate}
          numberOfMonths={numberOfMonths || defaultSchema.numberOfMonths}
          daySize={daySize || defaultSchema.daySize}
          minimumNights={minimumNights || defaultSchema.minimumNights}
          isOutsideRange={isOutsideRange}
          focusedInput={focusedInput}
          onDatesChange={handleDatesChange}
          onFocusChange={handleFocusChange}
          disabled={readonly}
          screenReaderInputMessage=' '
        />
      </div>
    </React.Fragment>
  )
}

DateRangePicker.propTypes = {
  // data
  formData: PropTypes.object.isRequired,
  defaultSchema: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  numberOfMonths: PropTypes.number,
  daySize: PropTypes.number,
  minimumNights: PropTypes.number,
  keyMap: PropTypes.object.isRequired,
  title: PropTypes.string,
  showAstrick: PropTypes.bool,
  inputToDisplay: PropTypes.string,
  focusedInput: PropTypes.string,
  isOutsideRange: PropTypes.func.isRequired,
  label: PropTypes.bool,
  // handlers
  handleDatesChange: PropTypes.func.isRequired,
  handleFocusChange: PropTypes.func
}

export default DateRangePicker
