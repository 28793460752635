import React, { Component } from 'react'
import { WidgetEventContext } from 'core/context'


const withEventHandler = (Widget) => {
  return class WithEventHandler extends Component {
    static contextType = WidgetEventContext

    handleClickEvent = () => {
      const { schema = {}, data = {}, onClick, index } = this.props
      const { eventHandler } = this.context
      const events = data.events || schema.events
      eventHandler(events)
      if (onClick) {
        onClick(index)
      }
    }

    render() {
      return (
        <span role="presentation" onClick={this.handleClickEvent}>
          <Widget {...this.props} />
        </span>
      )
    }
  }
}

export default withEventHandler
