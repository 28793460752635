import { connect } from 'react-redux'
import PopoverRenderer from '../../renderers/PopoverRenderer'
import * as popoverActions from '../actions/popoverActionsCreators'

const mapStateToProps = (state, ownProps) => {
  const { popover, widgetGroup, page } = state
  const widgetGroups = popover.schema ? widgetGroup[popover.schema.id] || {} : {}

  return {
    widgetGroupIds: Object.keys(widgetGroups),
    schema: state.popover.schema,
  }
}

export default connect(mapStateToProps, { ...popoverActions })(PopoverRenderer)
