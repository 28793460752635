export default {
  'schemaError': 'Schema Error: Use either schema or schemaUrl',
  'widgetApiError': 'WidgetApiError: Widget API not passed',
  'widgetDataError': 'WidgetDataError: Use either data or dataUrl',
  'internalStateError': 'Internal State Error: Some important data or property field missing',
  'modalTypeError': 'ModalType Error: Type of Modal is not specified',
  'tableSchemaError': 'Table Schema Error: Check for the column width',
  'layoutSectionError': 'Layout Error: LayoutSection can\'t be empty',
  'multiDataTableError': 'Table Schema Error: Data cannot be an Array'
}
