import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './TableRow.module.css'

function TableRow(props) {
  const { columns, rowKey, dataset } = props
  const { __iconPlus: iconPlus } = dataset
  const expandStyle = cx(iconPlus ? styles.plus : styles.minus, styles.expandIcon)
  const handleExpandChange = () => {
    props.handleExpandIconClick(dataset[rowKey])
  }
  let preWidth  = 0
  return (    
    <div key={dataset[rowKey]} className={styles.row}>
    {columns.map((col, i) => {
      if(i>-1){
      const style = { textAlign: col.textAlign || 'left' }
      
      const level = {}
      level.marginLeft = (dataset.__level - 1) * 10 + 'px'
      const content = col.bodyRender ? col.bodyRender(dataset) : dataset[col.name]
      const expandWrap = cx(styles.expandWrap, styles.firstColumnPart)
      let divelement = <div></div>
      
      if (col.width) {
        style.flexGrow = 0
        style.flexShrink = 0
        style.flexBasis = col.width
      }
      if(i<2){
        if(i==0)preWidth = parseInt(col.width.split('px')[0])+preWidth
        style.zIndex = 999
        if(i==1){
          style.paddingLeft = preWidth+'px'
          style.zIndex = 998
          preWidth = preWidth +parseInt(col.width.split('px')[0])
        }
        const linkStyle = {}
        linkStyle.color = 'blue'
        linkStyle.cursor = 'pointer'
        divelement = <div key={i} className={styles.column_item_fixed} style={style}>
        {i === 0 ? (
          <div className={styles.firstColumn} style={level}>
            <div className={expandWrap}>
              <span className={expandStyle} onClick={handleExpandChange} />
            </div>
            {dataset.link == null?(
              <div className={styles.firstColumnPart} >{content}</div>
            ):(
              <div className={styles.firstColumnPart} style={linkStyle} onClick={()=> window.open(dataset.link, "_blank")} >{content}</div>
            )}
          </div>
        ) : (
          content
        )}
      </div>
        }else{
          
          if(i==2)style.marginLeft = preWidth+ 'px'
          divelement = <div key={i} className={styles.column_item} style={style}>
          {i === 0 ? (
            <div className={styles.firstColumn} style={level}>
              <div className={expandWrap}>
                <span className={expandStyle} onClick={handleExpandChange} />
              </div>
              <div className={styles.firstColumnPart}>{content}</div>
            </div>
          ) : (
            content
          )}
        </div>
        }
          return (
            divelement
        )
        }
      })}
    </div>
  )
}

TableRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      textAlign: PropTypes.oneOf(['left', 'center', 'right']),
      bodyRender: PropTypes.func
    })
  ).isRequired,
  rowKey: PropTypes.string.isRequired,
  dataset: PropTypes.object.isRequired,
  handleExpandIconClick: PropTypes.func.isRequired
}

export default TableRow
