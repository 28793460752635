import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'

const ImageList = ({ schema = {}, data = [] }) => {
  return (
    <div className="cvc-c-image-list d-flex">
      {data.map((imageData) => {
        return (
          <Image schema={schema} data={imageData} />
        )
      })}
    </div>
  )
}

ImageList.propTypes = {
  schema: PropTypes.shape({
    src: PropTypes.string,
    altTitle: PropTypes.string,
    style: PropTypes.shape()
  }),
  data: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    altTitle: PropTypes.string
  }))
}
export default ImageList
