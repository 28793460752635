/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import bindAll from 'lodash.bindall'
import classNames from 'classnames'
import Image from '../components/Image'
import { handleAction } from '../redux/actions/appActionCreators'
import { renderData } from '../helpers/utils'

const ListItem = ({
  item,
  bgStyle,
  style,
  onclickHandler
}) => {
  const {
    status,
    title,
    subtitle,
    linkUrl,
    heading,
    titleRight,
    subtitleRight,
    textListRight,
    badge,
  } = item;
  const { tile = {} } = style

  return (
    <li
      className={classNames('tile', tile.className, { 'action': linkUrl })}
      key={shortid.generate()}
      role="presentation"
      onClick={e => onclickHandler(e, item)}
    >
      {heading && <p className="text-left text-gray">
        {heading}
      </p>}
      <div className={classNames('tile-img-text-container', style.image ? style.image.containerClassName : '')}>
        <div className={classNames('tile-img-cntr circle', bgStyle)}>
          <Image schema={{ style: style.image }} data={item.image} />
          {status && <span className={classNames('status', status)} /> }
        </div>
        <div className={classNames("text-left ml-3", style.text ? style.text.containerClassName : '')}>
          {titleRight && <p className="medium-copy m-0 text-truncate">
            {titleRight}
          </p>}
          {subtitleRight && <p className="subtitle-right m-0 text-truncate">
            {subtitleRight}
          </p>}
          {textListRight && textListRight.map((text) => {
            return <p key={shortid.generate()} className="text-left text-gray m-0 text-truncate">
              {text}
            </p>
          })}
          {badge && <span className={classNames('badge', badge.className)} title={badge.title || ''}>
            {badge.text}
          </span>}
        </div>
      </div>
      {title && (
        <p className="title">
          {title}
        </p>
      )}
      {subtitle && (
        <span className="subtitle">
          {subtitle}
        </span>
      )}
    </li>
  )
}

ListItem.propTypes = {
  item: PropTypes.any,
  bgStyle: PropTypes.string,
  style: PropTypes.object,
  onclickHandler: PropTypes.func
}

class Tile extends React.PureComponent {
  constructor() {
    super()
    bindAll(
      this,
      'onclickHandler',
      'renderTiles',
      'getTileAccommodationCount',
      'setTileAccommodation',
      'getLeftOverImageCount'
    )
    this.tileRef = null
    this.state = {
      accommodatedTilesCount: 0
    }
  }

  componentDidMount() {
    this.setTileAccommodation()
  }

  onclickHandler(e, item) {
    const { linkUrl } = item
    const action = {
      'id': 'tile-action',
      'schema': {
        'type': 'LINK',
        'label': '',
        'linkUrl': linkUrl || '#'
      },
      'data': linkUrl
    }
    if (linkUrl) {
      this.props.dispatch(handleAction(e, action))
    }
  }


  getLeftOverImageCount() {
    const { accommodatedTilesCount } = this.state
    const { data } = this.props
    return data.tiles.length - accommodatedTilesCount
  }

  getTileAccommodationCount() {
    const { schema, data } = this.props
    if (!this.tileRef) return data.tiles && data.tiles.length

    const { width } = this.tileRef.getBoundingClientRect()
    // Container width minus padding divided by one image size
    const tileToBeAccommodated = Math.floor((width - 32) / schema.style.imageWidth) || 1
    return (data.tiles && data.tiles.length <= tileToBeAccommodated) ? tileToBeAccommodated : tileToBeAccommodated - 1
  }

  setTileAccommodation() {
    const { schema = {}, data } = this.props
    const { style = {} } = schema
    if (style.singleRow) {
      this.setState({ accommodatedTilesCount: this.getTileAccommodationCount() }, () => {
        // When window is resized, calculate the accommodation again and set images accordingly
        window.addEventListener('resize', this.setTileAccommodation)
      })
    } else {
      this.setState({ accommodatedTilesCount: data.tiles.length })
    }
  }

  renderTiles() {
    const { schema = {}, data } = this.props
    const { tiles = [] } = data
    const { style = {} , placeholder } = schema
    const { tile = {} } = style
    const { accommodatedTilesCount } = this.state
    if (tiles.length === 0) {
      return (
        <div className="p-3 text-gray text-center">
          {placeholder || 'no items found.'}
        </div>
      )
    }
    const TilesList = tiles.slice(0, accommodatedTilesCount).map((item) => {
      const { image } = item
      const { bgStyle } = image
      return (
        <ListItem
          key={shortid.generate()}
          item={item}
          bgStyle={bgStyle}
          style={style}
          onclickHandler={this.onclickHandler}
        />
      )
    })
    if (style.singleRow && accommodatedTilesCount < tiles.length) {
      const leftOverImageCount = this.getLeftOverImageCount()
      const item = {
        image: {
          altTitle: `+${leftOverImageCount}`,
          placeholder: `+${leftOverImageCount}`
        },
        status: 'success',
        linkUrl: data.linkUrl
      }
      TilesList.push(
        <ListItem
          key={shortid.generate()}
          item={item}
          bgStyle="more-item"
          style={style}
          status={status}
          onclickHandler={this.onclickHandler}
        />
      )
    }
    return TilesList
  }

  renderHeading = () => {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div role="presentation" className={classNames('heading-container', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  render() {
    const { schema, data } = this.props
    const { heading, style = {} } = schema
    const { tile = {} } = style
    return (
      <div
        className={classNames('cvc-w cvc-w-tile', style.className)}
        ref={(instance) => this.tileRef = instance}
      >
        {this.renderHeading()}
        {schema.actions && (
          <div className='more-option'>
            {renderData(schema.actions, data.actions)}
          </div>
        )}
        <ul className={classNames('tile-container', tile.containerClassName)}>
          {this.renderTiles()}
        </ul>
      </div>
    )
  }
}

Tile.propTypes = {
  schema: PropTypes.shape({
    heading: PropTypes.object,
    style: PropTypes.object,
    properties: PropTypes.array,
    placeholder: PropTypes.string,
    actions: PropTypes.object
  }).isRequired,
  data: PropTypes.object
}

Tile.defaultProps = {
  data: {}
}

export default Tile
