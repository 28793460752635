import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { renderData } from '../helpers/utils'

const CustomWidget = (props) => {
  const {
    data = {},
    schema,
    options
  } = props
  const style = { ...schema.style, ...data.style }

  return (
    <div className={classNames('cvc-w', 'cvc-w-custom-widget', style.containerClassName)}>
      {
        schema.properties.map((property, index) => {
          const propertyStyle = property.style || {}
          const propertyData = property.id && data[property.id] ? data[property.id] : data;

          return (
            <div key={String(index)} className={classNames(propertyStyle.className)} {...property.tooltip}>
              {renderData(property, propertyData, options)}
            </div>
          )
        })
      }
    </div>
  )
}

CustomWidget.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  schema: PropTypes.shape({
    properties: PropTypes.array.isRequired,
    style: PropTypes.object
  }).isRequired
}

export default CustomWidget
