import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import shortid from 'shortid'

import Action from './Action'
import Icon from './Icon'

function renderActionIcon(action, levelClass, data) {
  const actionStyle = action.style || {}
  const dispatch = useDispatch()
  const listStyle = {...actionStyle, className: classNames(actionStyle.className, levelClass, `icn-actn-${action.icon}`)}
  const actionProps = {schema: {...action, style: listStyle}, data, dispatch }
  return (
      <Action key={shortid.generate()} {...actionProps} />
  )
}

const ActionDropdown = (props) => {
  const { schema, data, options } = props
  const dispatch = useDispatch()
  const defaultSchema = {
    style: { 'className': '' },
    actionItems: []
  }
  const actionItems = schema.actionItems || data
  const textActions = actionItems.filter((item) => {
    const hide = data && data[item.id] && data[item.id].hide;
    if (data && data[item.id]) {
      return !item.icon && !hide
    }

    return !item.icon;
  })

  const defaultActions = actionItems.filter((item) => {
    return (item.icon && item.showAsDefault)
  })
  const dropdownActions = actionItems.filter((item) => {
    return (item.icon && !item.showAsDefault)
  })
  const dropdownBtnStyle = schema.style || {}
  const { iconClassName, className, buttonClassName } = dropdownBtnStyle

  const hideDropdown = data.hide
  const dropdownIconsClass = textActions.length > 0 ?  'dropdown-icons' : 'dropdown-icons-only'
  if (hideDropdown) {
    return null
  }
  const dropdownId = shortid.generate();
  useEffect(() => {
    $('.dt-dropdown-container').on('shown.bs.dropdown', function(e) {
      const menuEl = $(this).find('.dropdown-menu.show')[0]
      const position = menuEl.getBoundingClientRect()

      // fixed positioning doesn't work under transfromed parent
      // use default positioning when inside transform (carousel).
      if ($(menuEl).parents('.slick-track').length > 0) return;

      $(menuEl).removeAttr('style')
      $(menuEl).css('visibility', 'hidden');
      setTimeout(() => {
        $(menuEl).attr("style", `position:fixed !important;top: ${position.y}px !important;left: ${position.x}px !important;`);

        if (position.bottom > window.innerHeight) {
          $(menuEl).attr("style", `position:fixed !important;top: ${position.y - position.height}px !important;left: ${position.x}px !important;`);
        } else if (position.right > window.innerWidth) {
          $(menuEl).attr("style", `position:fixed !important;top: ${position.y}px !important;left: ${position.x - position.width}px !important;`);
        }
        $(menuEl).css('visibility', 'visible');
      })
    })
    $('.dt-dropdown-container').on('hidden.bs.dropdown', function(e) {
      const menuEl = $(this).find('.dropdown-menu')[0]
      $(menuEl).removeAttr('style')
    })
  }, [])
  return (
    <React.Fragment>
      <div className={classNames('cvc-c-action-dropdown', dropdownBtnStyle.dropdownClassName)}>
        {defaultActions.length > 0 && <div className="default-icons">
          {
            defaultActions.map((action) => {
               return renderActionIcon(action, 'widget-icon', data)
            })
          }
        </div>}
        {(dropdownActions.length > 0 || textActions.length > 0) && (
          <div className={classNames('btn-group option-dropdown dt-dropdown-container', dropdownBtnStyle.className || defaultSchema.style.className)}>
            <button
              type="button"
              className={classNames(buttonClassName || `dropdown-toggle dropdown-regular px-1`, `dt-${dropdownId}`)}
              data-toggle="dropdown"
              onClick={e => {
                // in React v 17 document is no longer listening
                // for the events so we need to manually trigger it for bootstrap dropdown.
                // see this: https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
                e.preventDefault()
                e.stopPropagation()
                $(`.dt-${dropdownId}`).dropdown('toggle')
              }}
            >
              <Icon schema={{ className: iconClassName || 'fa fa-ellipsis-v' }} />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {dropdownActions.length > 0 && (<div className={dropdownIconsClass}>
                {
                  dropdownActions.map((action) => {
                     return renderActionIcon(action, 'dropdown-icon', data)
                  })
                }
              </div>)}
              {textActions.map((action) => {
                const actionStyle = action.style || {}
                const listStyle = { ...actionStyle, className: classNames(actionStyle.className, 'dropdown-item') }
                const actionProps = { schema: { ...action, style: listStyle }, data, options, dispatch }
                  return (
                    <Action key={shortid.generate()} {...actionProps} />
                  )
                })
              }
            </div>
          </div>)
        }
      </div>
    </React.Fragment>
  )
}

ActionDropdown.propTypes = {
  schema: PropTypes.shape({
    actionItems: PropTypes.array
  }).isRequired,
  data: PropTypes.object.isRequired
}

export default ActionDropdown