import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

const PureLayout = (props) => {
  const { schema = {}, parentId, parentWidgetGroupId } = props
  const { widgetGroups = [] } = schema

  return widgetGroups.map((widgetGroup) => {
    const { style = {} } = widgetGroup;

    return (
      <div className={classNames('row', style.className || widgetGroup.className)}>
        {widgetGroup.widgets.map((widget, index) => {
          const { hide, grids, style = {}, id } = widget
          const { xs, sm, md, lg, xl, auto, addDivider } = grids

          const Widget = require('core/widgets/' + widget.type).default

          if (!Widget) return null;

          return (
            <React.Fragment key={shortid.generate()}>
              {!hide && <div
                className={classNames(
                  'cvc-widget-container',
                  `${auto ? 'col' : ''}`,
                  `${xs ? 'col-'+xs : ''}`,
                  `${sm ? ' col-sm-'+sm : ''}`,
                  `${md ? ' col-md-'+md : ''}`,
                  `${lg ? ' col-lg-'+lg : ''}`,
                  `${xl ? ' col-xl-'+xl : ''}`,
                  style.className
                )}
              >
                <Widget
                  data={widget.data.widgetData}
                  schema={widget.schema}
                  widgetId={parentId}
                  widgetGroupId={parentWidgetGroupId}
                />
              </div>}
              {addDivider && <div className="w-100"></div>}
            </React.Fragment>
          )
        })}
      </div>
    )
  })
}

PureLayout.propTypes = {
  widgetGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    widgets: PropTypes.array,
    type: PropTypes.string,
    style: PropTypes.object
  })).isRequired,
  parentId: PropTypes.string.isRequired,
  parentWidgetGroupId: PropTypes.string.isRequired
}

export default PureLayout
