/* eslint-disable import/no-unresolved */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { WidgetEventContext } from 'core/context'
import Layout from './Layout'
import widgetsMap from 'core/helpers/widgetsMap'


class Carousel extends Component {
  static contextType = WidgetEventContext

  defaultSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }

  componentDidMount() {
    const { eventHandler, spreads } = this.context
    const { data } = this.props
    let shouldUpdateSpread = false
    if (spreads.spreads.length > 0) {
      const len = spreads.spreads.length
      if (!spreads.spreads[len - 1].drillDownWidgetId) {
        shouldUpdateSpread = true
      }
    } else if (!spreads.widgetId) {
      shouldUpdateSpread = true
    }
    if (data && data[0] && shouldUpdateSpread) {
      eventHandler([{
        type: 'UPDATE_SPREAD',
        eventData: {
          id: 'root_spread',
          widget: data[0].widgetId,
          drillDownWidget: data[0].widgetId,
          widgetId: data[0].widgetId
        }
      }])
    }
  }

  getSelectedWidget = () => {
    const { spreads } = this.context
    const spreadLength = spreads.spreads.length
    if (spreadLength === 0) return spreads.widgetId
    return spreads.spreads[spreadLength - 1].drillDownWidgetId
  }

  render() {
    const { schema, data = [] } = this.props
    const selectedWidgetId = this.getSelectedWidget()
    const sliderSettings = schema.settings || this.defaultSettings
    const { widgets, style = {} } = schema

    const layoutSchema = {
      className: 'carousel-layout',
      ...schema.layoutSchema
    }

    return (
      <div className={classNames('cvc-w', 'cvc-w-carousel', 'hide-widget-border', style.className)}>
        {data.length > 3 ? (
          <Slider {...sliderSettings}>
            {data.map((d, index) => {
              const isSelected = selectedWidgetId ? selectedWidgetId === d.widgetId : index === 0
              return (
                <Layout
                  index={index}
                  onClick={this.handleClick}
                  isSelected={isSelected}
                  schema={layoutSchema}
                  disableEvents={!isSelected}
                  data={d}
                  key={shortid.generate()}
                >
                  {widgets.filter((widget) => d[widget.id]).map((widget) => {
                    const WidgetToRender = widgetsMap[widget.type]
                    return (
                      <WidgetToRender
                        key={shortid.generate()}
                        schema={widget.schema}
                        data={d[widget.id]}
                      />
                    )
                  })}
                </Layout>
              )
            })}
          </Slider>
        ) : (
          <div className="row">
            {data.map((d, index) => {
              const isSelected = selectedWidgetId ? selectedWidgetId === d.widgetId : index === 0
              return (
                <div className={classNames(style.columnClassName || 'col-md-3')}>
                  <Layout
                    index={index}
                    onClick={this.handleClick}
                    disableEvents={!isSelected}
                    isSelected={isSelected}
                    schema={layoutSchema}
                    data={d}
                    key={shortid.generate()}
                  >
                    {widgets.filter((widget) => d[widget.id]).map((widget) => {
                      const WidgetToRender = widgetsMap[widget.type]
                      return (
                        <WidgetToRender
                          key={shortid.generate()}
                          schema={widget.schema}
                          data={d[widget.id]}
                        />
                      )
                    })}
                  </Layout>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

Carousel.contextTypes = {
  spreads: PropTypes.object
}


export default Carousel
