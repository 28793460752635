/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import shortid from 'shortid'

// DropdownList accepts items props as array of string or objs with multiselect functionality
class DropdownList extends React.PureComponent {
  constructor() {
    super()
    bindAll(
      this,
      'selectItem',
      'isItemSelected'
    )
  }

  selectItem(item) {
    const { multiselect, selectedItems } = this.props
    const isObjPassed = typeof (item) === 'object'
    if (multiselect) {
      let items = [].concat(selectedItems) || []
      if (isObjPassed) {
        const selectedIds = items.map(obj => obj.id)
        const index = selectedIds.indexOf(item.id)
        items = index === -1 ? items.concat(item) : items.filter(obj => obj.id !== item.id)
      } else {
        const index = items.indexOf(item)
        items = index === -1 ? items.concat(item) : items.splice(index, 1)
      }
      this.props.onItemSelect(items)
    } else {
      this.props.onItemSelect(item)
    }
  }

  isItemSelected(item) {
    const { multiselect, selectedItems } = this.props
    const isObjPassed = typeof (item) === 'object'
    let isSelected
    if (multiselect) {
      // TODO: check with example
      if (isObjPassed) {
        const selectedIds = selectedItems.map(obj => obj.id)
        isSelected = selectedIds.indexOf(item.id) !== -1
      } else {
        isSelected = selectedItems.indexOf(item) === -1
      }
    } else {
      isSelected = isObjPassed ? (selectedItems != null && item.id === selectedItems.id) : item === selectedItems
    }
    return isSelected
  }

  render() {
    const { items, label, alignMenu, styles = {} } = this.props
    const floatMenuClass = alignMenu === 'right' ? 'dropdown-menu-right' : ''
    return (
      <div className={`dropdown-menu ${floatMenuClass}`} aria-labelledby="dropdownMenuButton" style={styles}>
        {label
          && <p className="dropdown-label">{label}</p>
        }
        {items.length > 0 ? items.map((item) => {
            const isSelected = this.isItemSelected(item)
            return (
              <button className={`dropdown-item${isSelected ? ' selected' : ''}`} type="button" onClick={() => { this.selectItem(item) }} key={shortid.generate()}>
                { typeof (item) === 'object' ? item.label : item }
                <i className="list-selected fa fa-check" />
              </button>
            )
          }) : (<div className="dropdown-item">No Options</div>)
        }
      </div>
    )
  }
}

DropdownList.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  alignMenu: PropTypes.string,
  selectedItems: PropTypes.any,
  multiselect: PropTypes.bool,
  onItemSelect: PropTypes.func
}

DropdownList.defaultProps = {
  multiselect: false
}

export default DropdownList
