/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import { renderData } from '../../../helpers/utils'

class CustomText extends React.PureComponent {
  handleChange = (name, value) => {
    this.props.onChange(value)
  }

  render() {
    const { schema, placeholder, required, options = {} } = this.props
    const { title, name } = schema
    const value = this.props.value || schema.default
    const textSchema = options.text

    return (
      <React.Fragment>
        {
          title && (
            <label className="mb-2">
              {title}
              {required && options.astrick ? '*' : null}
            </label>
          )
        }
        {
          value ? value.split('\n').map((line) => {
            return (
              <p
                key={shortid.generate()}
                className={classNames(options.className)}
                dangerouslySetInnerHTML={{__html: line && textSchema ? renderData(textSchema, line.trim()) : line}}
              />
            )
          }) : <span className="px-1">-</span>
        }
      </React.Fragment>
    )
  }
}

CustomText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

CustomText.defaultProps = {
  value: ''
}

export default CustomText
