import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'
import bindAll from 'lodash.bindall'
import InlineEdit from 'core/widgets/InlineEdit'
import ReactTooltip from 'react-tooltip'

import { renderData, getPropertySchema } from '../helpers/utils'

class Grid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultSchema: {
        heading: null,
        show: [],
        header: null,
        style: {
          className: '',
          columns: 2
        }
      }
    }
    bindAll(
      this,
      'printGridHeader',
      'printEmptyTableHeader',
      'printTableRows',
      'printGridActions'
    )
  }

  printGridHeader(gridData) {
    const { header, properties } = this.props.schema
    if (header) {
      const { title, subtitle, detail, subdetail } = header
      const titleSchema = title ? getPropertySchema(properties, title.property_id) : {}
      const subtitleSchema = subtitle ? getPropertySchema(properties, subtitle.property_id) : {}
      const detailSchema = detail ? getPropertySchema(properties, detail.property_id) : {}
      const subdetailSchema = subdetail ? getPropertySchema(properties, subdetail.property_id) : {}
      return (
        <div className="grid-header">
          <div className="col-md-6 p-0">
            {gridData[titleSchema.id] && <div className="grid-title">{renderData(titleSchema, gridData[titleSchema.id])}</div>}
            {gridData[subtitleSchema.id] && <div className="grid-sub-title">{renderData(subtitleSchema, gridData[subtitleSchema.id])}</div>}
          </div>
          <div className="col-md-6 p-0">
            <div className="grid-sub-title float-right">
              {gridData[detailSchema.id] && <div>{renderData(detailSchema, gridData[detailSchema.id])}</div>}
              {gridData[subdetailSchema.id] && <div>{renderData(subdetailSchema, gridData[subdetailSchema.id])}</div>}
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !window.isPopoverVisible;
  }

  printEmptyTableHeader() {
    const { schema } = this.props
    const { defaultSchema } = this.state
    const gridStyle = schema.style || {}
    const colsCount = gridStyle.columns || defaultSchema.style.columns
    const ths = []
    for (let i = 0; i < colsCount; i += 1) {
      ths.push(<td key={shortid.generate()} className="p-0" />)
    }
    return (<thead className="d-none"><tr>{ths}</tr></thead>)
  }

  printTableRows(gridData) {
    const { schema } = this.props
    const { defaultSchema } = this.state
    const show = schema.show || defaultSchema.show
    const gridStyle = schema.style || {}
    const colsCount = gridStyle.columns || defaultSchema.style.columns
    const colnWidth = colsCount ? 100 / colsCount : 100
    const rows = []
    const dataKeys = show.length > 0 ? show : schema.properties.map(property => property.id)
    for (let i = 0; i < dataKeys.length; i += (i === dataKeys.length - 1 ? 1 : colsCount)) {
      const colns = []
      for (let count = 0; count < colsCount; count += 1) {
        const colKey = dataKeys[i + count]
        const colProps = getPropertySchema(schema.properties, colKey)

        // grid column key
        colns.push(
          <td
            key={shortid.generate()}
            className={count >= 1 ? 'bl-1-gray' : undefined}
            style={{ width: `${colnWidth / 2}%` }}
          >
           <span {...colProps.tooltip}>{colProps && colProps.title ? colProps.title : ''}</span>
          </td>
        )

        // grid column value
        colns.push(
          <td key={shortid.generate()} style={{ width: `${colnWidth / 2}%` }}>
            {colProps.inlineEdit ? <InlineEdit
                data={colProps.inlineEdit && gridData.popover ? gridData.popover[colKey] : {}}
                onClick={(status) => {
                  window.isPopoverVisible = status
                }}
                {...colProps.inlineEdit}
              >
                {renderData(colProps, gridData[colKey])}
              </InlineEdit> :
              colProps && colProps.title ? renderData(colProps, gridData[colKey]) : ''
            }
          </td>)
      }
      rows.push(<tr key={shortid.generate()}>{colns}</tr>)
    }
    return <tbody>{rows}</tbody>
  }

  printGridActions(actionData = {}) {
    const { schema } = this.props
    if (schema.actions) {
      return (
        <div className="grid-actions">
          {renderData(schema.actions, actionData)}
        </div>
      )
    }
    return null
  }

  render() {
    const { schema, data, children, options } = this.props
    const { defaultSchema } = this.state
    const { heading } = schema
    const style = schema.style || {}
    const gridClass = classNames('cvc-w', 'cvc-w-grid', style.className || defaultSchema.style.className)
    const grids = Array.isArray(data) ? data : [data]
    return (
      <React.Fragment>
        {grids.map((dataObj) => {
          return (
            <div className={gridClass} key={shortid.generate()}>
              {heading
              && (
                <div role="presentation" className={classNames('heading-container', heading.containerClassName)} {...heading.tooltip} >
                  <div className={classNames('d-inline-block', heading.className)}>{heading.title}</div>
                  <div className="clearfix ml-auto float-right">
                    { heading.actions && (
                      <React.Fragment>
                        {renderData(heading.actions, data.actions, options)}
                      </React.Fragment>)
                    }
                  </div>
                </div>
              )
              }
              {this.printGridHeader(dataObj.grid)}
              <table className="w-100 table-striped">
                {this.printEmptyTableHeader()}
                {this.printTableRows(dataObj.grid)}
              </table>
              {children}
              {this.printGridActions(dataObj.actions)}
            </div>
          )
        })
        }
      </React.Fragment>
    )
  }
}

Grid.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  schema: PropTypes.shape({
    heading: PropTypes.object,
    header: PropTypes.object,
    properties: PropTypes.array.isRequired,
    show: PropTypes.array,
    style: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  children: PropTypes.node
}

export default Grid
