import React from 'react'
import PropTypes from 'prop-types'


const LinearGradientBar = (props) => {
  const { x, y, height, width, fillStart, fillEnd, rx, ry } = props

  return (
    <g>
      <defs>
        <linearGradient id={`${x}${fillStart}grad`} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={fillStart} stopOpacity="100%" />
          <stop offset="100%" stopColor={fillEnd} stopOpacity="100%" />
        </linearGradient>
      </defs>
      <rect x={x} y={y} width={width} height={height} fill={`url(#${x}${fillStart}grad)`} rx={rx} ry={rx} />
    </g>
  )
}

LinearGradientBar.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  fillStart: PropTypes.string,
  fillEnd: PropTypes.string
}

export default LinearGradientBar
