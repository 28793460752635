import {
  SET_FILTER,
  UPDATE_FILTER,
  REMOVE_FILTER_ITEM,
  REMOVE_FILTER,
  REMOVE_ALL_WIDGET_FILTERS
} from '../constants/filtersConstants'

export const setFilter = (data, preventUrlUpdates) => {
  return {
    type: SET_FILTER,
    data,
    preventUrlUpdates
  }
}

export const updateFilter = (data, preventUrlUpdates) => {
  return {
    type: UPDATE_FILTER,
    data,
    preventUrlUpdates
  }
}

export const removeFilterItem = (data) => {
  return {
    type: REMOVE_FILTER_ITEM,
    data
  }
}

export const removeFilter = (data) => {
  return {
    type: REMOVE_FILTER,
    data
  }
}

export const removeAllWidgetFilters = (data = {}) => {
  return {
    type: REMOVE_ALL_WIDGET_FILTERS,
    data
  }
}