import {
  FETCHING_PAGE_SCHEMA,
  FETCH_PAGE_SCHEMA_SUCCESS,
  SET_LOADING_OVERLAY
} from '../constants/pageConstants'
import { VISIT_PAGE } from '../constants/appConstants'

export const $$initialState = {
  loadingSchema: true,
}

export default function pageReducer($$state = $$initialState, action) {
  const {
    type,
    schema,
    loadingSchema,
    loadingOverlay,
    url,
    filter,
    filterId,
    target,
    payload
  } = action
  switch (type) {
    case FETCHING_PAGE_SCHEMA:
      return {
        ...$$state,
        loadingSchema
      }
    case FETCH_PAGE_SCHEMA_SUCCESS:
      return {
        ...$$state,
        schema,
        loadingSchema: false
      }
    case SET_LOADING_OVERLAY:
      return {
        ...$$state,
        loadingOverlay
      }
    case VISIT_PAGE:
      window.open(url, target)
      return {
        ...$$state
      }
    default:
      return $$state
  }
}