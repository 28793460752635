import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'

import requestsManager from 'libs/api/requestsManager'
import FormInput from 'libs/components/FormInput'
import FormDropdown from 'libs/components/FormDropdown'
import Button from 'libs/components/Button'
import Loader from 'libs/components/Loader'

const visibilityOptions = ["Internal", "None", "Public"]

class TaskModal extends React.Component {
  constructor() {
    super()
    this.dateFormat = window.CC_MOMENT_JS_DATE_FORMAT
    this.state = {
      assignee: [],
      watchers: [],
      initialAssignee: [],
      initialWatchers: [],
      formLoaded: false,
      clientsLoading: true
    }
    bindAll(this,
      'setAssignee',
      'setWatcher',
      'fetchMembers',
      'handleChange',
      'handleDateChange',
      'validateForm',
      'setFormData',
      'assignTask',
      'formattedWatchersLabel',
      'handleVisibilityChange'
    )
  }

  setFormData(clients) {
    const {
      taskId,
      name,
      description,
      watchersAttributes,
      assigneeId,
      assigneeName,
      assigneeEmail,
      entityTitle,
      clientName,
      clientId,
      dueDate,
      visibility
    } = this.props
    let initialAssignee = []
    let initialWatchers = []
    let formValid = false
    if (taskId) {
      initialAssignee = { label: assigneeName + ' (' + assigneeEmail + ')', id: assigneeId }
      initialWatchers = watchersAttributes.map((watcher) => {
        const current_watcher = clients.find(w => w.id === watcher.user_id)
        return current_watcher
      })
      formValid = true
    }
    const task = {
      id: taskId,
      name,
      description,
      client_id: clientId,
      watchers_attributes: watchersAttributes,
      details: {
        due_date: dueDate,
        assignee_id: assigneeId,
        visibility: visibility || 'None'
      }
    }
    this.setState({
      clientsLoading: false,
      task,
      entityTitle,
      clientName,
      initialAssignee,
      initialWatchers,
      formValid,
      assignee: clients,
      watchers: clients
    })
  }

  componentDidMount() {
    this.fetchMembers()
    $('#taskModal').on('hide.bs.modal', () => { this.props.onModalHide(this.state.taskApiSucceed) })
  }

  setAssignee(assignee) {
    const { task } = this.state
    this.setState({
      task: {
        ...task,
        details: {
          ...task.details,
          assignee_id: assignee.id
        }
      },
    }, () => { this.validateForm() })
  }

  setWatcher(watchers) {
    const { task } = this.state
    const { watchersAttributes } = this.props
    const newWatchers = watchers.map((watcher) => { return { user_id: watcher.id } })
    const watcherAttrs = this.updatedWatchers(newWatchers, watchersAttributes)
    this.setState({
      task: {
        ...task,
        watchers_attributes: watcherAttrs
      },
    }, () => { this.validateForm() })
  }

  fetchMembers() {
    const { assigneeUrl } = this.props
    requestsManager.fetchEntities(assigneeUrl)
      .then((res) => {
        const clients = res.data.users.map((user) => {
          return {
            id: user.id,
            label: user.name + ' (' + user.email + ')'
          }
        })
        this.setFormData(clients)
      })
  }

  handleChange(e) {
    const { task } = this.state
    const name = e.target.name
    const value = e.target.value
    this.setState({
      task: {
        ...task,
        [name]: value
      }
    }, () => { this.validateForm() })
  }

  handleDateChange(date) {
    const { task } = this.state
    this.setState({
      task: {
        ...task,
        details: {
          ...task.details,
          due_date: moment(date).format('YYYY-MM-DD')
        }
      }
    }, () => { this.validateForm() })
  }

  handleVisibilityChange(visibility) {
    const { task } = this.state
    const { details } = task
    this.setState({
      task: {
        ...task,
        details: {
          ...details,
          visibility: visibility
        }
      }
    })
  }

  validateForm() {
    const { name, details } = this.state.task
    const formValid = (name.length > 0 && details.assignee_id != null && details.due_date != null && moment(details.due_date).isValid())
    this.setState({ formValid })
  }

  updatedWatchers(newWatchers, oldWatchers) {
    const newWatcherIds = newWatchers.map(watcher => watcher.user_id)
    const oldWatcherIds = oldWatchers.map(watcher => watcher.user_id)
    // add destroy flag for removed watchers
    const watchers = oldWatchers.map((watcher) => {
      return (newWatcherIds.indexOf(watcher.user_id) === -1) ? { ...watcher, _destroy: true } : watcher
    })
    // add new watchers to watchers list
    newWatchers.map((watcher) => {
      const index = oldWatcherIds.indexOf(watcher.user_id)
      if (index === -1) {
        watchers.push(watcher)
      }
    })
    return watchers
  }

  assignTask() {
    const { task } = this.state
    const { taskableId, taskableType, taskUrl } = this.props
    const url = task.id ? taskUrl : '/tasks.json'
    const requestsManagerFn = task.id ? requestsManager.updateEntity : requestsManager.submitEntity
    const data = task.id ? { task } : { taskable_id: taskableId, taskable_type: taskableType, task }
    this.setState({ formSubmitting: true })
    requestsManagerFn.call({}, url, data)
      .then(() => {
        this.props.addNotification({
          title: task.id ? 'Task Details Updated.' : 'Task Assigned Successfully.',
          level: 'success'
        })
        this.setState({ taskApiSucceed: true, formSubmitting: false }, () => { $('#taskModal').modal('hide') })
      })
      .catch((error) => {
        this.setState({ formSubmitting: false })
        this.props.addNotification({
          title: task.id ? 'Task Updation Failed!' : 'Task Creation Failed!',
          message: error.response.data.errors,
          level: 'error'
        })
      })
  }

  formattedWatchersLabel() {
    const { selectedWatchers } = this.state
    const watchersCount = selectedWatchers.length
    return watchersCount > 0 ? `${watchersCount} Watcher${watchersCount > 1 ? 's' : ''}` : undefined
  }

  render() {
    const {
      task,
      entityTitle,
      clientName,
      assignee,
      watchers,
      formValid,
      clientsLoading,
      initialAssignee,
      initialWatchers,
      formSubmitting,
      formLoaded
    } = this.state
    let actionBtnLabel = task ? task.id ? 'Save' : 'Assign' : ''
    actionBtnLabel = formSubmitting ? 'Submitting...' : actionBtnLabel
    const modalHeading = task ? task.id ? 'Edit Task' : 'Add New Task' : ''
    return (
      <div className="modal-container">
        <div className="modal fade tasks-modal" id="taskModal" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title center-title">{modalHeading}</div>
                <div className="text-gray float-right close" data-dismiss="modal"><i className="fa fa-times" aria-hidden="true" /></div>
              </div>
              <div className="modal-body overflow-visible">
                <React.Fragment>
                  {entityTitle &&
                    <div className="sec-info">{entityTitle} - {clientName}</div>
                  }
                </React.Fragment>
                {clientsLoading ? (
                  <Loader />
                ) : (
                  <div className="cvc-w cvc-w-form">
                    <div className="form-group field">
                      <fieldset>
                        <div className="form-group field">
                          <FormInput
                            type="text"
                            name="name"
                            className="form-control input-normal input-invite"
                            placeholder="Task"
                            value={task.name}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group field">
                          <FormInput
                          type="text"
                          name="description"
                          className="form-control input-normal input-invite"
                          placeholder="Additional Details"
                          value={task.description}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="float-right form-group field col-sm-6 pr-0">
                          <label>Due Date</label>
                          <DatePicker
                            selected={task.details.due_date ? moment(task.details.due_date) : ''}
                            onChange={this.handleDateChange}
                            placeholderText="Select..."
                            dateFormat={this.dateFormat}
                            minDate={moment()}
                            className="form-control input-normal input-invite datepicker"
                          />
                        </div>
                        <div className="form-group field col-sm-6 pl-0">
                          <label>Assignee</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select..."
                            defaultValue={initialAssignee}
                            options={assignee}
                            onChange={this.setAssignee}
                            getOptionLabel ={(option)=>option.label}
                            getOptionValue ={(option)=>option.id}
                          />
                        </div>
                        <div className="form-group field">
                          <label>Watchers</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select..."
                            defaultValue={initialWatchers}
                            options={watchers}
                            isMulti={true}
                            onChange={this.setWatcher}
                            getOptionLabel ={(option)=>option.label}
                            getOptionValue ={(option)=>option.id}
                          />
                        </div>
                        { !this.props.hideVisibility && <div className="form-group field">
                            <FormDropdown
                              value={task.details.visibility}
                              btnClass="dropdown-regular"
                              placeholderText="Visibility"
                              items={visibilityOptions}
                              selectedItems={task.details.visibility}
                              onItemSelect={this.handleVisibilityChange}
                              showLabel
                            />
                          </div>
                        }
                        <Button
                          className={`${formValid ? 'cvc-btn-primary' : 'cvc-btn-primary disabled'} mt-3 mb-3`}
                          onClick={this.assignTask}
                          disabled={!formValid || formSubmitting}
                        > {actionBtnLabel}
                        </Button>
                      </fieldset>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TaskModal.propTypes = {
  taskId: PropTypes.number,
  taskableId: PropTypes.number,
  taskableType: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  dueDate: PropTypes.string,
  watchersAttributes: PropTypes.array,
  assigneeId: PropTypes.number,
  assigneeName: PropTypes.string,
  assigneeEmail: PropTypes.string,
  entityTitle: PropTypes.string,
  clientName: PropTypes.string,
  clientId: PropTypes.number.isRequired,
  assigneeUrl: PropTypes.string.isRequired,
  taskUrl: PropTypes.string,
  onModalHide: PropTypes.func,
  addNotification: PropTypes.func.isRequired
}

TaskModal.defaultProps = {
  name: '',
  description: '',
  dueDate: null,
  taskableId: null,
  taskableType: null,
  entityTitle: '',
  clientName: '',
  taskUrl: '',
  watchersAttributes: [],
  assigneeId: null,
  assigneeName: '',
  assigneeEmail: ''
}

export default TaskModal
