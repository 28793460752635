import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import Linkify from 'linkifyjs/react'

import CommentInput from './CommentInput'

const CommentActions = ({ onSelectComment, onDeleteComment, onOptionsClick }) => {
  return (
    <div className="btn-group float-right option-dropdown notes-options ml-3" onClick={onOptionsClick}>
      <button
        type="button"
        className="dropdown-toggle dropdown-regular"
        id="dropdownMenuButton"
        data-toggle="dropdown"
      >
        <i className="fa fa-ellipsis-v" aria-hidden="true" />
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a
          rel="nofollow"
          className="dropdown-item"
          role="presentation"
          onClick={(e) => { e.stopPropagation(); onSelectComment() }}
        >
          Edit
        </a>
        <a
          rel="nofollow"
          className="dropdown-item"
          role="presentation"
          onClick={(e) => { e.stopPropagation(); onDeleteComment() }}
        >
          Delete
        </a>
      </div>
    </div>
  )
}

const CommentList = ({
  userId,
  comments,
  editing,
  containerClass,
  commentEditActions,
  onSelectComment,
  selectedComment,
  editedCommentContent,
  enableCommentEdit,
  onCommentChange,
  onDeleteComment,
  onEditComment,
  onCancelEditing,
  onOptionsClick
}) => {

  return (
    <div className={containerClass}>
      {
        comments.map(comment =>
          <div className="row mb-2 px-3" key={`comment-${comment.id}`}>
            <div className="col-sm-1 col-2">
              <div className="user-img-circle" data-tip={comment.user_name}>
                {comment.user_name.substring(0, 1)}
              </div>
            </div>
            <div className="col-sm-11 col-10">
              {/* Comment header */}
              <div className="mb-1">
                <div className="d-inline-block">
                  <span className="font-15 mr-2 font-weight-bold">{comment.user_name}</span>
                  {selectedComment !== comment.id && <span className="text-gray font-13">{comment.created_at}</span>}
                </div>
                { selectedComment !== comment.id && comment.can_edit &&
                  <CommentActions
                    onSelectComment={() => onSelectComment(comment.id)}
                    onDeleteComment={() => onDeleteComment(comment.id)}
                    onOptionsClick={onOptionsClick}
                  />
                }
              </div>
              {/* Comment/Edit comment */}
              <div className="w-100">
                {
                  selectedComment === comment.id ?
                  <CommentInput
                    value={editedCommentContent}
                    placeholder="Write the edited comment"
                    onChange={onCommentChange}
                  >
                    <button
                      className={classNames('cvc-btn-primary mr-3 d-inline-block', !enableCommentEdit && 'disabled')}
                      onClick={onEditComment}
                    >
                      Save
                    </button>
                    <button
                      className='cvc-btn-primary d-inline-block'
                      onClick={onCancelEditing}
                    >
                      Cancel
                    </button>
                  </CommentInput>
                    : <p className="font-16 w-75 comment-content"><Linkify options={{ attributes: { target: '_blank' } }}>{comment.content}</Linkify></p>
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default CommentList