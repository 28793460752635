import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

import { FilterPills } from 'core/common/filters'
import { renderData } from 'core/helpers/utils'
import withEventHandler from 'core/components/hocs/withEventHandler'


class LayoutWidget extends PureComponent {
  handleOnRemove = (item) => {
    const {
      widgetFilter,
      removeFilterItem
    } = this.props

    removeFilterItem({
      filter: widgetFilter,
      item
    })
  }

  render() {
    const {
      schema,
      children,
      data = {},
      isSelected,
      disableEvents,
      options,
      hideFilters,
      widgetFilter
    } = this.props
    const { header, style = {} } = schema
    const { layoutContainer = { hideWidgetBorder: true } } = style

    let actions = header && header.actions ? header.actions : schema.actions

    if (actions && !Array.isArray(actions)) {
      actions = [actions]
    }

    return (
      <div className={classNames('cvc-w', 'cvc-w-layout', { 'selected': isSelected }, schema.className)} style={style.styles}>
        <div className={classNames({'events-none': disableEvents})}>
          {header && (
            <div className={classNames('heading-container', header.className)}>
              <div className={classNames('d-inline-block align-middle')}>
                {(header.title && header.title.type === 'COMPONENT') ? renderData(header.title, data.header.title) : (header.title || data.title)}
              </div>
              {actions && actions.length > 0 && (
                <div className="d-inline-block float-right clearfix more-option">
                  {actions.map((action) => {
                    if (action.component.name === 'Action' && data.actions && data.actions[action.component.schema.id]) {
                      return (
                        <div key={shortid.generate()} className="d-inline-block">
                          {renderData(action, data.actions, options)}
                        </div>
                      )
                    } if (action.component.name === 'ActionDropdown' || action.component.name === 'ActionList') {
                      return (
                        <div key={shortid.generate()} className="d-inline-block">
                          {renderData(action, data.actions, options)}
                        </div>
                      )
                    } if (action.component.name === 'Action' && action.component.schema.type === 'FILTER') {
                      return (
                        <div key={shortid.generate()} className="d-inline-block">
                          {renderData(action, data.actions, options)}
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              )}
            </div>
          )}
          {!hideFilters && widgetFilter &&
            <FilterPills
              onRemove={this.handleOnRemove}
              filters={widgetFilter}
            />
          }
          <div className={classNames('layout-container', {
            'hide-widget-border': layoutContainer.hideWidgetBorder
          }, layoutContainer.className )}>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

LayoutWidget.propTypes = {
  schema: PropTypes.shape({
    header: PropTypes.object,
    renderer: PropTypes.any
  }),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.object,
  isSelected: PropTypes.bool
}
export default withEventHandler(LayoutWidget)
