import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEqual, isEmpty } from 'lodash/fp/'
import { debounce } from 'throttle-debounce'
import requestsManager from 'libs/api/requestsManager'
import widgetsMap from 'core/helpers/widgetsMap'

import { updateQueryStringParams, renderData } from 'core/helpers/utils'
import Loader from 'libs/components/Loader'

class Autocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchVal: '',
      data: null,
      dataLoading: false,
      prevData: {}
    }
    this.handleOnChange = debounce(500, this.handleOnChange)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if ((!prevState.data && !isEmpty(nextProps.data)) || !isEqual(prevState.prevData, nextProps.data)) {
      return {
        data: {
          widgetData: nextProps.data
        },
        prevData: nextProps.data
      }
    }
    return null
  }

  linkResponseHandler = (results) => {
    const { schema } = this.props
    const { events } = schema
    const { id, type } = events.onClick
    const urlKey = type === 'API' ? 'apiUrl' : 'linkUrl'
    const response = results.map(c => ({
      label: c.label,
      events: {
        [id]: {
          [urlKey]: c.url.path,
          apiPayload: c.apiPayload
        }
      }
    }))
    return response
  }


  eventResponseHandler = (results) => {
    // TODO: Define responseHandler based on event.type
    // if (event.type === 'MODAL') return this.modalResponseHandler(results)
    return this.linkResponseHandler(results)
  }

  handleOnChange = (newSearchTerm) => {
    const {
      widgetGroupId,
      widgetId,
      schema,
      dataUrl,
      widgetFilter = {},
      pageFilter = {},
      ignorePageFilter,
      fetchWidgetData,
      dispatch
    } = this.props
    const { queryParamName } = schema

    this.setState({ searchVal: newSearchTerm, dataLoading: true }, () => {
      const { searchVal } = this.state
      const autoCompleteDataUrl = updateQueryStringParams(
        dataUrl,
        {
          filter: {...pageFilter.data, ...widgetFilter.data},
          widget_group_id: widgetGroupId,
          widget_id: widgetId,
          name: newSearchTerm || ''
        }
      )

      if (searchVal === '') {
        requestsManager.fetchEntities(autoCompleteDataUrl).then((res) => {
          this.setState({ dataLoading: false, data: res.data })
        })
      } else {
        requestsManager.fetchEntities(autoCompleteDataUrl).then((res) => {
          const { widgetData } = res.data
          const autocompleteData = this.eventResponseHandler(widgetData.table)
          this.setState({
            dataLoading: false,
            data: {
              widgetData: {
                table: autocompleteData
              }
            }
          })
        })
      }
    })
  }

  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div role="presentation" className={classNames('heading-container', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  render() {
    const { data, dataLoading, searchVal } = this.state
    const { schema, widgetGroupId, dispatch } = this.props
    const { heading, style, listSchema } = schema
    const WidgetToRender = widgetsMap[listSchema.type]
    return (
      <div className={classNames('cvc-w cvc-w-autocomplete', style.container)}>
        {heading && this.renderHeading()}
        <div className={style.placeholderContainer}>
          <input
            type="text"
            placeholder={schema.placeholder}
            className={style.autocomplete}
            onChange={e => this.handleOnChange(e.target.value)}
          />
        </div>
        {schema.hideEmptySearchResults && searchVal.length == 0 ? null :
          <React.Fragment>
            {data && data.widgetData && !dataLoading
              ? (
                <WidgetToRender
                  dispatch={dispatch}
                  schema={listSchema.schema}
                  data={data.widgetData}
                  dataUrl={''}
                  widgetGroupId={widgetGroupId}
                  widgetId={listSchema.id}
                />
              )
              : <Loader className="inline-loader text-gray" />
            }
          </React.Fragment>
        }
      </div>
    )
  }
}

Autocomplete.propTypes = {
  dataUrl: PropTypes.string,
  widgetGroupId: PropTypes.string,
  schema: PropTypes.shape({
    heading: PropTypes.object,
    style: PropTypes.object,
    listSchema: PropTypes.object,
    events: PropTypes.object
  }).isRequired
}
export default Autocomplete
