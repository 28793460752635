import {
  SET_WIDGET_GROUPS,
  SHOW_WIDGET_GROUP
} from '../constants/widgetGroupConstants'
import {
  convertArrayToHash,
  getWidgetPersistedProps,
  inflateData,
  inflateSchema
} from '../../helpers/utils'

export const setWidgetGroups = (parentId, widgetGroups, parentWidgetGroupId, parentWidgetType) => {
  return (disptach, getState) => {
    const appState = getState()
    const widgetsObj = widgetGroups.reduce((map, obj) => {
      const widgets = obj.widgets.map((widget) => {
        let persisted = {}
        let widgetData = widget.data
        let widgetSchema = widget.schema
        if (widget && widget.type) {
          persisted = getWidgetPersistedProps({
            widgetId: widget.id,
            widgetGroupId: obj.id,
            widgetType: widget.type,
            appState
          })
          widgetData = inflateData({
            data: widgetData,
            widgetGroupId: obj.id,
            widgetId: widget.id,
            widgetType: widget.type,
            appState
          })
          if (widget.schema) {
            widgetSchema = inflateSchema({
              schema: widget.schema,
              widgetGroupId: obj.id,
              widgetId: widget.id,
              widgetType: widget.type,
              appState
            })
          }
        }

        return {
          ...widget,
          ...persisted,
          schema: widgetSchema,
          data: widgetData,
          loadingSchema: !widget.schema,
          loadingData: !widget.data,
          updatedAt: Math.random(),
          stamp: Math.random()
        }
      })

      map[obj.id] = convertArrayToHash(widgets)
      return map
    }, {})

    return disptach({
      type: SET_WIDGET_GROUPS,
      parentId,
      widgetGroups,
      widgets: widgetsObj
    })
  }
}

export const showWidgetGroup = (parentId, widgetGroupId, show = true) => {
  return {
    type: SHOW_WIDGET_GROUP,
    parentId,
    widgetGroupId,
    show
  }
}
