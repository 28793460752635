import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

import { renderData } from '../helpers/utils'

// TODO: This component should be converted into a Typography component
const Text = ({ schema, data }) => {
  const defaultSchema = {
    style: {
      className: '',
      containerClassName: 'break-word',
    }
  }
  const style = { ...schema.style, ...data.style }
  const textSchema = schema.text
  const { text, title } = data

  if (textSchema && textSchema.html) {
    return (
      <div
        title={title}
        style={data.styles}
        className={classNames(style.containerClassName || defaultSchema.style.containerClassName)}
      >
        <p
          key={shortid.generate()}
          className={classNames(style.className || defaultSchema.style.className)}
          dangerouslySetInnerHTML={{__html: text}}
        />
      </div>
    )
  }

  if (textSchema && textSchema.dataType === 'MARKDOWN') {
    return (
      <div
        title={title}
        style={data.styles}
        className={classNames(style.containerClassName || defaultSchema.style.containerClassName)}
      >
        <p
          key={shortid.generate()}
          className={classNames(style.className || defaultSchema.style.className)}
          dangerouslySetInnerHTML={{__html: renderData(textSchema, text)}}
        />
      </div>
    )
  }

  return (
    <div
      title={title}
      style={data.styles}
      className={classNames(style.containerClassName || defaultSchema.style.containerClassName)}
    >
      {
        text && text.split('\n').map((line) => {
          return (
            <p
              key={shortid.generate()}
              className={classNames(style.className || defaultSchema.style.className || 'mb-0')}
              style={style.styles}
              dangerouslySetInnerHTML={{__html: line && textSchema ? renderData(textSchema, line.trim()) : line}}
            />
          )
        })
      }
    </div>
  )
}

Text.propTypes = {
  schema: PropTypes.shape({
    className: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    text: PropTypes.string
  }).isRequired
}

export default Text
