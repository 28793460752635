import React, { Component } from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import classNames from "classnames"
import moment from "moment"
import { isEqual } from "lodash"
import { renderData } from "core/helpers/utils"
import { combineProps } from "core/helpers/utils"
import Calendar, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline"

import "react-calendar-timeline/lib/Timeline.css"

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "rowId",
  itemTimeStartKey: "start_time",
  itemTimeEndKey: "end_time",
  groupLabelKey: "title",
}

class CalendarTimeline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultSchema: {
        style: {
          header: {
            left: {},
            right: {},
          },
          sidebar: {
            left: {},
            right: {}
          }
        },
        header: {
          left: {},
          right: {},
          primary: {},
        },
        sidebar: {
          left: {},
          right: {},
        },
      },
      refreshing: false
    }
  }

  get defaultSchema() {
    return this.state.defaultSchema
  }

  get leftSidebar() {
    const { schema } = this.props
    const sidebar = (schema.timeline && schema.timeline.sidebar) || {}
    return combineProps(this.defaultSchema.sidebar.left, sidebar.left)
  }

  get rightSidebar() {
    const { schema } = this.props
    const sidebar = (schema.timeline && schema.timeline.sidebar) || {}
    return combineProps(this.defaultSchema.sidebar.right, sidebar.right)
  }

  get leftSidebarStyle() {
    const { schema } = this.props
    const sidebar = (schema.style && schema.style.sidebar) || {}
    return combineProps(this.defaultSchema.style.sidebar.left, sidebar.left)
  }

  get rightSidebarStyle() {
    const { schema } = this.props
    const sidebar = (schema.style && schema.style.sidebar) || {}
    return combineProps(this.defaultSchema.style.sidebar.right, sidebar.right)
  }

  get leftHeader() {
    const { schema } = this.props
    const header = (schema.timeline && schema.timeline.header) || {}
    return combineProps(this.defaultSchema.header.left, header.left)
  }

  get rightHeader() {
    const { schema } = this.props
    const header = (schema.timeline && schema.timeline.header) || {}
    return combineProps(this.defaultSchema.header.right, header.right)
  }

  get leftHeaderStyle() {
    const { schema } = this.props
    const header = (schema.style && schema.style.header) || {}
    return combineProps(this.defaultSchema.style.header.left, header.left)
  }

  get rightHeaderStyle() {
    const { schema } = this.props
    const header = (schema.style && schema.style.header) || {}
    return combineProps(this.defaultSchema.style.header.right, header.right)
  }

  get primaryHeader() {
    const { schema } = this.props
    const header = (schema.timeline && schema.timeline.header) || {}
    return combineProps(this.defaultSchema.header.primary, header.primary)
  }

  componentDidUpdate(prevProps, prevState) {
    const { schema: prevSchema } = prevProps
    const { schema } = this.props
    if (!isEqual(prevSchema.timeline, schema.timeline)) {
      this.setState({ refreshing: true })
      const self = this;
      setTimeout(() => {
        self.setState({ refreshing: false })
      }, 0)
    }
  }

  getColumn = (sidebarStyle, property) => {
    const columns =
      sidebarStyle && sidebarStyle.columns ? sidebarStyle.columns : []

    return columns.find((styleObj) => styleObj.id === property.id)
  }

  getColumnWidth = (sidebar, sidebarStyle, property) => {
    const column = this.getColumn(sidebarStyle, property)
    const columnWidth = column ? column.width : 100 / sidebar.properties.length
    return columnWidth
  }

  renderRow = ({ group, isRightSidebar }) => {
    const { data = {} } = this.props
    const sidebar = isRightSidebar ? this.rightSidebar : this.leftSidebar
    const sidebarStyle = isRightSidebar ? this.rightSidebarStyle : this.leftSidebarStyle
    const sidebarData = group.sidebar || {}

    return (
      <div
        key={shortid.generate()}
        className={classNames(
          'timeline-row tr', sidebarStyle.className
        )}
      >
        {sidebar.properties ? (
          sidebar.properties.map((property, index) => {
            const propertyStyle = property.style || {}
            const data = sidebarData || {}
            const column = this.getColumn(sidebarStyle, property)
            const columnWidth = this.getColumnWidth(sidebar, sidebarStyle, property)
            return (
              <div
                key={shortid.generate()}
                className={classNames('td', column ? column.className : '')}
                style={{ width: `${columnWidth}%` }}
              >
                <div className="timeline-data-wrapper">
                  {renderData(property, data[property.id])}
                </div>
              </div>
            )
          })
        ) : (
          <span className="group-title">{group.title}</span>
        )}
      </div>
    )
  }

  renderItem = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { schema, data = {} } = this.props
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

    const itemSchema = schema.timeline.itemTypes
      ? schema.timeline.itemTypes[item.itemProps.type]
      : {}
    const itemProps = getItemProps(item.itemProps) || {}
    return (
      <div
        key={shortid.generate()}
        {...itemProps}
        className={classNames('timeline-item', itemProps.className)}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {renderData(itemSchema, item.itemProps.data)}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    )
  }

  renderLeftHeader() {
    return (
      <SidebarHeader {...this.leftHeader} variant="left">
        {({ getRootProps }) => {
          return (
            <div
              className={classNames(
                'tr table-header',
                this.leftHeaderStyle.className
              )}
              {...getRootProps()}
            >
              {this.leftSidebar.properties &&
                this.leftSidebar.properties.map((property, index) => {
                  const columnWidth = this.getColumnWidth(
                    this.leftSidebar,
                    this.leftSidebarStyle,
                    property
                  )
                  return (
                    <div
                      key={shortid.generate()}
                      className={"th"}
                      style={{ width: `${columnWidth}%` }}
                      title={property.hoverText}
                    >
                      <span>{property.title}</span>
                    </div>
                  )
                })}
            </div>
          )
        }}
      </SidebarHeader>
    )
  }

  renderRightHeader() {
    return (
      <SidebarHeader {...this.rightHeader} variant="right">
        {({ getRootProps }) => {
          return (
            <div
              className={classNames(
                'tr table-header',
                this.rightHeaderStyle.className
              )}
              {...getRootProps()}
            >
              {this.rightSidebar.properties &&
                this.rightSidebar.properties.map((property, index) => {
                  const columnWidth = this.getColumnWidth(
                    this.rightSidebar,
                    this.rightSidebarStyle,
                    property
                  )
                  return (
                    <div
                      key={shortid.generate()}
                      className={"th"}
                      style={{ width: `${columnWidth}%` }}
                      title={property.hoverText}
                    >
                      <span>{property.title}</span>
                    </div>
                  )
                })}
            </div>
          )
        }}
      </SidebarHeader>
    )
  }

  renderHeaders = () => {
    return (
      <TimelineHeaders className="sticky">
        {this.renderLeftHeader()}
        <DateHeader
          {...this.primaryHeader}
          intervalRenderer={({ intervalContext, getIntervalProps, ...rest }) => {
              const label = this.primaryHeader ? this.primaryHeader.label : {}

              return (
                <span
                  {...getIntervalProps()}
                  {...label}
                  onClick={e => e.stopPropagation()}
                >
                  {intervalContext.intervalText}
                </span>
              )
            }
          }
        />
        {this.renderRightHeader()}
      </TimelineHeaders>
    )
  }

  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data = {} } = this.props

    return (
      <div
        role="presentation"
        className={classNames('heading-container', heading.containerClassName)}
        {...heading.tooltip}
      >
        {heading && heading.title && (
          <div
            className={classNames(
              "d-inline-block align-middle",
              heading.className
            )}
          >
            {renderData(
              heading.title,
              heading.title.type === "COMPONENT"
                ? data.heading.title
                : heading.title
            )}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          {heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions)}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  renderFooter() {
    const { footer = {} } = this.props.schema
    const { data = {} } = this.props

    const legends = footer.legends || {}
    return (
      <div
        className={classNames('footer-container', footer.containerClassName)}
      >
        {legends && (
          <div
            className={classNames(
              'timeline-legends',
              legends.containerClassName
            )}
          >
            {legends.items &&
              legends.items.map((legend) => (
                <div
                  key={shortid.generate()}
                  className={classNames('timeline-legend', legends.className)}
                >
                  {legend.icons.map((icon) => {
                    return (
                      <div
                        key={shortid.generate()}
                        className={classNames(
                          'd-inline-block legend-icon mx-1',
                          icon
                        )}
                      />
                    )
                  })}
                  <span className={classNames('legend-text mr-2 font-12')}>
                    {legend.text}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
  render() {
    const { schema, data = {} } = this.props
    const { heading, style = {} } = schema
    const timeline = schema.timeline || {}

    const defaultInterval = timeline.defaultTimeEnd - timeline.defaultTimeStart
    // adding extra interval to resolve scroll issues when scrolling through scrollbar.
    // Scrollbar is part of timeline without header which causes the header to froze after min/max time limit.
    let minTime, maxTime;
    if (timeline.minTime) {
      minTime = !timeline.fixed ? timeline.minTime - defaultInterval : timeline.minTime
    }
    if (timeline.maxTime) {
      maxTime = !timeline.fixed ? timeline.maxTime + defaultInterval : timeline.maxTime
    }
    const month = 60 * 60 * 1000 * 24 * 30

    return (
      <div className={classNames('cvc-w', 'cvc-w-calendar-timeline', style.className)}>
        {this.renderHeading()}
        <div
          className={classNames('timeline-container', style.containerClassName)}
        >
          {!this.state.refreshing && <Calendar
            groups={data.rows || []}
            items={data.items || []}
            keys={keys}
            // https://codesandbox.io/s/timeline-disable-zoom-b9mc6?file=/CustomTimeline.jsx
            defaultTimeStart={moment(timeline.defaultTimeStart)}
            defaultTimeEnd={moment(timeline.defaultTimeEnd)}
            maxZoom={schema.maxZoom || 12 * month}
            minZoom={schema.minZoom || 3 * month}
            sidebarWidth={this.leftSidebar.width || 0}
            rightSidebarWidth={this.rightSidebar.width || 0}
            groupRenderer={this.renderRow}
            itemRenderer={this.renderItem}
            canMove={false}
            canChangeGroup={false}
            canResize={false}
            traditionalZoom={true}
            onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
              if ((minTime && maxTime) && visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
                updateScrollCanvas(minTime, maxTime)
              } else if (minTime && visibleTimeStart < minTime) {
                updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
              } else if (maxTime && visibleTimeEnd > maxTime) {
                updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
              } else {
                updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
              }
            }}
          >
            {this.renderHeaders()}
          </Calendar>}
        </div>
        {this.renderFooter()}
      </div>
    )
  }
}

CalendarTimeline.propTypes = {
  schema: PropTypes.shape({
    heading: PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
    }),
    timeline: PropTypes.shape({
      defaultTimeStart: PropTypes.number.isRequired,
      defaultTimeEnd: PropTypes.number.isRequired,
    }),
    style: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
}

export default CalendarTimeline
