import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Action from './Action'

class SelectorList extends React.PureComponent {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    const { schema, data, dispatch } = this.props
    const { heading = {}, style = {}, action } = schema
    const { items, selectedItems } = data
    return (
      <div className={classNames('cvc-c-selector-list', style.containerClassName)}>
        <div className={classNames(style.className)}>
          <p className="list-label">
            {heading.title}
          </p>
          <React.Fragment>
            {items.map((item) => {
              const isSelected = (selectedItems.indexOf(item.value) != -1)
              const actionSchema = {
                style: {
                  className: classNames('list-item', { 'selected': isSelected }),
                  iconClassName: 'list-selected fa fa-check'
                },
                ...action
              }
              return <Action
                key={`list-item-${item.value}`}
                schema={actionSchema}
                data={item}
                dispatch={dispatch}
              />
            })
            }
          </React.Fragment>
        </div>
      </div>
    )
  }
}

SelectorList.propTypes = {
  schema: PropTypes.shape({
    label: PropTypes.string
  }),
  data: PropTypes.object.isRequired
}

export default SelectorList
