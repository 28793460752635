import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class NoAccess extends React.Component {
  render() {
    const { schema } = this.props
    const { style = {}, heading } = schema
    return (
      <div className={classNames('cvc-w', 'cvc-w-no-access', style.containerClassName)}>
        {(heading && heading.title) && (
          <div className={classNames('heading-container', heading.className)}>
            {heading.title}
          </div>
        )}
        <div className="py-3">
          <div className="no-access-icon" />
          <p className="small-gray text-center">No Access</p>
        </div>
      </div>
    )
  }
}

NoAccess.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  })
}

export default NoAccess
