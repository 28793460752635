import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import { useDispatch } from 'react-redux'
import Action from './Action'

const ActionList = (props) => {
  const { schema, data, options } = props
  const dispatch = useDispatch()
  const defaultSchema = {
    style: {
      'containerClassName': 'd-flex',
      'className': 'd-flex'
    },
    actionItems: []
  }
  const actionItems = schema.actionItems || defaultSchema.actionItems
  const style = schema.style || {}
  return (
    <div className={classNames(style.containerClassName || defaultSchema.style.containerClassName)}>
      {actionItems.map((action, index) => {
        const isLastAction = actionItems.length - 1 === index
        const margin = !isLastAction ? 'mr-2' : ''
        const actionStyle = action.style || {}
        return (
          <div className={classNames(actionStyle.containerClassName || style.className || defaultSchema.style.className, margin)} key={shortid.generate()}>
            <Action
              schema={action}
              data={data}
              options={options}
              dispatch={dispatch}
            />
          </div>
        )
      })
      }
    </div>
  )
}

ActionList.propTypes = {
  schema: PropTypes.shape({
    actionItems: PropTypes.array
  }).isRequired,
  data: PropTypes.object.isRequired
}

export default ActionList
