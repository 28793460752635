import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import debounce from 'lodash.debounce'

import requestsManager from 'libs/api/requestsManager'
import Autosuggest from 'react-autosuggest'
import Loader from 'libs/components/Loader'
import { cleanObject } from 'libs/utils'

class AutoComplete extends React.Component {
  constructor() {
    super()
    this.state = {
      value: '',
      suggestionLoading: false,
      suggestions: [],
      lastSelectedSuggestion: '',
      initialSuggestions: [],
      noSuggestions: false,
      lastSearchedQuery: ''
    }
    bindAll(this,
      'onChangeHandler',
      'onBlurHandler',
      'onFetchData',
      'renderInputComponent',
      'onSuggestionsClearRequested',
      'renderSuggestionsContainer',
      'getSuggestionValue',
      'getApiUrl'
    )
    this.debouncedFetchData = debounce(({ value }) => {
      return (this.onFetchData({ value }))
    }, 1000)
  }

  componentDidMount() {
    const { selectedValue } = this.props
    this.setState({ value: selectedValue, lastSelectedSuggestion: selectedValue, lastSearchedQuery: selectedValue })
  }

  onChangeHandler(event, { newValue }) {
    this.setState({ value: newValue })
  }

  onBlurHandler() {
    const inputVal = this.state.lastSelectedSuggestion || ''
    this.setState({ value: inputVal, noSuggestions: false })
  }

  onFetchData({ value }) {
    const { initialSuggestions } = this.state
    if (!value && initialSuggestions.length !== 0) {
      // prevent unnecessary calls when value is empty
      this.setState({ suggestions: initialSuggestions, lastSearchedQuery: '', noSuggestions: false })
      return false
    }
    this.setState({ suggestionLoading: true })
    requestsManager.fetchEntities(this.getApiUrl(value))
      .then((res) => {
        const suggestions = this.props.respDataFormatter(res.data)
        const isInputBlank = value.trim() === ''
        const noSuggestions = !isInputBlank && suggestions.length === 0
        this.setState({
          suggestions,
          initialSuggestions: value === '' ? suggestions : initialSuggestions,
          lastSearchedQuery: value,
          suggestionLoading: false,
          noSuggestions
        })
      })
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
      lastSearchedQuery: ''
    })
  }

  getSuggestionValue(suggestion) {
    const { valueKey } = this.props
    return suggestion[valueKey]
  }

  getApiUrl(value) {
    const { api, queryParamKey } = this.props
    let url = api
    const params = {
      [queryParamKey]: value
    }
    cleanObject(params)
    if (Object.keys(params).length > 0) {
      url += `${url.indexOf('?') >= 0 ? '&' : '?'}${$.param(params)}`
    }
    return url
  }

  renderInputComponent(inputProps) {
    const { value, noSuggestions, lastSearchedQuery } = this.state
    const { showLabel, placeholder } = this.props
    return (
      <div className={showLabel ? 'input-wrapper' : ''}>
        {showLabel && value.length > 0 &&
          <span className="sec-label">{placeholder}</span>
        }
        <input {...inputProps} />
        {noSuggestions &&
          <div className="no-suggestions">
            No results found for {`'${lastSearchedQuery}'`}
          </div>
        }
      </div>
    )
  }

  renderSuggestionsContainer({ containerProps, children }) {
    const { suggestionLoading, lastSearchedQuery } = this.state
    return (
      <div {... containerProps}>
        {suggestionLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            {lastSearchedQuery &&
              <p className="dropdown-label"> Showing results for {`'${lastSearchedQuery}'`} </p>
            }
            {children}
          </React.Fragment>)
        }
      </div>
    )
  }

  render() {
    const { value, suggestions } = this.state
    const { placeholder, className } = this.props
    const inputProps = {
      placeholder,
      value,
      className,
      onChange: this.onChangeHandler,
      onBlur: this.onBlurHandler
    }
    return (
      <React.Fragment>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.debouncedFetchData}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={suggestion => <div> {this.getSuggestionValue(suggestion)} </div>}
          onSuggestionSelected={(e, { suggestion }) => { this.setState({ lastSelectedSuggestion: this.getSuggestionValue(suggestion) }, this.props.onSuggestionSelected(suggestion)) }}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          shouldRenderSuggestions={() => { return true }}
          renderInputComponent={this.renderInputComponent}
          inputProps={inputProps}
        />
      </React.Fragment>
    )
  }
}

AutoComplete.propTypes = {
  api: PropTypes.string.isRequired,
  queryParamKey: PropTypes.string.isRequired,
  respDataFormatter: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  showLabel: PropTypes.bool,
  selectedValue: PropTypes.string,
  onSuggestionSelected: PropTypes.func
}

AutoComplete.defaultProps = {
  className: 'form-control',
  valueKey: 'name',
  placeholder: 'Search...',
  selectedValue: '',
  showLabel: false
}

export default AutoComplete
