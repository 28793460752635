import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import moment from 'moment'
import imagesMap from 'core/helpers/imagesMap'

const TaskDetail = ({ title, description, creator, createdAt, assigneeName, assigneePhoto, watchers, type, link, parentLink, parentLinkTitle }) => {
  const dateFormat = window.CC_MOMENT_JS_DATE_FORMAT
  const watcherStyle = {
    backgroundImage: `url(${assigneePhoto})`,
    width: '30px',
    height: '30px'
  }
  const assigneeSpanName = assigneeName ? assigneeName.split(' ')[0] : null
  const assigneeInitials = assigneeName ? assigneeName.substring(0, 1) : null
  watchers = watchers || []
  return (
    <div className="notes-content d-block">
      <div className="notes">
        <span className="txt-bolder d-inline-block">{title}</span>
        <React.Fragment>
          <p className="desp p-0">
            {description}
          </p>
          <p className="text-gray">{creator} created task  {moment(createdAt).format(`${dateFormat}, h:mm A`)}</p>
          <div className="d-inline-block mt-2" key={shortid.generate()}>
            {watchers.map((watcher) => {
              const style = {
                backgroundImage: `url(${watcher.user_photo})`,
                width: '30px',
                height: '30px',
                backgroundSize: 'cover'
               }
              const watcherInitials = watcher.user_name.substring(0, 1)
              const watcherSpanName = watcher.user_name.split(' ')[0]
              if (!watcher.user_photo) {
                return (
                  <div
                    className="user-img-circle mr-2 d-inline-block"
                    key={shortid.generate()}
                    data-tip={`${watcherSpanName} is the watcher`}
                  >
                    {watcherInitials}
                  </div>
                )
              }
              return (
                <div
                  className="mr-2 d-inline-block"
                  key={shortid.generate()}
                  data-tip={`${watcherSpanName} is the watcher`}
                >
                  <div className="circular-image" style={style} />
                </div>
              )
            })}
            {assigneePhoto &&
              <div
                className="d-inline-block"
                data-tip={`Assigned to ${assigneeSpanName}`}
              >
                <div className="circular-image" style={watcherStyle} />
              </div>
            }
            {!assigneePhoto && assigneeInitials && assigneeSpanName &&
              <div
                className="user-img-circle d-inline-block"
                data-tip={`Assigned to ${assigneeSpanName}`}
              >
                {assigneeInitials}
              </div>
            }
          </div>
          {parentLink && <p className="desp pt-3">
            <a href={parentLink} target="_blank">
              <img src={imagesMap['link-gray.svg']} className="external-task-link" /> {parentLinkTitle || parentLink}
            </a>
          </p>}
        </React.Fragment>
      </div>
    </div>
  )
}

TaskDetail.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  creator: PropTypes.string,
  createdAt: PropTypes.string,
  assigneeName: PropTypes.string,
  assigneePhoto: PropTypes.string,
  watchers: PropTypes.array
}

export default TaskDetail
