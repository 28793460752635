import React, { Component, useEffect, useState, useRef, useLayoutEffect, useMemo } from "react"
import { renderData } from 'core/helpers/utils'
import classNames from 'classnames'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import moment from 'moment'
import Select from 'react-select'
import isEqual from 'lodash/isEqual'
import { default as ReactDatePicker } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const EditableCell = ({
  property,
  cellData,
  options,
  row,
  cell
}) => {

  const isEditing = row.state.editing
  function updateCellState(state) {
    cell.setState({
      ...cell.state,
      updated: state
    })
  }

  if (isEditing && property.editable) {
    let editableEl = (
      <input
        value={cell.state.updated}
        onChange={(e) => updateCellState(e.target.value)}
        style={{
          width: '100%',
          border: '1px solid #cdced2',
          height: 25,
        }}
      />
    )

    if (property.editable.type === 'TEXTAREA') {
      editableEl = <textarea
        value={cell.state.updated}
        onChange={(e) => updateCellState(e.target.value)}
        style={{
          width: '100%',
          border: '1px solid #cdced2',
          height: 25,
        }}
      />
    }

    if (property.editable.type === 'DATE_PICKER') {
      const dateFormat = window.momentjsDateFormat
      editableEl = <ReactDatePicker
        selected={cell.state.updated ? moment(cell.state.updated) : null}
        onChange={(date) => { updateCellState(date ? moment(date).format('YYYY-MM-DD') : undefined) }}
        dateFormat={dateFormat}
        className="editable-cell-datepicker"
      />
    }

    if (property.editable.type === 'DROPDOWN') {
      const [isMenuOpen, setIsMenuOpen] = useState(false)
      const onInputChange = (options, { action }) => {
        if (action === "menu-close") {
          setIsMenuOpen(false)
        }
      }
      const customStyles = {
        control: () => ({
          width: '100%',
          height: 25,
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #cdced2'
        }),
        menu: (provided, state) => ({
          ...provided,
          width: 200,
        }),
        singleValue: (provided) => ({
          ...provided,
          minWidth: 10
        })
      }

      editableEl = (<Select
        styles={customStyles}
        options={property.editable.options}
        value={cell.state.updated}
        onChange={(value) => {
          updateCellState(value)
        }}
        menuPosition="fixed"
        menuPlacement="auto"
        openMenuOnFocus={true}
        // menuIsOpen={true}
      />)
    }

    return editableEl
  } else {
    if (!isEqual(cell.state.updated, cell.state.original)) {
      updateCellState(cell.state.original)
    }
  }

  return (
    <React.Fragment>{renderData(property, cellData, options)}</React.Fragment>
  )
}