import React from 'react'
import {connect} from 'react-redux';
import { values, find, findKey } from 'lodash/fp'
import { orderBy } from 'lodash'
/* eslint-disable import/no-unresolved */
import componentsMap from './componentsMap'
import widgetsMap from './widgetsMap'
import getFormatter from './formatters'
import { store } from '../redux/store/appStore'

const { Error } = componentsMap

function getWidget({ widgetGroupId, widgetId, appState }) {
  if (!appState) {
    throw 'appState is required to get stored widget.'
  }
  const widgets = appState.widget
  if (widgetId && widgets) {
    const widgetGroup = widgets[widgetGroupId]
    const allWidgets = values(widgetGroup)
    return find((widget) => {
      return widget.id === widgetId
    }, allWidgets)
  }
}

// Get widget props from stored widget which needs to be persisted
// after the refresh.
// Requires static method `getPersistedProps` in respective components
function getWidgetPersistedProps({ widgetId, widgetGroupId, widgetType, appState }) {
  let persisted = {}

  if (!widgetType) {
    return persisted
  }

  const WidgetClass = widgetsMap[widgetType]
  if (WidgetClass && WidgetClass.getPersistedProps) {
    const storedWidget = getWidget({ widgetGroupId, widgetId, appState })

    if (storedWidget) {
      persisted = WidgetClass.getPersistedProps(storedWidget)
    }
  }

  return persisted
}

function inflateWidgetGroups({ schema, widgetGroupId, widgetId, widgetType, appState }) {
  const WidgetClass = widgetsMap[widgetType]
  if (WidgetClass && WidgetClass.inflateWidgetGroups) {
    return WidgetClass.inflateWidgetGroups({ schema, widgetGroupId, widgetId, appState })
  }

  return schema.widgetGroups
}

function inflateData({ data, widgetGroupId, widgetId, widgetType, appState }) {
  if (!data) return data

  const WidgetClass = widgetsMap[widgetType]
  if (WidgetClass && WidgetClass.inflateData) {
    return WidgetClass.inflateData({ data, widgetGroupId, widgetId, appState })
  }

  return data
}

function inflateSchema({ schema, widgetGroupId, widgetId, widgetType, appState }) {
  if (!schema) return schema

  const WidgetClass = widgetsMap[widgetType]
  if (WidgetClass && WidgetClass.inflateSchema) {
    return WidgetClass.inflateSchema({ schema, widgetGroupId, widgetId, appState })
  }

  return schema
}

function renderComponent(property, data = {}, options = {}) {
  const ComponentToRender = componentsMap[property.component.name]
  const hideComponent = data.hide || property.hide

  if (!ComponentToRender) {
    return <Error error={`Component ${property.component.name} not found.`} />
  }

  // we need `disptach`` prop in Action
  // if you are planning to add mapDispatchProps
  // specificially add disptach like in widgetContainer
  // https://react-redux.js.org/using-react-redux/connect-mapdispatch#common-problems
  const ConnectedComponent = connect()(ComponentToRender)
  const componentProps = {
    schema: property.component.schema || {},
    data: property.component.data || data,
    options
  }

  return !hideComponent ? <ConnectedComponent {...componentProps} /> : null
}

function renderFormattedData(property, data) {
  if (data == null) {
    return property.nullValue || '-'
  }
  const formatter = getFormatter(property, data)
  if (property && property.dataType && data != null) {
    return formatter(property, data)
  }
  return data
}


function renderData(property, data, options) {
  if (!property) {
    return <Error error="Property not passed to method:renderData" />
  }
  if (property.type === 'COMPONENT') {
    return renderComponent(property, data, options)
  }
  return renderFormattedData(property, data)
}

function getPropertySchema(properties, propKey) {
  return properties.find(property => property.id === propKey) || {}
}

function convertArrayToHash(arr) {
  return arr.reduce((map, obj) => {
    map[obj.id] = obj
    return map
  }, {})
}

function combineProps(props1, props2) {
  return { ...props1, ...props2 }
}

function cleanObject(obj) {
  const properties = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < properties.length; i += 1) {
    const prop = properties[i]
    if (obj[prop] && typeof obj[prop] === 'object') {
      cleanObject(obj[prop])
    }
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '' || (typeof obj[prop] === 'object' && Object.keys(obj[prop]).length === 0)) {
      delete obj[prop]
    }
  }
}

function updateQueryStringParams(url, params) {
  cleanObject(params)
  let urlStr = url
  if (Object.keys(params).length > 0) {
    urlStr += `${url.indexOf('?') >= 0 ? '&' : '?'}${$.param(params)}`
  }
  return urlStr
}

function dragDropDisabler(e) {
  const disabledElements = ['input', 'textarea', 'select', 'option', 'button', 'i']
  if (disabledElements.indexOf(e.target.tagName.toLowerCase()) !== -1) {
    return true
  }
  const path = e.path || (e.composedPath && e.composedPath())
  return path.find((node) => {
    return node.classList ? node.classList.contains('option-dropdown') : false
  }) ? true : false
}

const syncWidget = (containerWidget, widgetGroupId, widgetId, widgetsMap) => {
  if (containerWidget.schema && containerWidget.schema.widgetGroups) {
    containerWidget.schema.widgetGroups = containerWidget.schema.widgetGroups.map((widgetGroup) => {
      const widgetGroupWidgets = widgetGroup.widgets.map((widget) => {
        const storedWidgetGroup = widgetsMap[widgetGroup.id]
        if (!storedWidgetGroup) return widget

        const storedWidget = storedWidgetGroup ? storedWidgetGroup[widget.id] : {}
        return syncWidget(storedWidget, widgetGroup.id, widget.id, widgetsMap)
      })

      return {
        ...widgetGroup,
        widgets: widgetGroupWidgets
      }
    })

    return containerWidget
  }

  return widgetsMap[widgetGroupId][widgetId]
}

function sortCondition(column, obj) {
  if (!obj) return ''

  const value = obj[column.id]

  if (typeof value === 'number') {
    return value
  }

  if (typeof value === 'object') {
    if (column.sortableOnKey) {
      return value[column.sortableOnKey] || ''
    }

    if (!value) return ''

    return value[Object.keys(value)[0]]
  }

  return (value || '').toLowerCase()
}

function sortTableRows(rows, column, sortOrder) {
  let tableRows = rows;

  if (column.sortableDisplayNull) {
    const nulls = rows.filter(obj => sortCondition(column, obj) === '');
    const nonNulls = rows.filter(obj => sortCondition(column, obj) !== '')

    const sortedNonNulls = orderBy(nonNulls, (obj) => {
      return sortCondition(column, obj)
    }, sortOrder)

    if (column.sortableDisplayNull === 'LAST') {
      tableRows = [...sortedNonNulls, ...nulls]
    } else {
      tableRows = [...nulls, ...sortedNonNulls]
    }
  } else {
    tableRows = orderBy(rows, (obj) => {
      return sortCondition(column, obj)
    }, sortOrder)
  }

  return tableRows
}

function getFilteredAt(pageFilter, widgetFilter) {
  if (pageFilter && widgetFilter) {
    return Math.max(pageFilter.updatedAt, widgetFilter.updatedAt)
  } else if (pageFilter) {
    return pageFilter.updatedAt
  } else if (widgetFilter) {
    return widgetFilter.updatedAt
  }

  return null
}

// returns location path
const getPageIdKey = () => window.location.pathname.substring(1).replace(/\//g, '-')

// prepend all filters keys in store with page id key
const getFilterPageIdKey = (filterId) => {
  return `${getPageIdKey()}-${filterId}`
}
// prepend all widget keys in store with page id key
const getWidgetPageIdKey = (widgetGroupId, widgetId) => {
  return `${getPageIdKey()}-${widgetGroupId}-${widgetId}`
}

const colors = {
  ligthGray: '#989ba1',
  gray: '#464950',
  green: '#00c57a'
}


export {
  renderData,
  renderFormattedData,
  getPropertySchema,
  convertArrayToHash,
  combineProps,
  updateQueryStringParams,
  colors,
  dragDropDisabler,
  syncWidget,
  getWidget,
  getWidgetPersistedProps,
  inflateWidgetGroups,
  inflateData,
  inflateSchema,
  sortTableRows,
  getFilteredAt,
  getPageIdKey,
  getFilterPageIdKey,
  getWidgetPageIdKey
}
