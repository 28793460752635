import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { renderData } from "core/helpers/utils"

const Heading = (props) => {
  const { schema, data = {}, options } = props
  const heading = schema

  return (
    <div
      role="presentation"
      className={classNames("heading-container", heading.containerClassName)}
      {...heading.tooltip}
    >
      {heading && heading.title && (
        <div
          className={classNames(
            "d-inline-block align-middle",
            heading.className
          )}
        >
          {renderData(
            heading.title,
            heading.title.type === "COMPONENT"
              ? data.heading.title
              : heading.title
          )}
        </div>
      )}
      <div className="clearfix ml-auto float-right">
        {heading.actions && (
          <React.Fragment>
            {renderData(heading.actions, data.actions, options)}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

Heading.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.object,
  options: PropTypes.object,
}

export default Heading
