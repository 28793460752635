import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DropdownList from './DropdownList'

const FilterItem = (props) => {
  const { title, selectedValue, ...others } = props
  return (
    <React.Fragment>
      <div className="form-group field">
        <div className="btn-group h-100">
          <label>{title}</label>
          <button type="button" className={classNames("dropdown-toggle dropdown-regular", { 'text-gray': !selectedValue })} id="dropdownMenuButton" data-toggle="dropdown">
            {selectedValue || 'Select...'}
          </button>
          <DropdownList {...others} />
        </div>
      </div>
    </React.Fragment>
  )
}

FilterItem.propTypes = {
  title: PropTypes.string.isRequired,
  selectedValue: PropTypes.string
}

export default FilterItem
