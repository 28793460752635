/* eslint-disable no-use-before-define */
import Immutable from 'seamless-immutable'

import {
  ADD_SPREAD,
  REMOVE_SPREAD,
  UPDATE_SPREAD,
  SET_WIDGET
} from '../constants/spreadConstants'


export const $$initialState = Immutable({
  'root_spread': {
    spreads: [],
    widget: null,
    encapsulators: []
  }
})

export default function spreadReducer($$state = $$initialState, action) {
  const { type, payload = {} } = action
  switch (type) {
    case SET_WIDGET: {
      const { id, widget, schemaUrl } = payload
      return $$state.merge({
        [id]: {
          ...$$state[id],
          widget,
          schemaUrl
        }
      })
    }
    case ADD_SPREAD: {
      const { id } = payload
      return $$state.merge({
        [id]: addSpread($$state[id], payload)
      })
    }
    case REMOVE_SPREAD: {
      const { spreadId = 'root_spread' } = payload
      return $$state.merge({
        [spreadId]: removeSpread($$state[spreadId], payload)
      })
    }
    case UPDATE_SPREAD: {
      const { id } = payload
      return $$state.merge({
        [id]: updateSpread($$state[id], payload)
      })
    }
    default:
      return $$state
  }
}

function updateSpread(spreadState, payload) {
  const {
    widget,
    widgetId,
    encapsulators,
    drillDownWidget
  } = payload
  // If there are no spreads, update the widget
  // Else update the last spread in the spread array
  if (spreadState.spreads.length === 0) {
    return {
      spreads: [],
      widget,
      encapsulators,
      widgetId
    }
  } else {
    const len = spreadState.spreads.length
    let mutableSpreads = spreadState.spreads.asMutable()
    const oldSpread = mutableSpreads[len-1]
    const newSpread = {
      label: oldSpread.label,
      drillDownWidget: drillDownWidget ? drillDownWidget : oldSpread.drillDownWidget,
      drillDownWidgetId: widgetId ? widgetId : oldSpread.drillDownWidgetId,
      encapsulators: encapsulators ? encapsulators : oldSpread.encapsulators
    }
    mutableSpreads[len-1] = newSpread
    return {
      ...spreadState,
      spreads: mutableSpreads
    }
  }
}

function removeSpread(spreadState, spreadIndex) {
  const { widget, spreads, encapsulators, widgetId } = spreadState
  return {
    spreads: spreadIndex < 1 ? [] : spreads.slice(0, spreadIndex),
    encapsulators: spreadIndex < 1 ? [] : encapsulators,
    widget,
    widgetId
  }
}


function addSpread(spreadState, payload) {
  const { spread, previousState } = payload
  const { label, encapsulators } = spread
  const { spreads } = spreadState
  const previousSpreads = spreads.asMutable({ deep: true })
  if (previousSpreads.length > 0) {
    let spreadToUpdate = previousSpreads[previousSpreads.length - 1]
    spreadToUpdate.drillDownWidget = previousState.drillDownWidget
    spreadToUpdate.encapsulators = previousState.encapsulators
  }
  return {
    ...spreadState,
    widgetId: spreads.length > 0 ? spreadState.widget : previousState.widget,
    widget: spreads.length > 0 ? spreadState.widget : previousState.widget,
    encapsulators: spreads.length > 0 ? spreadState.encapsulators : previousState.encapsulators,
    spreads: [...previousSpreads, { label, encapsulators }]
  }
}
