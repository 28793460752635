import React from 'react'
import PropTypes from 'prop-types'
import widgetsMap from 'core/helpers/widgetsMap'

import Layout from '../layouts/Layout'


const LayoutRenderer = (props) => {
  const { widgetGroups, widgets, parent, parentId, parentWidgetGroupId } = props

  return (
    <React.Fragment>
      {widgetGroups.map(widgetGroup => {
        return (
          <Layout
            key={widgetGroup.id}
            widgetGroup={widgetGroup}
            widgets={widgets}
            parentId={parentId}
          />
        );
      })}
    </React.Fragment>
  )
}

LayoutRenderer.propTypes = {
  widgets: PropTypes.object,
  widgetGroups: PropTypes.array,
  parentId: PropTypes.string.isRequired
}

export default LayoutRenderer
