/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import bindAll from "lodash.bindall";
import { renderData, getWidget } from "../helpers/utils";
import { showWidgetGroup } from "../redux/actions/widgetGroupActionCreators";

class Tab extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedItem: {
        id: null,
      },
    };
    bindAll(this, "onTabClick", "renderTabs", "renderActions");
  }

  static beforeRenderLayout(storedWidget = {}, widgetGroup) {
    // Specifically manage the widgetgroup before rendering layout
    if (storedWidget.selectedItem) {
      const containerIds = storedWidget.selectedItem.containerIds
      widgetGroup.show = containerIds.includes(widgetGroup.id)
    }
  }

  static inflateWidgetGroups({ schema = {}, widgetGroupId, widgetId, appState}) {
    const widgetGroups = schema.widgetGroups || []

    const availableTabs = schema.properties
      .filter((property) => {
        return !property.hide
      })

    return widgetGroups.map((widgetGroup) => {
      // Available tabs
      if (availableTabs.length > 0) {
        const storedWidget = getWidget({ widgetGroupId, widgetId, appState })
        if (storedWidget && storedWidget.selectedItem) {
          const prevSelectedTab = storedWidget.selectedItem
          //
          const isPrevSelectedTabAvailable = availableTabs.find((tab) => {
            return tab.id === prevSelectedTab.id
          })

          if (isPrevSelectedTabAvailable) {
            return { ...widgetGroup, show: prevSelectedTab.containerIds.includes(widgetGroup.id) }
          }
        }
      }

      const visibleTabs = availableTabs
        .find((tab) => tab.containerIds.includes(widgetGroup.id) && tab.selected)
      return { ...widgetGroup, show: !!visibleTabs }
    })
  }

  // persist this data on refresh
  static getPersistedProps(prevProps = {}) {
    const { selectedItem } = prevProps
    return { selectedItem }
  }

  componentDidMount() {
    const {
      schema,
      selectedItem,
    } = this.props;

    const { properties } = schema;
    const visibleTabs = properties
      .filter((property) => !property.hide)

    if (visibleTabs.length === 0) {
      return;
    }

    const selectedTab =
      visibleTabs
        .find((property) => {
          if (selectedItem) {
            return (selectedItem.id === property.id)
          }

          return property.selected
        }) || visibleTabs[0]

    this.onTabClick(selectedTab)
  }

  updateSelectedTab(selectedTab) {
    const {
      schema,
      widgetId,
      widgetGroupId,
      widgetGroups,
      updateWidget,
    } = this.props;

    let updates = {
      selectedItem: selectedTab,
    }

    if (widgetGroups) {
      const updatedWidgetGroups = widgetGroups.map((widgetGroup) => {
        const containerIds = selectedTab.containerIds
        widgetGroup.show = containerIds.includes(widgetGroup.id)
        return {...widgetGroup}
      })


      const properties = schema.properties.map((tab) => {
        return {
          ...tab,
          selected: selectedTab.id == tab.id
        }
      })

      updates = {
        ...updates,
        schema: {
          ...schema,
          widgetGroups: [
            ...updatedWidgetGroups
          ],
          properties
        }
      }
    }

    updateWidget(widgetGroupId, widgetId, updates)
  }

  onTabClick(property) {
    const { selectedItem, widgetId, schema } = this.props;

    if (selectedItem && selectedItem.id !== property.id) {
      selectedItem.containerIds.map((id) =>
        this.props.dispatch(showWidgetGroup(widgetId, id, false))
      );
      property.containerIds.map((id) =>
        this.props.dispatch(showWidgetGroup(widgetId, id))
      );
    } else if (!selectedItem && property) {
      // if we are hiding the selected tab through profiler
      // we need to show the widgets belong to the first tab.
      schema.properties.map((tab) => {
        tab.containerIds.map((id) => {
          this.props.dispatch(showWidgetGroup(widgetId, id, tab.id === property.id))
        })
      })
    }

    this.updateSelectedTab(property)
  }

  renderTabs() {
    const { properties, style = {} } = this.props.schema
    const { data, selectedItem } = this.props
    const navItemStyles = style.navItem && style.navItem.styles ? style.navItem.styles : {}

    const visibleTabs = properties
      .filter((property) => !property.hide)

    if (visibleTabs.length == 0) {
      return null
    }

    return visibleTabs
      .map((property, index) => {
        const isLastTab = properties.length - 1 === index;
        const activeClassName = classNames(
          'nav-link d-inline-block',
          {
            'active': selectedItem && selectedItem.id === property.id
          }
        );

        const options = { deleted: property.deleted }
        return (
          <li
            className={classNames(
              'nav-item d-flex align-items-center',
              {
                deleted: property.deleted
              }
            )}
            style={navItemStyles}
            id={property.id}
            key={property.id}
            role="presentation"
            onClick={() => this.onTabClick(property)}
          >
            <div className={activeClassName}>
              {property.title}
            </div>
            {property.badge && (
              <div className="d-inline-block">
                <span className={classNames('font-12 badge badge-danger ml-2 mb-1')}>
                  {property.badge}
                </span>
              </div>
            )}
            {property.actions && data && (
              <div className="d-inline-block ml-2">
                {renderData(property.actions, data.actions, options)}
              </div>
            )}
          </li>
        );
      });
  }

  renderActions() {
    const { actions } = this.props.schema;
    const { data = {} } = this.props;
    if (actions) {
      return renderData(actions, data.actions);
    }
    return null;
  }

  renderHeading = () => {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div role="presentation" className={classNames('heading-container d-inline-block', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  render() {
    const { schema, data = {}, children } = this.props;
    const { style = {}, heading, actions } = schema;
    const tabAlignment =
      style.alignment === "right" ? "float-right" : undefined;
    const { tabContainer = { hideWidgetBorder: true } } = style;

    return (
      <div
        className={classNames("cvc-w", "cvc-w-tabs", style.containerClassName)}
      >
        <div
          className={classNames("tab-header clearfix", heading ? heading.containerClassName : "")}
        >
          {heading && style.alignment === "right" && (
            this.renderHeading()
          )}
          <div
            className={classNames("d-flex align-items-center", tabAlignment)}
          >
            <ul
              className={classNames("nav tab-nav")}
              id="pills-tab"
              role="tablist"
            >
              {this.renderTabs()}
            </ul>
            {actions && (
              <div className="tab-actions">{this.renderActions()}</div>
            )}
          </div>
        </div>
        <div className={classNames('tab-container', {
          'hide-widget-border': tabContainer.hideWidgetBorder
        }, tabContainer.className )} style={tabContainer.styles}>
          {children}
        </div>
      </div>
    );
  }
}

Tab.defaultProps = {
  data: {}
}

Tab.propTypes = {
  schema: PropTypes.shape({
    properties: PropTypes.array.isRequired,
    style: PropTypes.object,
    actions: PropTypes.object,
  }).isRequired,
  widgetId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default Tab;
