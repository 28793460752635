import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getDropdownObjectsList } from 'libs/components/EnumValuesHelper'
import DropdownList from './DropdownList'

const getDropDownAttributes = (value, options, placeholder, readonly, multiselect) => {
  let selectedItems = null
  let labelClass = null
  let rootClass = null
  let btnText = placeholder
  const enumOptions = options.enumKey
    ? getDropdownObjectsList(options.enumKey) : options.enumOptions.map((option) => {
      return { id: option.value, label: option.label }
    })

  if (multiselect) {
    selectedItems = value ? enumOptions.filter(option => value.some(v => option.id === v[options.payloadUniqueKey])) : []
    btnText = selectedItems.length > 0 ? `${selectedItems.length} selected` : btnText
    labelClass = classNames('placeholder', { 'dropdown-label': (value.length > 0 && placeholder) })
    rootClass = classNames('btn-group h-100', { 'valid-status': value.length > 0 })
  } else {
    selectedItems = enumOptions.find(option => option.id === value)
    btnText = selectedItems ? selectedItems.label : btnText
    labelClass = classNames('placeholder', { 'dropdown-label': (value && placeholder) })
    rootClass = classNames('btn-group h-100', { 'valid-status': value })
  }

  const dropdownClass = classNames('dropdown-toggle dropdown-regular ellipsis', options.classNames, { 'disabled': readonly, 'text-gray': !selectedItems })
  return {
    selectedItems,
    btnText,
    enumOptions,
    dropdownClass,
    labelClass,
    rootClass
  }
}

const Dropdown = (props) => {
  const {
    id,
    value,
    readonly,
    required,
    options,
    onChange,
    multiple,
    schema
  } = props
  // For multiselect the placeholder comes inside options not the props
  const placeholder = props.placeholder || options.placeholder
  const { title } = schema
  const {
    selectedItems,
    btnText,
    enumOptions,
    dropdownClass,
    labelClass,
    rootClass
  } = getDropDownAttributes(value, options, placeholder, readonly || options.readonly, multiple)

  return (
    <div className={rootClass}>
      {!options.label && (<label>{title}{required && options.astrick ? '*' : null}</label>)}
      <button type="button" className={dropdownClass} id="dropdownMenuButton" data-toggle="dropdown">
        <p className="ellipsis m-0 pr-3">
          {btnText}
        </p>
      </button>
      <DropdownList
        multiselect={multiple}
        items={enumOptions}
        label={options.label}
        styles={{ maxHeight: options.maxMenuHeight }}
        onItemSelect={(item) => {
          // close bootstrap dropdown after selecting an item
          $('.dropdown-menu.show').removeClass('show');
          if (multiple) {
            if (options.customizePayload) {
              const selectedItem = []
              item.forEach(i => selectedItem.push({
                [options.payloadUniqueKey]: i.id
              }))
              onChange(selectedItem)
            } else onChange(item)
          } else onChange(item.id)
        }}
        selectedItems={selectedItems}
      />
    </div>
  )
}

Dropdown.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  options: PropTypes.object,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func
}

export default Dropdown
