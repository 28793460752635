import requestsManager from 'libs/api/requestsManager'

import {
  FETCHING_PAGE_SCHEMA,
  FETCH_PAGE_SCHEMA_SUCCESS,
  CLEAR_PAGE_FILTER,
  SET_LOADING_OVERLAY
} from '../constants/pageConstants'

import { setWidgetGroups } from './widgetGroupActionCreators'


export const fetchingPageSchema = (loadingSchema = true) => {
  return {
    type: FETCHING_PAGE_SCHEMA,
    loadingSchema
  }
}

export const fetchPageSchemaSuccess = (schema) => {
  return {
    type: FETCH_PAGE_SCHEMA_SUCCESS,
    schema
  }
}

export const setLoadingOverlay = (loadingOverlay = false) => {
  return {
    type: SET_LOADING_OVERLAY,
    loadingOverlay
  }
}

export const setPageSchemaAndWidgetGroups = (schema) => {
  return (dispatch) => {
    dispatch(fetchPageSchemaSuccess(schema))
    dispatch(setWidgetGroups(schema.id, schema.widgetGroups))
  }
}

export const fetchPageSchema = (page, force = false) => {
  return (dispatch) => {
    if (!page.schema || force) {
      dispatch(fetchingPageSchema())
      const requestsManagerFn = requestsManager.fetchEntities
      return (
        requestsManagerFn.call({}, page.schemaUrl)
          .then((resp) => {
            dispatch(setPageSchemaAndWidgetGroups(resp.data))
          })
          .catch((error) => { console.info(error) })
      )
    }
    dispatch(setPageSchemaAndWidgetGroups(page.schema))
  }
}