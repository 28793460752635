/* eslint-disable react/destructuring-assignment */
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import shortid from 'shortid'

import { FilterPills } from 'core/common/filters'

import { renderData } from 'core/helpers/utils'
import graphBlockFormatter from 'core/helpers/graphBlockFormatters'

/* eslint-disable import/no-unresolved */
import * as graphTypes from '../components/graph'
import { handleAction } from '../redux/actions/appActionCreators'


class Graph extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleOnRemove = (item) => {
    const {
      widgetFilter,
      removeFilterItem
    } = this.props

    removeFilterItem({
      filter: widgetFilter,
      item
    })
  }

  getData = () => {
    const {
      data,
      schema,
      pageFilter,
      widgetFilter,
      subscribedFilterId
    } = this.props
    const newData = JSON.parse(JSON.stringify(data))
    const { highlightKey } = schema

    if (schema.graph.name === 'Funnel') {
      newData.graph.forEach((dataItem) => {
        const selectedData = widgetFilter && widgetFilter.data && widgetFilter.data[highlightKey]
        let isUnselected = false

        if (selectedData && Array.isArray(selectedData)) {
          isUnselected = selectedData.indexOf(dataItem.label) < 0
        } else if (typeof selectedData == 'string') {
          isUnselected = selectedData.toLowerCase() !== dataItem.label.toLowerCase()
        }

        if (isUnselected) {
          dataItem.backgroundColor = dataItem.unselected_bg_color
        }
      })
    }

    if (schema.graph.name === 'Bar' || schema.graph.name === 'Pie') {
      newData.graph.forEach((dataItem) => {
        if (widgetFilter) {
          dataItem.filterData = widgetFilter.data
        }
        if (pageFilter && subscribedFilterId === pageFilter.id) {
          dataItem.filterData = pageFilter.data
        }
      })
    }

    return newData
  }

  handleOnClick = (eventSchema, eventData) => {
    if (eventSchema && eventData) {
      this.props.dispatch(
        handleAction(null, { schema: eventSchema, data: eventData })
      )
    }
  }

  renderFooter = () => {
    const { schema, data } = this.props
    const footer = data.footer || schema.footer
    const footerLegends = (footer.legends ? (Array.isArray(footer.legends) ? footer.legends : [footer.legends]) : [])

    return (
      <div className={classNames('graph-footer-container', footer.containerClassName)} style={footer.customStyle}>
        {footerLegends.length > 0 ? footerLegends.map((legends) => (
          <div
            className={classNames(
              'graph-legends',
              legends.containerClassName
            )}
          >
            {legends.items &&
              legends.items.map((legend) => (
                <div
                  key={shortid.generate()}
                  className={classNames('graph-legend', legend.className || legends.className)}
                >
                  {legend.icons && legend.icons.map((icon) => {
                    return (
                      <div
                        key={shortid.generate()}
                        style={legend.iconStyles}
                        className={classNames(
                          'd-inline-block legend-icon mx-1',
                          icon
                        )}
                      />
                    )
                  })}
                  <span className={classNames('legend-text mr-2 font-12')}>
                    {legend.text}
                  </span>
                </div>
              ))}
          </div>
        )) : (
          <div className={classNames('graph-footer', footer.className)}>
            {renderData(footer, footer.type === 'COMPONENT' || data.footer ? data.footer : footer.title)}
          </div>
        )}
      </div>
    )
  }

  renderGraphContent = () => {
    const { schema, data, widgetFilter } = this.props
    const { graph } = schema
    const footer = data.footer || schema.footer
    const GraphToRender = graphTypes[graph.name]

    const grpahEl = <GraphToRender
      filter={widgetFilter ? widgetFilter.data : null}
      schema={graph.schema}
      data={this.getData()}
      onClickHandler={this.handleOnClick}
    />

    // Recharts container doesn't respond correctly when using flexbox with resizable
    // container, specifically with bootstrap column without grids
    // https://github.com/recharts/recharts/issues/172#issuecomment-523362671
    if (schema.responsive) {
      const responsiveStyle = schema.responsive.style || {}

      return (
        <div
          className={classNames("graph-content", graph.containerClassName)}
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: responsiveStyle.height || 220
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            }}
            className={classNames('responsive-graph-container', schema.responsive.containerClassName)}
          >
            {grpahEl}
            {
              footer && this.renderFooter()
            }
          </div>
        </div>
      )
    }

    return (
      <div className={classNames("graph-content", graph.containerClassName)}>
        {grpahEl}
        {
          footer && this.renderFooter()
        }
      </div>
    )
  }

  render() {
    const {
      schema,
      data,
      options,
      hideFilters,
      widgetFilter
    } = this.props
    const { heading } = schema
    const style = schema.style || {}
    return (
      <div className={classNames('cvc-w', 'cvc-w-graph', style.className)}>
        {
          heading && (
            <div className={classNames('heading-container', heading.containerClassName)}>
              <div className={classNames('d-inline-block align-middle', heading.className)}>
                {renderData(heading, heading.type === 'COMPONENT' ? data.heading : heading.title)}
              </div>
              <div className="clearfix ml-auto float-right">
                { heading.actions && (
                  <React.Fragment>
                    {renderData(heading.actions, data.actions, options)}
                  </React.Fragment>)
                }
              </div>
            </div>
          )
        }
        {!hideFilters && widgetFilter &&
          <FilterPills
            onRemove={this.handleOnRemove}
            filters={widgetFilter}
          />
        }
        { this.renderGraphContent() }
      </div>
    )
  }
}

Graph.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pageFilter: PropTypes.object,
  widgetFilter: PropTypes.object
}

export default Graph
