/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import Form from 'react-jsonschema-form'
import classNames from 'classnames'
import { cloneDeepWith, get, isEmpty } from 'lodash/fp'
import requestsManager from 'libs/api/requestsManager'
import Select, { createFilter } from 'react-select'
import { formatError } from 'libs/utils'
import { success, error } from 'react-notification-system-redux'
import { getDropdownObjectsList } from 'libs/components/EnumValuesHelper'

/* eslint-disable import/no-unresolved */
import * as formWidgets from '../components/form/widgets'
import formFields from '../components/form/fields'
import { FILTER_TYPE_PAGE } from '../helpers/constants'

class Filter extends React.Component {
  constructor(props) {
    super(props)
    const { data } = props
    this.state = {
      formData: data,
      initialData: data,
      hasDataRetained: true,
      dirty: false,
      key: Date.now(),
      filterName: '',
      savedFilters: [],
      selectedFilterId: null,
      selectedFilterName: '',
      inputModified: false,
      notificationOptions: {
        title: '',
        message: '',
        position: 'tc',
        autoDismiss: 3,
      },
    }
    bindAll(this, 'setLiveValidate', 'applyFilter', 'getFilters')
  }

  componentDidMount() {
    const { savedFilterListUrl, widgetId } = this.props
    requestsManager.fetchEntities(`/saved_filters.json?filter_id=${widgetId}`)
      .then((res) => {
        this.setState({ savedFilters: res.data })
      })
      .catch((errResponse) => {
        const { data } = errResponse.response
        const errorText = formatError(data) || 'Unable to fetch saved Filters.'
        this.props.dispatch(
          error({
            ...this.state.notificationOptions,
            title: 'Unable to fetch saved Filters',
            message: errorText,
          })
        )
      })
  }

  setLiveValidate({ formData }) {
    const { initialData } = this.state
    this.setState({
      formData,
      hasDataRetained: JSON.stringify(formData) === JSON.stringify(initialData),
      dirty: true,
    })
  }

  getFilters() {
    const { uiSchema, jsonSchema } = this.props.schema
    const { filters } = this.state.formData
    const filterUiSchema = uiSchema.filters
    const DateRangePickerKeys = Object.keys(filterUiSchema).filter(
      (filterKey) => filterUiSchema[filterKey]['ui:field'] === 'dateRange'
    )

    let DateRangePickerFilterObj = {}
    DateRangePickerKeys.forEach((key) => {
      DateRangePickerFilterObj = {
        ...DateRangePickerFilterObj,
        ...filters[key],
        [key]: undefined,
      }
    })
    return { ...filters, ...DateRangePickerFilterObj }
  }

  getDisplayFilters() {
    const { uiSchema, jsonSchema } = this.props.schema;
    const { filters } = this.state.formData;
    const displayFilters = {};
    Object.keys(filters).forEach((key) => {
      if (!isEmpty(filters[key]) || typeof filters[key] == "number") {
        const property = jsonSchema.properties.filters.properties[key];

        if (property.type === "array") {
          const uiOptions = uiSchema.filters[key]["ui:options"];
          let arrayNames = [];
          // get options from enum key
          if (uiOptions && uiOptions.enumKey) {
            const list = getDropdownObjectsList(uiOptions.enumKey);
            arrayNames = filters[key].map((item) => {
              const enumObj = list.find((listItem) => {
                return listItem.id === item;
              });

              return enumObj.label;
            })
          } else if (property.items && property.items.enumNames) {
            const filterValues = typeof filters[key] == 'string' ? [filters[key]] : filters[key]
            arrayNames = filterValues.map((item) => {
              return property.items.enumNames[property.items.enum.indexOf(item)];
            });
          }

          displayFilters[key] = arrayNames;
        } else if (property.type === "number" || property.type === "string") {
          if (property.enum) {
            displayFilters[key] =
              property.enumNames[property.enum.indexOf(filters[key])];
          } else {
            displayFilters[key] = filters[key];
          }
        }
      }
    });

    return { ...displayFilters };
  }
  applyFilter() {
    const { schema, widgetId, pageId } = this.props
    const filters = this.getFilters()
    const displayFilters = this.getDisplayFilters()
    const payload = {
      filter: {
        id: widgetId,
        type: schema.filterType,
        data: filters,
        displayFilters,
        refresh: schema.filter ? schema.filter.refresh : null,
        cache: schema.cache === false ? false : true,
      },
    }

    this.props.setFilter(payload, schema.preventUrlUpdates)

    $('#modal').modal('hide')
  }

  markLabelAsFalse = (uiSchema) => {
    return cloneDeepWith((value, key) => {
      if (get('ui:widget', value) || get('ui:field', value)) {
        if (!get('ui:options', value)) {
          return {
            ...value,
            'ui:options': {
              label: false,
            },
          }
        }
      }
      // mark default labels from uiSchema as false
      if (key === 'ui:options' && value.label !== true) {
        return { ...value, label: false }
      }
    }, uiSchema)
  }

  saveApplyFilter = () => {
    const { widgetId, saveFilterUrl } = this.props
    const filterName = this.state.inputModified ? this.state.filterName : this.state.selectedFilterName
    const payload = {
      filter_id: widgetId,
      title: this.state.inputModified ? this.state.filterName : this.state.selectedFilterName,
      filters: this.state.formData.filters,
    }

    if (this.state.selectedFilterId && (filterName.trim() === this.state.selectedFilterName.trim())) {
      payload['id'] = this.state.selectedFilterId
    }
    requestsManager
      .submitEntity('/saved_filters/upsert.json', payload)
      .then((res) => {
        this.applyFilter()
      })
      .catch((errResponse) => {
        const { data } = errResponse.response
        const errorText = formatError(data) || 'Filter name already taken.'
        this.props.dispatch(
          error({
            ...this.state.notificationOptions,
            title: 'Filter Creation failed',
            message: errorText,
          })
        )
      })
  }

  getLabel = (props) => {
    const { widgetId } = this.props
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          {<div className="font-14">{props.label}</div>}
        </div>
        <span
          className={classNames('icon-trash cursor-pointer')}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            requestsManager
              .deleteEntity(`/saved_filters/${props.value}.json`)
              .then(() => {
                requestsManager
                  .fetchEntities(`/saved_filters.json?filter_id=${widgetId}`)
                  .then((res) => {
                    this.setState({ savedFilters: res.data })
                  })
                this.props.dispatch(
                  success({
                    ...this.state.notificationOptions,
                    title: `Filter deleted.`,
                    message: `"${props.label}" filter deleted successfully.`,
                  })
                )
              })
              .catch((errResponse) => {
                const { data } = errResponse.response
                const errorText = formatError(data) || 'Unable to remove Filter.'
                this.props.dispatch(
                  error({
                    ...this.state.notificationOptions,
                    title: 'Filter Deletion failed',
                    message: errorText,
                  })
                )
              })
          }}
        ></span>
      </div>
    )
  }

  renderSavedFilters = () => {
    const { savedFilters = [] } = this.state

    if (savedFilters.length <= 0) return null
    const filterConfig = {
      stringify: (option) => {
        return option.data.label
      },
    }

    return (
      <Select
        className="saved-filters-select mb-3"
        isClearable
        isSearchable
        maxMenuHeight={250}
        formatOptionLabel={this.getLabel}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        placeholder="Select saved filter..."
        controlShouldRenderValue
        onChange={(value) => {
          if (!value) {
            return this.setState({
              key: Date.now(),
              selectedFilterId: null,
              selectedFilterName: '',
              hasDataRetained:
                JSON.stringify(formData) === JSON.stringify(initialData),
              formData: {},
              dirty: false,
            })
          }

          const { initialData } = this.state
          const formData = {
            filters: value.data.filters,
          }

          this.setState({
            selectedFilterId: value.data.id,
            selectedFilterName: value.data.title,
            key: Date.now(),
            hasDataRetained:
              JSON.stringify(formData) === JSON.stringify(initialData),
            formData,
            dirty: false,
          })
        }}
        options={savedFilters.map((savedFilter) => {
          return {
            label: savedFilter.title,
            value: savedFilter.id,
            data: savedFilter,
          }
        })}
        styles={{
          control: base => ({
            ...base,
            "&:hover": {
              borderColor: '#cccccc',
              boxShadow: '0 0 0 1px #cccccc'
            }
          })
        }}
      />
    )
  }

  renderSaveFilterBtn = () => {
    const modalBody = $('.modal-body')
    if (!this.state.dirty) {
      // conditionally increase the modal height.
      if (modalBody) {
         modalBody.css('max-height', 'calc(100vh - 120px)');
       }
      return
    }

    if (modalBody) {
       modalBody.css('max-height', 'calc(100vh - 190px)');
     }

    return (
      <div className="bg-white-primary p-2">
        <div className="font-12 text-left">Save this filter for future use</div>
        <div className="d-flex align-items-center filer-name-input w-100">
          <input
            type="text"
            value={this.state.inputModified ? this.state.filterName : this.state.selectedFilterName}
            className="form-control mr-4 px-0 py-2"
            placeholder="Filter Name"
            onChange={(e) => {
              const value = e.target.value

              this.setState({
                filterName: value,
                inputModified: true
              })
            }}
          />
          <div
            className="cvc-btn-secondary x-small"
            onClick={this.saveApplyFilter}
            style={{ width: 140 }}
          >
            Save & Apply
          </div>
          <div className="input-group-append"></div>
        </div>
      </div>
    )
  }

  render() {
    const { uiSchema, jsonSchema } = this.props.schema
    const { formData, hasDataRetained } = this.state

    return (
      <div className={classNames('cvc-w', 'cvc-w-filter-form')}>
        {this.renderSavedFilters()}
        <Form
          uiSchema={this.markLabelAsFalse(uiSchema)}
          schema={jsonSchema}
          formData={formData}
          widgets={formWidgets}
          fields={formFields}
          onChange={this.setLiveValidate}
          key={this.state.key}
        >
          <div className="action-buttons clearfix text-center p-0">
            {this.renderSaveFilterBtn()}
            <button type="button" className="filter-btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className={classNames('filter-btn-green', {
                disabled: hasDataRetained,
              })}
              onClick={this.applyFilter}
            >
              Apply
            </button>
          </div>
        </Form>
      </div>
    )
  }
}

Filter.propTypes = {
  schema: PropTypes.shape({
    uiSchema: PropTypes.object,
    jsonSchema: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
  widgetId: PropTypes.string,
}

export default Filter
