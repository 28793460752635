/* eslint-disable import/prefer-default-export */
/**
 * When new action is added, if you want to refresh the widget
 * add stamp to the widget. Refer existing actions and refer
 * WidgetRenderer code on how stamp is used shouldComponentUpdate
 */
export const FETCHING_WIDGET_SCHEMA = 'FETCHING_WIDGET_SCHEMA'
export const FETCH_WIDGET_SCHEMA_SUCCESS = 'FETCH_WIDGET_SCHEMA_SUCCESS'
export const FETCHING_WIDGET_DATA = 'FETCHING_WIDGET_DATA'
export const FETCH_WIDGET_DATA_SUCCESS = 'FETCH_WIDGET_DATA_SUCCESS'
export const RELOAD_WIDGETS = 'RELOAD_WIDGETS'
export const SET_ENCAPSULATOR_SCHEMA_URL = 'SET_ENCAPSULATOR_SCHEMA_URL'
export const UPDATE_WIDGET = 'UPDATE_WIDGET'
export const DELETE_WIDGET = 'DELETE_WIDGET'
export const DELETE_WIDGET_TAB_ITEM = 'DELETE_WIDGET_TAB_ITEM'
export const DELETE_WIDGET_TABLE_ROW = 'DELETE_WIDGET_TABLE_ROW'
export const RESTORE_WIDGET_TABLE_ROW = 'RESTORE_WIDGET_TABLE_ROW'
export const ADD_WIDGET_TABLE_ROW = 'ADD_WIDGET_TABLE_ROW'
export const MARK_WIDGET_DIRTY = 'MARK_WIDGET_DIRTY'
export const RESET_WIDGET = 'RESET_WIDGET'
