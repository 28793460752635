import {
  FETCHING_MODAL_SCHEMA,
  FETCH_MODAL_SCHEMA_SUCCESS,
  FETCH_MODAL_SCHEMA_FAIL,
  SHOW_MODAL,
  HIDE_MODAL
} from '../constants/modalConstants'

export const $$initialState = {
  modalType: '',
  schema: null,
  show: false,
  dismissed: false
}

function modalReducer($$state = $$initialState, action = null) {
  const {
    type, schema, loadingSchema, schemaUrl, schemaParams
  } = action
  switch (type) {
    case FETCHING_MODAL_SCHEMA:
      return {
        ...$$state,
        loadingSchema
      }
    case FETCH_MODAL_SCHEMA_SUCCESS:
      return {
        ...$$state,
        schema,
        loadingSchema: false
      }
    case SHOW_MODAL:
      return {
        ...$$state,
        schema,
        schemaUrl,
        schemaParams,
        show: true
      }
    case HIDE_MODAL:
    case FETCH_MODAL_SCHEMA_FAIL:
      return {
        ...$$state,
        schema: null,
        schemaUrl: null,
        schemaParams: null,
        show: false,
        dismissed: true
      }
    default:
      return $$state
  }
}

export default modalReducer
