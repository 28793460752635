import { connect } from 'react-redux'
import TableRowRenderer from '../../renderers/TableRowRenderer'
import * as tableRowActions from '../actions/tableRowActionCreators'

const mapStateToProps = (state, ownProps) => {
  const { row, widgetGroup, page } = state
  const widgetGroups = row.schema ? widgetGroup[row.schema.id] || {} : {}

  return {
    widgetGroupIds: Object.keys(widgetGroups),
    schema: state.row.schema,
    loadingSchema: state.row.loadingSchema,
    error: state.row.error
  }
}

export default connect(mapStateToProps, { ...tableRowActions })(TableRowRenderer)
