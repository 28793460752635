import { isEmpty, omit } from 'lodash/fp'
import { clone } from 'libs/utils'
import { cleanObject } from 'libs/utils'
import {
  SET_WIDGET_CACHE
} from '../constants/widgetCacheConstants'

export const $$initialState = {}

function widgetCacheReducer($$state = $$initialState, action = null) {
  const { type } = action

  switch (type) {
    case SET_WIDGET_CACHE: {
      const { payload = {}, id } = action

      const previousData = $$state[id] ? $$state[id] : { id }
      return {
        ...$$state,
        [id]: {
          ...previousData,
          data: {
            ...previousData.data,
            ...payload.data
          },
          schema: {
            ...previousData.schema,
            ...payload.schema
          }
        }
      }
    }

    default:
      return $$state
  }
}


export default widgetCacheReducer