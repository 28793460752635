import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Element } from 'react-scroll'

/* eslint-disable import/no-unresolved */
import * as layoutMap from '../layouts'

const WidgetGroupRenderer = ({ widgetGroup, children }) => {
  if (!widgetGroup) {
    return null
  }
  const { id, type, style={} } = widgetGroup
  if (widgetGroup.show === false) {
    return null
  }

  return (
    <Element name={widgetGroup.id} className={classNames('row', style.className)} style={style.styles}>
      {children}
    </Element>
  )
}

WidgetGroupRenderer.propTypes = {
  widgetGroup: PropTypes.object
}

export default WidgetGroupRenderer
