import React from 'react'
import PropTypes from 'prop-types'

export const showConfirmModal = (schema) => {
  const confirmModal = $('#confirm-dialog');
  const yes = confirmModal.find('#confirm-yes')
  const no = confirmModal.find('#confirm-no')

  if (schema && schema.confirmDialog) {
    const description = confirmModal.find('#confirm-dialog-description')
    const title = confirmModal.find('#confirm-dialog-title')

    const titleText = schema.confirmDialog.title
    title.text(titleText || 'Please confirm')

    const descriptionText = schema.confirmDialog.description
    description.html(descriptionText || 'Would you like to proceed?')

    yes.html(schema.confirmDialog.confirmText || 'Confirm')
    no.html(schema.confirmDialog.cancelText || 'Cancel')
  }

  confirmModal.modal('show')

  return new Promise((resolve, reject) => {
    yes.on('click', function() {
      resolve(true)
      confirmModal.modal('hide')
    })

    no.on('click', function() {
      resolve(false)
      confirmModal.modal('hide')
    })
  })
};

const ConfirmDialog = ({ schema, data }) => {
  return (
    <div className="modal-container">
      <div className="modal" id="confirm-dialog" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="confirm-dialog-title"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-3">
              <p id="confirm-dialog-description"></p>
            </div>
            <div className="modal-footer">
              <button type="button" className="cvc-btn-primary" id="confirm-no" data-dismiss="modal">Cancel</button>
              <button type="button" className="cvc-btn-primary" id="confirm-yes">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmDialog.propTypes = {
  schema: PropTypes.shape({
    className: PropTypes.string
  }),
  data: PropTypes.shape({
    text: PropTypes.string.isRequired
  })
}

export default ConfirmDialog
