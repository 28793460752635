import { connect } from 'react-redux'
import { find, pickBy } from 'lodash/fp'
import queryString from 'query-string'
import { getFilterPageIdKey, getPageIdKey } from 'core/helpers/utils'
import PageRenderer from '../../renderers/PageRenderer'
import * as pageActions from '../actions/pageActionCreators'
import * as filterActions from '../actions/filtersActionCreators'
import * as appActions from '../actions/appActionCreators'

import { FILTER_TYPE_PAGE } from '../../helpers/constants';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => {
  const { page, widgetGroup, modal, filters } = state
  const widgetGroups = page.schema ? widgetGroup[page.schema.id] || {} : {}

  const pageFilters = pickBy((filter, key) => {
    return key === `${getPageIdKey()}-${filter.id}`
  }, filters)

  const pageFilter = find((filter) => {
      const filterId = getFilterPageIdKey(filter.id)
      return filter.type === FILTER_TYPE_PAGE && pageFilters[filterId]
    }, Object.values(pageFilters))

  const query = queryString
    .parse(location.search, {decode: true});

  return {
    page,
    pageFilter,
    widgetGroupIds: Object.keys(widgetGroups),
    notifications: state.notifications,
    modal,
    loadingOverlay: page.loadingOverlay,
    query
  }
}

export default connect(
  mapStateToProps,
  {
    ...appActions,
    ...pageActions,
    ...filterActions
  }
)(PageRenderer)
