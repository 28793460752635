/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'

import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'
import Loader from 'libs/components/Loader'


// NOTE: There are a lot of hardcoded stuff here because the backend APIs need to return the
// results in a standard structure
const Menu = ({ items, onItemSelect, loading }) => {
  if (loading) {
    return (
      <div className="autocomplete-menu">
        <Loader className="inline-loader text-gray" />
      </div>
    )
  }
  if (items.length === 0) {
    return (
      <div className="autocomplete-menu">
        <div className="dropdown-item"> No Options </div>
      </div>
    )
  }
  return (
    <div className="autocomplete-menu">
      {items.map(item => (
        <div key={item.id} className="dropdown-item" onMouseDown={() => onItemSelect(item)}>
          {item.label}
        </div>
      ))}
    </div>
  )
}

Menu.propTypes = {
  items: PropTypes.array,
  onItemSelect: PropTypes.func,
  loading: PropTypes.bool
}

class Autocomplete extends Component {
  state = {
    searchString: '',
    data: [],
    loading: false,
    showMenu: false,
    params: null
  }

  constructor(props) {
    super(props)
    this.fetchData = debounce(500, this.fetchData)
  }

  componentDidMount() {
    this.fetchData()
  }

  handleOnChange = (searchString) => {
    this.setState({ searchString, params: searchString }, () => {
      this.fetchData()
    })
  }

  fetchData = () => {
    const { searchString } = this.state
    const { uiSchema, name } = this.props
    const { autocompleteUrl, searchParam } = uiSchema['ui:options']
    const url = updateQueryStringParams(autocompleteUrl, { [`${searchParam}`]: searchString })
    this.setState({ loading: true })
    if (name === 'id') {
      requestsManager.fetchEntities(url).then((res) => {
        const { results } = res.data
        const autocompleteData = results.map((c) => {
          return { id: c.id, label: c.name }
        })
        this.setState({ loading: false, data: autocompleteData })
      })
    } else {
      requestsManager.fetchEntities(url).then((res) => {
        const { results } = res.data
        const autocompleteData = results.map((c) => {
          return { id: c.id, label: c.label }
        })
        this.setState({ loading: false, data: autocompleteData })
      })
    }
  }

  handleItemSelect = (selectedItem) => {
    this.setState({ searchString: selectedItem.label })
    this.props.onChange(selectedItem.id)
  }

  toggleMenu = (e) => {
    const { showMenu } = this.state
    this.setState({ showMenu: !showMenu })
  }

  render() {
    const { data, loading, searchString, showMenu } = this.state
    const { uiSchema, schema } = this.props
    const { label, readonly, astrick } = uiSchema['ui:options']
    const { title } = schema
    return (
      <div className="autocomplete-dropdown">
        {!label && (<label>{title}{astrick ? '*' : null}</label>)}
        <input
          readOnly={readonly}
          className="search-input-bar"
          value={searchString}
          placeholder={uiSchema['ui:placeholder']}
          type="text"
          onChange={e => this.handleOnChange(e.target.value)}
          onFocus={this.toggleMenu}
          onBlur={this.toggleMenu}
        />
        {
          showMenu
          && (
          <Menu
            items={data}
            onItemSelect={this.handleItemSelect}
            loading={loading}
          />
          )
        }
      </div>
    )
  }
}

Autocomplete.propTypes = {
  uiSchema: PropTypes.object,
  name: PropTypes.string,
  formContext: PropTypes.object,
  onChange: PropTypes.func
}

export default Autocomplete
