import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { renderData } from '../helpers/utils'
import Icon from './Icon'

const Figure = ({ schema, data }) => {
  const dataStyle = data.style && data.style.figure ? data.style.figure : null
  const schemaStyle = schema.style && schema.style.figure ? schema.style.figure : {}

  const figureStyle = { ...schemaStyle, ...dataStyle }

  return (
    <div className={classNames("cvc-c-figure", figureStyle.className)} title={ data.title || schema.title }>
      {data.headerIcon && <div className={classNames('figure-header-icon', figureStyle.headerIcon)}>
        <div className="text-center"><Icon schema={{ className: data.headerIcon }} /></div>
      </div>}
      {data.header && <div className={classNames('figure-header', figureStyle.header)}>{data.header}</div>}
      <div className={classNames('figure-body', figureStyle.body)}>
        <div className={classNames('figure-prefix', figureStyle.prefix)}>{data.prefix}</div>
        <div className={classNames('figure-icon', data.icon)} />
        <div className={classNames('figure-value', figureStyle.value)}>
          {
            (schema.value && schema.value.type === 'COMPONENT') ?
              renderData(schema.value, data && data.value ? data.value : '') :
              data.value
          }
        </div>
        <div className={classNames('figure-suffix', figureStyle.suffix)}>{data.suffix}</div>
      </div>
      <div className={classNames('figure-footer', figureStyle.footer)}>
        {
          (schema.footer && schema.footer.type === 'COMPONENT') ?
            renderData(schema.footer, data && data.footer ? data.footer : '') :
            data.footer
        }
      </div>
    </div>
  )
}

Figure.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired
}

export default Figure
