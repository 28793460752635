import React, { PureComponent } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import Pill from "core/common/pill"
import { formatFilterData } from "core/redux/selectors/filterSelectors"

class FilterPills extends PureComponent {
  render() {
    const { onRemove, schema = {}, type } = this.props

    const filters = formatFilterData(this.props.filters)
    const isPagePill = type == "page"

    return (
      <div
        className={classNames(
          "cvc-c-filter-pills",
          isPagePill ? "page-filter-pills" : "widget-filter-pills"
        )}
      >
        {filters.length > 0 &&
          filters.map((filter) => (
            <Pill
              key={filter.label}
              removeParam={{
                key: filter.key,
                value: filter.value,
                displayFilterValue: filter.displayFilterValue
              }}
              onRemove={onRemove}
            >
              {filter.label}
            </Pill>
          ))}
      </div>
    )
  }
}

FilterPills.propTypes = {
  filters: PropTypes.object,
  onRemove: PropTypes.func,
}
export default FilterPills
