import React from 'react'
import PropTypes from 'prop-types'
import EncapsulatorContainer from 'core/redux/containers/EncapsulatorContainer'


const Encapsulator = (props) => {
  const {
    schema,
    children,
    widgetId,
    spreadId
  } = props
  return (
    <EncapsulatorContainer id={widgetId} schema={schema} spreadId={spreadId} />
  )
}
Encapsulator.propTypes = {
  schema: PropTypes.object,
  children: PropTypes.node,
  widgetId: PropTypes.string,
  spreadId: PropTypes.string
}
export default Encapsulator
