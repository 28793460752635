import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEqual, isEmpty } from 'lodash/fp/'
import Loader from 'libs/components/Loader'
import LoadingOverlay from 'react-loading-overlay'
/* eslint-disable import/no-unresolved */
import { WidgetEventContext } from 'core/context'
import widgetsMap from 'core/helpers/widgetsMap'
import { getFilteredAt } from 'core/helpers/utils'
import WidgetContainer from 'core/redux/containers/WidgetContainer'
import Error from '../components/Error'
import ErrorMap from '../helpers/errors'
import WidgetGroupContainer from '../redux/containers/WidgetGroupContainer'
import LayoutRenderer from './LayoutRenderer'

class WidgetRenderer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      showCollapsible: true
    }
    const { widget } = props
    if (!widget.schema && !widget.schemaUrl) {
      this.setState({ error: ErrorMap.schemaError })
    } else if (!widget.data && !widget.dataUrl) {
      this.setState({ error: ErrorMap.widgetDataError })
    }
  }

  getChildContext() {
    const {
      widgetGroupId,
      widgetId,
      widgetFilter,
      subscribedFilterId,
      spreads,
      widget
    } = this.props
    return {
      widgetGroupId,
      widgetId,
      widgetFilter,
      subscribedFilterId,
      spreads,
      widget
    }
  }

  componentDidMount() {
    const {
      widgetGroupId,
      widget,
      pageFilter,
      widgetFilter,
      spreads: unFormattedspreads
    } = this.props
    const { error } = this.state

    const spreads = this.formatSpreadParams(unFormattedspreads)

    const filteredAt = getFilteredAt(pageFilter, widgetFilter)

    if (!error && widget.id) {
      const forceRefresh = (filteredAt || widget.filteredAt) ? filteredAt !== widget.filteredAt : false
      // Loading Schema and Data
      this.props.fetchWidgetSchema({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: forceRefresh
      })
      this.props.fetchWidgetData({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: forceRefresh
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { widget = {} } = this.props
    const nextWidget = nextProps.widget || {}

    return (
      widget.stamp !== nextWidget.stamp
      || this.state.showCollapsible !== nextState.showCollapsible
    )
  }

  componentDidUpdate(prevProps) {
    const {
      widgetGroupId,
      widget,
      pageFilter,
      widgetFilter,
      spreads,
      filters
    } = this.props
    const { error } = this.state

    if (error || !widget.id) return

    const reloadWidgetSchema = widget.loadingSchema && (prevProps.widget.loadingSchema !== widget.loadingSchema)

    if (reloadWidgetSchema) {
      this.props.fetchWidgetSchema({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: true
      })
    } else if (widget.updatedAt !== prevProps.widget.updatedAt) {
      this.props.fetchWidgetSchema({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: false
      })
    }

    const reloadWidgetData = widget.loadingData && (
      prevProps.widget.loadingData !== widget.loadingData
      || prevProps.widget.filterStamp !== widget.filterStamp // allow multiple simulteneous filter requests
    )

    if (reloadWidgetData) {
      this.props.fetchWidgetData({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: true
      })
    } else if (widget.updatedAt !== prevProps.widget.updatedAt) {
      this.props.fetchWidgetData({
        widgetGroupId,
        widget,
        pageFilter,
        widgetFilter,
        spreads,
        force: false
      })
    }
  }

  formatSpreadParams = (unFormattedspreads) => {
    const { spreads: secondLevelSpreads, widgetId, widget } = unFormattedspreads
    const spreads = secondLevelSpreads && secondLevelSpreads.map((spread) => {
      const { label, drillDownWidget, drillDownWidgetId } = spread
      return drillDownWidget ? { label, drillDownWidget, drillDownWidgetId } : { label }
    })
    return (secondLevelSpreads ? { widget, widgetId, spreads } : { widget, widgetId })
  }

  handleWidgetEvent = (events) => {
    const {
      removeAllWidgetFilters,
      updateSpread,
      schemaUrlSetter,
      updateWidget,
      widget,
      widgetGroupId
    } = this.props

    if (events && events.length > 0) {
      events.forEach((event) => {
        const { eventData } = event
        if (event.type === 'ENCAPSULATOR') {
          schemaUrlSetter(eventData.id, eventData.schemaUrl)
        } else {
          updateSpread(eventData)

          // Not to clear filters when page refreshes
          if (event.type !== 'UPDATE_SPREAD') {
            removeAllWidgetFilters()
          }
        }

        updateWidget(widgetGroupId, widget.id, { stamp: Math.random() })
      })
    }
  }

  toggleCollapsible = () => {
    this.setState({
      showCollapsible: !this.state.showCollapsible
    })
  }

  // eslint-disable-next-line react/sort-comp
  eventContext = {
    eventHandler: this.handleWidgetEvent
  }

  renderCollapsible = (widgetElement) => {
    const { showCollapsible } = this.state

    return (
      <React.Fragment>
        <div className="cvc-w-collapsible">
          <hr className="mt-3 mb-3 ml-2 mr-2" />
          <i
            className={classNames('cursor-pointer collapsible-icon', showCollapsible ? 'up-arrow' : 'down-arrow')}
            role="presentation"
            onClick={this.toggleCollapsible}
          />
        </div>
        <div style={{ 'display': showCollapsible ? 'block' : 'none' }}>
          {widgetElement}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { widget, widgets, spreads, ...otherProps } = this.props
    const { error, showCollapsible } = this.state

    if (error) {
      return <Error error={error} />
    }

    if (
      (!widget.data && widget.loadingData && widget.type !== 'Autocomplete')
      || (!widget.schema && widget.loadingSchema)
    ) {
      return <Loader className="inline-loader" />
    }

    if (isEmpty(widget.schema)) {
      return null
    }

    const showOverlyLoader = widget.loadingData || widget.loadingSchema || widget.loadingOverlay

    const WidgetToRender = widgetsMap[widget.type]
    const { data, schema, collapsible, dataUrl, ...otherWidgetProps } = widget
    const { widgetGroups = [], ...otherSchema } = schema
    const widgetSchema = { ...otherSchema }
    const widgetGroupIds = widgetGroups.map(widgetGroup => widgetGroup.id)

    const widgetElement = (
      <WidgetToRender
        schema={widgetSchema}
        data={data ? data.widgetData : {}}
        dataUrl={dataUrl}
        widgetGroups={widgetGroups}
        {...otherWidgetProps}
        {...otherProps}
        options={{ deleted: widget.deleted, dirty: widget.dirty }}
      >
        <LayoutRenderer
          widgetGroups={widgetGroups}
          widgets={widgets}
          parentId={widget.id}
          parentWidgetGroupId={otherProps.widgetGroupId}
          parent={widget}
        />
      </WidgetToRender>
    )

    return (
      <WidgetEventContext.Provider value={{ ...this.eventContext, spreads }}>
        <LoadingOverlay
          active={!widget.hideLoader && showOverlyLoader}
          className={classNames('h-100', {
            deleted: widget.deleted,
            reset: widget.reset
          })}
          spinner={<Loader className="inline-loader" />}
          fadeSpeed={0}
          styles={{
            overlay: base => ({
              ...base,
              background: widget.refreshOverlayColor ? widget.refreshOverlayColor : 'rgba(255, 255, 255, 0.6)'
            })
          }}
        >
          {collapsible ? this.renderCollapsible(widgetElement) : widgetElement}
        </LoadingOverlay>
      </WidgetEventContext.Provider>
    )
  }
}

WidgetRenderer.propTypes = {
  widget: PropTypes.object.isRequired,
  fetchWidgetSchema: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  widgetGroupId: PropTypes.string.isRequired,
  widgetId: PropTypes.string.isRequired,
  pageFilter: PropTypes.object
}

WidgetRenderer.childContextTypes = {
  widgetGroupId: PropTypes.string,
  widgetId: PropTypes.string,
  widgetFilter: PropTypes.object,
  subscribedFilterId: PropTypes.string,
  spreads: PropTypes.object,
  widget: PropTypes.object
}

export default WidgetRenderer
