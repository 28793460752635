import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class SpreadRenderer extends PureComponent {
  // componentDidMount() {
  //   const { id, data, setWidget } = this.props
  //   if (data.widget) {
  //     setWidget({ id, ...data })
  //   }
  // }

  handleRemoveSpread = (index) => {
    const { spreads, primaryWidgetEncapsulators, removeSpread } = this.props
    if (index === 0) {
      removeSpread(primaryWidgetEncapsulators, 0)
    } else {
      removeSpread(spreads[index - 1].encapsulators, index)
    }
  }

  render() {
    const { breadcrumbs } = this.props
    if (breadcrumbs.length < 1) return null
    return (
      <nav aria-label="breadcrumb">
        <ol className="cvc-c-breadcrumb">
          {breadcrumbs.map((breadcrumb, index) => {
            const active = index === breadcrumbs.length - 1
            return (
              <li
                key={breadcrumb}
                role="presentation"
                className={classNames('breadcrumb-item', { 'active': active })}
                aria-current="page"
                onClick={() => this.handleRemoveSpread(index)}
              >
                {breadcrumb}
              </li>
            )
          })}
        </ol>
      </nav>
    )
  }
}

SpreadRenderer.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  setWidget: PropTypes.func,
  spreads: PropTypes.array,
  primaryWidgetEncapsulators: PropTypes.any,
  removeSpread: PropTypes.func,
  breadcrumbs: PropTypes.array
}
export default SpreadRenderer
