import React from 'react'
import classNames from 'classnames'
import shortid from 'shortid'
import { map } from 'lodash'
import { Timeline, TimelineEvent } from 'react-event-timeline'
import requestsManager from 'libs/api/requestsManager'
import { getErrorText } from 'libs/utils'
import { handleAction } from '../redux/actions/appActionCreators'
// import RichTextEditor from '../components/RichTextEditor'
import Form from './Form'
import {
  renderData,
} from '../helpers/utils'

class Event extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false
    }
  }

  handleEdit = () => {
    this.setState({ editing: true })
  }

  handleCancel = () => {
    this.setState({ editing: false })
  }

  render() {
    const { children, data = {}, schema } = this.props
    const { editing } = this.state

    if (editing && !schema.editable) {
      throw 'Missing `editable` key/object in the schema.'
    }

    if (editing) {
      return (
        <Form
          schema={schema.editable}
          data={data.editable}
          options={{
            callback: ({ success, cancel }) => {
              if (success || cancel) {
                this.handleCancel()
              }
            }
          }}
        />
      )
    }

    return (
      <div className="d-inline-block position-relative">
        {data.editable && <i className={classNames(data.editable.icon || 'icon-edit', "edit-icon-position position-absolute")} onClick={this.handleEdit} />}
        {children}
      </div>
    )
  }
}

class EventTimeline extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultLineStyles: {
        top: 25,
        height: '94%',
        width: 1,
        color: '#cdced2',
        background: '#cdced2'
      },
      defaultEventStyles: {
        titleStyle: {
          color: '#142142',
          fontSize: 16
        },
        bubbleStyle: {
          background: 'transparent',
          border:  'none'
        },
        iconStyle: {
          left: 1
        },
        contentStyle: {
          background: 'none',
          border: 'none',
          boxShadow: 'none',
          padding: 0,
          marginTop: 0,
          marginBottom: 10
        },
        titleStyle: {
          fontWeight: '500',
          fontSize: 18
        }
      }
    }
  }

  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data = {} } = this.props

    if (!heading.title && !heading.actions) return null

    return (
      <div
        role="presentation"
        className={classNames('heading-container', heading.containerClassName)}
        {...heading.tooltip}
      >
        {heading && heading.title && (
          <div
            className={classNames(
              "d-inline-block align-middle",
              heading.className
            )}
          >
            {renderData(
              heading.title,
              heading.title.type === "COMPONENT"
                ? data.heading.title
                : heading.title
            )}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          {heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions)}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  renderActions() {
    const { schema, data } = this.props
    const actionData = data.actions || []
    return (
      <React.Fragment>
        {schema.actions
          && (
            <React.Fragment>
              {renderData(schema.actions, actionData)}
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  render() {
    const { schema = {}, data = {}, dispatch } = this.props
    const { defaultLineStyles, defaultEventStyles } = this.state


    const style = schema.style || {}
    const events = data.events || []

    return (
      <div className={classNames('cvc-w-event-timeline', schema.containerClassName)}>
        {this.renderHeading()}
        <Timeline
          lineStyle={style.lineStyles || defaultLineStyles}
          className={classNames('timeline', schema.className)}
        >
          {
            events.map((event) => {
              const { icon, ...restProps } = event
              const properties = event.properties || []

              return (
                <TimelineEvent
                  {...defaultEventStyles}
                  {...restProps}
                  icon={<i className={icon} />}
                >
                  <Event
                    data={event}
                    schema={schema}
                  >
                    {
                      properties.map((property) => {
                        const propertySchema = schema.properties[property.type]
                        return (
                          <div
                            className="event-timeline-item-content"
                          >
                            {renderData(propertySchema, property.data)}
                          </div>
                        )
                      })
                    }
                  </Event>
                </TimelineEvent>
              )
            })
          }
        </Timeline>
        {this.renderActions()}
      </div>
    )
  }
}

export default EventTimeline