import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactSelect, { createFilter } from 'react-select'
import { defaultTheme } from 'react-select'
import Image from 'core/components/Image'

// styled components

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        right: 0,
      }}
      {...props}
    />
  )
}
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative', cursor: 'pointer' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
)

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 4,
    border: 'none',
    borderWidth: 0,
    boxShadow: 'none',
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    borderBottom: '1px dotted #f1f2f5',
    padding: 10,
    backgroundColor: isFocused ? '#f1f2f5' : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: '#eef6ff',
    },
  }),
  menu: () => ({
    boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
  }),
  groupHeading: (provided) => ({
    ...provided,
    fontSize: 12,
    textTransform: 'unset',
    fontWeight: 'normal',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 175
  })
}

class Select extends Component {
  state = { isOpen: false, value: undefined }
  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }
  onSelectChange = (value) => {
    this.toggleOpen()
    this.setState({ value })
  }
  renderUserImage = ({ altTitle, image }) => {
    return (
      <Image
        schema={{
          style: {
            imageClassName: 'user-image mr-2 p-0',
            textClassName: 'select-user-text-image mr-2',
          },
        }}
        data={{
          altTitle: altTitle,
          styles: {
            width: 24,
            height: 24,
            background: image ? `url(${image})` : null,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          },
        }}
      />
    )
  }
  getLabel = ({ id, label, icon, image, altTitle }) => {
    if (!icon && !image && !altTitle) return label

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          {(image || altTitle) && this.renderUserImage({ altTitle, image })}
          {label && <div className="font-14">{label}</div>}
        </div>
        {icon && <div className={classNames('cursor-auto', icon)}></div>}
      </div>
    )
  }
  render() {
    const { isOpen, value } = this.state
    const { schema, data = {} } = this.props
    const {
      dropdownLabel = '',
      dropdownIcon = {},
      searchIcon,
      style = {}
    } = schema
    const options = data.options || []

    const filterConfig = {
      stringify: (option) => {
        return `${(option.data && option.data.label) || option.label} ${
          option.value
        }`
      },
    }

    const DropdownIndicator = () => (
      <div className="mr-3 d-flex align-items-center">
        <i className={classNames(searchIcon || "icon-edit")}></i>
      </div>
    )
    return (
      <div className={classNames("cvc cvc-c-select", style.containerClassName)}>
        <Dropdown
          isOpen={isOpen}
          onClose={this.toggleOpen}
          target={
            <span
              className={classNames(
                'badge cursor-pointer p-2',
                !isOpen ? 'badge-light' : ''
              )}
              style={{
                backgroundColor: isOpen ? '#cdced2' : '',
              }}
              onClick={this.toggleOpen}
            >
              {dropdownIcon.left && (
                <i
                  className={classNames(
                    dropdownIcon.left || 'fa fa-angle-down fa-2x'
                  )}
                ></i>
              )}
              {!isNaN(data.badge) && (
                <span
                  className={classNames(
                    style.badgeText || 'font-16 pl-1 font-weight-normal'
                  )}
                >
                  {data.badge}
                </span>
              )}
              {dropdownLabel && (
                <span className={classNames('font-16 pl-1 font-weight-normal')}>
                  {dropdownLabel}
                </span>
              )}
              {dropdownIcon.right && (
                <i
                  className={classNames(
                    dropdownIcon.right || 'fa fa-angle-down fa-2x pl-1'
                  )}
                ></i>
              )}
            </span>
          }
        >
          <ReactSelect
            autoFocus
            backspaceRemovesValue={false}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={this.onSelectChange}
            getOptionLabel={this.getLabel}
            options={options}
            placeholder="Search..."
            styles={selectStyles}
            tabSelectsValue={false}
            filterOption={createFilter(filterConfig)}
            // value={value}
          />
        </Dropdown>
      </div>
    )
  }
}

Select.propTypes = {
  schema: PropTypes.shape(),
  data: PropTypes.shape(),
}

export default Select
