import React from 'react'
import PropTypes from 'prop-types'

import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const CustomTooltip = (props) => {
  const { active, payload, label } = props
  if (active && payload && payload.length) {
    const newPayload = payload.map((oldPayload) => {
      const tooltipName = oldPayload.payload[oldPayload.dataKey + '_tooltip_name']
      const tooltipValue = oldPayload.payload[oldPayload.dataKey + '_tooltip_value']

      const updatedPayload = {
        ...oldPayload,
        name: tooltipName ? tooltipName : oldPayload.name,
        value: tooltipValue ? tooltipValue : oldPayload.value
      }
      return updatedPayload
    })

    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  return <DefaultTooltipContent {...props} />;
};

export default CustomTooltip;