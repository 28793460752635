import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { renderData } from '../helpers/utils'
import { useDispatch } from 'react-redux'
import Switcher from './Switcher'
import Action from './Action'


const Header = (props) => {
  const {
    title,
    subtitle,
    notifications,
    actions,
    data = {},
    alert,
    style={}
  } = props
  const {
    prevPageSchema = {},
    switcherSchema
  } = props
  const defaultSchema = {
    'prevPageSchema': {
      'id': 'prev-page',
      'type': 'LINK',
      'title': prevPageSchema.title,
      'label': '',
      'linkUrl': prevPageSchema.prevPageUrl || '',
      'style': {
        'className': 'd-inline-block px-2 text-btn',
        'iconClassName': prevPageSchema.iconClassName || 'back-arrow'
      }
    }
  }
  const tooltip = {
    'data-tip': 'You have no unread notifications'
  }

  const dispatch = useDispatch()

  return (
    <div className="cvc-c-header col-lg-12 content-main-header pl-0">
      {alert && <div className={classNames('alert', alert.style ? alert.style.className : '')} role="alert" dangerouslySetInnerHTML={{
          __html: alert.html || ''
        }} />
      }
      <div className={classNames('header-container d-flex justify-content-between align-items-start w-100', style.containerClassName)}>
        <div className="d-flex">
          {prevPageSchema && prevPageSchema.prevPageUrl
            && <Action schema={defaultSchema.prevPageSchema} data={{}} dispatch={dispatch} />
          }
          <div className={classNames('title-container', style.className, { 'title-small': subtitle })}>
            <div className="title">
              {
                (title && title.type === 'COMPONENT') ?
                  renderData(title, data && data.title ? data.title : '') :
                  title
              }
            </div>
            <p className="sub-title m-0">
              {
                (subtitle && subtitle.type === 'COMPONENT') ?
                  renderData(subtitle, data && data.subtitle ? data.subtitle : '') :
                  subtitle
              }
            </p>
          </div>
        </div>

        <div className="panel-actions justify-content-end">
          {switcherSchema && (
            <Switcher
              schema={switcherSchema}
              data={data.switcher}
            />
          )}
          {actions
             && renderData(actions, data)
           }
          {/*notifications !== false
            && <div className="notification" {...tooltip} />
          */}
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  prevPageSchema: PropTypes.object,
  switcherSchema: PropTypes.object,
  notifications: PropTypes.bool,
  actions: PropTypes.object
}

Header.defaultProps = {
  notifications: true
}

export default Header
