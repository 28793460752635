import React from 'react'
import PropTypes from 'prop-types'
import { renderData } from '../../../../helpers/utils'

var total = {}


const RADIAN = Math.PI / 180

const Label = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    endAngle,
    value,
    dataType,
    id,
    angle,
    index,
    labelsCount,
    fontSize = 10,
    color = '#000',
    labelProps = {}
  } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 2;
  const x = cx + radius * Math.cos((270 + (angle||0)) * RADIAN);
  const y = cy + radius * Math.sin((270 + (angle||0)) * RADIAN);

  total[id] = (total[id] || 0) + value;
  if(labelsCount - 1 === index && total[id])
  {
    let value = total[id]
    total[id] = null
    return (
      <text
        fontSize={fontSize}
        x={x}
        y={y}
        fill={color}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {renderData({ ...labelProps, dataType: dataType || 'CURRENCY' }, value)}
      </text>
    );
  }
  else
  {
    return (null)
  }
}

Label.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  value: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number
}

export default Label