import { createSelector } from 'reselect'

import getFormatter from 'core/helpers/formatters'

export const formatFilterData = createSelector(
  getFilterData, ({ data: filter = {}, displayFilters = {} }) => {
    if (!filter) return []
    let formattedFilter = []
    Object.keys(filter).forEach(key => {
      let label = null
      if (Array.isArray(filter[key])) {
        let filterValues = [...new Set(filter[key])]
        let displayFilterValues = displayFilters[key] ? displayFilters[key] : filterValues

        filterValues.forEach((filterVal, index) => {
          const labelPrefix = getFormatter({ dataType: 'STRING' })(null, key)
          formattedFilter.push({
            key,
            value: filterVal,
            label: `${labelPrefix}: ${displayFilterValues[index]}`,
            displayFilterValue: displayFilterValues[index]
          })
        })
      } else if(filter[key]) {
        const labelPrefix = getFormatter({ dataType: 'STRING' })(null, key)
        formattedFilter.push({
          key,
          value: filter[key],
          label: `${labelPrefix}: ${displayFilters[key] ? displayFilters[key] : filter[key]}`,
          displayFilterValue: `${displayFilters[key] ? displayFilters[key] : filter[key]}`
        })
      }
    })
    return formattedFilter
  }
)

function getFilterData(state) {
  if (!state) return null

  return { data: state.data, displayFilters: state.displayFilters }
}
