import React, { PureComponent } from "react";
import { combineProps } from "core/helpers/utils";
import moment from "moment"

import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { renderData } from "../../helpers/utils";

const dateFormat = window.CC_MOMENT_JS_DATE_FORMAT;

const addDays = (date, days) => {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}

const shorDate = (date) => {
  return moment(date).format("MMM-YYYY");
}

const CustomizedDot = (props) => {
  const { index, last, cx, cy, r, stroke, strokeWidth, fill } = props;

  if (index === 0) {
    return null;
  } else if (index < last) {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={r}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        style={{ backgroundClip: "content-box", padding: "10px" }}
        onMouseEnter={() => document.getElementById(`timeline-event-${index - 1}`).classList.toggle("event-mouseover")}
        onMouseLeave={() => document.getElementById(`timeline-event-${index - 1}`).classList.toggle("event-mouseover")}
      />
    );
  } else {
    return (
      <rect
        x={cx - r - 3}
        y={cy - r + 3}
        width={r * 2}
        height={r * 2}
        style={{ fill: "red", strokeWidth: 1, stroke: "white", backgroundClip: "content-box" }}
        onMouseEnter={() => document.getElementById(`timeline-event-${index - 1}`).classList.toggle("event-mouseover")}
        onMouseLeave={() => document.getElementById(`timeline-event-${index - 1}`).classList.toggle("event-mouseover")}
      />
    );
  }
};


class EvolutionSegment extends Line {
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  rgbToHex(rgb) {
    return (
      "#" +
      ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)
    );
  }

  getGradientColor = (percent, fromColor, toColor) => {
    const fromRgb = this.hexToRgb(fromColor);
    const toRgb = this.hexToRgb(toColor);
    return this.rgbToHex({
      r: Math.round(fromRgb.r + (toRgb.r - fromRgb.r) * percent),
      g: Math.round(fromRgb.g + (toRgb.g - fromRgb.g) * percent),
      b: Math.round(fromRgb.b + (toRgb.b - fromRgb.b) * percent),
    });
  };

  getX = (a, b, zX) => {
    return `${a.x},${a.y} ${b.x},${b.y} ${zX},${b.y} ${zX},${a.y}`;
  };

  getY = (a, b, zY) => {
    return `${a.x},${a.y} ${b.x},${b.y} ${b.x},${zY} ${a.x},${zY}`;
  };

  render() {
    const { points, fill, xfill, yfill, stroke, strokeWidth, left, bottom, gradientOffset } = this.props;
    const yFormatter = this.props.yAxis.tickFormatter;
    const polygons = [];
    const stepPoints = (points.length - 2) + gradientOffset;
    const stepSize = 1 / (stepPoints > 0 ? stepPoints : 1);

    for (let i = 1; i < points.length; i++) {
      let p1 = this.getX(points[i - 1], points[i], points[0].x);
      let p2 = this.getY(points[i - 1], points[i], points[0].y);

      let xColor = this.getGradientColor(stepSize * (i - 1), xfill, fill);
      let yColor = this.getGradientColor(stepSize * (i - 1), yfill, fill);

      polygons.push(
        <polygon
          key={`x-poly-${i}`}
          points={p1}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill={xColor}
        />
      );
      polygons.push(
        <polygon
          key={`y-poly-${i}`}
          points={p2}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill={yColor}
        />
      );

      if (i > 1) {
        polygons.unshift(
          <text
            key={`budget_increase_${i}`}
            y={points[i].y}
            textRendering="geometricPrecision"
            className={"small"}
            style={{ fill: "#555" }}
          >
            <tspan x={left + 5} dy="20" textAnchor="start">
              +{yFormatter(points[i].payload.budget_increase)}
            </tspan>
          </text>
        );

        polygons.unshift(
          <text
            key={`timeline_increase_${i}`}
            y={points[0].y - 32}
            textRendering="geometricPrecision"
            className={"small"}
            style={{ fill: "#555" }}
          >
            <tspan x={points[i].x - 5} dy="1em" textAnchor="end">+{points[i].payload.duration_increase}</tspan>
            <tspan x={points[i].x - 5} dy="1em" textAnchor="end">wks</tspan>
          </text>
        );
      }
    }

    polygons.unshift(
      <text
        key="budget"
        y={points[1].y + 30}
        textRendering="geometricPrecision"
        style={{ font: "normal 18px Sans-Serif", fill: "#fff" }}
      >
        <tspan x={points[0].x + 10}>Initial Budget</tspan>
        <tspan x={points[0].x + 10} dy="1.2em">
          {yFormatter(points[1].payload.budget)}
        </tspan>
      </text>
    );
    polygons.unshift(
      <text
        key="eta"
        y={points[0].y - 30}
        textAnchor="end"
        textRendering="geometricPrecision"
        style={{ font: "normal 18px Sans-Serif", fill: "#fff" }}
      >
        <tspan x={points[1].x - 10}>Initial ETA</tspan>
        <tspan x={points[1].x - 10} dy="1.2em">
          {points[1].payload.duration} wks
        </tspan>
      </text>
    );
    return <React.Fragment>{polygons.reverse()}</React.Fragment>;
  }
}


const Evolution = (props) => {
  const defaultSchema = {
    container: {
      width: "90%",
      height: 450,
    },
    chart: {
      margin: {
        top: 10,
        right: 20,
        left: 30,
        bottom: 15,
      },
    },
    xAxis: {},
    yAxis: {},
    cartesian: {
      vertical: false, // To hide/show vertical cartesian lines
      horizontal: false, // To hide/show horizontal cartesian lines
      strokeDasharray: "3 3",
      fill: "#ccc"
    },
    segment: {
      gradientOffset: 0
    },
  };
  const { schema, data } = props;
  const { cartesian, container, chart, segment, xAxis, yAxis } = schema;
  const containerProps = combineProps(defaultSchema.container, combineProps(container, data.container));
  const cartesianProps = combineProps(defaultSchema.cartesian, combineProps(cartesian, data.cartesian));
  const chartProps = combineProps(defaultSchema.chart, combineProps(chart, data.chart));
  const segmentProps = combineProps(defaultSchema.segment, combineProps(segment, data.segment));
  const segmentCount = data.graph.length - 1;
  const initialDate = new Date(data.graph[0].date);
  const ticks = data.graph.map(item => item[xAxis.dataKey]);
  let prevQuarter = ""
  const xAxisFormat = (tick) => {
    let tickDate = addDays(initialDate, 7 * tick);
    let year = moment(tickDate).format("YY");
    let quarter = moment(tickDate).quarter();
    let curQuarter = `Q${quarter}'${year}`;
    prevQuarter = prevQuarter === curQuarter ? "" : curQuarter;
    return prevQuarter;
  }

  const customTick = (tick) => {
    const { x, y, payload } = tick;
    let tickValue = xAxisFormat(payload.value)
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-40)">
          {tickValue}
        </text>
      </g>
    );
  }

  return (
    <ResponsiveContainer {...containerProps} >
      <ComposedChart data={data.graph} {...chartProps}>
        <CartesianGrid {...cartesianProps} />
        <XAxis
          dataKey={xAxis.dataKey}
          type="number"
          domain={['dataMin', 'dataMax']}
          allowDataOverflow={true}
          allowDuplicatedCategory={false}
          axisLine={{ stroke: "#FED840", strokeWidth: 6 }}
          ticks={ticks}
          tickSize={3}
          tick={customTick}
        />
        <YAxis
          dataKey={yAxis.dataKey}
          type="number"
          ticksSize={3}
          domain={[0, "dataMax"]}
          axisLine={{ stroke: "#FED840", strokeWidth: 6 }}
          tickFormatter={(tick, type) => renderData({ dataType: type || "CURRENCY" }, tick)}
        />
        <EvolutionSegment
          dataKey={yAxis.dataKey}
          fill="#CCCCCC"
          xfill="#3FAB3C"
          yfill="#00A0E3"
          stroke="white"
          strokeWidth="1"
          {...segmentProps}
        />
        <Line
          dataKey={yAxis.dataKey}
          stroke="#fff"
          strokeWidth={2}
          dot={
            <CustomizedDot
              last={segmentCount}
              fill="#FECC00"
              r={6}
              strokeWidth={1}
            />
          }
          activeDot={
            <CustomizedDot
              last={segmentCount}
              fill="#EF7F1A"
              r={8}
              strokeWidth={1}
            />
          }
          animated={false}
        />
        <Tooltip
          cursor={false}
          offset={20}
          contentStyle={{ visibility: "hidden" }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default Evolution;
