import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { find, pickBy } from 'lodash/fp'
import WidgetRenderer from '../../renderers/WidgetRenderer'
import * as widgetActions from '../actions/widgetActionCreators'
import * as filterActions from '../actions/filtersActionCreators'
import * as widgetCacheActions from '../actions/widgetCacheActionCreators'
import { FILTER_TYPE_PAGE } from '../../helpers/constants';
import { getFilterPageIdKey, getPageIdKey } from 'core/helpers/utils'

const mapStateToProps = (state, ownProps) => {
  const {
    widgetGroupId,
    widgetId,
    subscribedFilterId,
    spreadId
  } = ownProps
  const { page, spread } = state
  const widgets = state.widget[widgetGroupId] || {}
  const widget = widgets[widgetId] || {}
  let spreads = state.spread.asMutable({ deep: true })
  Object.keys(spreads).forEach(key => {
    delete spreads[key].encapsulators
    spreads[key].spreads.forEach(spread => {
      delete spread.encapsulators
    })
  })

  const pageFilters = pickBy((filter, key) => {
    return key === `${getPageIdKey()}-${filter.id}`
  }, state.filters)


  const pageFilter = !widget.ignorePageFilter ? find((filter) => {
      const filterId = getFilterPageIdKey(filter.id)
      return filter.type === FILTER_TYPE_PAGE && pageFilters[filterId]
    }, Object.values(pageFilters)) : null

  const widgetFilter = find((filter) => {
    const filterId = getFilterPageIdKey(filter.id)
    return (
      filter.type !== FILTER_TYPE_PAGE &&
      pageFilters[filterId] &&
      pageFilters[filterId].id === subscribedFilterId
    );
  }, Object.values(pageFilters))

  return {
    spreads,
    widget,
    spreads: spreadId ? spread[spreadId].asMutable() : {},
    pageFilter,
    widgetFilter,
    pageId: page.schema.id,
    filters: Object.values(state.filters),
    widgets: state.widget
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      ...widgetActions,
      ...filterActions,
      ...widgetCacheActions
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetRenderer)
