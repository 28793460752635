import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ className }) => (
  <div className={className}><div></div><div></div><div></div></div>
)

Loader.propTypes = {
  className: PropTypes.string
}

Loader.defaultProps = {
  className: 'loader'
}

export default Loader
