import React from 'react'
import PropTypes from 'prop-types'

import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, Tooltip, Legend, LabelList } from 'recharts'
import { combineProps, colors } from 'core/helpers/utils'

import CustomLabel from './helpers/CustomLabel'
/* eslint-disable import/no-unresolved */
import shapesMap from '../graph/helpers/shapes'

const Bubble = (props) => {
  const { schema, data } = props
  const { graph: graphData, events: eventsData = {} } = data

  const defaultSchema = {
    containerStyle: {
      width: '100%',
      height: 100
    },
    xAxis: {
      padding: { left: 10, right: 10 },
      type: 'category',
      tickLine: false,
      axisLine: false,
      tick: {
        fill: 'gray',
        fontSize: 12
      },
      tickCount: graphData.length
    },
    yAxis: {
      type: 'number',
      tick: false,
      tickLine: false,
      axisLine: false,
      hide: true
    },
    legend: {
      show: false,
      iconType: 'circle',
      iconSize: 8,
      align: 'left',
      verticalAlign: 'bottom',
      wrapperStyle: {
        color: colors.gray,
        fontSize: '12px'
      }
    }
  }

  const { style, xAxis, yAxis, tooltip, legend, events, childProps } = schema
  const { label = {}, shape, ...otherScatterProps } = childProps
  const { containerStyle, ...otherStyle } = style || {}
  const { type, ...otherLabelProps } = label || {}
  const ShapeToRender = (typeof shape === 'object' && shape.type === 'custom') ? shapesMap[shape.name] : undefined
  const chartContainerProps = combineProps(defaultSchema.containerStyle, containerStyle)
  const xAxisProps = combineProps(defaultSchema.xAxis, xAxis)
  const yAxisProps = combineProps(defaultSchema.yAxis, yAxis)
  const scatterProps = combineProps({ shape: (ShapeToRender ? <ShapeToRender {...shape} /> : shape) }, otherScatterProps)
  const labelProps = type === 'custom' ? combineProps({ content: CustomLabel }, otherLabelProps) : otherLabelProps
  const legendProps = combineProps(defaultSchema.legend, legend)

  const { show: showLegend, wrapperStyle, ...otherLegendProps } = legendProps
  const eventSchema = events ? events.onclick : null
  const eventData = events ? eventsData[events.onclick.id] || {} : null
  return (
    <ResponsiveContainer {...chartContainerProps}>
      <ScatterChart {...otherStyle} onClick={events ? () => props.onClickHandler(eventSchema, eventData) : undefined}>
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        {showLegend
          && <Legend wrapperStyle={combineProps(defaultSchema.legend.wrapperStyle, wrapperStyle)} {...otherLegendProps} />
        }
        {tooltip && <Tooltip {...tooltip} />}
        <Scatter data={graphData} {...scatterProps}>
          {label && <LabelList {...labelProps} />}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  )
}

Bubble.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func.isRequired
}

export default Bubble
