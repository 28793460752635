import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bindAll from 'lodash.bindall'
import moment from 'moment'
import shortid from 'shortid'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import ReactTooltip from 'react-tooltip'

import { cleanObject, getUrlParam, setUrlParam } from 'libs/utils'
import requestsManager from 'libs/api/requestsManager'
import Button from 'libs/components/Button'
import FilterPanel from 'libs/components/FilterPanel'
import FilterItem from 'libs/components/FilterItem'
import NotificationComponentHoc from 'libs/components/NotificationComponentHoc'
import TabList from 'libs/components/TabList'
import AutoComplete from 'libs/components/AutoComplete'

import Header from '../common/Header'
import EntityItem from '../common/EntityItem'
import TaskModal from '../tasks/TaskModal'
import Notes from 'core/components/Notes'
import Switcher from 'core/components/Switcher'
import Select from 'core/components/Select'

const tabItemList = ['Created', 'Notified', 'All']
const defaultTabItem = tabItemList[2]

class Index extends React.Component {
  constructor() {
    super()
    // Setting the selected tab in constructor so that getFilterUrl gets the
    // selected tab present in the URL's query string
    const selectedTab = this.getInitialTab()
    setUrlParam({ tab: selectedTab })
    this.state = {
      notes: [],
      clients: [],
      entityTypeList: {},
      notesLoading: false,
      selectedChecklist: null,
      tabItems: tabItemList,
      entities: [],
      selectedTab,
      filters: { startDate: moment(), endDate: null, client: null, noteableType: '' },
      selectedFilters: { startDate: moment(), endDate: null, client: null, noteableType: '' },
      showFilters: false,
      filterApplied: false,
      selectedEntity: null,
      editableNote: null,
      clientId: null,
      saving: false
    }
    bindAll(this,
      'getNoteables',
      'getInitialTab',
      'assignTask',
      'setSelectedChecklist',
      'selectEntity',
      'setEntityUrl',
      'createNewNote',
      'notesApiRequestHandler',
      'hideTaskModal',
      'getFilterUrl',
      'setSelectedNav',
      'showFilters',
      'hideFilters',
      'removeFilters',
      'showNotesModal',
      'hideNotesModal'
    )
  }

  componentDidMount() {
    const { apis, noteableEntities } = this.props
    const entityTypeList = noteableEntities
    const clientId = getUrlParam('client_id', apis.noteables)
    this.setState({ clientId, entityTypeList })
    this.getNoteables(false)
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  getInitialTab() {
    const tabFromUrl = getUrlParam('tab')
    return tabItemList.indexOf(tabFromUrl) > -1 ? tabFromUrl : defaultTabItem
  }

  getNoteables(resetSelectedEntity = true) {
    const liIdFromUrl = parseInt(getUrlParam('li_id'))
    const liTypeFromUrl = getUrlParam('li_type')
    const itemIdFromUrl = parseInt(getUrlParam('l_id'))
    const noteableUrl = this.getFilterUrl()
    this.setState({ selectedChecklist: null })
    requestsManager.fetchEntities(noteableUrl)
      .then((res) => {
        const { id, name, slug, noteables } = res.data
        const defaultSelectedEntity = noteables[0]
        const client = slug ? { id, name, slug } : null
        const selectedEntity =
          (liIdFromUrl && !resetSelectedEntity) ?
            noteables.find(note =>
              note.noteable_id === liIdFromUrl && note.noteable_type === liTypeFromUrl)
          : (itemIdFromUrl) ? noteables.find(note => note.id === itemIdFromUrl )
          || defaultSelectedEntity : defaultSelectedEntity
        // Set the new entity_id in the URL
        if (selectedEntity) {
          this.setEntityUrl(selectedEntity)
        }
        this.setState({
          entities: noteables,
          client,
          selectedEntity,
        })
      })
  }

  setEntityUrl(entity) {
    setUrlParam({
      li_id: entity.noteable_id,
      li_type: entity.noteable_type,
      l_id: entity.id,
    })
  }

  setSelectedChecklist(checklist) {
    this.node.scrollIntoView()
    this.setState({ selectedChecklist: checklist})
  }

  setSelectedNav(selectedTab) {
    this.setState({ selectedTab, creatingNote: false }, this.getNoteables)
    setUrlParam({ tab: selectedTab })
  }

  getFilterUrl() {
    const { selectedFilters, selectedTab, entityTypeList } = this.state
    const { apis } = this.props
    const { startDate, endDate, client, noteableType } = selectedFilters
    let url = apis.noteables
    const params = {
      filter: {
        client_id: client ? client.id : null,
        action: selectedTab.toLowerCase(),
        noteable_type: entityTypeList[noteableType],
        created_at_from: startDate && endDate ? moment(startDate).format('YYYY-MM-DD') : '',
        created_at_to: startDate && endDate ? moment(endDate).format('YYYY-MM-DD') : ''
      }
    }
    cleanObject(params)
    if (Object.keys(params).length > 0) {
      url += `${url.indexOf('?') >= 0 ? '&' : '?'}${$.param(params)}`
    }
    return url
  }

  selectEntity(entity) {
    this.setEntityUrl(entity)
    this.setState({
      selectedEntity: entity,
      selectedChecklist: null,
      creatingNote: entity.id === null,
    })
  }

  createNewNote() {
    const { entities, client } = this.state
    const noteable = { id: null, client_id: client.id, client_name: client.name, client_slug: client.slug, title: 'New  Note', created_at: moment().toString(), localId: shortid.generate() }
    this.setState({ entities: [noteable].concat(entities), selectedEntity: noteable, creatingNote: true, selectedChecklist: null })
  }

  notesApiRequestHandler({ note }) {
    if (note.id) {
      const { selectedEntity, entities } = this.state
      const entity = {
        ...selectedEntity,
        title: note.description || selectedEntity.title
      }
      const updatedEntities = [entity].concat(entities.slice(1))
      this.setState({ entities: updatedEntities }, () => {
        // if new noteables are not fetched then fetch it once
        if (!selectedEntity.id) {
          this.getNoteables()
        }
      })
    }
  }

  assignTask(taskableTitle) {
    const { noteable_id: taskableId, noteable_type: taskableType } = this.state.selectedEntity
    this.setState({ showTaskModal: true, taskableTitle, taskableId, taskableType}, () => { $('#taskModal').modal('show') })
  }

  showFilters() {
    this.setState({ showFilters: true}, () => { $('#filtersModal').modal('show') })
  }

  hideTaskModal(taskAssigned = false) {
    if (taskAssigned) {
      this.setState({ showTaskModal: false, selectedChecklist: null })
    } else {
      this.setState({ showTaskModal: false })
    }
  }

  hideFilters(updateNoteables = false) {
    const { filters, selectedFilters } = this.state
    if (updateNoteables) {
      this.setState({ selectedFilters: filters, showFilters: false, filterApplied: true, creatingNote: false }, this.getNoteables)
    } else {
      // reset filters
      this.setState({ filters: selectedFilters, showFilters: false })
    }
  }

  removeFilters() {
    const filters = { startDate: moment(), endDate: null, client: null, noteableType: '' }
    this.setState({ filters, selectedFilters: filters, filterApplied: false }, this.getNoteables)
  }

  showNotesModal(note) {
    this.setState({ editableNote: note }, () => { $('#notes').modal('show') })
  }

  hideNotesModal(noteUpdated = false) {
    this.setState({ editableNote: null }, () => {
      if (noteUpdated) {
        this.getNoteables(false)
      }
    })
  }

  get formattedDateRange() {
    const { startDate, endDate } = this.state.filters
    return (startDate && endDate) ? `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}` : ''
  }

  notesListUpdated = ({ saving, apiError }) => {
    if (saving) {
      this.setState({ saving })
    } else {
      setTimeout(() => this.setState({ saving }), 1000)
    }
  }

  render() {
    const {
      clientId,
      entities,
      selectedEntity,
      notes,
      notesLoading,
      client,
      selectedChecklist,
      filters,
      showFilters,
      filterApplied,
      showTaskModal,
      taskableTitle,
      taskableId,
      taskableType,
      editableNote,
      selectedTab,
      creatingNote,
      entityTypeList,
      selectedFilters,
      tabItems,
    } = this.state
    const { startDate, endDate } = selectedFilters
    const entityTypes = Object.keys(entityTypeList)
    const { currentUser, addNotification, apis} = this.props
    const isFilterApplied = filters.client !== null || filters.noteableType !== '' || moment(filters.endDate).isValid()
    const showCreateNoteBtn = client && (selectedTab !== 'Notified') && !filterApplied
    const disableCreateNoteBtn = (creatingNote && selectedEntity.id === null) || (entities.length > 0 && entities[0].id === null)
    return (
      <div className="content-main pb-2" ref={(node) => { this.node = node }}>
        <div className="row">
          <Header
            clientSwitcher={
              clientId && currentUser.internal ? (
                <Switcher
                  schema={{
                    selectedItem: {
                     id: this.props.client.id,
                     label: this.props.client.name
                    },
                    redirectUrl: '/clients',
                    switcherApi: '/clients.json',
                    dropdownLabel: 'Switch Customers',
                  }}
                />
              ) : null
            }
            title="Notes"
          />
        </div>
        <div className="row px-md-none">
          <div className="col-lg-4 section-cntr">
            <div className="content-main-sec sec-large p-0">
              <ul className="tasks">
                <div className="header">
                  {filterApplied ? (
                    <p className="d-inline-block"> Filter </p>
                  ) : (
                    <TabList items={tabItems} selectedItem={selectedTab} onItemSelect={this.setSelectedNav} />
                  )}
                  <div className="float-right notes-controls">
                    <span className={`icons ${filterApplied ? 'filter-notes-applied' : 'filter-notes'}`} title="Apply Filters" role="presentation" onClick={this.showFilters} />
                    {filterApplied &&
                      <span className="icons close" role="presentation" onClick={this.removeFilters} />
                    }
                  </div>
                </div>
                <ul className="notes">
                  {entities.map((entity) => {
                    const isSelected = selectedEntity && selectedEntity.id ? (entity.id === selectedEntity.id) : (selectedEntity ? selectedEntity.localId === entity.localId : false )

                    return (
                      <EntityItem
                        title={entity.title}
                        isSelected={isSelected}
                        clientName={entity.client_name}
                        date={entity.created_at}
                        showTime
                        key={shortid.generate()}
                        onClickHandler={() => this.selectEntity(entity)}
                      />
                    )
                  })}
                </ul>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 section-cntr">
            <div className="content-main-sec sec-large p-0">
              <div className="notes">
                <div className="header p-2">
                  <div className="d-flext align-items-center justify-content-start">
                    {this.state.saving && <span className="p-2 text-gray">Saving...</span>}
                    {creatingNote &&
                      <p className="p-2"> New Note </p>
                    }
                    {selectedChecklist &&
                      <Button
                        className="btn btn-assign-task"
                        onClick={() => this.assignTask(selectedChecklist.item)}
                      > Assign Task
                      </Button>
                    }
                  </div>
                  {selectedEntity && <div className="d-flex align-items-center justify-content-end">
                    <Select
                      schema={{
                        dropdownIcon: {
                          left: 'icon-watch'
                        },
                        searchIcon: 'icon-search'
                      }}
                      data={selectedEntity.watchers}
                    />
                  </div>}
                </div>
                <div className="py-3">
                  {selectedEntity && <Notes
                    schema={{
                      hideHeader: true,
                      hideForm: true
                    }}
                    data={{
                      form: {
                        noteable_id: selectedEntity.noteable_id,
                        noteable_type: selectedEntity.noteable_type,
                        client_id: selectedEntity.client_id,
                        user_id: this.props.currentUser.id,
                      },
                      list: {
                        filters: {
                          noteable_id: selectedEntity.noteable_id,
                          noteable_type: selectedEntity.noteable_type,
                          client_id: selectedEntity.client_id,
                          action: selectedTab !== 'All' ? selectedTab.toLowerCase() : '',
                          created_at_from: (startDate && endDate) ? moment(startDate).format('YYYY-MM-DD') : '',
                          created_at_to: (startDate && endDate) ? moment(endDate).format('YYYY-MM-DD') : ''
                        }
                      },
                      watchers: selectedEntity.watchers
                    }}
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showFilters &&
          <FilterPanel onFilterClose={this.hideFilters} disableFilterBtn={!isFilterApplied} >
            <div className="form-group field field-object">
              {!client &&
                <div className="form-group field">
                  <div className="btn-group h-100">
                    <label>Customer Name</label>
                    <AutoComplete
                      api="/clients.json"
                      queryParamKey="name"
                      respDataFormatter={data => data.results}
                      className="form-control input-normal input-invite"
                      placeholder="Select..."
                      valueKey="label"
                      selectedValue={filters.client ? filters.client.label : ''}
                      onSuggestionSelected={(item) => { this.setState({ filters: { ...filters, client: item } }) }}
                    />
                  </div>
                </div>
              }
              <FilterItem
                title={'Entity'}
                items={entityTypes}
                selectedItems={filters.noteableType}
                selectedValue={filters.noteableType}
                onItemSelect={(item) => { this.setState({ filters: { ...filters, noteableType: item } }) }}
              />
              <div className="form-group field">
                <div className="cvc-daterange-picker">
                  <label>Date</label>
                  <div className="btn-group h-100">
                    <button type="button" className={classNames("dropdown-toggle dropdown-regular", { 'text-gray': !this.formattedDateRange })} id="dropdownMenuButton" data-toggle="dropdown">
                      {this.formattedDateRange || 'Select...'}
                    </button>
                  </div>
                  <DateRangePicker
                    startDateId={'startDate'}
                    startDate={filters.startDate}
                    endDateId={'endDate'}
                    endDate={filters.endDate}
                    numberOfMonths={1}
                    daySize={28}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }) => this.setState({ filters: { ...filters, startDate, endDate } })}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                  />
                </div>
              </div>
            </div>
          </FilterPanel>
        }
        {showTaskModal &&
          <TaskModal
            name={taskableTitle}
            entityTitle={selectedEntity.title || ''}
            taskableId={taskableId}
            taskableType={taskableType}
            clientId={selectedEntity.client_id}
            assigneeUrl={apis.assigneeUrl}
            clientName={selectedEntity.client_name}
            onModalHide={this.hideTaskModal}
            addNotification={addNotification}
          />
        }
        <ReactTooltip
          effect="solid"
          multiline={true}
          className="cvc-tooltip"
          place="bottom"
        />
      </div>
    )
  }
}

Index.propTypes = {
  addNotification: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  apis: PropTypes.object.isRequired
}

export default NotificationComponentHoc(Index)
