import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ schema, data }) => {
  if (schema.multiple) {
    if (Array.isArray(data) && data.length == 2) {
      const outer = data[0]
      const inner = data[1]

      return (
        <CircularProgressbarWithChildren
          {...inner}
        >
          {/* Foreground path */}
          <CircularProgressbar
            {...outer}
          />
        </CircularProgressbarWithChildren>
      )
    }
    return <span>invalid data</span>
  }

  const progressBarProps = (data || schema) ? {
    ...schema,
    ...data
  } : null
  return (
    <CircularProgressbar
      {...progressBarProps}
      className={classNames(schema.className, 'cvc-c-circular-progress-bar')}
    />
  )
}

CircularProgressBar.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired
}

export default CircularProgressBar
