import React from 'react'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'


const CommentInput = ({
  value,
  actions,
  maxlength,
  placeholder,
  enableSave,
  children,
  onChange,
  onFocus
}) => {
  return (
    <React.Fragment>
      <Textarea
        minRows={2}
        value={value}
        type="text"
        className="form-control mb-3 comment"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
      />
      {children}
    </React.Fragment>
  )
}

export default CommentInput
