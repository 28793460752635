import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { debounce } from 'throttle-debounce'

import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'


class ApiAutocomplete extends Component {
  state = {
    inputValue: '',
    options: [],
    value: null,
    isLoading: false
  }

  componentDidMount() {
    const { defaultOptions, value } = this.props
    if (value) {
      this.setState({ value })
    }
    if (defaultOptions) {
      this.setState({ isLoading: true })
      this.loadOptions()
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.additionalQueryParams) !== JSON.stringify(prevProps.additionalQueryParams)) {
      this.setState({ value: null, options: [], isLoading: true })
      this.loadOptions()
    }
  }

  loadOptions = () => {
    const {
      autocompleteUrl,
      queryKey,
      respDataFormatter,
      additionalQueryParams
    } = this.props
    const { inputValue } = this.state
    const url = updateQueryStringParams(autocompleteUrl, { ...additionalQueryParams, [queryKey]: inputValue })
    requestsManager.fetchEntities(url).then(res => {
      this.setState({ options: respDataFormatter(res.data), isLoading: false })
    })
  }

  debouncedLoadOptions = debounce(500, this.loadOptions)

  handleItemSelect = (selectedItems) => {
    const { onItemSelect } = this.props
    this.setState({ value: selectedItems })
    onItemSelect(selectedItems)
  }

  handleInputChange = (inputValue) => {
    if (inputValue !== this.state.inputValue) {
      this.setState({ inputValue, isLoading: true, options: [] })
      this.debouncedLoadOptions()
    }
  }

  render() {
    const {
      label,
      readonly,
      multiselect,
      getOptionLabel,
      getOptionValue
    } = this.props
    const {
      inputValue,
      value,
      options,
      isLoading
    } = this.state
    return (
      <React.Fragment>
        {label
          && <label className="sec-label">{label}</label>
        }
        <Select
          options={options}
          inputValue={inputValue}
          className="react-select-container"
          classNamePrefix="react-select"
          menuPlacement="auto"
          isDisabled={readonly}
          isMulti={multiselect}
          isLoading={isLoading}
          value={value}
          onInputChange={this.handleInputChange}
          onChange={this.handleItemSelect}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
        />
      </React.Fragment>
    )
  }
}
ApiAutocomplete.propTypes = {
  label: PropTypes.string,
  readonly: PropTypes.bool,
  multiselect: PropTypes.bool,
  value: PropTypes.object,
  autocompleteUrl: PropTypes.string,
  queryKey: PropTypes.string,
  additionalQueryParams: PropTypes.object,
  respDataFormatter: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onItemSelect: PropTypes.func,
  defaultOptions: PropTypes.any
}
export default ApiAutocomplete
