import React from "react"
export const DragHandle = (props) => {
  const { isDragging, ...rest } = props
  return (
    <div
      {...rest}
      className="data-wrapper d-inline-block"
      style={{
        height: "1rem",
        cursor: isDragging ? "grabbing" : "grab",
      }}
    />
  )
}