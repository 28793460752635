import React from 'react'
import PropTypes from 'prop-types'
import SpreadContainer from 'core/redux/containers/SpreadContainer'


const Spread = (props) => {
  const { widgetId, data, schema } = props
  return (
    <SpreadContainer
      id={widgetId}
      data={data}
      schema={schema}
    />
  )
}

Spread.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  widgetId: PropTypes.string
}

export default Spread
