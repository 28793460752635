import { isEmpty } from 'lodash/fp'

function cleanObject(obj) {
  const properties = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < properties.length; i += 1) {
    const prop = properties[i]
    if (obj[prop] && typeof obj[prop] === 'object') {
      cleanObject(obj[prop])
    }
    if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '' || (typeof obj[prop] === 'object' && Object.keys(obj[prop]).length === 0)) {
      delete obj[prop]
    }
  }
}

function getUrlParam(name, url) {
  let urlStr = url
  if (!urlStr) urlStr = window.location.href
  const nameStr = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${nameStr}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(decodeURIComponent(urlStr))
  if (!results) return null
  if (!results[2]) return ''
  return results[2].replace(/\+/g, ' ')
}

function setUrlParam(urlParamObj) {
  if (history.pushState) {
    let newQueryString = window.location.search
    Object.keys(urlParamObj).forEach(key => {
      newQueryString = updateOrAddQueryString(newQueryString, key, urlParamObj[key])
    })
    const newurl =
      window.location.protocol + "//" + window.location.host + window.location.pathname + newQueryString
    window.history.pushState({ path: newurl },'',newurl)
  }
}

function updateOrAddQueryString(uri, key, value) {
  if (!uri) return uri
  const re = new RegExp("([?&])("+ key + "=)[^&#]*", "g");
  if (uri.match(re))
    return value === null ? uri.replace(re, '') : uri.replace(re, '$1$2' + value)

  // need to add parameter to URI
  if (value) {
    const paramString = (uri.indexOf('?') < 0 ? "?" : "&") + key + "=" + value;
    const hashIndex = uri.indexOf('#');
    if (hashIndex < 0)
      return uri + paramString
    else
      return uri.substring(0, hashIndex) + paramString + uri.substring(hashIndex)
  }
  return uri
}

function parseValue(val, decimals = 2) {
  if (val != null) {
    return parseFloat(parseFloat(val).toFixed(decimals))
  }
  return null
}

function formatCount(value, decimals) {
  if (value === null) {
    return 0
  }
  const valAbbrs = ['', 'K', 'M', 'B']
  const i = parseValue(value) === 0 ? value : Math.floor(Math.log(value) / Math.log(1000))
  let result = parseValue(value / (1000 ** i), decimals)
  result += valAbbrs[parseValue(i, 0)]
  return result
}

function disableExponentCharacter(e) {
  const type = e.target.type
  const keyCode = e.keyCode
  if (type === 'number' && (keyCode === 69 || keyCode === 101)) { // disabled 'e' and 'E' characters
    e.preventDefault()
    return false
  }
}

function clone(object) {
  return JSON.parse(JSON.stringify(object));
}

function formatError(data) {
  if (!data) return 'Unknown error occured.';

  let errorText = '';

  const { base, errors } = data

  if (base) {
    errorText += Array.isArray(base) ? base.join(', ') : base
  }

  if (errors) {
    if (errors.base) {
      errorText += Array.isArray(errors.base) ? errors.base.join(', ') : errors.base
    } else {
      errorText += Array.isArray(errors) ? errors.join(', ') : errors
    }
  }

  return errorText;
}

function getErrorText(error) {
  const statusCode = error.response ? error.response.status : null;
  const response = error && error.response ? error.response : {}
  const { data, status } = response
  return formatError(data)
}

/*
  formats: {}

  template: { "a": "{b.c}" }
  data: { b: { c: "replaced"} }

  output: { a: "relaced"}
 */
function populateJSON(template, data, format = {}) {
  if (isEmpty(data)) return template;

  const templateString = JSON.stringify(template)
  const formatsRegexes = {
    '{}': /\{([\w\.]*)\}/g,
    '<>': /\<([\w\.]*)\>/g
  }

  const replaceRegex = formatsRegexes[format] || /\{([\w\.]*)\}/g;

  const resultTemplate = templateString.replace(replaceRegex, function(str, key) {
    var keys = key.split("."), v = data[keys.shift()];
    for (var i = 0, l = keys.length; i < l; i++) v = v && v[keys[i]];
    return (typeof v !== "undefined" && v !== null) ? v : undefined;
  });

  const object = JSON.parse(resultTemplate, (key, value) => {
    if (value === 'undefined') return undefined
    return value
  })

  const obj = JSON.parse(JSON.stringify(object))
  // TODO: Missing support for 'array'. Remove this once support is added.
  if (obj['tableRow'] && obj['tableRow']['data_point'] && obj['tableRow']['data_point']['data'] && obj['tableRow']['data_point']['data']['roles'] ) {
    obj['tableRow']['data_point']['data']['roles'] = obj['tableRow']['data_point']['data']['roles'].split(',')
  }
  console.log("obj: " + JSON.stringify(obj, null, 2))

  return obj
}

/**
 * Required string format
 *  FUNCTION:<function Name> function a() {}
 *
 * This will create a function named "a" in the window and return
 * window.a function.
 */
function convertStringToFn(fnString) {
  if (typeof fnString === 'string' && fnString.startsWith('FUNCTION:')) {
    try {
      const trimmedString = fnString.replace('FUNCTION:', '')
      const callbackName = trimmedString.substring(0, trimmedString.indexOf(' '))

      eval(fnString.replace(callbackName, `window["${callbackName}"]=`))
      return window[callbackName]
    } catch(e) {
      throw `Invalid function syntax in function schema: "${fnString}"`
    }
  }
  return fnString
}

// rjsf - util
function isArguments(object) {
  return Object.prototype.toString.call(object) === "[object Arguments]";
}

// rjsf - util
function dataURItoBlob(dataURI) {
  // Split metadata from data
  const splitted = dataURI.split(",");
  // Split params
  const params = splitted[0].split(";");
  // Get mime-type from params
  const type = params[0].replace("data:", "");
  // Filter the name property from params
  const properties = params.filter(param => {
    return param.split("=")[0] === "name";
  });
  // Look for the name and use unknown if no name property.
  let name;
  if (properties.length !== 1) {
    name = "unknown";
  } else {
    // Because we filtered out the other property,
    // we only have the name case here.
    name = properties[0].split("=")[1];
  }

  // Built the Uint8Array Blob parameter from the base64 string.
  const binary = atob(splitted[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Create the blob object
  const blob = new window.Blob([new Uint8Array(array)], { type });

  return { blob, name };
}

// rjsf - util
function deepEquals(a, b, ca = [], cb = []) {
  // Partially extracted from node-deeper and adapted to exclude comparison
  // checks for functions.
  // https://github.com/othiym23/node-deeper
  if (a === b) {
    return true;
  } else if (typeof a === "function" || typeof b === "function") {
    // Assume all functions are equivalent
    // see https://github.com/rjsf-team/react-jsonschema-form/issues/255
    return true;
  } else if (typeof a !== "object" || typeof b !== "object") {
    return false;
  } else if (a === null || b === null) {
    return false;
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  } else if (a instanceof RegExp && b instanceof RegExp) {
    return (
      a.source === b.source &&
      a.global === b.global &&
      a.multiline === b.multiline &&
      a.lastIndex === b.lastIndex &&
      a.ignoreCase === b.ignoreCase
    );
  } else if (isArguments(a) || isArguments(b)) {
    if (!(isArguments(a) && isArguments(b))) {
      return false;
    }
    let slice = Array.prototype.slice;
    return deepEquals(slice.call(a), slice.call(b), ca, cb);
  } else {
    if (a.constructor !== b.constructor) {
      return false;
    }

    let ka = Object.keys(a);
    let kb = Object.keys(b);
    // don't bother with stack acrobatics if there's nothing there
    if (ka.length === 0 && kb.length === 0) {
      return true;
    }
    if (ka.length !== kb.length) {
      return false;
    }

    let cal = ca.length;
    while (cal--) {
      if (ca[cal] === a) {
        return cb[cal] === b;
      }
    }
    ca.push(a);
    cb.push(b);

    ka.sort();
    kb.sort();
    for (var j = ka.length - 1; j >= 0; j--) {
      if (ka[j] !== kb[j]) {
        return false;
      }
    }

    let key;
    for (let k = ka.length - 1; k >= 0; k--) {
      key = ka[k];
      if (!deepEquals(a[key], b[key], ca, cb)) {
        return false;
      }
    }

    ca.pop();
    cb.pop();

    return true;
  }
}

// rjsf - util
function schemaRequiresTrueValue(schema) {
  // Check if const is a truthy value
  if (schema.const) {
    return true;
  }

  // Check if an enum has a single value of true
  if (schema.enum && schema.enum.length === 1 && schema.enum[0] === true) {
    return true;
  }

  // If anyOf has a single value, evaluate the subschema
  if (schema.anyOf && schema.anyOf.length === 1) {
    return schemaRequiresTrueValue(schema.anyOf[0]);
  }

  // If oneOf has a single value, evaluate the subschema
  if (schema.oneOf && schema.oneOf.length === 1) {
    return schemaRequiresTrueValue(schema.oneOf[0]);
  }

  // Evaluate each subschema in allOf, to see if one of them requires a true
  // value
  if (schema.allOf) {
    return schema.allOf.some(schemaRequiresTrueValue);
  }

  return false;
}

function shouldRender(comp, nextProps, nextState) {
  const { props, state } = comp;
  return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export {
  cleanObject,
  getUrlParam,
  setUrlParam,
  parseValue,
  formatCount,
  disableExponentCharacter,
  updateOrAddQueryString,
  clone,
  formatError,
  getErrorText,
  populateJSON,
  convertStringToFn,
  dataURItoBlob,
  schemaRequiresTrueValue,
  shouldRender,
  deepEquals
}
