import React from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types'
import TableTree from "../common/react-table-tree-custom";

class TreeGrid extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { schema, data, children } = this.props
    const { list, rootId, columns } = data.tablegrid;
    const { bodyClassName } = schema.style
    const { defaultstate, cellcolor, calendarrange, topmosttype, currentdateweekcolor, frequency} = schema.properties
    const dataList = []
    list.map((r, index) => {
      const row = r
      columns.map((col, index) => {
        if(col.column_type && col.column_type === "date"){
          let style = {}
          for(let c=0; c<cellcolor.length; c++){
            if(cellcolor[c].minimum == null){
              cellcolor[c].minimum = -999999999999
            }
            if(cellcolor[c].exclusiveMaximum == null){
              cellcolor[c].exclusiveMaximum = 999999999999
            }
            if(Number(row[col.name]) >= cellcolor[c].minimum 
              && Number(row[col.name]) < cellcolor[c].exclusiveMaximum ){
              if(row.toprow != null){
                style = {backgroundColor: cellcolor[c].fill} 
              }else{
                style = {backgroundColor: cellcolor[c].light}
              }
              if (col.today === "1") {
                style.borderLeft = "2px solid" +currentdateweekcolor
                style.borderRight = "2px solid" +currentdateweekcolor
              }
              break;
            }
          }

          row[col.name] = <div style={style}>{row[col.name]}</div>
        }
        return row[col.name]
      })
      dataList.push(row)
    })

    let updated_columns =
      columns.map((col, index) => {
        return col
      })
    return (
      <TableTree
        datasets={dataList}
        columns={updated_columns}
        rootId={rootId}
        header={{ fixed: false, top: 3 }}
      />
    );
  }
}

export default TreeGrid
