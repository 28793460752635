const formatters = {
  'Funnel': funnelFormatter
}

function graphBlockFormatter(type, data) {
  const formatter = formatters[type]
  return formatter ? formatter(data) : data
}

function funnelFormatter(data) {
  return data.label.raw
}

export default graphBlockFormatter
