import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'

import {
  FETCHING_TABLE_ROW_SCHEMA,
  FETCH_TABLE_ROW_SCHEMA_SUCCESS,
  FETCH_TABLE_ROW_SCHEMA_FAIL,
  RESET_TABLE_ROW_SCHEMA
} from '../constants/tableRowConstants'

import { setWidgetGroups } from './widgetGroupActionCreators'

export const fetchingTableRowSchema = (loadingSchema = true) => {
  return {
    type: FETCHING_TABLE_ROW_SCHEMA,
    loadingSchema
  }
}

export const fetchTableRowSchemaSuccess = (schema) => {
  return {
    type: FETCH_TABLE_ROW_SCHEMA_SUCCESS,
    schema
  }
}

export const fetchTableRowSchemaFail = (error) => {
  return {
    type: FETCH_TABLE_ROW_SCHEMA_FAIL,
    error: error.response? error.response.status : null
  }
}

export const setTableRowSchemaAndWidgetGroups = (schema) => {
  return (dispatch) => {
    dispatch(setWidgetGroups(schema.id, schema.widgetGroups))
    dispatch(fetchTableRowSchemaSuccess(schema))
  }
}

export const fetchTableRowSchema = (row, force = false) => {
  return (dispatch) => {
    if (!row.schema || force) {
      dispatch(fetchingTableRowSchema())
      const requestsManagerFn = requestsManager.fetchEntities
      const { schemaUrl, schemaParams, data } = row
      let url = schemaParams && Object.keys(schemaParams).length > 0 ? updateQueryStringParams(schemaUrl, schemaParams) : schemaUrl
      url = data && Object.keys(data).length > 0 ? updateQueryStringParams(schemaUrl, data) : url

      return (
        requestsManagerFn.call({}, url)
          .then((resp) => {
            dispatch(setTableRowSchemaAndWidgetGroups(resp.data))
          })
          .catch((error) => {
            dispatch(fetchTableRowSchemaFail(error))
          })
      )
    }

    dispatch(setTableRowSchemaAndWidgetGroups(row.schema))
  }
}

export const resetTableRowSchema = (rowId, widgetGroupIds) => {
  return {
    type: RESET_TABLE_ROW_SCHEMA,
    rowId,
    widgetGroupIds
  }
}
