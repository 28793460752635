import React from 'react'
import bindAll from 'lodash.bindall'
import NotificationSystem from 'react-notification-system'

const NotificationComponentHoc = (InnerComponent) => {
  return class NotificationComponentWrapper extends React.Component {
    constructor() {
      super()
      this.notificationSystem = null
      bindAll(this, [
        'addNotification'
      ])
    }

    addNotification(notification) {
      const defaultOptions = {
        position: 'tc',
        autoDismiss: 3
      }
      this.notificationSystem.addNotification({ ...defaultOptions, ...notification })
    }

    render() {
      return (
        <React.Fragment>
          <InnerComponent {...this.props} addNotification={this.addNotification} />
          <NotificationSystem ref={(n) => { this.notificationSystem = n }} />
        </React.Fragment>
      )
    }
  }
}

export default NotificationComponentHoc
