import {
  FETCHING_POPOVER_SCHEMA,
  FETCH_POPOVER_SCHEMA_SUCCESS,
  RESET_POPOVER_SCHEMA
} from '../constants/popoverConstants'

export const $$initialState = {
  schema: null,
}

function popoverReducer($$state = $$initialState, action = null) {
  const {
    type, schema, loadingSchema,
  } = action
  switch (type) {
    case FETCHING_POPOVER_SCHEMA:
      return {
        ...$$state,
        loadingSchema
      }
    case FETCH_POPOVER_SCHEMA_SUCCESS:
      return {
        ...$$state,
        schema,
        loadingSchema: false
      }
    case RESET_POPOVER_SCHEMA:
      return {
        ...$$state,
        schema: null
      }
    default:
      return $$state
  }
}

export default popoverReducer
