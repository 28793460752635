import React from 'react'
import PropTypes from 'prop-types'


const GraphHeader = (props) => {
  const { schema, data } = props
  const { title, classNames } = schema
  return (
    <div className={classNames.header || 'mb-1'}>
      <div className={classNames.title || 'sec-label'}>{title}</div>
      <div className={classNames.legend}>
        {data.map(d => (
          <span key={d.legend} className="header-legends">
            <svg width="8" height="8" version="1.1">
              <circle className="recharts-symbols" cx="4" cy="4" r="3" stroke={d.color} strokeWidth="2" fill="none" />
            </svg>
            <span className="legend-text">{d.legend}</span>
          </span>
        ))}
      </div>
    </div>
  )
}

GraphHeader.propTypes = {
  schema: PropTypes.shape({
    classNames: PropTypes.object,
    title: PropTypes.string
  }).isRequired,
  data: PropTypes.array.isRequired
}
export default GraphHeader
