import { findKey } from 'lodash/fp'

function getValues(key) {
  return window.CC_ENUMS[key]['entries']
}

function getDefaultValue(key) {
  return window.CC_ENUMS[key]['default']
}

function getSelectedDisplayText(key, value) {
  const selectorValues = getValues(key)
  const isObjPassed = typeof (value) === 'object'
  const comparedValue = isObjPassed ? value.id : value
  return selectorValues[comparedValue]
}

function getDropdownObjectsList(key) {
  const values = getValues(key)
  return Object.keys(values).map((item) =>  ({ id: item, label: values[item] }) )
}

const getNoteVisibility = () => {
  const noteVisibilityEnum = window.CC_ENUMS['note.visibility']
  const defaultVisibility = findKey((value) => {
    return value === noteVisibilityEnum.default
  }, noteVisibilityEnum.entries)

  const visibilities = noteVisibilityEnum.entries

  return { defaultVisibility, visibilities }
}

export {
  getValues,
  getSelectedDisplayText,
  getDropdownObjectsList,
  getDefaultValue,
  getNoteVisibility
}
