import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import moment from 'moment'


const HistoryItem = ({ item }) => {
  // eslint-disable-next-line radix
  const historyTime = moment.unix(parseInt(item.time)).format('h:mm A')
  return (
    <li className="chat-item">
      <div className="thumb-image-44" />
      <div className="msg-cntr">
        <div className="name">
          {item.by}
          <span className="chat-time">{historyTime}</span>
        </div>
        {item.what.map((change) => { return (<p className="message" key={shortid.generate()}>{change}</p>) })
        }
      </div>
    </li>
  )
}

HistoryItem.propTypes = {
  item: PropTypes.object.isRequired
}

const History = ({ schema, data }) => {
  return (
    <div className={classNames('cvc-c-history', schema.style.containerClassName)}>
      <ul className="chat-window">
        {data.length > 0
          ? data.map((dayHistory) => {
            const historyDate = moment.unix(parseInt(dayHistory.date, 10))
            return (
              <div key={shortid.generate()}>
                <div className="day-header">
                  <h4 className="day-title">
                    <span>{historyDate.format('MMM D, YYYY')}</span>
                  </h4>
                </div>
                {dayHistory.changes.map(historyItem => <HistoryItem item={historyItem} key={shortid.generate()} />)}
              </div>
            )
          })
          : <div className="text-center pt-3 text-gray">No history available</div>
        }
      </ul>
    </div>
  )
}

History.propTypes = {
  schema: PropTypes.shape({
    editMode: PropTypes.bool
  }),
  data: PropTypes.array
}

export default History
