import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import WidgetGroupContainer from '../redux/containers/WidgetGroupContainer'
import LayoutRenderer from './LayoutRenderer'
import Error from '../components/Error'
import ErrorMap from '../helpers/errors'

class ModalRenderer extends React.Component {
  constructor() {
    super()
    this.state = {
      error: '',
      positions: {
        'center': '',
        'bottom': 'modal-bottom',
        'right': 'filter-modal'
      },
      sizes: {
        'small': '',
        'medium': 'modal-md',
        'large': 'modal-xl',
        'smallest': 'modal-sm'
      },
      defaultSchema: {
        style: { className: '', position: 'center', size: 'small' },
        heading: {},
        closeButton: true
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { schema, schemaUrl, show } = nextProps.modal
    if (show && !schema && !schemaUrl) {
      return { error: ErrorMap.schemaError }
    }
    return null
  }
  // PureComponents have builtin shouldComponentUpdate

  componentDidUpdate(prevProps) {
    const { modal } = this.props
    const { error } = this.state

    const fetchSchema = modal.show && (modal.show !== prevProps.modal.show)
    // Loading Schema
    if (!error && fetchSchema) {
      this.props.fetchModalSchema(modal)
    }
    ReactTooltip.hide()
    ReactTooltip.rebuild()
    // show modal if modal is inserted in dom
    if (modal.show && !$('#modal').data('bs.modal')) {
      const { schema } = modal;
      const modalOptions = schema && schema.modalOptions || {}
      $('#modal').modal({ ...modalOptions }, 'show')
      $('#modal').on('hide.bs.modal', () => {
        const { widgetGroupIds, rightPanelWidgetGroupIds, hideModal } = this.props
        hideModal(schema.id, widgetGroupIds)
        if (schema.rightPanel) {
          hideModal(schema.rightPanel.id, rightPanelWidgetGroupIds)
        }
        $('.modal-backdrop').remove()
      })
    }
  }

  render() {
    const {
      modal,
      widgetGroupIds,
      rightPanelWidgetGroupIds,
      widgetFiltersApplied,
      removeAllWidgetFilters
    } = this.props
    const { schema, show } = modal
    const { defaultSchema, error, positions, sizes } = this.state
    if (!show || !schema) {
      return null
    }
    if (error) {
      return (
        <div className="modal-container">
          <div id="modal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <Error error={error} />
              </div>
            </div>
          </div>
        </div>
      )
    }
    const rightPanel = schema.rightPanel
    const modalStyle = schema.style || {}
    const modalHeading = schema.heading || { title: '' }
    const modalPosition = (modalStyle.position || defaultSchema.style.position)
    const showCloseBtn = schema.closeButton !== undefined ? schema.closeButton : defaultSchema.closeButton
    const positionClassName = positions[modalPosition]
    const sizeClassName = modalPosition === 'center' ? sizes[(modalStyle.size || defaultSchema.style.size)] : null
    const containerClass = classNames('modal fade', positionClassName, (modalStyle.className || defaultSchema.style.className), { 'overflow-modal-content': modalPosition == 'center'})
    const dialogClass = classNames('modal-dialog', sizeClassName)
    const bodyClassName = classNames('modal-body', modalStyle.bodyClassName)
    const resetWidgetFiltersOnChange = schema && schema.widgetGroups && schema.widgetGroups.length > 0 ? schema.widgetGroups[0].resetWidgetFiltersOnChange : false
    return (
      <div className="modal-container">
        <div
          id="modal"
          className={containerClass}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className={dialogClass}>
            <div className={classNames("modal-content", rightPanel ? "modal-left-panel" : null)}>
              {(modalHeading.title !== '' || showCloseBtn) &&
                <div className="modal-header">
                  <div className="modal-title center-title">{modalHeading.title}</div>
                  {showCloseBtn &&
                    <div className="text-gray float-right close" data-dismiss="modal">
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                  }
                </div>
              }
              <div className={bodyClassName}>
                {widgetFiltersApplied && resetWidgetFiltersOnChange ? (
                  <div className="p-3 d-flex flex-column align-items-center">
                    <div>All the widget level filters will be cleared</div>
                      <div className="d-flex justify-content-around wd-60">
                      <a
                        className="cvc-btn-primary small mt-3 mr-2"
                        role="presentation"
                        onClick={() => removeAllWidgetFilters({ cached: true })}
                      >
                        <p className="m-0" title="Create">Confirm</p>
                      </a>
                      <a
                        className="cvc-btn-primary small mt-3"
                        role="presentation"
                        data-dismiss="modal"
                      >
                        <p className="m-0" title="Create">Cancel</p>
                      </a>
                    </div>
                  </div>
                ) : (
                    <LayoutRenderer
                      widgetGroups={schema.widgetGroups}
                      parentId={schema.id}
                    />
                  )
                }
              </div>
            </div>
            {
              rightPanel &&
              <div className="modal-content modal-right-panel">
                <LayoutRenderer
                  widgetGroups={rightPanel.widgetGroups}
                  parentId={rightPanel.id}
                />
              </div>
            }
          </div>
        </div>
    </div>
    )
  }
}

ModalRenderer.propTypes = {
  modal: PropTypes.shape({
    schema: PropTypes.object,
    show: PropTypes.bool,
    widgetToUpdate: PropTypes.object
  }),
  widgetGroupIds: PropTypes.array.isRequired,
  fetchModalSchema: PropTypes.func,
  hideModal: PropTypes.func
}

ModalRenderer.defaultProps = {
  modal: {}
}
export default ModalRenderer
