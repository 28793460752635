import {
  SET_WIDGET_CACHE
} from '../constants/widgetCacheConstants'
import { getWidgetPageIdKey } from 'core/helpers/utils'

export const setWidgetCache = ({
  data,
  widgetGroupId,
  widgetId
}) => {
  return {
    type: SET_WIDGET_CACHE,
    payload: data,
    id: getWidgetPageIdKey(widgetGroupId, widgetId)
  }
}