import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import bindAll from "lodash.bindall";
import { renderData } from "../helpers/utils";


function formatData(value, type, formatter) {
  const property = {
    dataType: type,
    formatter: formatter == "DATE" ? "NUMERIC" : formatter,
    postfix: formatter == "DATE" ? " wks" : ""
  };
  
  return renderData(property, value);
}

class Timeline extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedItem: {
        id: null,
      },
    };
    bindAll(this);
  }

  componentDidMount() {}

  render() {
    const { schema, data } = this.props;
    const { hideWidgetBorder = true } = schema;

    return (
      <div
        className={classNames("cvc-w", "cvc-w-timeline", {
          "hide-widget-border": hideWidgetBorder,
        })}
      >
        <div className={classNames("timeline-container")}>
          <ul className={classNames("timeline")}>
            {data.events.map(this.renderEvent)}
          </ul>
        </div>
      </div>
    );
  }

  renderEvent(event, index, all) {
    const rowClass = 
      index === 0
        ? "initial"
        : index === all.length - 1 ? "final" : "event";

    return (
      <li key={`timeline_event_${index}`} className={classNames("event-row")}>
        <div className="event-body no-border">
          <div id={`timeline-event-${index}`} className={classNames("container mb-2 event-title")}>
            <div className={rowClass} />
            <div className="row">
              <div className="pr-2 text-center">{event.date}</div>
              <div className="px-2 font-weight-bold border-left border-secondary text-center">
                {event.name}
              </div>
              {event.status && (
                <div className="px-2 font-weight-bold border-left border-secondary">
                  <span className="mr-2">Status:</span>
                  <span style={{ color: "#00c57a" }}>{event.status}</span>
                </div>
              )}
            </div>
          </div>
          {event.subtitle && (
            <div className="event-subtitle">{event.subtitle}</div>
          )}
          {
            <div className="detail-card-container container d-inline-block">
              <div className="row mt-1">
                {event.cards.map((card, i) => (
                  <div
                    key={`timeline_event_detail_card_${i}`}
                    className={classNames(
                      "detail-card",
                      "col border rounded ml-2 d-inline text-center text-truncate p-1"
                    )}
                  >
                    <div className="event-header small">{card.headerText}</div>
                    <div className="event-content">{formatData(card.value, card.type)}</div>
                    {card.impact && (
                      <div className="event-footer">
                        ({formatData(
                          card.impact, 
                          "INCREMENT",
                          card.type
                        )})
                      </div>
                    )}
                    {card.footerText && (
                      <div className="event-footer">
                        {card.footerText}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </li>
    );
  }

  renderEventDetails() {}
}

Timeline.propTypes = {
  widgetId: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    properties: PropTypes.array,
    style: PropTypes.object,
    actions: PropTypes.object,
  }).isRequired,
  data: PropTypes.object,
};

export default Timeline;
