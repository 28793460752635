import React from 'react'

const cartesianDataChecker = (schema, data) => {
  let emptyData = true;
  if (!schema.childProps) return emptyData;
  const dataKeys = schema.childProps.map(prop => prop.dataKey)
  data.forEach((dataObj) => {
    dataKeys.forEach((key) => {
      /* In case of cartesian we dont want to show empty graph message(No data found) even if all values are 0 */
      if (dataObj[key] || dataObj[key] === 0) {
        emptyData = false
      }
    })
  })
  return emptyData
}

const polarDataChecker = (schema, data) => {
  let emptyData = true
  data.forEach((dataObj) => {
    if (dataObj.value) {
      emptyData = false
    }
  })
  return emptyData
}


const dataCheckers = {
  'polar': polarDataChecker,
  'cartesian': cartesianDataChecker
}

const graphDataChecker = (name, schema, data) => {
  if (schema.childProps && schema.childProps.find(child => child.minPointSize > 0)) {
    return false
  }
  return dataCheckers[name](schema, data)
}

function withDataChecker(name, Component) {
  class WithDataChecker extends React.PureComponent {
    render() {
      const { schema, data } = this.props
      const { legend, style } = schema
      if (graphDataChecker(name, schema, data.graph)) {
        const height = style && style.containerStyle && style.containerStyle.height ? style.containerStyle.height : '140px';

        /* TODO: Try to find a solution to get the height and heading style from the schema */
        return (
          <React.Fragment>
            {legend && legend.customLegends
              && <span className="font-14">{legend.legendTitle}</span>}
            <div
              className="text-gray d-flex justify-content-center align-items-center"
              style={{ height }}
            >
              <span>{ schema.placeholder ? schema.placeholder : 'No data found' }</span>
            </div>
          </React.Fragment>
        )
      }
      return <Component {...this.props} />
    }
  }
  return WithDataChecker
}

export default withDataChecker
