import { connect } from 'react-redux'
import EncapsulatorRenderer from 'core/renderers/EncapsulatorRenderer'
import * as actions from '../actions/encapsulatorActionCreators'

const mapStateToProps = (state, ownProps) => {
  const { id, spreadId } = ownProps
  const spreads = state.spread[spreadId]
  if (!state.encapsulator[id]) {
    return {
      id,
      schemaUrl: null,
      loadingSchema: true,
      spreads
    }
  }
  return {
    ...state.encapsulator[id],
    spreads
  }
}

export default connect(mapStateToProps, actions)(EncapsulatorRenderer)
