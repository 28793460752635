import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip
} from 'recharts'

import { combineProps, colors } from 'core/helpers/utils'
import customLabelMap from './helpers/pie/customLabelMap'
import PolarTooltip from "./helpers/PolarTooltip";

const PieGraph = (props) => {
  const { schema, data } = props
  const { graph: graphData } = data

  const defaultSchema = {
    containerStyle: {
      width: '100%',
      height: 140
    },
    pie: {
      labelLine: false
    },
    legend: {
      show: true,
      iconType: 'circle',
      iconSize: 8,
      align: 'left',
      verticalAlign: 'bottom',
      wrapperStyle: {
        color: colors.gray,
        fontSize: '12px'
      }
    }
  }
  const {
    style,
    pieChart,
    legend,
    label = {},
    tooltip,
    pies
  } = schema
  const { containerStyle } = style || {}
  const chartContainerProps = combineProps(defaultSchema.containerStyle, containerStyle)
  const legendProps = combineProps(defaultSchema.legend, legend)
  const pieChartProps = combineProps(defaultSchema.pieChart, pieChart)
  const { show: showLegend, wrapperStyle, ...otherLegendProps } = legendProps
  return (
    <ResponsiveContainer {...chartContainerProps}>
      <PieChart {...pieChartProps}>
        {showLegend
          && (
          <Legend
            wrapperStyle={combineProps(defaultSchema.legend.wrapperStyle, wrapperStyle)}
            {...otherLegendProps}
          />
          )
        }
        {tooltip
          && (
            <Tooltip
              isAnimationActive={false}
              {...tooltip}
              content={(
                <PolarTooltip
                  chartData={graphData.inner || graphData}
                  dataType={label.dataType}
                  tooltipProps={tooltip}
                  hidePercentage={tooltip.hidePercentage}
                />
              )}
            />
          )
        }
        {pies.map((pie) => {
          const CustomLabel = pie.label.name ? customLabelMap[pie.label.name] : null;
          const showLabel = pie.label && pie.label.show || pie.label.show === undefined;

          return (
            <Pie
              isAnimationActive={false}
              key={shortid.generate()}
              data={graphData[pie.id]}
              {...combineProps(defaultSchema.pie, pie)}
              label={
                showLabel && pie.label.name ? (
                  <CustomLabel
                    id={shortid.generate()}
                    dataType={pie.label.dataType}
                    angle={pie.label.angle}
                    fontSize={pie.label.fontSize}
                    position={pie.label.textAnchor}
                    labelProps={pie.label}
                    labelsCount={pie.childProps.length}
                  />
                ) : showLabel
              }
            >
              {
                graphData[pie.id].map((entry, index) => {
                  const color = pie.childProps.find(p => p.dataKey === entry[pie.labelKey]).color || colors.green

                  return (
                    <Cell fill={color} key={entry[pie.labelKey]} />
                  )
                })
              }
            </Pie>
          )
        })}
      </PieChart>
    </ResponsiveContainer>
  )
}

PieGraph.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  fill: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired
}

PieGraph.defaultProps = {
  data: {
    eventsData: {}
  }
}

export default PieGraph