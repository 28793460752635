import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'
import { error as notificationError } from "react-notification-system-redux";
import { formatError } from 'libs/utils'

import {
  FETCHING_MODAL_SCHEMA,
  FETCH_MODAL_SCHEMA_SUCCESS,
  FETCH_MODAL_SCHEMA_FAIL,
  SHOW_MODAL,
  HIDE_MODAL
} from '../constants/modalConstants'
import { setLoadingOverlay } from './pageActionCreators'
import { setWidgetGroups } from './widgetGroupActionCreators'

export const fetchingModalSchema = (loadingSchema = true) => {
  return {
    type: FETCHING_MODAL_SCHEMA,
    loadingSchema
  }
}

export const fetchModalSchemaSuccess = (schema) => {
  return {
    type: FETCH_MODAL_SCHEMA_SUCCESS,
    schema
  }
}

export const fetchModalSchemaFail = () => {
  return {
    type: FETCH_MODAL_SCHEMA_FAIL
  }
}

export const setModalSchemaAndWidgetGroups = (schema) => {
  return (dispatch) => {
    dispatch(fetchModalSchemaSuccess(schema))
    dispatch(setWidgetGroups(schema.id, schema.widgetGroups))
    if (schema.rightPanel) {
      const { rightPanel } = schema
      dispatch(setWidgetGroups(rightPanel.id, rightPanel.widgetGroups))
    }
  }
}

export const fetchModalSchema = (modal, force = false) => {
  return (dispatch) => {
    if (!modal.schema || force) {
      dispatch(setLoadingOverlay(true))
      dispatch(fetchingModalSchema())
      const requestsManagerFn = requestsManager.fetchEntities
      const { schemaUrl, schemaParams } = modal
      const url = schemaParams && Object.keys(schemaParams).length > 0 ? updateQueryStringParams(schemaUrl, schemaParams) : schemaUrl
      return (
        requestsManagerFn.call({}, url)
          .then((resp) => {
            dispatch(setModalSchemaAndWidgetGroups(resp.data))
            dispatch(setLoadingOverlay(false))
          })
          .catch((error) => {
            if (error && error.response) {
              const { data, status } = error.response
              const errorText = formatError(data)
              dispatch(notificationError({
                position: 'tc',
                autoDismiss: 3,
                title: 'Error',
                message: errorText
              }))
            }

            dispatch(fetchModalSchemaFail())
            dispatch(setLoadingOverlay(false))
          })
      )
    }
    dispatch(setModalSchemaAndWidgetGroups(modal))
  }
}

export const showModal = (schemaUrl, schemaParams) => {
  return {
    type: SHOW_MODAL,
    schemaUrl,
    schemaParams
  }
}

export const hideModal = (modalId, widgetGroupIds) => {
  return {
    type: HIDE_MODAL,
    modalId,
    widgetGroupIds,
    modalType: null,
    modalProps: null
  }
}