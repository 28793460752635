import React from 'react'
import PropTypes from 'prop-types'
import { renderData } from '../../../../helpers/utils'

const RADIAN = Math.PI / 180

const Label = (props) => {
  const {
    value,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    dataType,
    fontSize = 14,
    color = "#fff",
    position,
    labelProps = {}
  } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      fontSize={fontSize}
      x={x}
      y={y}
      fill={color}
      textAnchor={position ? position : (x > cx ? 'start' : 'end')}
      dominantBaseline="central"
    >
      {renderData({ ...labelProps, dataType: dataType || 'CURRENCY' }, value)}
    </text>
  );
}

Label.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  value: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number
}

export default Label