
import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'
import {
  FETCH_ENCAPSULATOR_SCHEMA_REQUEST,
  FETCH_ENCAPSULATOR_SCHEMA_SUCCESS,
  FETCH_ENCAPSULATOR_SCHEMA_FAILURE, // TODO: Use this action for error handling
  SET_ENCAPSULATOR_SCHEMA_URL
} from '../constants/encapsulatorConstants'
import { setWidgetGroups } from './widgetGroupActionCreators'


export const schemaUrlSetter = (id, schemaUrl) => {
  return (dispatch) => ({
    type: SET_ENCAPSULATOR_SCHEMA_URL,
    payload: {
      id,
      schemaUrl
    }
  })
}

const requestEncapsulatorSchema = (id, schemaUrl) => ({
  type: FETCH_ENCAPSULATOR_SCHEMA_REQUEST,
  payload: {
    id,
    schemaUrl
  }
})

export const setEncapsulatorSchema = (schema, id) => ({
  type: FETCH_ENCAPSULATOR_SCHEMA_SUCCESS,
  payload: {
    id,
    schema
  }
})

export const fetchEncapsulatorSchema = (id, schemaUrl, spreads={}, setEncapsulator=false) => {
  return (dispatch) => {
    if (setEncapsulator) {
      dispatch(schemaUrlSetter(id, schemaUrl))
    }
    dispatch(requestEncapsulatorSchema(id, schemaUrl))
    const url = updateQueryStringParams(schemaUrl, { spreads: spreads })
    requestsManager.fetchEntities(url)
      .then((res) => {
        dispatch(setWidgetGroups(id, res.data.widgetGroups))
        dispatch(setEncapsulatorSchema(res.data, id))
      })
  }
}
