/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import bindAll from 'lodash.bindall'
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'

import Widget from '../widgets/DateRangePicker'


class DateRangeField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultSchema: {
        startDate: null,
        endDate: null,
        placeholder: 'Date Range',
        numberOfMonths: 1,
        daySize: 28,
        dateFormat: 'YYYY-MM-DD',
        btnDateFormat: 'MMM D, YYYY',
        minimumNights: 0,
        disablePastDays: false,
        disableFutureDays: false
      },
      focusedInput: null
    }
    bindAll(this, 'formatDateRangePicker', 'onDatesChange', 'excludeDates')
  }

  onDatesChange({ startDate, endDate }) {
    const { uiSchema } = this.props
    const { keyMap, dateFormat } = uiSchema['ui:options']
    const { defaultSchema } = this.state
    const momentDateFormat = dateFormat || defaultSchema.dateFormat

    this.props.onChange({
      [keyMap.startDate]: startDate ? moment(startDate).format(momentDateFormat) : '',
      [keyMap.endDate]: endDate ? moment(endDate).format(momentDateFormat) : ''
    })
  }

  formatDateRangePicker() {
    const { formData, uiSchema } = this.props
    const { defaultSchema } = this.state

    const { keyMap, btnDateFormat } = uiSchema['ui:options']
    const momentDateFormat = btnDateFormat || defaultSchema.btnDateFormat

    const startDate = formData[keyMap.startDate]
    const endDate = formData[keyMap.endDate]
    return (startDate && endDate) ? `${moment(startDate).format(momentDateFormat)}-${moment(endDate).format(momentDateFormat)}` : ''
  }

  excludeDates(day) {
    const options = this.props.uiSchema['ui:options']
    const { defaultSchema } = this.state
    const today = moment()
    const disablePastDays = options.disablePastDays || defaultSchema.disablePastDays
    const disableFutureDays = options.disableFutureDays || defaultSchema.disableFutureDays
    if (disablePastDays) {
      return isInclusivelyBeforeDay(day, today.subtract(1, 'days'))
    }
    if (disableFutureDays) {
      return isInclusivelyAfterDay(day, today.add(1, 'days'))
    }
    return false
  }

  render() {
    const { formData = {}, uiSchema, required, schema } = this.props
    const { defaultSchema, focusedInput } = this.state
    const options = uiSchema['ui:options']
    const placeholder = uiSchema['ui:placeholder'] || this.props.placeholder
    const { numberOfMonths, daySize, minimumNights, keyMap, label, readonly } = options

    return (
      <Widget
        formData={formData}
        uiSchema={uiSchema}
        defaultSchema={defaultSchema}
        placeholder={placeholder}
        numberOfMonths={numberOfMonths}
        daySize={daySize}
        minimumNights={minimumNights}
        keyMap={keyMap}
        label={label}
        title={schema.title}
        showAstrick={required && options.astrick}
        inputToDisplay={this.formatDateRangePicker()}
        focusedInput={focusedInput}
        isOutsideRange={this.excludeDates}
        handleDatesChange={this.onDatesChange}
        handleFocusChange={input => this.setState({ focusedInput: input })}
        readonly={readonly}
      />
    )
  }
}

DateRangeField.propTypes = {
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object,
  required: PropTypes.bool,
  placeholder: PropTypes.string
}

export default DateRangeField
