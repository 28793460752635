import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

import { renderData } from 'core/helpers/utils'
import withEventHandler from 'core/components/hocs/withEventHandler'


class ContainerWidget extends PureComponent {
  render() {
    const {
      schema,
      style = {},
      children
    } = this.props

    const {
      hideWidgetBorder = true
    } = schema

    return (
      <div className={classNames('cvc-w', 'cvc-w-container', { 'hide-widget-border': hideWidgetBorder }, schema.className)} style={style.styles}>
        <React.Fragment>{children}</React.Fragment>
      </div>
    )
  }
}

ContainerWidget.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
export default ContainerWidget
