import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import {
  ResponsiveContainer,
  LineChart,
  Line as ReChartLine,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
  Legend,
  Label,
  LabelList,
  CartesianGrid,
  Brush
} from 'recharts'

import { combineProps, colors, renderFormattedData } from 'core/helpers/utils'
import CustomTooltip from './helpers/CustomTooltip'
import CustomLabel from './helpers/CustomLabel'
import HeaderLegend from './helpers/HeaderLegend'
import withDataChecker from '../../helpers/withDataChecker'

const Line = (props) => {
  const { schema, data } = props
  const {
    graph: graphData,
    events: eventsData = {},
    referenceLines: referenceLinesData = {},
    xAxis: xAxisData = {},
    yAxis: yAxisData = {}
  } = data

  const defaultSchema = {
    containerStyle: {
      width: '100%',
      height: 300
    },
    xAxis: {
      tickLine: false,
      axisLine: false,
      tick: {
        fill: colors.gray,
        fontSize: 14
      },
      hide: schema.style.layout === 'vertical'
    },
    yAxis: {
      tickLine: false,
      axisLine: false,
      tick: {
        fill: colors.gray,
        fontSize: 14,
        textAnchor: 'start'
      },
      hide: schema.style.layout === 'horizontal'
    },
    legend: {
      show: true,
      iconType: 'circle',
      iconSize: 8,
      align: 'left',
      verticalAlign: 'bottom',
      wrapperStyle: {
        width: '100%',
        color: colors.gray,
        fontSize: '12px'
      }
    },
    cartesian: {
      vertical: false, // To hide/show vertical cartesian lines
      horizontal: false, // To hide/show horizontal cartesian lines
      strokeDasharray: '0 0'
    }
  }
  const {
    style,
    xAxis = {},
    yAxis = {},
    tooltip = {},
    legend,
    events,
    cartesian,
    childProps,
    referenceLines,
    scrollable
    } = schema
  const { containerStyle, ...otherStyle } = style || {}
  const chartContainerProps = combineProps(defaultSchema.containerStyle, containerStyle)
  const xAxisProps = {...combineProps(defaultSchema.xAxis, xAxis), ...xAxisData}
  const yAxisProps = {...combineProps(defaultSchema.yAxis, yAxis), ...yAxisData};
  const legendProps = combineProps(defaultSchema.legend, legend)
  const cartesianProps = combineProps(defaultSchema.cartesian, cartesian)
  const { show: showLegend, wrapperStyle, ...otherLegendProps } = legendProps
  const eventSchema = events ? events.onclick : null
  const eventData = events ? eventsData[events.onclick.id] || {} : null
  const isScrollable = (data.scrollable || scrollable)

  const { dataType, ...tooltipProps } = tooltip

  const CustomizedDot = (props) => {
    const {
        cx, cy, stroke, payload, value,
        } = props
    const { dot } = childProps[0]
    if (referenceLineY && otherStyle.aboveThresholdStroke) {
      const yThreshold = parseInt(referenceLineY.y)
      if (value >= yThreshold) {
        return (<circle key={shortid.generate()} cx={cx} cy={cy} r={5} stroke={otherStyle.aboveThresholdStroke} strokeWidth={2} fill="#fff" />)
      } else {
        return (<circle key={shortid.generate()} cx={cx} cy={cy} r={5} stroke={dot.stroke} strokeWidth={2} fill="#fff" />)
      }
    } else {
      return (<circle key={shortid.generate()} cx={cx} cy={cy} r={5} stroke={dot.stroke} strokeWidth={2} fill="#fff" />)
    }
  }
  return (
    <ResponsiveContainer {...chartContainerProps}>
      <LineChart {...otherStyle} data={graphData} onClick={events ? () => props.onClickHandler(eventSchema, eventData) : undefined}>
        <CartesianGrid {...cartesianProps} />
        <XAxis
          tickFormatter={(value) => renderFormattedData(xAxis, value)}
          {...xAxisProps}
        />
        <YAxis
          tickFormatter={(value) => renderFormattedData(yAxis, value)}
          {...yAxisProps}
        />
        {
          referenceLines && referenceLines.map((referenceLine) => {
              const { label, ...otherProps } = referenceLine
              const referenceData = referenceLinesData[referenceLine.dataKey] || {}
              const { label: labelDataProps, ...otherDataProps } = referenceData

              const referenceLineProps = {
                ...otherProps,
                ...otherDataProps,
              };

              const labelProps = {
                ...label,
                ...labelDataProps,
              };

              return (
                <ReferenceLine {...referenceLineProps}>
                <Label formatter={(value) => renderFormattedData(labelProps, value)} {...labelProps} /> </ReferenceLine>
              )
            }
          )
        }
        {
          showLegend &&
            <Legend
              wrapperStyle={combineProps(defaultSchema.legend.wrapperStyle, { ...wrapperStyle, paddingTop: isScrollable ? 10 : 0 })}
              content={legendProps.customLegends ? <HeaderLegend title={legendProps.legendTitle} /> : null}
              {...otherLegendProps}
            />
        }
        {
          tooltipProps &&
            <Tooltip
              content={tooltipProps.custom ? <CustomTooltip /> : null}
              formatter={(value) => {
                return renderFormattedData({ dataType }, value)
              }}
              isAnimationActive={false}
              {...tooltipProps}
            />
        }
        {
          childProps.map((bar) => {
            const { label, customShape, fillStart, fillEnd, ...otherBarProps } = bar
            const barProps = combineProps(defaultSchema.bar, otherBarProps)
            const { type, ...otherLabelProps } = label || {}
            const labelProps = type === 'custom' ? combineProps({ content: CustomLabel }, otherLabelProps) : otherLabelProps
            return (
              <ReChartLine
                  isAnimationActive={false}
                  key={shortid.generate()}
                  dot={CustomizedDot}
                  {...barProps}
                  >
                {label && <LabelList {...labelProps} />}
              </ReChartLine>
            )
          })
        }
        {
          isScrollable && <Brush
            height={14}
            startIndex={0}
            endIndex={5}
            {...scrollable}
            {...data.scrollable}
            tickFormatter={() => ''}
          />
        }
      </LineChart>
    </ResponsiveContainer>
  )
}

Line.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  isLineChart: PropTypes.bool
}

Line.defaultProps = {
  data: {
    eventsData: {}
  }
}

export default withDataChecker('cartesian', Line)