import {
  fetchingWidgetData,
  reloadWidgets,
} from './widgetActionCreators'
import { success, error } from 'react-notification-system-redux'

export const socketHandlerMap = {
  'ACTION_REFRESH_SOCKET': actionRefreshHandler,
}

// example message: {
//   status: 'IN_PROGRESS, SUCCESS, FAILED'
//   message:
// }
function actionRefreshHandler(action, channelData, callback) {
  const { schema } = action;

  const status = channelData.status
  const actionMessage = channelData.message

  switch (status) {
    case 'IN_PROGRESS':
      callback({ actionInProgress: true, actionMessage })
      break;
    case 'SUCCESS':
      schema.refresh.widgets.forEach((widget) => {
        if (widget.widgetId) {
          action.dispatch(fetchingWidgetData(widget.widgetGroupId, widget.widgetId))
        } else {
          action.dispatch(reloadWidgets(widget.widgetGroupId))
        }
      })

      callback({ actionInProgress: false })

      // if no notification key passed from schema
      if (schema.notifications && schema.notifications.success) {
        action.dispatch(success({
          ...action.notificationOptions,
          title: schema.notifications.success.title || '',
          message: actionMessage
        }))
      }

      break;
    case 'FAILED':
      callback({ actionInProgress: false })
      if (schema.notifications && schema.notifications.error) {
        action.dispatch(error({
          ...action.notificationOptions,
          title: schema.notifications.error.title || '',
          message: actionMessage
        }))
      }
      break;
  }
}
