import { combineReducers } from 'redux'
import { reducer as notifications } from 'react-notification-system-redux'
import { pickBy, isEmpty, omitBy } from 'lodash'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createTransform } from 'redux-persist';

import pageReducer, { $$initialState as $$pageState } from './pageReducer'
import modalReducer, { $$initialState as $$modalState } from './modalReducer'
import widgetReducer, { $$initialState as $$widgetState } from './widgetReducer'
import widgetGroupReducer, { $$initialState as $$widgetGroupState } from './widgetGroupReducer'
import encapsulatorReducer, { $$initialState as $$encapsulatorState } from './encapsulatorReducer'
import spreadReducer, { $$initialState as $$spreadState } from './spreadReducer'
import filtersReducer, { $$initialState as $$filtersState } from './filtersReducer'
import popoverReducer, { $$initialState as $$popoverState } from './popoverReducer'
import tableRowReducer, { $$initialState as $$tableRowState } from './tableRowReducer'
import widgetCacheReducer, { $$initialState as $$widgetCacheState } from './widgetCacheReducer'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['filters', 'widgetCache'],
  stateReconciler: (cachedState, originalState, reducedState, config) => {
    let finalState = { ...reducedState }

    if (!isEmpty(cachedState.filters) && isEmpty(originalState.filters)) {
      finalState = {
        ...finalState,
        filters: { ...omitBy(cachedState.filters, (value) => !value.cache) }
      }
    }

    if (!isEmpty(cachedState.widgetCache)) {
      finalState = {
        ...finalState,
        widgetCache: { ...cachedState.widgetCache }
      }
    }

    return finalState
  }
}


const rootReducer = combineReducers({
  page: pageReducer,
  modal: modalReducer,
  widget: widgetReducer,
  widgetGroup: widgetGroupReducer,
  encapsulator: encapsulatorReducer,
  spread: spreadReducer,
  filters: filtersReducer,
  popover: popoverReducer,
  row: tableRowReducer,
  notifications,
  widgetCache: widgetCacheReducer,
})

export default persistReducer(rootPersistConfig, rootReducer)

export const initialStates = {
  page: $$pageState,
  modal: $$modalState,
  widget: $$widgetState,
  widgetGroup: $$widgetGroupState,
  encapsulator: $$encapsulatorState,
  spread: $$spreadState,
  filters: $$filtersState,
  popover: $$popoverState,
  row: $$tableRowState,
  widgetCache: $$widgetCacheState
}
