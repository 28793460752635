import React from 'react'
import shortid from 'shortid'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getDropdownObjectsList } from 'libs/components/EnumValuesHelper'

const RadioButton = (props) => {
  const { label, id, value, required, options, onChange } = props
  const {
    containerClassName,
    inline,
    astrick,
    enumKey,
    iconClass,
    styledRadioBtn,
    readonly,
    className,
    iconSelectedClasses,
    iconClasses
  } = options
  const enumOptions =
    enumKey ?
      getDropdownObjectsList(enumKey) :
      options.enumOptions.map((option) => {
        return {
          id: option.value,
          label: label == false ? '' : (option.label ? option.label : option.value)
        }
      })

  return (
    <React.Fragment>
      <label htmlFor={id}>
        {label}
        {required && astrick ? '*' : null}
      </label>
      <div className={classNames('radio-btn-group', containerClassName)}>
        {enumOptions.map(option => (styledRadioBtn ? (
          <StyledRadioBtn
            key={shortid.generate()}
            readonly={readonly}
            inline={inline}
            value={value}
            onChange={onChange}
            option={option}
            iconClass={iconClass}
            className={className}
            iconSelectedClass={option.id === value ? iconSelectedClasses[option.id].iconSelectedClass : iconSelectedClasses[option.id].iconUnselectedClass}
            iconWrapperClass={iconSelectedClasses[option.id].iconWrapperClass}
          />
        )
          : (
            <DefaultRadioBtn
              id={id}
              key={shortid.generate()}
              readonly={readonly}
              inline={inline}
              value={value}
              onChange={onChange}
              option={option}
              className={className}
              iconClass={iconClasses ? iconClasses[option.id] : null}
            />
          )))}
      </div>
    </React.Fragment>
  )
}

const DefaultRadioBtn = ({ id, inline, readonly, value, onChange, option, className, iconClass }) => {
  return (
    <div className={classNames({ 'd-inline-block': inline }, className)}>
      <input
        name={id}
        value={option.id}
        type="radio"
        checked={String(option.id) === String(value)}
        onChange={e => onChange(e.target.value)}
        disabled={readonly}
      />
      {
        iconClass ?
          <i
            className={classNames('mr-3', iconClass)}
            aria-hidden="true"
          /> :
          <span>{option.label}</span>
      }
    </div>
  )
}

DefaultRadioBtn.propTypes = {
  inline: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  option: PropTypes.object,
  value: PropTypes.any,
  id: PropTypes.any

}

const StyledRadioBtn = ({ inline, readonly, onChange, option, iconClass, iconSelectedClass, iconWrapperClass, className }) => {
  return (
    <div className={classNames({ 'd-inline-block': inline }, className)}>
      <span className={iconWrapperClass}>
        <i
          className={classNames(iconClass, iconSelectedClass, { 'disabled': readonly })}
          aria-hidden="true"
          onClick={e => onChange(option.id)}
        />
      </span>
      <span className="font-14">{option.label}</span>
    </div>
  )
}

StyledRadioBtn.propTypes = {
  inline: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  option: PropTypes.object,
  iconClass: PropTypes.string,
  iconSelectedClass: PropTypes.string,
  iconWrapperClass: PropTypes.string
}

RadioButton.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  onChange: PropTypes.func
}

export default RadioButton
