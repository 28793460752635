/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { EditorState, ContentState } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import bindAll from 'lodash.bindall'

import Button from 'libs/components/Button'

const linkifyPlugin = createLinkifyPlugin({ component: ((props) => { return <Button {...props} className="link" target="_blank" /> }) })
const plugins = [linkifyPlugin]

class TextEditor extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      editorState: EditorState.createEmpty(),
      rawData: ''
    }
    bindAll(
      this,
      'focus',
      'onEditorStateChange'
    )
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      editorState: value ? EditorState.createWithContent(ContentState.createFromText(value)) : EditorState.createEmpty(),
      rawData: value
    })
  }

  onEditorStateChange(editorState) {
    const { rawData: currentText } = this.state
    const rawData = editorState.getCurrentContent().getPlainText()
    this.setState({ editorState, rawData }, () => {
      if (currentText !== rawData && this.props.onChange) {
        this.props.onChange(rawData !== '' ? rawData : null)
      }
    })
  }

  focus(e) {
    this.editor.focus()
    const target = $(e.target)
    if (target.closest('a.link').length > 0) {
      e.preventDefault()
      window.open(target.html(), '_blank')
      $('.editor .public-DraftEditor-content').blur()
    }
  }

  scrollContent() {
    $('.editor').scrollTop(0)
  }

  render() {
    const { label, placeholder, required, readonly, options } = this.props
    return (
      <React.Fragment>
        { label && (
        <label>
          {label}
          {required && options.astrick ? '*' : null}
        </label>
        ) }
        <div className={classNames('editor', { readonly })} onClick={this.focus} onBlur={this.scrollContent} role="presentation">
          <Editor
            editorState={this.state.editorState}
            onChange={this.onEditorStateChange}
            plugins={plugins}
            readOnly={readonly}
            placeholder={!readonly ? placeholder : ''}
            ref={(element) => { this.editor = element }}
          />
        </div>
      </React.Fragment>
    )
  }
}

TextEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

TextEditor.defaultProps = {
  value: '',
  readonly: false,
  placeholder: 'Start typing...'
}

export default TextEditor
