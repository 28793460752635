import React, { Component } from 'react'
import { isEmpty } from 'lodash/fp/'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { updateQueryStringParams } from 'core/helpers/utils'
import { debounce } from 'throttle-debounce'
import { cleanObject, populateJSON } from 'libs/utils'

import requestsManager from 'libs/api/requestsManager'

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

class ApiAutocomplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [], // Data set for Menu Items
      inputValue: '',
      value: null, // Initial Value
      isLoading: false,
    }
  }

  get dataUrl() {
    const { dataUrl, watch, searchParam } = this.props.uiSchema['ui:options']
    const { formContext } = this.props
    const { inputValue } = this.state

    if (watch && formContext) {
      try {
        const data = populateJSON(watch, formContext)
        cleanObject(data)
        if (!isEmpty(data)) {
          return updateQueryStringParams(dataUrl, {
            ...data,
            [`${searchParam}`]: inputValue,
          })
        }
      } catch (err) {}
    }

    return null
  }

  get createUrl() {
    const { create = {}, watch, searchParam } = this.props.uiSchema['ui:options']
    const { formContext } = this.props
    const { createPayloadTemplate } = create
    const { inputValue } = this.state

    if (watch && formContext) {
      try {
        const data = populateJSON(watch, formContext)
        cleanObject(data)
        if (!isEmpty(data)) {
          return updateQueryStringParams(create.createUrl, {
            ...data,
            [`${searchParam}`]: inputValue,
          })
        }
      } catch (err) {}
    }

    return null
  }

  // Set the Menu Items
  componentDidMount() {
    const { dataUrl } = this.props.uiSchema['ui:options']
    this.fetchData(dataUrl)
  }

  fetchData = () => {
    const { dataUrl, watch, searchParam } = this.props.uiSchema['ui:options']
    const { formContext } = this.props
    const { inputValue } = this.state

    this.setState({ isLoading: true })
    if (watch) {
      if (!this.dataUrl) return;

      requestsManager.fetchEntities(this.dataUrl).then((res) => {
        const options = res.data.results
        this.setState({ isLoading: false, options })
      })
    } else {
      const separator = dataUrl.indexOf('?') > -1 ? '&' : '?'
      const url = `${dataUrl + separator + searchParam}=${inputValue}`
      requestsManager.fetchEntities(url).then((res) => {
        const options = res.data.results
        this.setState({ isLoading: false, options })
      })
    }
  }

  handleItemSelect = (selectedItems) => {
    this.setState({ isLoading: false, value: selectedItems })
    const { schema } = this.props
    if (schema.type === 'array') {
      this.handleMultiItemSelect(selectedItems)
    } else {
      this.handleSingleItemSelect(selectedItems)
    }
  }

  handleSingleItemSelect = (selectedItem) =>
    this.props.onChange(selectedItem ? selectedItem.id : selectedItem)

  /*
    By default react-jsonschema-form expects an array of integers but in order to customize
    the payload for our needs we do this in the if else clause -
    IF:
    customized payload is required, we send an array of objects
    Ex - if payloadUniqueKey is 'user_id', we send - [{user_id: 1}, {user_id: 2}, ...]

    ELSE:
    We send an array of integers as expected by react-jsonschema-form
    Ex - [1, 2, ...]
  */
  handleMultiItemSelect = (selectedItems) => {
    const { uiSchema } = this.props
    const { customizePayload, payloadUniqueKey } = uiSchema['ui:options']
    customizePayload
      ? this.props.onChange(
          selectedItems.map((item) => ({ [payloadUniqueKey]: item.id }))
        )
      : this.props.onChange(selectedItems.map((item) => item.id))
  }

  debouncedFetchClients = debounce(500, (url) => {
    return this.fetchData()
  })

  handleInputChange = (inputValue) => {
    this.setState({ inputValue, isLoading: true }, this.debouncedFetchClients)
  }

  handleCreate = (inputValue) => {
    const { create = {}, watch } = this.props.uiSchema['ui:options']
    const { formContext } = this.props
    const { createPayloadTemplate } = create

    this.setState({ isLoading: true })

    let payload = createPayloadTemplate
      ? populateJSON(createPayloadTemplate, { name: inputValue })
      : { name: inputValue }

    let createUrl = create.createUrl;
    if (watch && this.createUrl) {
      createUrl = this.createUrl
    }

    requestsManager.submitEntity(createUrl, payload).then((res) => {
      const { options } = this.state
      const newOption = res.data
      this.handleItemSelect(newOption)
    })
  }

  renderSelect = (creatable) => {
    const { options, value, inputValue, isLoading } = this.state
    const { uiSchema, schema } = this.props
    const {
      readonly,
      label,
      astrick,
      isClearable,
      watch,
      create,
    } = uiSchema['ui:options']
    const placeholder = uiSchema['ui:placeholder']
    const multiselect = schema.type === 'array'

    if (create) {
      const {
        createLabelPrefix = '',
        createLabelSuffix = '',
        createUrl,
        noOptionsMessage = 'No options (type to add new folder)'
      } = create

      if (!createUrl) {
        throw 'Missing autocomplete create Url'
      }

      return (
        <CreatableSelect
          className="react-select-container"
          classNamePrefix="react-select"
          inputValue={inputValue}
          isClearable={isClearable}
          value={value}
          options={options}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          formatCreateLabel={(inputValue) => {
            if (createLabelPrefix || createLabelSuffix) {
              return `${createLabelPrefix}${inputValue}${createLabelSuffix}`
            }

            return `Create "${inputValue}"`
          }}
          onFocus={() => {
            if (!watch) {
              return
            }
            this.setState({ isLoading: true, options: [] })
            this.fetchData()
          }}
          isLoading={isLoading}
          isMulti={multiselect}
          isDisabled={readonly}
          onChange={this.handleItemSelect}
          onCreateOption={this.handleCreate}
          onInputChange={this.handleInputChange}
          getOptionLabel={(option) => option.label || option.name}
          getOptionValue={(option) => option.id}
        />
      )
    }

    return (
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        inputValue={inputValue}
        isClearable={isClearable}
        placeholder={placeholder}
        value={value}
        options={options}
        isLoading={isLoading}
        isMulti={multiselect}
        isDisabled={readonly}
        onChange={this.handleItemSelect}
        onInputChange={this.handleInputChange}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
      />
    )
  }

  render() {
    const { uiSchema, schema } = this.props
    const { label, astrick } = uiSchema['ui:options']
    const { title } = schema
    return (
      <React.Fragment>
        {!label && (
          <label>
            {title || uiSchema['ui:placeholder']}
            {astrick ? '*' : null}
          </label>
        )}
        {this.renderSelect()}
      </React.Fragment>
    )
  }
}

ApiAutocomplete.propTypes = {
  uiSchema: PropTypes.object,
  schema: PropTypes.object,
}

export default ApiAutocomplete
