import { createSelector } from 'reselect'

export function getSpreads(state, id) {
  if (!state[id]) return []
  return state[id].spreads
}

export function getPrimaryWidget(state, id) {
  if (!state[id]) return null
  return state[id].widget
}

export const getBreadcrumbs = createSelector(
  [getSpreads, getPrimaryWidget],
  (spreads, primaryWidget) => {
    if (!primaryWidget || spreads.length < 1) return []
    let breadcrumbArray = [primaryWidget]
    spreads.forEach((spread, index) => {
      let breadcrumbString = `${spread.label}`
      if (spread.drillDownWidget) {
        breadcrumbString = `${breadcrumbString}-${spread.drillDownWidget}`
      }
      breadcrumbArray.push(breadcrumbString)
    })
    return breadcrumbArray
  }
)

export function getPrimaryWidgetEncapsulators(state, id) {
  if (!state[id]) return null
  return state[id].encapsulators
}
