import React from 'react'
import PropTypes from 'prop-types'

import imagesMap from 'core/helpers/imagesMap'

const Image = ({ schema = {}, data = {} }) => {
  const src = schema.src || data.src
  const altTitle = schema.altTitle || data.altTitle
  const placeholder = schema.placeholder || data.placeholder
  const style = schema.style || {}
  const styles = { ...style.styles, ...data.styles}
  const placeholderText = placeholder || (altTitle || '').substr(0, 1)

  if (src) {
    return (
      <img src={imagesMap[src] || src} alt={altTitle} className={style.imageClassName} style={style.styles} />
    )
  }

  if (styles && (styles.backgroundImage || styles.background)) {
    return (
      <div
        alt={altTitle}
        className={style.imageClassName}
        style={{...styles}}
      />
    )
  }

  return (
    <span className={style.textClassName}>
      {placeholderText}
    </span>
  )
}

Image.propTypes = {
  schema: PropTypes.shape({
    src: PropTypes.string,
    altTitle: PropTypes.string,
    style: PropTypes.shape()
  }),
  data: PropTypes.shape({
    src: PropTypes.string,
    altTitle: PropTypes.string
  })
}
export default Image
