/* eslint-disable react/button-has-type */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from 'libs/components/Loader'
import WidgetGroupContainer from '../redux/containers/WidgetGroupContainer'
import LayoutRenderer from './LayoutRenderer'

class TableRowRenderer extends Component {
  componentDidMount() {
    const { row, schema, show } = this.props

    if (!schema && row) {
      this.props.fetchTableRowSchema(row)
    }
  }

  render() {
    const {
      schema,
      loadingSchema,
      widgetGroupIds,
      error
    } = this.props

    if (error) {
      return (
        <div
          style={{
            padding: 15,
            textAlign: 'center',
            background: '#f1f2f5',
            color: 'red'
          }}
        >{ error === 401 ? 'Unauthorized.' : 'There was an error while fetching data.'}</div>
      )
    }

    if (!loadingSchema && !schema) {
      return null
    }

    if (loadingSchema) {
      return (
        <div style={{overflow: 'auto'}}>
          <Loader className="inline-loader text-gray" />
        </div>
      )
    }

    return (
      <div className="px-md-none">
        <LayoutRenderer
          widgetGroups={schema.widgetGroups}
          parentId={schema.id}
        />
      </div>
    )
  }

  componentWillUnmount() {
    const { schema, widgetGroupIds } = this.props
    const schemaId = schema ? schema.id : null
    this.props.resetTableRowSchema(schemaId, widgetGroupIds)
  }
}

TableRowRenderer.propTypes = {
  row: PropTypes.shape({
    schemaUrl: PropTypes.string,
    schema: PropTypes.object
  }),
  schema: PropTypes.object,
}

export default TableRowRenderer
