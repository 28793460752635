/* eslint-disable react/destructuring-assignment */
import React, { Component, useState,  } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import classNames from 'classnames'

import { getDropdownObjectsList } from 'libs/components/EnumValuesHelper'


/*
  We can extract the menu items either from the global enums variable using getDropdownObjectsList
  or the enum/enumNames provided in the form's jsonSchema
*/
const getDropDownOptions = (options, items) => {
  return options.enumKey
    ? getDropdownObjectsList(options.enumKey) : items.enum.map((option, index) => {
      return { id: option, label: items.enumNames[index], icon: items.enumIcons ? items.enumIcons[index] : null}
    })
}

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex "
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

class Autocomplete extends Component {
  state = {
    options: [], // Data set for Menu Items
    value: null
  }

  // Set the Menu Items
  componentDidMount() {
    const { uiSchema, schema, formData } = this.props
    const items = schema.type === 'array' ? schema.items : { enum: schema.enum, enumNames: schema.enumNames, enumIcons: schema.enumIcons }
    const options = getDropDownOptions(uiSchema['ui:options'], items)
    const initialValue = formData ? this.extractInitialValue(formData, options) : null
    this.setState({ options, value: initialValue })
  }

  extractInitialValue = (formData, options) => {
    const { schema } = this.props
    if (schema.type === 'array') {
      const values = []
      options.forEach((option) => {
        if (formData.includes(option.id)) {
          values.push(option)
        }
      })
      return values
    }
    return options.filter(option => option.id === formData)[0]
  }

  handleItemSelect = (selectedItems) => {
    this.setState({ value: selectedItems })
    const { schema } = this.props
    if (schema.type === 'array') {
      this.handleMultiItemSelect(selectedItems)
    } else {
      this.handleSingleItemSelect(selectedItems)
    }
  }

  handleSingleItemSelect = selectedItem => this.props.onChange(selectedItem.id)

  /*
    By default react-jsonschema-form expects an array of integers but in order to customize
    the payload for our needs we do this in the if else clause -
    IF:
    customized payload is required, we send an array of objects
    Ex - if payloadUniqueKey is 'user_id', we send - [{user_id: 1}, {user_id: 2}, ...]

    ELSE:
    We send an array of integers as expected by react-jsonschema-form
    Ex - [1, 2, ...]
  */
  handleMultiItemSelect = (selectedItems) => {
    const { uiSchema } = this.props
    const { customizePayload, payloadUniqueKey } = uiSchema['ui:options']
    customizePayload
      ? this.props.onChange(selectedItems.map(item => ({ [payloadUniqueKey]: item.id })))
      : this.props.onChange(selectedItems.map(item => item.id))
  }

  getLabel = ({ id, label, icon }) => {
    if (!icon) return label;

    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>{label}</div>
        <div className={icon}></div>
      </div>
    );
  }

  render() {
    const { options, value } = this.state
    const { uiSchema, schema } = this.props
    const { readonly, label, astrick, maxMenuHeight } = uiSchema['ui:options']

    const multiselect = schema.type === 'array'
    const { title } = schema
    const multiselectProps = multiselect ? {
      components: {
        Option: InputOption
      }
    } : {}
    return (
      <React.Fragment>
        {!label && (<label>{title}{astrick ? '*' : null}</label>)}
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          value={value}
          options={options}
          isDisabled={readonly}
          isMulti={multiselect}
          menuPlacement="auto"
          placeholder={uiSchema['ui:placeholder']}
          maxMenuHeight={maxMenuHeight || 300}
          onChange={this.handleItemSelect}
          getOptionLabel={this.getLabel}
          getOptionValue={option => option.id}
          closeMenuOnScroll={false}
          closeMenuOnSelect={!multiselect}
          {...multiselectProps}
          // defaultMenuIsOpen={true}
        />
      </React.Fragment>
    )
  }
}

Autocomplete.propTypes = {
  uiSchema: PropTypes.object,
  schema: PropTypes.object,
  formData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
  onChange: PropTypes.func
}

export default Autocomplete
