import requestsManager from 'libs/api/requestsManager'
import { updateQueryStringParams } from 'core/helpers/utils'

import {
  FETCHING_POPOVER_SCHEMA,
  FETCH_POPOVER_SCHEMA_SUCCESS,
  RESET_POPOVER_SCHEMA
} from '../constants/popoverConstants'

import { setWidgetGroups } from './widgetGroupActionCreators'

export const fetchingPopoverSchema = (loadingSchema = true) => {
  return {
    type: FETCHING_POPOVER_SCHEMA,
    loadingSchema
  }
}

export const fetchPopoverSchemaSuccess = (schema) => {
  return {
    type: FETCH_POPOVER_SCHEMA_SUCCESS,
    schema
  }
}

export const setPopoverSchemaAndWidgetGroups = (schema) => {
  return (dispatch) => {
    dispatch(fetchPopoverSchemaSuccess(schema))
    dispatch(setWidgetGroups(schema.id, schema.widgetGroups))
  }
}

export const fetchPopoverSchema = (popover, force = false) => {
  return (dispatch) => {
    if (!popover.schema || force) {
      dispatch(fetchingPopoverSchema())
      const requestsManagerFn = requestsManager.fetchEntities
      const { schemaUrl, schemaParams, data } = popover
      let url = schemaParams && Object.keys(schemaParams).length > 0 ? updateQueryStringParams(schemaUrl, schemaParams) : schemaUrl
      url = data && Object.keys(data).length > 0 ? updateQueryStringParams(schemaUrl, data) : url

      return (
        requestsManagerFn.call({}, url)
          .then((resp) => {
            dispatch(setPopoverSchemaAndWidgetGroups(resp.data))
          })
          .catch((error) => { console.info(error) })
      )
    }
    dispatch(setPopoverSchemaAndWidgetGroups(popover.schema))
  }
}

export const resetPopoverSchema = (popoverId, widgetGroupIds) => {
  return {
    type: RESET_POPOVER_SCHEMA,
    popoverId,
    widgetGroupIds
  }
}
