import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'


const Legend = (props) => {
  const { payload } = props
  return (
    <ul className="recharts-default-legend">
      {
        payload.map(data => (
          <li key={shortid.generate()} className="recharts-legend-item legend-item-0">
            <svg className="recharts-surface" width="6" height="6" version="1.1">
              <circle
                className="recharts-symbols"
                cx="3"
                cy="3"
                r="3"
                fill={data.color}
              />
            </svg>
            <span className="recharts-legend-item-text">{data.value}</span>
          </li>
        ))
      }
    </ul>
  )
}

Legend.propTypes = {
  payload: PropTypes.array
}

export default Legend
