import { connect } from 'react-redux'
import SpreadRenderer from 'core/renderers/SpreadRenderer'

import * as actions from '../actions/spreadActionCreators'
import {
  getSpreads,
  getBreadcrumbs,
  getPrimaryWidgetEncapsulators
} from '../selectors/spreadSelector'


const mapStateToProps = (state, ownProps) => ({
  spreads: getSpreads(state.spread, ownProps.id),
  breadcrumbs: getBreadcrumbs(state.spread, ownProps.id),
  primaryWidgetEncapsulators: getPrimaryWidgetEncapsulators(state.spread, ownProps.id)
})

export default connect(mapStateToProps, actions)(SpreadRenderer)
