import React, { Children } from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
  const { disabled, className, href, show, onClick, children, target } = props
  const btnClass = disabled ? `${className} disabled` : className
  if (show) {
    if (href) {
      return (
        <a href={href} className={btnClass} onClick={onClick} target={target}>
          {Children.toArray(children)}
        </a>
      )
    }

    return (
      <button className={btnClass} onClick={onClick}>
        {Children.toArray(children)}
      </button>
    )
  }

  return null
}

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string
}

Button.defaultProps = {
  className: 'cvc-btn-primary',
  show: true,
  disabled: false
}

export default Button
