/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bindAll from 'lodash.bindall'
import { default as ReactSlider } from 'react-rangeslider'
import debounce from 'lodash.debounce'
import 'react-rangeslider/lib/index.css'

class Slider extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      weightageLabel: null,
      'sliderConfig': {
        'slider': {
          'containerClassName': 'cvc-slider',
          'className': 'progress-slider',
          handleLabel: val => `${val}%`
        },
        'tick-slider': {
          'containerClassName': 'cvc-tick-slider',
          'className': 'priority-slider'
        }
      }
    }
    bindAll(
      this,
      'setValue',
      'highlightSelectedLabel',
      'setWeightageLabel'
    )
    this.debouncedSetValue = debounce((value) => {
      return this.setValue(value)
    }, 20)
  }

  componentDidMount() {
    const { value, options } = this.props
    const { max, min } = options
    // TODO: Handle case when intial val not given
    const val = value !== undefined ? value : parseInt(((max - min) + 1) / 2, 10)
    this.highlightSelectedLabel(val)
  }

  setValue(value) {
    const { readonly } = this.props.options
    if (readonly) return
    this.highlightSelectedLabel(value)
    this.props.onChange(value)
  }

  setWeightageLabel(value) {
    const { weightageLabels } = this.props.options

    if (weightageLabels) {
      return this.setState({ weightageLabel: weightageLabels[value] || '' })
    }

    let weightageLabel = 'Unknown'
    if (value < 4) {
      weightageLabel = 'Critical'
    } else if (value < 7) {
      weightageLabel = 'High'
    } else {
      weightageLabel = 'Nice to Have'
    }
    this.setState({ weightageLabel })
  }

  highlightSelectedLabel(val) {
    const { type } = this.props.options
    if (type !== 'tick-slider') {
      return false
    }
    const slider = this.sliderContext
    const rangeSliderLabels = $(slider).find('.rangeslider__label-item')
    const curLabel = $(slider).find(`.rangeslider__label-item:nth-child(${rangeSliderLabels.length - (val - 1)})`)
    rangeSliderLabels.removeClass('selected')
    curLabel.addClass('selected')
    this.setWeightageLabel(val)
  }

  render() {
    const { sliderConfig, weightageLabel } = this.state
    const { label, value, required, readonly, options } = this.props
    const { className, type, min, max, labels, orientation, tooltip, astrick } = options

    const slider = sliderConfig[type]
    const { containerClassName } = slider
    const sliderClassName = slider.className
    const sliderVal = value !== undefined ? value : parseInt(((max - min) + 1) / 2, 10)
    return (
      <div className={classNames(containerClassName, { 'disabled': readonly })} ref={(comp) => { this.sliderContext = comp }}>
        <label className="silder-status-label">
          {label}
          {required && astrick ? '*' : null}
          <span className="font-weight-bold ml-1">
            {weightageLabel}
          </span>
        </label>
        <div className={classNames(sliderClassName, className)}>
          <ReactSlider
            value={sliderVal}
            min={min}
            max={max}
            orientation={orientation || 'horizontal'}
            tooltip={tooltip || false}
            labels={labels}
            handleLabel={slider.handleLabel ? slider.handleLabel(sliderVal) : undefined}
            onChange={val => this.debouncedSetValue(val)}
          />
        </div>
      </div>
    )
  }
}

Slider.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  options: PropTypes.object,
  label: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func
}

export default Slider
