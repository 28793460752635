import { pickBy, isEqual, isEmpty, every } from 'lodash/fp/'
import { cleanObject, updateOrAddQueryString } from 'libs/utils'
import { getPageIdKey } from 'core/helpers/utils'

function prepareUrlFromFilters(queryFilters) {
  let newQueryString = window.location.search
  Object.keys(queryFilters).forEach(key => {
    const value = isEmpty(queryFilters[key]) ? null : queryFilters[key]
    newQueryString = updateOrAddQueryString(newQueryString, key, value)
  })

  return `${window.location.protocol}//${window.location.host}${window.location.pathname}${newQueryString}`
}

// These are the events that will
// be able to update the filters in url
const filterEventTypes = [
  'SET_FILTER',
  'UPDATE_FILTER',
  'REMOVE_FILTER_ITEM',
  'REMOVE_FILTER',
]

// These are the items from reducers will reflact in URL
// whenever state changes.
const whitelist = ['filters'];

const blacklistUrls = [
  '/tasks',
  '/notes',
  '/project_manager',
  '/sponsor',
  '/delivery',
  '/success'
];

export default function filters(intialFilters) {
  const pageId = getPageIdKey()
  let previousFilters = pickBy((value, key) => { return key !== '_persist' && key.startsWith(pageId) }, intialFilters)

  return ({ getState }) => (next) => (action) => {
    // Call the next dispatch method in the middleware chain.
    const result = next(action)

    if (action.preventUrlUpdates) {
      return result
    }

    const state = getState()

    try {
      const newFilters = pickBy((value, key) => { return key !== '_persist' && key.startsWith(pageId) }, state.filters)

      // @todo remove this once we have implemented
      // full spread support across pages.
      const supportedRoute = every((blacklistUrl) => {
        return !window.location.pathname.endsWith(blacklistUrl)
      }, blacklistUrls);

      if (!supportedRoute) {
        return result;
      }
      cleanObject(newFilters)

      // 1. We need to make sure we don't udpate the filters if already set
      // else turbolink will try to reload the page which is not required.
      // 2. Only modify urls for events related to filterEventTypes
      if (!isEqual(previousFilters, newFilters) && filterEventTypes.indexOf(action.type) >= 0) {
        const queryFilters = {
          filters: isEmpty(newFilters) ? null : JSON.stringify(newFilters)
        }

        const newUrl = prepareUrlFromFilters(queryFilters)

        // To not reload the page on filter change.
        // When upgrading turbolinks please check
        // https://github.com/turbolinks/turbolinks/issues/491
        // https://github.com/turbolinks/turbolinks/issues/75
        window.Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(newUrl, window.Turbolinks.uuid())
        previousFilters = newFilters
      }
    } catch (error) {
      console.error('Error while updating query filter: ', error.message)
    }

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return result
  }
}
