import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

const TabList = ({ items, selectedItem, onItemSelect }) => {
  return (
    <ul className="nav nav-pills" id="pills-tab" role="tablist">
      {items.map((item) => {
        return (
          <li key={shortid.generate()} className="nav-item">
            <a className={`nav-link${item === selectedItem ? ' active' : ''}`} role="presentation" onClick={() => onItemSelect(item)}>{item}</a>
          </li>
        )
      })
      }
    </ul>
  )
}

TabList.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func
}

export default TabList
