import React from 'react'
import PropTypes from 'prop-types'
import requestsManager from 'libs/api/requestsManager'
import { formatError } from 'libs/utils'
import { debounce } from 'throttle-debounce'
import tinymce from 'tinymce'

const contentCss = require('!!postcss-loader!tinymce/skins/lightgray/content.min.css')

class RichTextEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
    this.searchInProgress = null;
  }

  componentDidMount() {
    const {
      name,
      insertProperty,
      displayListProperty,
      searchKey,
      atSymbol,
      onHideCallback,
      handleChange,
      handleBlur,
      placeholder,
      imageUpload,
      tinyOptions = {},
      relink
    } = this.props

    const self = this

    const imageUploadProps = imageUpload ? {
      images_upload_handler: function image_upload_handler(blobInfo, success, failure, progress) {
        const { actions, imageableId, imageableType } = imageUpload

        if (!actions || !imageableId || !imageableType) {
          throw 'Missing actions, imageableId or imageableType in imageUpload'
        }

        if (!actions.create || !actions.create.apiUrl) {
          throw 'missing create action apiUrl in imageUpload actions'
        }

        const formData = new FormData()
        const requestsManagerFn = requestsManager.submitEntity

        formData.append('image[image]', blobInfo.blob())
        formData.append('image[imageable_id]', imageableId)
        formData.append('image[imageable_type]', imageableType)

        requestsManagerFn.call({}, actions.create.apiUrl, formData, true)
          .then((res) => {
            success(window.location.origin + res.data.imageUrl)
            handleChange(name, tinymce.activeEditor.getContent())
          })
          .catch((error) => {
            const { data } = error.response
            const errorText = formatError(data) || 'Invalid file format. Valid file formats are - JPEG, GIF, PNG';
            failure(errorText)
          })
      }
    } : {}

    const relinkProps = relink ? {
      relink: {
        patterns: relink.patterns,
        searchLink: (matchedWord, callback) => {
          this.searchLink(matchedWord, callback)
        },
      }
    } : {}

    tinymce.init({
      selector: `textarea[name="${name}"]`,
      body_class: 'cvc-c-richtext-editor',
      plugins: [
        'code mention lists image link placeholder paste table autolink relink'
      ],
      menubar: false,
      branding: false,
      height: 150,
      toolbar: 'bold italic underline link | removeformat',
      skin: false,
      mentions: {
        delay: 0,
        match: false,
        sort: false,
        source: (query, process, delimiter) => {
          self.debouncedSearchMentioners(query, process)
        },
        insert: function(item) {
          return `<span class="tiny-user-tag" style="${item.is_customer ? 'background-color: #fffdaf;' : ''}" contenteditable="false" data-mention-id="@${item.username}">@${item.name}</span>&nbsp;`
        },
        render: function(item) {
          if (item.is_customer) {
            return (
              '<li style="background-color: #fffdaf;">' +
                '<span>' + item.nameEmail + '</span>' +
              '</li>'
            )
          }
          return (
            '<li>' +
              '<span>' + item.nameEmail + '</span>' +
            '</li>'
          )
        }
      },
      link_context_toolbar: true,
      elementpath: false,
      resize: false,
      statusbar: false,
      convert_urls: false,
      setup: function (ed) {
        ed.on('init', function(args) {
          self.setState({
            loading: false
          })
        });
        ed.on('change',function(e) {
          handleChange(name, e.target.getContent())
        });
        ed.on('keyup',function(e) {
          handleChange(name, ed.getContent())
        });
        ed.on('blur',function(e) {
          handleBlur(name, ed.getContent())
        });
      },
      content_style: contentCss + '\n .tiny-user-tag { color: #42526E;pointer-events: none;background:rgba(9,30,66,0.1);border-radius: 20px;border: 1px solid #ddd;padding: 0px 0.3em 2px 0.23em; } ',
      ...tinyOptions,
      ...imageUploadProps, // used in image plugin
      ...relinkProps // used in relink plugin
    })
  }

  setContent() {
    tinymce.activeEditor.setContent('');
  }

  searchLink = (matchedWord, callback) => {
    const { relink = {}} = this.props
    if (!relink.apiUrl) {
      return;
    }
    $.getJSON(relink.apiUrl, {
      q: matchedWord,
      ...relink.apiPayload
    }, (data) => {
      return callback(data)
    })
  }

  searchMentioners = (query, callback) => {
    const { mentionsUrl } = this.props
    if (!mentionsUrl) {
      return
    }

    // cancel previous query if processing
    // is still under progress
    if (this.searchInProgress) {
      this.searchInProgress.abort();
      this.searchInProgress = null;
    }

    this.searchInProgress = $.getJSON(mentionsUrl, {
      q: query
    }).done((data) => {
      let i
      const userIds = []
      const uniqueUsers = []
      const users  = data.users
      for (i = 0; i < users.length; i += 1) {
        const user  = users[i]
        if (userIds.indexOf(user.username) === -1) {
          user.nameEmail = `${user.name} (${user.email})`
          uniqueUsers.push(user)
          userIds.push(user.username)
        }
      }
      return callback(uniqueUsers)
    })
  }

  debouncedSearchMentioners = debounce(250, this.searchMentioners)

  render() {
    const {
      name,
      value,
      className,
      placeholder,
      style = {}
    } = this.props

    return (
      <React.Fragment>
        <textarea
          className={className}
          name={name}
          placeholder={placeholder}
          defaultValue={value}
          style={style}
          disabled={this.state.loading}
        />
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    const { name, id } = this.props
    tinymce.remove(`textarea[name="${name}"]`)
  }
}

RichTextEditor.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  style: PropTypes.object,
  tinyOptions: PropTypes.object,
  imageUpload: PropTypes.object,
  handleBlur: PropTypes.func,
}

RichTextEditor.defaultProps = {
  displayListProperty: 'name',
  handleChange: () => {},
  handleBlur: () => {}
}

export default RichTextEditor