/* eslint-disable react/destructuring-assignment */
import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import classNames from "classnames"
import { success, error } from 'react-notification-system-redux'
import { handleAction } from "../redux/actions/appActionCreators"
import { renderData } from "../helpers/utils"

class List extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      notificationOptions: {
        title: '',
        message: '',
        position: 'tc',
        autoDismiss: 3
      },
    }
  }

  handleItemClick = (e, eventSchema, itemData = {}) => {
    const eventsData = itemData.events
    const action = {
      schema: eventSchema,
      data: eventsData[eventSchema.id],
    }
    this.props.dispatch(
      handleAction(e, action, (response) => {
        const { notifications } = eventSchema

        // if no notification key passed from schema
        if (Object.keys(notifications).length === 0) {
          return false
        }

        if (response.status === "success") {
          this.props.dispatch(
            success({
              ...this.state.notificationOptions,
              title: notifications.success.title,
            })
          )
        } else {
          this.props.dispatch(
            error({
              ...this.state.notificationOptions,
              title: notifications.error.title,
              message: response.error,
            })
          )
        }
      })
    )
  }

  renderListItems = () => {
    const { schema, data } = this.props
    const { items = [] } = data
    const { style = {}, placeholder, events } = schema
    const { item = {} } = style

    if (items.length === 0) {
      return (
        <div className="p-3 text-gray text-center">
          {placeholder || "no items found."}
        </div>
      )
    }

    return items.map((itemData) => {
      const { linkUrl } = itemData
      const itemClassName = classNames(item.className, itemData.style ? itemData.style.className : '')

      return (
        <li
          className={classNames("list-item", itemClassName, {
            action: linkUrl,
            'cursor-pointer': events && events.onClick && itemData
          })}
          key={shortid.generate()}
          role="presentation"
          onClick={(e) => {
            if (events) {
              this.handleItemClick(e, events.onClick, itemData)
            }
          }}
        >
          {schema.properties.map((property) => {
            const propertyStyle = property.style || {}
            const propertyData = itemData[property.id]

            if ((propertyData && propertyData.hide) || property.hide) {
              return null
            }
            return (
              <div className={propertyStyle.className} key={shortid.generate()}>
                {renderData(property, propertyData)}
              </div>
            )
          })}
        </li>
      )
    })
  }

  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div
        role="presentation"
        className={classNames("heading-container", heading.containerClassName)}
        {...heading.tooltip}
      >
        {heading && heading.title && (
          <div
            className={classNames(
              "d-inline-block align-middle",
              heading.className
            )}
          >
            {renderData(
              heading.title,
              heading.title.type === "COMPONENT"
                ? data.heading.title
                : heading.title
            )}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          {heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { schema, data } = this.props
    const { heading, style = {} } = schema
    const { item = {} } = style

    return (
      <div className={classNames("cvc-w-list", style.className)} >
        {heading && this.renderHeading()}
        <ul className={classNames("list-container", style.containerClassName)}>
          {this.renderListItems()}
        </ul>
      </div>
    )
  }
}

List.propTypes = {
  schema: PropTypes.shape({
    heading: PropTypes.object,
    style: PropTypes.object,
    properties: PropTypes.array,
    placeholder: PropTypes.string,
    actions: PropTypes.object,
  }).isRequired,
  data: PropTypes.object,
}

List.contextTypes = {
  store: PropTypes.object,
}

List.defaultProps = {
  data: {},
}

export default List
