import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import { range } from 'lodash/fp'

import { renderData, getPropertySchema } from '../helpers/utils'

class Text extends React.PureComponent {

  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div role="presentation" className={classNames('heading-container', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  renderText() {
    const { schema: { defaultEmptyTextLines, style = {} }, data } = this.props
    const { text } = data

    if (!defaultEmptyTextLines && !text) {
      return null
    }

    if (!text) {
      if (!defaultEmptyTextLines) {
        return null
      }

      const emptyLines = range(0, defaultEmptyTextLines)
      return emptyLines.map((index) => (
        <br key={shortid.generate()} />
      ))
    }

    return (
      text && text.split('\n').map((line) => {
        return (
          <p
            className={classNames('px-3', style.className)}
            key={shortid.generate()}
            dangerouslySetInnerHTML={{__html: line}}
          />
        )
      })
    )
  }

  render() {
    const { schema } = this.props
    const style = schema.style || {}

    return (
      <div className={classNames('cvc-w', 'cvc-w-text', style.containerClassName)}>
        {this.renderHeading()}
        {this.renderText()}
      </div>
    )
  }
}

Text.propTypes = {
  schema: PropTypes.shape({
    className: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    text: PropTypes.string
  }).isRequired
}

export default Text
