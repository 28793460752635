import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

import { renderData } from '../helpers/utils'

const IFrame = ({ schema, data }) => {
  const defaultSchema = {
    style: {
      className: ''
    }
  }
  const style = { ...schema.style, ...data.style }
  const { src } = data

  return (
    <iframe
      className={classNames("cvc-c-iframe", style.className)}
      src={src + '#toolbar=0&navpanes=0&scrollbar=0'}
      width={style.width || "100%"}
      height={style.height || "600"}
      frameborder="0"
    />
  )
}

IFrame.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.shape({
    src: PropTypes.string.isRequired
  }).isRequired
}

export default IFrame
