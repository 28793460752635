import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


class Pill extends PureComponent {
  handleRemove = () => {
    const { onRemove, removeParam } = this.props
    onRemove(removeParam)
  }

  render() {
    const {
      children,
      className
    } = this.props
    return (
      <span className={classNames('cvc-c-pill', className)}>
        {children}
        <span
          className="remove"
          role="presentation"
          aria-label="remove"
          onClick={this.handleRemove}
        />
      </span>
    )
  }
}

Pill.propTypes = {
  children: PropTypes.any,
  onRemove: PropTypes.func,
  removeParam: PropTypes.object,
  className: PropTypes.string
}

export default Pill
