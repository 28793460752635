/* eslint-disable no-use-before-define */
import objectToFormdata from 'object-to-formdata'
import jp from 'jsonpath'
import { formatError } from 'libs/utils'
import requestsManager from 'libs/api/requestsManager'
import { VISIT_PAGE } from '../constants/appConstants'
import { PAGE_TARGET_BLANK, PAGE_TARGET_SELF } from '../constants/pageConstants'
import {
  DELETE_WIDGET,
  DELETE_WIDGET_TABLE_ROW,
  DELETE_WIDGET_TAB_ITEM,
  RESTORE_WIDGET_TABLE_ROW,
  ADD_WIDGET_TABLE_ROW
} from '../constants/widgetConstants'

import {
  fetchingWidgetData,
  fetchingWidgetSchema,
  reloadWidgets,
  deleteWidget,
  markWidgetDirty,
  resetWidget
} from './widgetActionCreators'
import { syncWidget, getWidget } from "../../helpers/utils";
import { showModal, hideModal } from './modalActionCreators'
import { setLoadingOverlay } from './pageActionCreators'
import { addSpread } from './spreadActionCreators'
import { updateFilter } from './filtersActionCreators'
import { resetPopoverSchema } from './popoverActionsCreators'

const actionsMap = {
  'MODAL': modalHandler,
  'FILTER': filterHandler,
  'LINK': linkHandler,
  'API': apiHandler,
  'SPREAD': spreadHandler,
  'UPDATE_FILTER': updateFilterHandler,
  'HIDE_POPOVER': hidePopoverHandler,
  'HIDE_MODAL': hideModalHandler,
  'SAVE_OR_UPDATE_WIDGET_API': saveOrUpdateWidgetApiHandler,
  'DELETE_WIDGET': deleteWidgetHandler,
  'DELETE_WIDGET_TABLE_ROW': deleteTableRowHandler,
  'DELETE_WIDGET_TAB_ITEM': deleteWidgetTabItemHandler,
  'RESTORE_WIDGET_TABLE_ROW': restoreTableRowHandler,
  'RESET_WIDGET': resetWidgetHandler,
  'ADD_WIDGET_TABLE_ROW': addTableRowHandler,
  'REFRESH_WIDGET': refreshWidgetHandler,
}

// eslint-disable-next-line import/prefer-default-export
export const handleAction = (e, action, callback) => {
  const { type } = action.schema
  if (e) {
    e.stopPropagation()
  }
  return actionsMap[type] && actionsMap[type](action, callback)
}

function shouldRefreshSchema(refresh, widget) {
  return (
    (
      refresh.schema && (
        !widget.hasOwnProperty('schema') ||
        widget.hasOwnProperty('schema') &&
        widget.schema
      )
    ) ||
    (!refresh.schema && widget.schema)
  )
}

function modalHandler(action) {
  const { modalSchemaUrl, modalParams } = action.data
  return (dispatch) => {
    dispatch(showModal(modalSchemaUrl || action.schema.modalSchemaUrl, modalParams))
  }
}

function spreadHandler(action) {
  return (dispatch) => {
    dispatch(addSpread(action.data))
  }
}

function hidePopoverHandler() {
  window.isPopoverVisible = false;

  return (dispatch) => {
    dispatch(resetPopoverSchema())
  }
}

function hideModalHandler() {
  $('#modal').modal('hide')
  return (dispatch) => {
    dispatch(hideModal())
  }
}

function updateFilterHandler(action) {
  return (dispatch) => {
    const { schema, data } = action

    const { filters = [], preventUrlUpdates } = schema;
    const filterParams = data.filterParams;

    if (!filters) {
      console.warn('Missing filters in UPDATE_FILTER schema');
      return
    }

    if (!filterParams) {
      console.warn('Missing filterParams in UPDATE_FILTER data');
      return
    }

    filters.forEach((filterSchema) => {
      const filter = {
        id: filterSchema.id,
        data: filterParams,
        type: filterSchema.type,
        refresh: filterSchema.refresh,
        cache: filterSchema.cache === false ? false : true
      };

      dispatch(updateFilter({ filter }, preventUrlUpdates))
    });
  }
}

function filterHandler(action) {
  const { modalSchemaUrl, modalParams, filter } = action.data
  const modalFilterData = filter ? filter.data : {}
  return (dispatch) => {
    dispatch(showModal(modalSchemaUrl || action.schema.modalSchemaUrl, { ...modalParams, filter: modalFilterData }))
  }
}

function linkHandler(action) {
  const { linkUrl, newTab } = action.data
  return {
    type: VISIT_PAGE,
    url: linkUrl || action.schema.linkUrl,
    target: (newTab || action.schema.newTab) ? PAGE_TARGET_BLANK : PAGE_TARGET_SELF
  }
}

function deleteWidgetHandler(action) {
  const { schema, data } = action
  const { widgetId, widgetGroupId } = schema
  const { markDirty } = schema

  return (dispatch, getState) => {
    const appState = getState()

    const widget = getWidget({
      widgetGroupId,
      widgetId,
      appState
    });

    dispatch(deleteWidget(schema.widgetGroupId, schema.widgetId, !widget.deleted))

    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function deleteTableRowHandler(action) {
  const { data, schema } = action
  const { actionsId, id, widgetId, widgetGroupId } = schema

  return (dispatch) => {
    dispatch({
      type: DELETE_WIDGET_TABLE_ROW,
      data: {
        ...data,
        actionId: id,
        actionsId: actionsId
      },
    })

    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function deleteWidgetTabItemHandler(action) {
  const { data, schema } = action
  const { itemId, widgetId, widgetGroupId } = schema

  return (dispatch) => {
    dispatch({
      type: DELETE_WIDGET_TAB_ITEM,
      data: {
        ...data,
        itemId,
        widgetId,
        widgetGroupId
      },
    })

    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function restoreTableRowHandler(action) {
  const { data, schema } = action
  const { actionsId, id, widgetId, widgetGroupId } = schema

  return (dispatch) => {
    dispatch({
      type: RESTORE_WIDGET_TABLE_ROW,
      data: {
        ...data,
        actionId: id,
        actionsId: actionsId
      },
    })

    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function resetWidgetHandler(action) {
  const { schema, data } = action
  const { reset } = schema

  return (dispatch) => {
    if (reset) {
      reset.widgets.forEach((widget) => {
        if (widget.widgetId) {
          dispatch(fetchingWidgetData(widget.widgetGroupId, widget.widgetId, true, schema.actionPayload))
          if (shouldRefreshSchema(reset, widget)) {
            dispatch(fetchingWidgetSchema(widget.widgetGroupId, widget.widgetId, true, schema.actionPayload))
          }
          dispatch(resetWidget(widget.widgetGroupId, widget.widgetId))
        } else {
          dispatch(reloadWidgets(widget.widgetGroupId))
        }
      })
    }
    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function refreshWidgetHandler(action) {
  const { schema, data } = action
  const { refresh, actionPayload, refreshPage, closeModal } = schema

  if (closeModal) {
    $('#modal').modal('hide')
  }

  return (dispatch) => {
    if (refresh) {
      dispatch(refreshWidgets(refresh, actionPayload))
    }

    if (refreshPage) {
      window.location.reload()
    }
  }
}

function addTableRowHandler(action) {
  const { schema, data } = action
  const { apiPayload } = data;
  const { closeModal } = schema;

  if (closeModal) {
    $('#modal').modal('hide')
  }

  return (dispatch) => {
    dispatch({
      type: ADD_WIDGET_TABLE_ROW,
      data: {
        ...apiPayload,
        tableRow: {
          ...apiPayload.tableRow,
          added: true
        }
      }
    })

    const markDirty = data.markDirty || schema.markDirty
    dispatch(markWidgetsDirty(markDirty))
  }
}

function saveOrUpdateWidgetApiHandler(action, callback) {
  const {
    refresh,
    refreshPage,
    redirectUrl,
    widgetId,
    widgetGroupId,
    version,
    updated_at
  } = action.schema

  const {
    apiPayload: payload,
    apiUrl,
    apiType
  } = action.data

  return (dispatch, getState) => {
    const appState = getState()

    const widget = getWidget({
      widgetGroupId,
      widgetId,
      appState
    });

    // apply formatter here
    const syncedWidget = syncWidget(
      widget,
      widgetGroupId,
      widgetId,
      appState.widget
    );

    const apiPayload = {
      ...payload,
      jwp_widget: {
        ...(payload && payload.jwp_widget),
        widget_group_id: widgetGroupId,
        widget_id: widgetId,
        version: version,
        updated_at: updated_at,
        widget: syncedWidget,
      },
    }

    dispatch(setLoadingOverlay(true))
    const requestsManagerMap = {
      'POST': requestsManager.submitEntity,
      'PUT': requestsManager.updateEntity,
      'DELETE': requestsManager.deleteEntity,
      'GET': requestsManager.fetchEntities
    }
    const requestsManagerFn = requestsManagerMap[apiType || action.schema.apiType]

    return (
      requestsManagerFn.call({}, apiUrl || action.schema.apiUrl, apiPayload)
        .then((resp) => {
          dispatch(setLoadingOverlay(false))
          if (callback) {
            callback({ status: 'success', resp })
          }
          if (refresh) {
            dispatch(refreshWidgets(refresh))
          }
          if (redirectUrl) {
            window.Turbolinks.visit(redirectUrl)
          }
          if (refreshPage) {
            window.location.reload()
          }
        })
        .catch((error) => {
          dispatch(setLoadingOverlay(false))

          // Prevent default notification when unauthorized
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 401) {
            return;
          }

          const { data } = error.response
          const errorText = formatError(data);
          if (callback) {
            callback({ status: 'error', error: errorText })
          }
        })
    );
  }
}

function apiHandler(action, callback) {
  const { formData, apiPayloadFromWidget } = action.schema
  const { apiUrl, apiType } = action.data
  const {
    refresh,
    closeModal,
    closePopover,
    refreshPage,
    redirectUrl,
    markDirty,
    loadingOverlay = true,
    pageLeaveWarning = false
  } = action.schema

  const actionPayload = action.data.actionPayload || action.schema.actionPayload
  const apiPayload = action.data.apiPayload || action.schema.apiPayload
  return (dispatch, getState) => {
    let payloadData = { ...apiPayload }
    if (apiPayloadFromWidget) {
      let payload = {}
      const { widgetId, widgetGroupId, dataPath, onlyVisible } = apiPayloadFromWidget
      const appState = getState()

      const widget = getWidget({
        widgetGroupId,
        widgetId,
        appState
      });

      if (widget.data && widget.data.widgetData) {
        payload = jp.query(widget.data.widgetData, dataPath);

        if (onlyVisible && widget.schema && widget.schema.style && widget.schema.style.initialRowDisplay) {
          payload = widget.schema.style.initialRowDisplay && !widget.viewAll ? payload.slice(0, widget.schema.style.initialRowDisplay) : payload
        }
      }
      payloadData = { ...payloadData, data: payload }
    }
    dispatch(setLoadingOverlay(loadingOverlay))
    const requestsManagerMap = {
      'POST': requestsManager.submitEntity,
      'PUT': requestsManager.updateEntity,
      'DELETE': requestsManager.deleteEntity,
      'GET': requestsManager.fetchEntities
    }
    const requestsManagerFn = requestsManagerMap[apiType || action.schema.apiType]
    const requestData = formData ? objectToFormdata(apiPayload) : payloadData

    window.requestInProgress = pageLeaveWarning
    return (
      requestsManagerFn.call({}, apiUrl || action.schema.apiUrl, requestData, formData)
        .then((resp) => {
          window.requestInProgress = false
          dispatch(setLoadingOverlay(false))
          if (refresh) {
            dispatch(refreshWidgets(refresh, actionPayload))
          }
          if (redirectUrl) {
            window.Turbolinks.visit(redirectUrl)
          }
          if (refreshPage) {
            window.location.reload()
          }
          if (closeModal) {
            $('#modal').modal('hide')
          }
          if (closePopover) {
            hidePopoverHandler();
          }
          if (callback) {
            callback({ status: 'success', resp })
          }
          dispatch(markWidgetsDirty(markDirty))
        })
        .catch((error) => {
          window.requestInProgress = false
          dispatch(setLoadingOverlay(false))

          // Prevent default notification when unauthorized
          const statusCode = error.response ? error.response.status : null;
          if (statusCode === 401) {
            return;
          }
          const response = error && error.response ? error.response : {}
          const { data, status } = response
          const errorText = formatError(data)
          if (callback) {
            callback({ status: 'error', error: errorText })
          }
        })
    )
  }
}

function refreshWidgets(refresh, actionPayload = {}) {
  return (dispatch) => {
    refresh.widgets.forEach((widget) => {
      const widgetActionPayload = widget.actionPayload ? widget.actionPayload : {}
      const refreshPayload = { ...actionPayload, ...widgetActionPayload }

      if (widget.widgetId) {
        if (shouldRefreshSchema(refresh, widget)) {
          dispatch(fetchingWidgetSchema(widget.widgetGroupId, widget.widgetId, true, refreshPayload))
        }
        dispatch(fetchingWidgetData(widget.widgetGroupId, widget.widgetId, true, refreshPayload))
      } else {
        dispatch(reloadWidgets(widget.widgetGroupId))
      }
    })
  }
}

export function markWidgetsDirty(markDirty) {
  return (dispatch) => {
    if (markDirty) {
      markDirty.widgets.forEach((widget) => {
        if (widget.widgetId) {
          dispatch(markWidgetDirty(widget.widgetGroupId, widget.widgetId))
        } else {
          dispatch(markWidgetDirty(widget.widgetGroupId))
        }
      })
    }
  }
}