import React from 'react'
import PropTypes from 'prop-types'

const Circle = (props) => {
  const { x, y, dataKey, fill, offsetTop = 0 } = props
  const colors = {
    danger: '#ff6a39',
    warning: '#f6c423',
    disabled: '#cdd9e7',
    info: '#67dafb'
  }
  const lineTopPosition = {
    danger: 17,
    warning: 11,
    disabled: 24,
    info: 15,
  }
  // eslint-disable-next-line react/destructuring-assignment
  const shapeStyle = props[dataKey]
  return (
    <svg x={x} y={y + offsetTop} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <defs>
        <circle id="a" cx="11" cy="11" r="11" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill={colors[shapeStyle] || fill} xlinkHref="#a" />
        <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={`M.5 ${lineTopPosition[shapeStyle] || 0}h20`} />
      </g>
    </svg>
  )
}

Circle.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dataKey: PropTypes.string,
  fill: PropTypes.string,
  offsetTop: PropTypes.number
}

export default Circle
