import React from 'react'
import PropTypes from 'prop-types'

const Flag = (props) => {
  const { x, y, dataKey, fill, offsetTop = 0 } = props
  const colors = {
    danger: '#ff6a39',
    warning: '#f6c423',
    disabled: '#cdd9e7',
    info: '#67dafb'
  }
  // eslint-disable-next-line react/destructuring-assignment
  const shapeStyle = props[dataKey]
  return (
    <svg x={x} y={y + offsetTop} xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
      <path fill={colors[shapeStyle] || fill} d="M17 4.515a76.134 76.134 0 0 0-9.445-.903c-3.779 0-5.707-1.806-5.707-1.806V0L0 .003V20h1.848v-9.168s1.93 1.805 5.707 1.805c3.164.105 6.319.407 9.445.903l-2.833-4.514L17 4.515z" />
    </svg>
  )
}

Flag.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dataKey: PropTypes.string,
  fill: PropTypes.string,
  offsetTop: PropTypes.number
}

export default Flag
