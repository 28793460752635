import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import D3Funnel from 'd3-funnel'
import isEqual from 'lodash/isEqual'

import { renderData } from '../../helpers/utils'
import Legends from './helpers/Legends'
import withDataChecker from '../../helpers/withDataChecker'


class Funnel extends PureComponent {
  constructor() {
    super()
    this.state = {
      defaultOptions: {
        block: {
          dynamicHeight: true,
          minHeight: 25
        }
      }
    }
    this.funnel = React.createRef()
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate(prevProps) {
    const { filter, data } = this.props
    if (!isEqual(filter, prevProps.filter) || !isEqual(data, prevProps.data)) {
      this.drawChart()
    }
  }

  handleGraphClick = (clickData) => {
    const { schema, data, onClickHandler } = this.props
    const { graph: graphData } = data;

    const dataItem = graphData.find(d => d.label === clickData.label.raw)

    if (schema.events && schema.events.onClick) {
      const onClickSchema = schema.events.onClick;

      if (onClickHandler && onClickSchema && dataItem.events) {
        onClickHandler(onClickSchema, dataItem.events[onClickSchema.id])
      }
    }
  }

  drawChart = () => {
    const { schema, data, onClickHandler } = this.props
    const { graph: graphData } = data
    const { defaultOptions } = this.state
    const options = schema.options || defaultOptions
    const chart = new D3Funnel(this.funnel)

    chart.draw(
      graphData,
      {
        ...options,
        label: { format: this.formatLabels },
        events: {
          click: {
            block: (clickData) => this.handleGraphClick(clickData)
          }
        }
      }
    )
  }

  formatLabels = (label) => {
    const { data: { graph } } = this.props
    const dataItem = graph.find(d => d.label === label)
    const dataProperty = {
      dataType: dataItem.dataType
    }
    return renderData(dataProperty, dataItem.value)
  }

  generateLegendData = () => {
    const { data: { graph } } = this.props
    if (graph.length === 0) {
      return graph
    }
    return graph.map(dataItem => ({
      value: dataItem.label,
      color: dataItem.backgroundColor
    }))
  }

  render() {
    return (
      <React.Fragment>
        <div ref={(n) => { this.funnel = n }} />
        <Legends payload={this.generateLegendData()} />
      </React.Fragment>
    )
  }
}

Funnel.propTypes = {
  schema: PropTypes.shape({
    options: PropTypes.object
  }),
  data: PropTypes.object.isRequired,
  filter: PropTypes.any,
  onClickHandler: PropTypes.func
}

export default withDataChecker('polar', Funnel)
