import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Icon = (props) => {
  const { schema, onClickHandler, data = {}} = props
  // If classname is present in the data then club it with schema and data
  const iconClassName = data ? classNames(schema.className, data.className) : schema.className
  return (
    <span className={classNames('cvc-c-icon')}>
      <i className={iconClassName} style={data && data.styles} title={data.title || schema.title} role="presentation" onClick={onClickHandler} />
    </span>
  )
}

Icon.propTypes = {
  schema: PropTypes.shape({
    className: PropTypes.string.isRequired
  }).isRequired,
  onClickHandler: PropTypes.func,
  data: PropTypes.shape({
    className: PropTypes.string,
    styles: PropTypes.object
  })
}

export default Icon
