import React from 'react'
import PropTypes from 'prop-types'

import { colors } from 'core/helpers/utils'

const Label = (props) => {
  const { value, fill, textAnchor, fontSize, viewBox, dx, dy, offsetTop } = props
  const x = viewBox.width + viewBox.x
  const y = viewBox.y + offsetTop || 50
  return (
    <text
      x={x}
      y={y}
      dy={dy}
      dx={dx}
      fontSize={fontSize || 14}
      textAnchor={textAnchor || 'middle'}
    >
      <tspan x={x} fill={fill}>{value.primary}</tspan>
      {
        value && value.secondary && value.secondary.split(/\\r|\\n/).map((secondaryText, index) => {
          return (
            <tspan
              fill={colors.ligthGray}
              fontSize={12}
              x={x}
              dy={ index ? '1.3em' : '1.6em' }
            >
              {secondaryText}
            </tspan>
          )
        })
      }
    </text>
  )
}

Label.propTypes = {
  value: PropTypes.object,
  textAnchor: PropTypes.string,
  fontSize: PropTypes.number,
  viewBox: PropTypes.object,
  dx: PropTypes.number,
  dy: PropTypes.number,
  fill: PropTypes.string,
  offsetTop: PropTypes.number
}

export default Label
