import React from 'react'
import PropTypes from 'prop-types'

import { renderData } from '../../../helpers/utils'


const getSectorPercentage = (data, sectorValue) => {
  if (sectorValue <= 0) return 0
  let total = 0
  data.forEach((dataItem) => {
    total += dataItem.value > 0 ? dataItem.value : 0
  })
  const percentage = sectorValue / total * 100
  // Round off to one decimal digit. Note: Don't change this to Math.round as
  // it doesn't give accurate rounding off results
  // http://www.jacklmoore.com/notes/rounding-in-javascript/
  return +(`${Math.round(`${percentage}e+1`)}e-1`)
}

const PolarTooltip = (props) => {
  const {
    active,
    payload,
    chartData = [],
    dataType,
    tooltipProps = {},
    hidePercentage
  } = props
  const payloadProperty = { ...tooltipProps, dataType }
  if (!active) return null
  return (
    <div className="cvc-c-polar-tooltip shadow-sm bg-white">
      <span className="label-container">
        <span className="label">{`${payload[0].name}`}</span>
        :
        <span className="payload">
          {renderData(payloadProperty, payload[0].value)}
        </span>
      </span>
      {!hidePercentage && <span className="sector-percentage">
        {`${getSectorPercentage(chartData, payload[0].value)} %`}
      </span>}
    </div>
  )
}

PolarTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  chartData: PropTypes.array,
  dataType: PropTypes.string
}

export default PolarTooltip
