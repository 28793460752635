/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import { pick, isEmpty } from 'lodash'
import Form from 'react-jsonschema-form'
import classNames from 'classnames'
import { debounce, omit, omitBy, map, some, isEqual } from 'lodash'
import { cloneDeepWith, get } from 'lodash/fp'
import { markWidgetsDirty } from 'core/redux/actions/appActionCreators'

/* eslint-disable import/no-unresolved */
import * as formWidgets from '../components/form/widgets'
import formFields from '../components/form/fields'
import {
  getWidget,
  getWidgetPageIdKey
} from "core/helpers/utils"

const defaultCacheDataKeys = ['formData']
const defaultCacheSchemaKeys = ['visibleFilters']

class InlineFilter extends React.Component {
  static inflateSchema({ schema = {}, widgetGroupId, widgetId, appState }) {
    const storedWidget = getWidget({ widgetGroupId, widgetId, appState })
    const cachedWidgetId = getWidgetPageIdKey(widgetGroupId, widgetId)
    let cachedProperties = {}
    // retrieve cached properties from the store and inject it at run time.
    if (
      appState.widgetCache &&
      appState.widgetCache[cachedWidgetId]
    ) {
      const widgetCache = appState.widgetCache[cachedWidgetId]
      const cacheKeys =
        schema && schema.browserCache
          ? schema.browserCache
          : defaultCacheSchemaKeys
      if (widgetCache && widgetCache.schema) {
        cachedProperties = pick(widgetCache.schema, cacheKeys)
      }

      // override if local store is available
      if (storedWidget && storedWidget.schema) {
        cachedProperties = pick(storedWidget.schema, cacheKeys)
      }
    }

    if (!isEmpty(cachedProperties)) {
      return {
        ...schema,
        ...cachedProperties
      }
    }

    return schema
  }

  static inflateData({ data = {}, widgetGroupId, widgetId, appState }) {
    const storedWidget = getWidget({ widgetGroupId, widgetId, appState })
    const cachedWidgetId = getWidgetPageIdKey(widgetGroupId, widgetId)

    let cachedProperties = {}
    // retrieve cached properties from the store and inject it at run time.
    if (
      appState.widgetCache &&
      appState.widgetCache[cachedWidgetId]
    ) {
      const widgetCache = appState.widgetCache[cachedWidgetId]
      const cacheKeys =
        data.widgetData && data.widgetData.browserCache
          ? data.widgetData.browserCache
          : defaultCacheDataKeys
      // check in the main cache
      if (widgetCache && widgetCache.data) {
        cachedProperties = pick(widgetCache.data, cacheKeys)
      }

      // override if local store is available
      if (storedWidget && storedWidget.data) {
        cachedProperties = pick(storedWidget.data.widgetData, cacheKeys)
      }
    }

    // cachedProperties = (!storedWidget || !storedWidget.data) ? cachedProperties : {}

    if (!isEmpty(cachedProperties)) {
      return {
        ...data,
        widgetData: {
          ...data.widgetData,
          ...cachedProperties
        }
      }
    }

    return data
  }

  constructor(props) {
    super(props)

    const { data = {}, schema } = props

    const visibleFilters = []
    if (!schema.jsonSchema.properties.filters.properties) {
      throw 'filters properties not found in jsonSchema'
    }

    map(schema.jsonSchema.properties.filters.properties, (value, key) => {
      if (!value.canHide) {
        visibleFilters.push(key)
      }
    })

    this.state = {
      formData: data.formData,
      visibleFilters: schema && schema.visibleFilters ? schema.visibleFilters : visibleFilters
    }

    this.debouncedApplyFilter = debounce(this.applyFilter, 400)
  }

  get isMoreOptionsVisible() {
    const { jsonSchema, visibleFilters } = this.props.schema
    return some(jsonSchema.properties.filters.properties, (value, key) => {
      return value.canHide
    }) || visibleFilters
  }

  componentDidMount() {
    const { data = {} } = this.props
    this.applyFilter({ formData: data.formData, silent: true })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.schema.visibleFilters, this.props.schema.visibleFilters)) {
      this.applyFilter({ formData: this.state.formData })
    }
  }

  getFilters = () => {
    const { uiSchema, visibleFilters = []} = this.props.schema
    const { formData = {} } = this.state
    const { filters = {} } = formData

    if (this.isMoreOptionsVisible) {
      let updatedFilters = omitBy(filters, (value, key) => {
        return this.isKeyHidden(key)
      })

      // #TODO: remove this code to send individual keys once
      // active record query is fixed
      let nestedKeys = {}
      Object.keys(updatedFilters).map((key) => {
        if (typeof updatedFilters[key] == 'object' && !Array.isArray(updatedFilters[key])) {
          nestedKeys = {...nestedKeys, ...updatedFilters[key]}
        }
      })

      return { ...updatedFilters, ...nestedKeys }
    }

    return filters
  }

  applyFilter = ({ formData, silent }) => {
    const { schema, data, widgetId, widgetGroupId, fetchWidgetDataSuccess, pageId } = this.props
    this.setState({ formData })

    const filters = this.getFilters()

    this.setWidgetCacheData({ formData })
    const payload = {
      filter: {
        id: widgetId,
        type: schema.filterType,
        data: filters,
        refresh: schema.filter ? schema.filter.refresh : null,
        cache: schema.cache === false ? false : true
      },
      silent,
    }
    this.props.setFilter(payload, schema.preventUrlUpdates)

    const updatedWidgetData = {
      widgetData: {
        ...data,
        formData: {
          ...formData
        },
      }
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, updatedWidgetData)
    this.props.dispatch(markWidgetsDirty(schema.markDirty))
  }

  canHideProperty = (name) => {
    const property = this.props.schema.jsonSchema.properties.filters.properties[name]
    return property && property.canHide
  }

  setWidgetCacheSchema = (cacheData) => {
    const { schema, setWidgetCache, widgetGroupId, widgetId } = this.props
    const cacheKeys =
      schema && schema.browserCache
        ? schema.browserCache
        : defaultCacheSchemaKeys
    const dataToCache = pick(cacheData, cacheKeys)
    if (!isEmpty(dataToCache)) {
      setWidgetCache({
        widgetGroupId,
        widgetId,
        data: { schema: dataToCache }
      })
    }
  }

  setWidgetCacheData(cacheData) {
    const { data, setWidgetCache, widgetGroupId, widgetId } = this.props
    const cacheKeys =
      data && data.browserCache
        ? data.browserCache
        : defaultCacheDataKeys
    const dataToCache = pick(cacheData, cacheKeys)
    if (!isEmpty(dataToCache)) {
      setWidgetCache({
        widgetGroupId,
        widgetId,
        data: { data: dataToCache }
      })
    }
  }

  isKeyHidden = (key) => {
    const { visibleFilters = []} = this.props.schema
    return this.canHideProperty(key) && !visibleFilters.includes(key)
  }

  markLabelAsFalse = (uiSchema) => {
    return cloneDeepWith((value, key) => {
      if (get('ui:widget', value) || get('ui:field', value)) {
        if (!get('ui:options', value)) {
          return {
            ...value,
            'ui:options': {
              label: false,
            },
            hide: this.isKeyHidden(key)
          }
        }
        return {
          ...value,
          hide: this.isKeyHidden(key)
        }
      }
      // mark default labels from uiSchema as false
      if (key === 'ui:options' && value.label !== true) {
        return { ...value, label: false }
      }
    }, uiSchema);
  }

  renderSelectionDropdown = () => {
    const { jsonSchema, style, moreOptions, visibleFilters = [] } = this.props.schema;

    if (!jsonSchema.properties.filters.properties) {
      throw `No filter options found in schema`
    }

    const options = map(jsonSchema.properties.filters.properties, (value, key) => {
      return {
        canHide: value.canHide,
        key: key,
        label: value.title,
        type: value.type
      }
    })
    .filter((option) => {
      return option.canHide && !visibleFilters.includes(option.key)
    })

    if (!options || options.length == 0) return null

    const moreOptionsStyle = style.moreOptions ? style.moreOptions.styles : {}

    return (
      <div
        style={{...moreOptionsStyle}}
        className={classNames('small-dark text-right', style.moreOptions && style.moreOptions.className)}
      >
        <div className="dropdown py-2">
          <span
            className="dropdown-toggle cursor-pointer py-1"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            { moreOptions && moreOptions.label && visibleFilters.length == 0 ? moreOptions.label : '+ More' }
          </span>
          <div className="dropdown-menu dropdown-menu-right p-0" aria-labelledby="dropdownMenuButton">
            {options.map((option) => {
              return (
                <div
                  className="dropdown-item"
                  onClick={() => {
                    const { schema, widgetId, widgetGroupId, updateWidget } = this.props
                    const updatedVisibleFilters = visibleFilters.concat(option.key)
                    this.setWidgetCacheSchema({ visibleFilters: updatedVisibleFilters })
                    const updatedSchema = {
                      ...schema,
                      visibleFilters: updatedVisibleFilters
                    }

                    updateWidget(widgetGroupId, widgetId, {
                      schema: updatedSchema,
                      stamp: Math.random()
                    })
                  }}
                >{option.label}</div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  onFieldRemove = (name) => {
    const { schema, widgetId, widgetGroupId, updateWidget } = this.props
    const { visibleFilters = [] } = schema

     const updatedFilters = visibleFilters.filter((option) => {
       return !(option === name)
     })
     this.setWidgetCacheSchema({ visibleFilters: updatedFilters })
     const updatedSchema = {
       ...schema,
       visibleFilters: updatedFilters
     }
     updateWidget(widgetGroupId, widgetId, {
       schema: updatedSchema,
       stamp: Math.random()
     })

     const formData = {
       ...this.state.formData,
       filters: {
         ...omit(this.state.formData.filters, name)
       }
     }
     this.setState({ formData })
  }

  render() {
    const { uiSchema, jsonSchema, style = {} } = this.props.schema
    const { formData } = this.state

    const formContext = {
      onFieldRemove: this.onFieldRemove,
    };

    return (
      <div className={classNames(
        'cvc-w', 'cvc-w-inline-filter', style.containerClassName, {
          'more-options-form': this.isMoreOptionsVisible
        }
      )}>
        <div className={classNames(style.className)}>
          <Form
            className="form w-100"
            uiSchema={this.markLabelAsFalse(uiSchema)}
            schema={jsonSchema}
            formData={formData}
            formContext={formContext}
            widgets={formWidgets}
            fields={formFields}
            onChange={this.debouncedApplyFilter}
            showErrorList={false}
          >
            <div />
          </Form>
          {this.isMoreOptionsVisible && this.renderSelectionDropdown()}
        </div>
      </div>
    )
  }
}

InlineFilter.propTypes = {
  schema: PropTypes.shape({
    uiSchema: PropTypes.object,
    jsonSchema: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired,
  widgetId: PropTypes.string,
  widgetGroupId: PropTypes.string.isRequired,
  fetchWidgetDataSuccess: PropTypes.func,
  updateWidget: PropTypes.func,
}

InlineFilter.contextTypes = {
  store: PropTypes.object
}

export default InlineFilter
