import { connect } from 'react-redux'
import { find, pickBy } from 'lodash/fp'
import ModalRenderer from '../../renderers/ModalRenderer'
import * as modalActions from '../actions/modalActionCreators'
import * as filtersActions from '../actions/filtersActionCreators'
import { FILTER_TYPE_PAGE } from '../../helpers/constants';
import { getFilterPageIdKey, getPageIdKey } from 'core/helpers/utils'

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state, ownProps) => {
  const { modal, widgetGroup, page } = state
  const widgetGroups = modal.schema ? widgetGroup[modal.schema.id] || {} : {}
  const rightPanelWidgetGroups = modal.schema && modal.schema.rightPanel ? widgetGroup[modal.schema.rightPanel.id] || {} : {}


  const pageFilters = pickBy((filter, key) => {
    return key === `${getPageIdKey()}-${filter.id}`
  }, state.filters)

  const foundWidgetFilters = find((filter) => {
    const filterId = getFilterPageIdKey(filter.id)
    return (
      filter.type !== FILTER_TYPE_PAGE &&
      pageFilters[filterId]
    );
  }, Object.values(pageFilters))

  return {
    modal,
    widgetFiltersApplied: foundWidgetFilters,
    widgetGroupIds: Object.keys(widgetGroups),
    rightPanelWidgetGroupIds: Object.keys(rightPanelWidgetGroups)
  }
}

export default connect(mapStateToProps, {...modalActions, ...filtersActions})(ModalRenderer)
