// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "channels"
import "bootstrap"
import jQuerty from 'jquery';

import ReactOnRails from 'react-on-rails';
import tinymce from './tinymce';

import TasksIndex from '../bundles/Engagement/components/tasks/Index';
import NotesIndex from '../bundles/Engagement/components/notes/Index';
import App from '../core/App';

// Referring: https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
// react_on_rails will see these components in the browser.
ReactOnRails.register({
  NotesIndex,
  TasksIndex,
  App
});
