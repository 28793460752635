import React from 'react'
import PropTypes from 'prop-types'


const Header = ({ title, backBtnHandler, clientSwitcher }) => {
  const tooltip = {
    'data-tip': 'You have no unread notifications'
  }

  return (
    <div className="col-lg-12 content-main-header pl-0">
      {backBtnHandler &&
        <a className="text-btn" role="presentation" onClick={backBtnHandler}> <i className="back-arrow" /> </a>
      }
      <div className="content-panel-title">{title}</div>
      <div className="float-right">
        {clientSwitcher}
        <div className="notification mt-2" {...tooltip} />
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  backBtnHandler: PropTypes.func,
  clientSwitcher: PropTypes.node
}

export default Header
