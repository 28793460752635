/* eslint-disable react/button-has-type */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import PopoverContainer from '../redux/containers/PopoverContainer'

class InlineEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
    this.popoverRect = {};
  }

  handleOnClick = (e) => {
    const show = !this.state.show;
    this.setState({ show: show && !window.isPopoverVisible })
    this.props.onClick(show);
  }

  handleClickOutside = (e) => {
    if (this.popoverRef && this.popoverRef.popoverDiv) {
      const el = this.popoverRef.popoverDiv;
      const elViewPort = el.getBoundingClientRect();
      const left = elViewPort.left;
      const top = elViewPort.top;
      const right = left + elViewPort.width;
      const bottom = left + elViewPort.height;

      if (
        (e.clientX >= left && e.clientX <= right) &&
        (e.clientY >= top)
      ) {
        return;
      }
    }

    this.handleOnClick(e);
  }

  renderPopover = () => {
    const style = this.props.style || {};
    const bgColor = style.backgroundColor || '#FFFFFF';

    return (
      <div className="d-inline-block">
        <Popover
          isOpen={this.state.show}
          ref={(popoverRef) => this.popoverRef = popoverRef}
          position={style.position || 'bottom'}
          onClickOutside={this.handleClickOutside}
          containerClassName={classNames('cvc-popover react-tiny-popover-container', style.containerClassName)}
          content={({ position, targetRect, popoverRect }) => {
            this.popoverRect = popoverRect;
            return <ArrowContainer
              position={position}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={bgColor}
              arrowSize={10}
            >
              <div
                className={classNames('cvc-w', 'cvc-w-inline-edit p-1', style.className)}
                style={{ backgroundColor: bgColor }}
              >
                <PopoverContainer popover={{...this.props}} />
              </div>
            </ArrowContainer>
          }
        }
        >
          <div className="d-inline-block" onClick={this.handleOnClick}>
            <div>{this.props.children}</div>
          </div>
        </Popover>
      </div>
    )
  }

  render() {
    if (this.state.show) {
      return this.renderPopover();
    }

    return (
      <div className="d-inline-block popover-block" onClick={this.handleOnClick}>
        {this.props.children}
      </div>
    )
  }
}

InlineEdit.propTypes = {
  schema: PropTypes.object,
  popoverSchemaUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default InlineEdit
