import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'

import WidgetContainer from 'core/redux/containers/WidgetContainer'
import WidgetGroupContainer from 'core/redux/containers/WidgetGroupContainer'

const Layout = (props) => {
  const { widgetGroup, parentId } = props
  const { widgets } = widgetGroup
  return (
    <WidgetGroupContainer
      widgetGroup={widgetGroup}
      parentId={parentId}
      widgetGroupId={widgetGroup.id}
    >
      {widgets.map((widget, index) => {
        const { hide, grids, style = {}, id, subscribedFilterId, spreadId } = widget
        const { xs, sm, md, lg, xl, auto, addDivider } = grids

        return (
          <React.Fragment key={id}>
            {!hide && <div
              className={classNames(
                'cvc-widget-container',
                `${auto ? 'col' : ''}`,
                `${xs ? 'col-'+xs : ''}`,
                `${sm ? ' col-sm-'+sm : ''}`,
                `${md ? ' col-md-'+md : ''}`,
                `${lg ? ' col-lg-'+lg : ''}`,
                `${xl ? ' col-xl-'+xl : ''}`,
                style.className
              )}
              style={style.styles}
            >
              <WidgetContainer
                widgetId={widget.id}
                widgetGroupId={widgetGroup.id}
                subscribedFilterId={subscribedFilterId}
                spreadId={spreadId}
              />
            </div>}
            {addDivider && <div className="w-100"></div>}
          </React.Fragment>
        )
      })}
    </WidgetGroupContainer>
  )
}

Layout.propTypes = {
  widgetGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    widgets: PropTypes.array,
    type: PropTypes.string,
    style: PropTypes.object
  }),
  parentId: PropTypes.string.isRequired
}

export default Layout
