import request from 'axios'
import ReactOnRails from 'react-on-rails'

import { showLoginDialog } from 'core/components/LoginDialog'

export const addAuthInterceptor = () => {
  request.interceptors.response.use(undefined, function (error) {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
      showLoginDialog()
    }

    return Promise.reject(error);
  })
}

export default {

  /**
   * Retrieve list of entities from server using AJAX call.
   *
   * @returns {Promise} - Result of ajax call.
   */
  fetchEntities(url) {
    return request({
      method: 'GET',
      url,
      responseType: 'json'
    })
  },

  /**
   * Submit new entity to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  submitEntity(url, entity, multipart = false) {
    const headers = ReactOnRails.authenticityHeaders()
    if (multipart) {
      headers['content-type'] = 'multipart/form-data'
    }
    return request({
      method: 'POST',
      url,
      responseType: 'json',
      headers,
      data: entity
    })
  },

  updateEntity(url, entity) {
    return request({
      method: 'PUT',
      url,
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders(),
      data: entity
    })
  },

  deleteEntity(url, entity) {
    return request({
      method: 'DELETE',
      url,
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders(),
      data: entity
    })
  }
}
