import React from 'react'
import PropTypes from 'prop-types'
import { each, isEqual } from 'lodash'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip
} from 'recharts'

import { combineProps, colors } from 'core/helpers/utils'
import withDataChecker from '../../helpers/withDataChecker'
import customLabelMap from './helpers/pie/customLabelMap'
import PolarTooltip from './helpers/PolarTooltip'


const PieGraph = (props) => {
  const { schema, data } = props
  const { graph: graphData } = data

  const defaultSchema = {
    containerStyle: {
      width: '100%',
      height: 140
    },
    pie: {
      labelLine: false,
      outerRadius: '100%'
    },
    legend: {
      show: true,
      iconType: 'circle',
      iconSize: 8,
      align: 'left',
      verticalAlign: 'bottom',
      wrapperStyle: {
        color: colors.gray,
        fontSize: '12px'
      }
    }
  }
  const {
    style,
    pieChart,
    legend,
    childProps,
    labelKey,
    label = {},
    tooltip,
    events,
    pie,
    dataKey,
    selection,
    cursor
  } = schema
  const { containerStyle } = style || {}
  const chartContainerProps = combineProps(defaultSchema.containerStyle, containerStyle)
  const legendProps = combineProps(defaultSchema.legend, legend)
  const pieChartProps = combineProps(defaultSchema.pieChart, pieChart)
  const pieProps = combineProps(defaultSchema.pie, pie)
  const CustomLabel = label.name ? customLabelMap[label.name] : null
  const { show: showLegend, wrapperStyle, ...otherLegendProps } = legendProps
  const eventSchema = events ? events.onClick : null

  return (
    <ResponsiveContainer {...chartContainerProps}>
      <PieChart {...pieChartProps}>
        {showLegend
          && (
          <Legend
            wrapperStyle={combineProps(defaultSchema.legend.wrapperStyle, wrapperStyle)}
            {...otherLegendProps}
          />
          )
        }
        {tooltip
          && (
          <Tooltip
            isAnimationActive={false}
            {...tooltip}
            content={(
              <PolarTooltip
                chartData={graphData}
                dataType={label.dataType}
                hidePercentage={tooltip.hidePercentage}
              />
            )}
          />
          )
        }
        <Pie
          isAnimationActive={false}
          data={graphData}
          label={label.name ? <CustomLabel dataType={label.dataType} /> : label.show}
          {...pieProps}
        >
          {
            graphData.map((entry) => {
              const color = childProps.find(p => p.dataKey === entry[labelKey]).color || colors.green
              let selected = false;

              if (entry.filterData) {
                each(entry.filterData, (value, key) => {
                  selected = selected || (entry.filterKey === key && isEqual(entry.filterValue, value))
                })
              }

              const selectionStyle = selected ? selection : {}

              return <Cell
                fill={color}
                key={entry[labelKey]}
                cursor={cursor ? cursor : 'pointer'}
                onClick={() => {
                  const onClickId = eventSchema ? eventSchema.id : null
                  const eventData = entry.events && onClickId ? entry.events[onClickId] || {} : {};
                  props.onClickHandler(eventSchema, eventData[dataKey])
                }}
                {...selectionStyle}
              />
            })
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

PieGraph.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  fill: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired
}

PieGraph.defaultProps = {
  data: {
    eventsData: {}
  }
}

export default withDataChecker('polar', PieGraph)
