import React, { Component, useEffect, useState, useRef, useLayoutEffect, useMemo } from "react"
import { useDispatch } from 'react-redux'
import {
  useTable,
  useColumnOrder,
  useExpanded,
  usePagination,
  useRowState
} from "react-table"
import classNames from 'classnames'
import { populateJSON } from 'libs/utils'
import { success, error } from "react-notification-system-redux"
import { pick, isEmpty, isEqual } from "lodash"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable"
import { handleAction } from 'core/redux/actions/appActionCreators'
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { showConfirmModal } from "core/components/ConfirmDialog";
import { DraggableTableRow } from "core/components/table/DraggableTableRow";
import { StaticTableRow } from "core/components/table/StaticTableRow";
import Icon from "core/components/Icon"

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}

export const ReactTable = ({
  columns,
  data,
  expandedRows,
  columnOrder,
  hiddenColumns,
  updateRowOrder,
  enableDragDrop,
  handleRowClick,
  currentPage,
  pageCount: controlledPageCount,
  initialState,
  schema,
  renderRowSubComponent,
  isPaginationEnabled,
  fetchPaginatedData,
}) => {
  const [activeId, setActiveId] = useState()
  const dispatch = useDispatch()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { expanded, pageIndex, pageSize },
    toggleAllRowsExpanded
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState,
      pageCount: controlledPageCount,
      initialCellStateAccessor: cell => {
        let data = cell.row.original[cell.column.id]
        if (cell.row.original.editable && cell.row.original.editable[cell.column.id]) {
          data = cell.row.original.editable[cell.column.id]
        }

        return {
          original: data,
          updated: data
        }
      }
    },
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowState
  )
  const prevIsPaginationEnabled = usePrevious(isPaginationEnabled)
  const prevCurrentPage = usePrevious(prevCurrentPage)
  const modifiedItem = useState('')

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const notificationOptions = {
    title: "",
    message: "",
    position: "tc",
    autoDismiss: 3,
  }

  function isDragDropDisabled() {
    return (
      schema.dragdrop &&
      schema.dragdrop.confirmDrop &&
      schema.dragdrop.confirmDrop.sortBy &&
      (
        schema.dragdrop.confirmDrop.sortBy !== initialState.sortBy ||
        schema.dragdrop.confirmDrop.sortOrder !== initialState.sortOrder
      )
    )

  }
  function handleDragStart(event) {
    if (isDragDropDisabled()) {
      showConfirmModal(schema.dragdrop).then((confirm) => {
        const columnToSort = columns.find((column) => column.accessor === schema.dragdrop.confirmDrop.sortBy)
        if (confirm && columnToSort) {
          return columnToSort.sort(schema.dragdrop.confirmDrop.sortOrder)
        }
        return null;
      });
      return;
    }

    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    if (isDragDropDisabled()) {
      return;
    }
    // add check here to see if the data.sortOrder === stack rank else show alert
    const { active, over } = event
    if (active.id !== over.id) {
      updateRowOrder(+active.id, +over.id)
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  useEffect(() => {
    setColumnOrder(columnOrder)
    setHiddenColumns(hiddenColumns)
    if (schema.expand) {
      toggleAllRowsExpanded(true)
    }
  }, [columnOrder, hiddenColumns, columns])

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ id }) => id === activeId)

    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  React.useEffect(() => {
    if (!isEqual(prevIsPaginationEnabled, isPaginationEnabled)) {
      gotoPage(currentPage)
    }


    if (isPaginationEnabled && prevIsPaginationEnabled) {
      // to overcome the cases like "page 3 of 2"
      if (pageIndex + 1 > pageOptions.length) {
        gotoPage(0)
      } else {
        fetchPaginatedData({ pageIndex, pageSize })
      }
    }
  }, [isPaginationEnabled, pageIndex, pageSize, currentPage])

  const preparePayload = (editedRow) => {
    let payload = {}

    editedRow.cells.forEach((cell) => {
      if (cell.state.original !== cell.state.updated) {
        payload = {
          ...payload,
          [cell.column.id]: cell.state.updated
        }
      }
    })

    return payload
  }

  const handleRowUpdate = (eventSchema, editedRowData, payload = {}, callback) => {
    const eventsData = editedRowData.events
    const apiPayload = populateJSON(schema.editablePayloadTemplate, payload)

    const action = { schema: eventSchema, data: {
      ...eventsData[eventSchema.id],
      apiPayload: apiPayload
    }}
    dispatch(handleAction(null, action, (response) => {
      const { notifications } = eventSchema

      // if no notification key passed from schema
      if (Object.keys(notifications).length === 0) {
        return false
      }

      if (response.status === 'success') {
        dispatch(success({
          ...notificationOptions,
          title: notifications.success.title
        }))
      } else {
        dispatch(error({
          ...notificationOptions,
          title: notifications.error.title,
          message: response.error
        }))
      }
      if (callback) {
        callback()
      }
    }))
  }


  const expandRowSchema = schema.events && schema.events.onExpand
  const onClickSchema = schema.events && schema.events.onClick
  const updateRowSchema = schema.events && schema.events.onRowUpdate

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table className="table transparent-background">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      className={classNames('th', {
                        'pt-3': !schema.heading
                      })}
                      style={{ width: column.columnWidth + "%" }}
                    >
                      {
                        // Render the header
                        column.render("Header")
                      }
                      {column.sortable && (
                        <span
                          className="sort"
                          role="presentation"
                          onClick={() => {
                            column.sort()
                          }}
                        >
                          <Icon
                            schema={{
                              className: classNames("fa fa-sort-up", {
                                active:
                                  column.isSorted && column.sortOrder === "asc",
                              }),
                            }}
                          />
                          <Icon
                            schema={{
                              className: classNames("fa fa-sort-down", {
                                active:
                                  column.isSorted &&
                                  column.sortOrder === "desc",
                              }),
                            }}
                          />
                        </span>
                      )}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            {rows.map((row, i) => {
              prepareRow(row)
              const rowProps = row.getRowProps()
              const isRowEditing = row.state.editing
              const isRowHovering = row.state.hovering

              return (
                <React.Fragment key={rowProps.key}>
                  <DraggableTableRow
                    key={row.id}
                    row={row}
                    handleRowClick={() => {
                      onClickSchema &&
                        handleRowClick(onClickSchema, row.original)
                    }}
                    enableDragDrop={enableDragDrop}
                    enableRowClick={onClickSchema}
                    showActions={updateRowSchema}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      {!isRowEditing && <span className="fa fa-edit px-1 font-16" onClick={() => row.setState((r) => {
                        return {
                          ...r,
                          editing: true
                        }
                      })}></span>}
                      {isRowEditing && <div className="d-flex align-items-end justify-content-end">
                        <span className="fa fa-times px-1 font-16" onClick={() => row.setState((r) => {
                          return {
                            ...r,
                            editing: false
                          }
                        })}></span>
                        <span className="fa fa-check px-1 font-16" onClick={() => {
                          const payload = preparePayload(row)
                          handleRowUpdate(updateRowSchema, { ...row.original }, payload, () => {
                            row.setState((r) => {
                              return {
                                ...r,
                                editing: false
                              }
                            })
                          })
                        }}></span>
                      </div>}
                    </div>
                  </DraggableTableRow>
                  {row.isExpanded &&
                    expandRowSchema &&
                    renderRowSubComponent({
                      data: row,
                      schema: expandRowSchema,
                    })}
                </React.Fragment>
              )
            })}
          </SortableContext>
        </tbody>
      </table>
      {enableDragDrop && (
        <DragOverlay dropAnimation={null}>
          {activeId && (
            <table style={{ width: "100%" }} className="table">
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            className="th"
                            style={{ width: column.columnWidth + "%" }}
                          />
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody>
                <StaticTableRow row={selectedRow} />
              </tbody>
            </table>
          )}
        </DragOverlay>
      )}
      {isPaginationEnabled && (
        <div className="m-2 d-flex align-items-center justify-content-end float-right">
          <div className="mx-2 small-grey d-inline-block">
            Page{' '}<strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </div>
          <ul class="pagination justify-content-end">
            <li class={classNames("page-item", { disabled: !canPreviousPage})} onClick={() => gotoPage(0)}>
              <span class="page-link">First</span>
            </li>
            <li class={classNames("page-item", { disabled: !canPreviousPage})} onClick={() => previousPage()}>
              <span class="page-link">Previous</span>
            </li>
            <li class={classNames("page-item", { disabled: !canNextPage})} onClick={() => nextPage()}>
              <span class="page-link">Next</span>
            </li>
            <li class={classNames("page-item", { disabled: !canNextPage})} onClick={() => gotoPage(pageCount - 1)}>
              <span class="page-link">Last</span>
            </li>
          </ul>
          <span className="small-grey pl-2">
            Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              className="pagination-input"
              onKeyUp={e => {
                const code = e.keyCode || e.which
                // Enter
                if (code === 13) {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }
              }}
              style={{ width: '100px' }}
            />
          </span>
        </div>
      )}
    </DndContext>
  )
}