import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import { each, isEqual } from 'lodash'
import { deepEquals } from 'libs/utils'
import {
  ResponsiveContainer,
  BarChart,
  Bar as ReChartBar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Legend,
  Label,
  LabelList,
  CartesianGrid,
  Brush
} from "recharts";

import { combineProps, colors, renderFormattedData } from 'core/helpers/utils'
import { convertStringToFn } from 'libs/utils'
import CustomLabel from './helpers/CustomLabel'
import HeaderLegend from './helpers/HeaderLegend'
import LinearGradientBar from './helpers/shapes/LinearGradientBar'
import withDataChecker from '../../helpers/withDataChecker'
import CustomTooltip from "./helpers/CustomTooltip";

class Bar extends Component {
  render() {
    const { schema, data } = this.props
    const {
      graph: graphData,
      events: eventsData = {},
      referenceLines: referenceLinesData = {},
      xAxis: xAxisData = {},
      yAxis: yAxisData = {}
    } = data

    const defaultSchema = {
      containerStyle: {
        width: "100%",
        height: 300,
      },
      xAxis: {
        tickLine: false,
        axisLine: false,
        tick: {
          fill: colors.gray,
          fontSize: 14,
        },
        hide: schema.style.layout === "vertical",
      },
      yAxis: {
        tickLine: false,
        axisLine: false,
        tick: {
          fill: colors.gray,
          fontSize: 14,
          textAnchor: "start",
        },
        hide: schema.style.layout === "horizontal",
      },
      legend: {
        show: true,
        iconType: "circle",
        iconSize: 8,
        align: "left",
        verticalAlign: "bottom",
        wrapperStyle: {
          color: colors.gray,
          fontSize: "12px",
        },
      },
      bar: {
        fill: colors.green,
        minPointSize: 0,
        radius: [30, 30, 30, 30],
        barSize: 12,
        isAnimationActive: false,
      },
      cartesian: {
        vertical: false, // To hide/show vertical cartesian lines
        horizontal: false, // To hide/show horizontal cartesian lines
        strokeDasharray: "0 0",
      },
    };
    const {
      style,
      xAxis = {},
      yAxis = {},
      tooltip,
      legend,
      events,
      cartesian,
      childProps,
      selection,
      referenceLines,
      cursor,
      scrollable
    } = schema
    const { containerStyle, ...otherStyle } = style || {}

    const chartContainerProps = combineProps(
      defaultSchema.containerStyle,
      containerStyle
    );

    const xAxisProps = {...combineProps(defaultSchema.xAxis, xAxis), ...xAxisData};
    if (xAxisProps.domain) {
      xAxisProps.domain = xAxisProps.domain.map((domain) => {
        return convertStringToFn(domain)
      })
    }
    const yAxisProps = {...combineProps(defaultSchema.yAxis, yAxis), ...yAxisData};
    if (yAxisProps.domain) {
      yAxisProps.domain = yAxisProps.domain.map((domain) => {
        return convertStringToFn(domain)
      })
    }
    const legendProps = combineProps(defaultSchema.legend, legend);
    const cartesianProps = combineProps(defaultSchema.cartesian, cartesian);

    const { show: showLegend, wrapperStyle, ...otherLegendProps } = legendProps;

    const eventSchema = events ? events.onClick : null;
    const isScrollable = (data.scrollable || scrollable)

    return (
      <ResponsiveContainer {...chartContainerProps}>
        <BarChart {...otherStyle} data={graphData}>
          <CartesianGrid {...cartesianProps} />
          <XAxis tickFormatter={(value) => renderFormattedData(xAxis, value)} {...xAxisProps} />
          <YAxis tickFormatter={(value) => renderFormattedData(yAxis, value)} {...yAxisProps} />
          {showLegend && (
            <Legend
              wrapperStyle={combineProps(
                defaultSchema.legend.wrapperStyle,
                { ...wrapperStyle, paddingTop: isScrollable ? 10 : 0 }
              )}
              content={
                legendProps.customLegends ? (
                  <HeaderLegend
                    title={legendProps.legendTitle}
                    fill={legendProps.customLegends.fill}
                  />
                ) : null
              }
              {...otherLegendProps}
            />
          )}
          {tooltip && (
              <Tooltip
                formatter={(value) => renderFormattedData(tooltip, value)}
                content={tooltip.custom ? <CustomTooltip /> : null}
                isAnimationActive={false}
                {...tooltip}
            />
          )}
          {
            referenceLines && referenceLines.map((referenceLine) => {
                const { label, ...otherProps } = referenceLine
                const referenceData = referenceLinesData[referenceLine.dataKey] || {}
                const { label: labelDataProps, ...otherDataProps } = referenceData

                const referenceLineProps = {
                  ...otherProps,
                  ...otherDataProps,
                };

                const labelProps = {
                  ...label,
                  ...labelDataProps,
                };

                return (
                  <ReferenceLine {...referenceLineProps}>
                  <Label formatter={(value) => renderFormattedData(labelProps, value)} {...labelProps} /> </ReferenceLine>
                )
              }
            )
          }
          {childProps.map((bar) => {
            const {
              label,
              labels,
              customShape,
              fillStart,
              fillEnd,
              rx,
              ry,
              ...otherBarProps
            } = bar;
            const barProps = combineProps(defaultSchema.bar, otherBarProps);
            return (
              <ReChartBar
                key={shortid.generate()}
                shape={
                  customShape ? (
                    <LinearGradientBar
                      fillStart={fillStart}
                      fillEnd={fillEnd}
                      rx={rx}
                      ry={ry}
                    />
                  ) : null
                }
                {...barProps}
              >
                {
                  graphData.map((entry, index) => {
                      const onClickId = eventSchema ? eventSchema.id : null
                      const eventData = entry.events && onClickId ? entry.events[onClickId] || {} : {}

                      let selected = false

                      if (entry.filterData) {
                        each(entry.filterData, (value, key) => {
                          // if multiple bar charts compare the filterData with data key
                          if (childProps.length > 1) {
                            selected = selected || (entry.filterKey === key && bar.dataKey === value)
                          } else {
                            selected = selected || (entry.filterKey === key && isEqual(entry.filterValue, value))
                          }
                        })
                      }

                      const selectionStyle = selected ? selection : {}

                      return <Cell
                        key={`cell-${index}`}
                        fill={entry.fill ? entry.fill : barProps.fill}
                        onClick={() => {
                          this.props.onClickHandler(eventSchema, {
                            ...eventData[bar.dataKey],
                            dataKey: bar.dataKey,
                          });
                        }}
                        cursor={cursor ? cursor : 'pointer'}
                        {...selectionStyle}
                      />
                    }
                  )
                }
                {
                  (labels || []).concat(label || []).map((l, index) => {
                      const { type, ...otherLabelProps } = l || {};
                      const labelProps = type === "custom"
                          ? combineProps({ content: CustomLabel }, otherLabelProps)
                          : otherLabelProps;
                      return (<LabelList key={`label-${index}`} formatter={(value) => renderFormattedData(labelProps, value)} {...labelProps} />);
                    }
                  )
                }
              </ReChartBar>
            );
          })}
          {
            isScrollable && <Brush
              height={14}
              stroke="#cdced2"
              {...scrollable}
              {...data.scrollable}
              tickFormatter={() => ''}
            />
          }
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

Bar.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

Bar.defaultProps = {
  data: {
    eventsData: {},
  },
};

export default withDataChecker("cartesian", Bar);
