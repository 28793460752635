import React, { Component } from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import { dataURItoBlob, shouldRender } from "libs/utils"

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`)
}

function processFile(file) {
  const { name, size, type } = file
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.onerror = reject
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type,
      })
    }
    reader.readAsDataURL(file)
  })
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile))
}

function FilesInfo(props) {
  const { filesInfo } = props
  if (filesInfo.length === 0) {
    return null
  }
  return (
    <ul className="file-info">
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type } = fileInfo
        return (
          <li key={key}>
            <strong>{name}</strong> ({type}, {size} bytes)
          </li>
        )
      })}
    </ul>
  )
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter((dataURL) => typeof dataURL !== "undefined")
    .map((dataURL) => {
      const { blob, name } = dataURItoBlob(dataURL)
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      }
    })
}

class FilePreview extends React.Component {
  constructor(props) {
    super(props)
    const { value } = props
    const values = Array.isArray(value) ? value : [value]
    this.state = { values, filesInfo: extractFileInfo(values), files: [] }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState)
  }

  onChange = (event) => {
    const { multiple, onChange } = this.props
    processFiles(event.target.files).then((filesInfo) => {
      const state = {
        values: filesInfo.map((fileInfo) => fileInfo.dataURL),
        filesInfo,
        files: event.target.files,
      }
      this.setState(state, () => {
        if (multiple) {
          onChange(state.values)
        } else {
          onChange(state.values[0])
        }
      })
    })
  }

  renderFilePreview = () => {
    const { files, values } = this.state
    return (
      <div className="preview-container">
        <div className="preview-text-container mb-4">
          <div className="dashed">
            <span>Preview</span>
          </div>
        </div>
        <div className="preview-file-container">
          {files.length > 0 &&
            values.map((value, index) => {
              return (
                <div
                  key={shortid.generate()}
                  className="d-flex align-items-center justify-content-center"
                >
                  {value.startsWith("data:image") ? (
                    <img src={value} style={{ maxHeight: 600 }} />
                  ) : (
                    <iframe
                      className="preview-frame"
                      src={value + "#toolbar=0&navpanes=0&scrollbar=0"}
                      width="100%"
                      height="600"
                      frameborder="0"
                    />
                  )}
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  renderEditPreview = (value) => {
    if (!value) return null;
    return (
      <div className="preview-container">
        <div className="preview-text-container mb-4">
          <div className="dashed">
            <span>Preview</span>
          </div>
        </div>
        <div className="preview-file-container">
          <div
            key={shortid.generate()}
            className="d-flex align-items-center justify-content-center"
          >
            {value.startsWith("data:image") ? (
              <img src={value} style={{ maxHeight: 600 }} />
            ) : (
              <iframe
                className="preview-frame"
                src={value + "#toolbar=0&navpanes=0&scrollbar=0"}
                width="100%"
                height="600"
                frameborder="0"
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { label, required, multiple, id, readonly, disabled, autofocus, options, value } = this.props
    const { files, filesInfo } = this.state

    return (
      <div className="file-preview">
        <p>
          { label && (
              <label>
                {label}
                {required && options.astrick ? '*' : null}
              </label>
            )
          }
          <input
            ref={(ref) => (this.inputRef = ref)}
            id={id}
            type="file"
            disabled={readonly || disabled}
            onChange={this.onChange}
            defaultValue=""
            autoFocus={autofocus}
            multiple={multiple}
            accept={options.accept}
          />
        </p>
        {files.length > 0 && this.renderFilePreview()}
        {(files.length <= 0 && value) && this.renderEditPreview(value)}
      </div>
    )
  }
}

FilePreview.defaultProps = {
  autofocus: false,
}

FilePreview.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  autofocus: PropTypes.bool,
}

export default FilePreview
