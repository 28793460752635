import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEmpty } from 'lodash/fp'

const Input = ({ id, label, placeholder, type, value, required, onChange, options = {} }) => {
  const fieldEmptyClass = !isEmpty(value) && options.label === false ? ' valid-input' : ''
  const inputClass = classNames('mat-form-group', fieldEmptyClass)
  const fieldVal = value !== null ? value : undefined
  const placeholderText = (options.label === false ? '' : placeholder)
  return (
    <div className={inputClass}>
      <input
        value={(type === 'number') && fieldVal ? parseFloat(fieldVal) : fieldVal}
        type={type}
        className={options.classNames}
        placeholder={placeholderText}
        onChange={(e) => {
          const inputvalue = e.target.value
          const returnValue = options.sendNullWhenEmpty == false ? '' : null
          onChange(!inputvalue.trim() ? returnValue : inputvalue)
        }}
        readOnly={options.readonly}
      />
      {options.label === false
        && (
        <label htmlFor={id}>
          {placeholder || label}
          {required && options.astrick ? '*' : null}
        </label>
        )
      }
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  onChange: PropTypes.func
}

Input.defaultProps = {
  type: 'text'
}

export default Input