import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import shortid from 'shortid'
import classNames from 'classnames'
import requestsManager from 'libs/api/requestsManager'
import Loader from 'libs/components/Loader'

class Switcher extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      items: [],
      totalPages: 1,
    }
    bindAll(this, 'fetchItems')
  }

  componentDidMount() {
    const { data = {} } = this.props

    if (data.results) {
      this.setState({
        items: data.results,
        totalPages: data.total_pages || 1
      })
    } else {
      this.fetchItems()
    }
  }

  fetchItems() {
    const { switcherApi } = this.props.schema
    this.setState({ loading: true })
    requestsManager.fetchEntities(switcherApi)
      .then((res) => {
        this.setState({
          loading: false,
          items: res.data.results,
          totalPages: res.data.total_pages || 1
        })
      })
      .catch((error) => { console.error(error) })
  }

  render() {
    const { selectedItem, redirectUrl, dropdownLabel, style = {} } = this.props.schema
    const { totalPages, items, loading } = this.state
    const itemsList = items.map((item) => {
      const isSelected = (selectedItem.id === item.id)
      return (
        <a className={classNames('dropdown-item', { 'selected': isSelected })} href={item.url.path} key={shortid.generate()}>
          {item.label}
          {item.is_new
            && <span className="list-new-btn">New</span>
          }
        </a>
      )
    })
    if (totalPages > 1) {
      itemsList.push(
        <a key={shortid.generate()} className="dropdown-item" href={redirectUrl}>
          <span className="text-gray">
            Show All
            <i className="arrow-right-gray" />
          </span>
        </a>
      )
    }
    return (
      <div className={classNames("cvc-c-switcher d-inline-block", style.containerClassName)}>
        <div className="btn-group">
          <button
            type="button"
            className={classNames("dropdown-toggle switcher-dropdown-toggle text-truncate", style.className)}
            style={style.styles}
            id="dropdownMenuButton"
            data-toggle="dropdown"
          >
            {selectedItem.label}
          </button>
          <div className="dropdown-menu dropdown-menu-right customer-dropdown" aria-labelledby="dropdownMenuButton">
            <React.Fragment>
              <p className="switcher-dropdown-label">{dropdownLabel || ''}</p>
              {loading
                ? <Loader />
                : (
                  <React.Fragment>
                    {itemsList}
                  </React.Fragment>
                )
              }
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }
}

Switcher.propTypes = {
  schema: {
    selectedItem: PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    }),
    switcherApi: PropTypes.string,
    redirectUrl: PropTypes.string,
    dropdownLabel: PropTypes.string
  }
}

Switcher.defaultProps = {
  schema: {
    selectedItem: {
      id: null,
      label: 'Select Item'
    },
    switcherApi: '/clients.json',
    redirectUrl: '/clients',
    dropdownLabel: 'Switch Customers'
  }
}

export default Switcher
