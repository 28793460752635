import { ADD_SPREAD, REMOVE_SPREAD, SET_WIDGET } from '../constants/spreadConstants'
import { SET_ENCAPSULATOR_SCHEMA_URL } from '../constants/encapsulatorConstants'

export function setWidget(payload) {
  return {
    type: SET_WIDGET,
    payload
  }
}

export function addSpread(payload) {
  return (dispatch) => {
    const { spread } = payload
    const { encapsulators } = spread.spread
    // TODO: try to do this in a single action
    encapsulators.forEach((encapsulator) => {
      dispatch({
        type: SET_ENCAPSULATOR_SCHEMA_URL,
        payload: {
          id: encapsulator.id,
          schemaUrl: encapsulator.schemaUrl
        }
      })
    })
    dispatch({
      type: ADD_SPREAD,
      payload: spread
    })
  }
}

export function removeSpread(encapsulators, spreadIndex) {
  return (dispatch) => {
    // TODO: try to do this in a single action
    encapsulators.forEach((encapsulator) => {
      dispatch({
        type: SET_ENCAPSULATOR_SCHEMA_URL,
        payload: {
          id: encapsulator.id,
          schemaUrl: encapsulator.schemaUrl
        }
      })
    })
    dispatch({
      type: REMOVE_SPREAD,
      payload: spreadIndex
    })
  }
}
