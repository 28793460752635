import {
  FETCHING_TABLE_ROW_SCHEMA,
  FETCH_TABLE_ROW_SCHEMA_SUCCESS,
  FETCH_TABLE_ROW_SCHEMA_FAIL,
  RESET_TABLE_ROW_SCHEMA
} from '../constants/tableRowConstants'

export const $$initialState = {
  schema: null,
}

function tableRowReducer($$state = $$initialState, action = null) {
  const {
    type,
    schema,
    loadingSchema,
    row,
    error
  } = action
  switch (type) {
    case FETCHING_TABLE_ROW_SCHEMA:
      return {
        ...$$state,
        loadingSchema,
        error: null
      }
    case FETCH_TABLE_ROW_SCHEMA_SUCCESS:
      return {
        ...$$state,
        schema,
        loadingSchema: false,
        error: null
      }
    case FETCH_TABLE_ROW_SCHEMA_FAIL:
    case RESET_TABLE_ROW_SCHEMA:
      return {
        ...$$state,
        schema: null,
        loadingSchema: false,
        error
      }
    default:
      return $$state
  }
}

export default tableRowReducer
