import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'core/components/Icon'

function valueForSize(circleSize, standardValue) {
  return parseInt((circleSize * standardValue)/11)
}

function valuesForSize(progressCircleSize) {
  return {
    labelFontSize: valueForSize(progressCircleSize, 36),
    descriptionFontSize: valueForSize(progressCircleSize, 16),
    iconFontSize: valueForSize(progressCircleSize, 24),
    iconWidth: valueForSize(progressCircleSize, 74),
    iconHeight: valueForSize(progressCircleSize, 32)
  }
}

const Circle = (props) => {
  const { schema, data } = props
  const { graph: graphData, events: eventsData = {} } = data
  const { events } = schema
  const style = schema.style || {}
  const { labelColor, bars, primaryValue, primaryLabel, iconStyle, description, secondaryValue = 0, piRotation = 210, paddingAngle = 60, progressCircleSize = 11 } = graphData
  const cssProperties = valuesForSize(progressCircleSize)

  const paddingTopValue = (paddingAngle == 0 && progressCircleSize < 10)  ? parseInt((progressCircleSize * 35)/8) : valueForSize(progressCircleSize, 60)
  const progressArc = progressCircleSize/2 + 0.1
  const progressCircleHalf = progressCircleSize/2
  const tooltip = graphData.tooltip || schema.tooltip
  const iconPaddingTop = ( paddingAngle == 0 && piRotation == 0 ) ? 0 : 12
  const textMarginTop = ( paddingAngle == 0 && piRotation == 0 ) ? 2 : 7

  const totalSecondaryValue = secondaryValue + primaryValue
  const primaryValueClass = primaryValue > 50 ? 'progress-50-plus' : 'progress-50-minus'
  const secondaryValueClass = totalSecondaryValue > 50 ? 'secondary-50-plus' : 'secondary-50-minus'
  const progressWrapperClass = `${primaryValueClass} ${secondaryValueClass}`
  const wrapperClassName = classNames('cvc-c-circle', style.className, progressWrapperClass, { 'cursor-pointer': schema.events })
  const eventSchema = events ? events.onclick : null
  const eventData = events ? eventsData[events.onclick.id] || {} : null
  return (
    <div
      title={tooltip}
      className={wrapperClassName}
      role="presentation"
      style={{ "--progressCircleSize": `${progressCircleSize}em` }}
      onClick={() => props.onClickHandler(eventSchema, eventData)}
    >
      <div className="label" style={{ paddingTop: `${paddingTopValue}px` }}>
        <Icon schema={{ className: 'arrow' }} />
        <i className="arrow" />
        <span className="value" style={{ color: labelColor, "--labelFontSize": `${cssProperties.labelFontSize}px` }}>
          {primaryLabel || primaryValue || 0}
        %
        </span>
        <p className="text" style={{ "--descriptionFontSize": `${cssProperties.descriptionFontSize}px`, "--textMarginTop": `${textMarginTop}%`}}>
          {description}
        </p>
        <div className="icon" style={{ "--iconFontSize": `${cssProperties.iconFontSize}px`, "--iconWidth": `${cssProperties.iconWidth}px`, "--iconHeight": `${cssProperties.iconHeight}px`, "--iconPaddingTop": `${iconPaddingTop}%`}}>
          <Icon schema={{ className: iconStyle }} />
        </div>
      </div>
      { bars.map((bar, i) => {
        const innerStyle = {
          transform: `rotate(${bar.rotationValue * ((360 - paddingAngle) / 100)}deg)`,
          border: `1em solid ${bar.background}`,
          zIndex: `${bar.zIndex}`,
          "--progressCircleSize": `${progressCircleSize}em`,
          "--progressCircleSizeHalf": `${progressCircleHalf}em`
        }

        return (
          <div className={classNames('pie', `${bar.arcClass}`)} style={{ transform: `rotate(${piRotation}deg)`, "--progressCircleSize": `${progressCircleSize}em`, "--progressClip": `${progressArc}em` }} key={i}>
            <div className="left-side half-circle" style={innerStyle} />
            <div className="right-side half-circle" style={{ border: `1em solid ${bar.background}`, "--progressCircleSize": `${progressCircleSize}em`, "--progressCircleSizeHalf": `${progressCircleHalf}em` }} />
          </div>
        )
      })
      }
      {paddingAngle > 0
      && (
      <div className="pie arc2" style={{ transform: `rotate(${360 - piRotation}deg)`, "--progressCircleSize": `${progressCircleSize}em`, "--progressClip": `${progressArc}em` }}>
        <div className="left-side half-circle" style={{ transform: `rotate(${paddingAngle}deg)`, "--progressCircleSize": `${progressCircleSize}em`, "--progressCircleSizeHalf": `${progressCircleHalf}em` }} />
        <div className="right-side half-circle" style={{ "--progressCircleSize": `${progressCircleSize}em`, "--progressCircleSizeHalf": `${progressCircleHalf}em` }} />
      </div>
      )
      }
      <div className="outer-circle" />
    </div>
  )
}

Circle.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.shape({
    graph: PropTypes.shape({
      bars: PropTypes.array,
      primaryValue: PropTypes.number,
      secondaryValue: PropTypes.number,
      iconStyle: PropTypes.string,
      description: PropTypes.string,
      tooltip: PropTypes.string,
      piRotation: PropTypes.number,
      paddingAngle: PropTypes.number,
      labelOffsetTop: PropTypes.string
    }).isRequired,
    events: PropTypes.object
  }).isRequired,
  onClickHandler: PropTypes.func.isRequired
}

export default Circle
