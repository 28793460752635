import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import shortid from 'shortid'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { cleanObject, getUrlParam, setUrlParam } from 'libs/utils'
import requestsManager from 'libs/api/requestsManager'
import NotificationComponentHoc from 'libs/components/NotificationComponentHoc'
import TabList from 'libs/components/TabList'
import Loader from 'libs/components/Loader'
import FilterPanel from 'libs/components/FilterPanel'
import FilterItem from 'libs/components/FilterItem'
import Button from 'libs/components/Button'
import AutoComplete from 'libs/components/AutoComplete'
import ApiAutocomplete from 'libs/components/ApiAutocomplete'
import Header from '../common/Header'
import EntityItem from '../common/EntityItem'
import TaskDetail from './TaskDetail'
import TaskModal from './TaskModal'
import CommentList from './CommentList'
import CommentInput from './CommentInput'
import Switcher from 'core/components/Switcher'
import { STATUS_TYPES } from './constants'

const navItems = {
  user: [{
    display: 'Assigned',
    action: 'assigned'
  }, {
    display: 'Created',
    action: 'created'
  }, {
    display: 'Watcher',
    action: 'watcher'
  }, {
    display: 'All',
    action: 'all_roles'
  }],
  customer: [{
    display: 'My Tasks',
    action: 'all_roles'
  }, {
    display: 'Other Tasks',
    action: 'viewer'
  }]
}

const roleValues = [{
  id: 'created',
  label: 'Creator'
}, {
  id: 'assigned',
  label: 'Assignee'
}, {
  id: 'watcher',
  label: 'Watcher'
}]

class Index extends React.Component {
  constructor() {
    super()
    // There could be two context for the usage of this component. Use this variable for
    // conditional rendering of data between the two contexts
    // TODO: Swapnil has to ask the team if there can be a better way of get the context
    this.userContext = window.location.pathname.indexOf('clients') > 0 ? 'customer' : 'user'
    this.dateFormat = window.CC_MOMENT_JS_DATE_FORMAT
    this.state = {
      tasks: [],
      entityTypeList: {},
      comments: [],
      newComment: '',
      showCommentBox: true,
      editedCommentContent: '',
      selectedComment: null,
      selectedTask: null,
      tasksLoading: true,
      taskDetailsLoading: true,
      navItems: navItems[this.userContext].map(item => item.display),
      selectedNav: this.userContext === 'customer' ? 'My Tasks' : getUrlParam('li_id') ? 'All' : 'Assigned',
      filters: {
        startDate: moment(),
        endDate: null,
        client: null,
        entity: '',
        role: '',
        status: ['Open', 'Reopened']
      },
      selectedFilters: {
        startDate: moment(),
        endDate: null,
        client: null,
        entity: '',
        role: '',
        status: ['Open', 'Reopened']
      },
      showFilters: false,
      filterApplied: false,
      commentsLoading: false,
      enableCommentSave: false,
      enableCommentEdit: false,
      clientId: null
    }
    bindAll(this,
      'getTasks',
      'getTaskDetails',
      'getComments',
      'postComment',
      'getCommentsUrl',
      'handleEditComment',
      'handleDeleteComment',
      'handleCancelEditing',
      'handleCommentChange',
      'handleSelectComment',
      'setSelectedNav',
      'selectTask',
      'applyTaskAction',
      'showResolvedTasks',
      'hideResolvedTasks',
      'showFilters',
      'hideFilters',
      'removeFilters',
      'editTask',
      'createTask',
      'hideTaskModal',
      'emptyTasksPlaceholder',
      'handleWriteComment',
      'handleOptionsClick',
      'inViewData',
      'resetCommentAttributes',
      'getAction',
      'setInitialFilters',
      'checkFilter'
    )
  }

  componentDidMount() {
    const { api, taskableEntities } = this.props
    const entityTypeList = taskableEntities
    const clientId = getUrlParam('client_id', api)
    this.setState({ clientId,  entityTypeList })
    this.getTasks(false)
    this.setInitialFilters()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  setInitialFilters() {
    const entity = getUrlParam('entity')
    const entityName = getUrlParam('entity_name')
    const url = `/tasks/entities.json?filter[taskable_type]=${entity}&filter[entity_id]=${entityName}`
    if (entity && entityName) {
      requestsManager.fetchEntities(url).then(res => {
        const { filters } = this.state
        const initialFilters = { ...filters, entity, entityName: res.data.entities[0] }
        this.setState({
          filters: initialFilters,
          selectedFilters: initialFilters,
          filterApplied: true
        }, () => this.getTasks(false))
      })
    } else {
      this.getTasks(false)
    }
  }

  getTasks(resetSelectedTask = true) {
    const { selectedTask } = this.state
    const url = this.getTaskUrl()
    this.setState({ tasksLoading: true, taskDetailsLoading: true })
    requestsManager.fetchEntities(url)
      .then((res) => {
        const { id, name, slug, tasks, meta } = res.data
        const client = slug ? { id, name, slug } : null
        const liIdFromUrl = parseInt(getUrlParam('li_id'))
        const taskFromLiId = tasks.find(task => task.id === liIdFromUrl)
        const nextSelectedTask = resetSelectedTask ? tasks[0] : taskFromLiId ? taskFromLiId : tasks[0]
        if (nextSelectedTask && liIdFromUrl !== nextSelectedTask.id) {
          setUrlParam({ li_id: nextSelectedTask.id })
        }
        this.setState({ client, tasks, selectedTask: nextSelectedTask, resolveCount: (meta.Resolved || 0), tasksLoading: false }, this.getTaskDetails)
      })
  }

  resetCommentAttributes() {
    this.setState({
      selectedComment: null,
      editedCommentContent: '',
      newComment: '',
      enableCommentSave: false,
      enableCommentEdit: false
    })
  }

  getTaskDetails() {
    const { selectedTask } = this.state
    if (selectedTask) {
      const { url } = selectedTask.actions.show
      this.setState({ taskDetailsLoading: true })
      requestsManager.fetchEntities(url)
        .then((res) => {
          this.setState({ taskDetails: res.data, taskDetailsLoading: false })
        })
    } else {
      this.setState({ taskDetails: null, taskDetailsLoading: false })
    }
    this.getComments()
  }

  getCommentsUrl() {
    const { selectedTask } = this.state
    return `/comments.json?commentable_type=Task&commentable_id=${selectedTask.id}`
  }

  getComments() {
    const { selectedTask } = this.state
    if (selectedTask) {
      const url = this.getCommentsUrl()
      requestsManager.fetchEntities(url)
        .then((res) => {
          const { comments, show_comment_box } = res.data
          comments.forEach(comment => comment.created_at = moment(comment.created_at).format(`${this.dateFormat}, h:mm A`))
          this.setState({comments: comments, commentsLoading: false, showCommentBox: show_comment_box})
        })
        .catch((err) => {
          this.setState({commentsLoading: false, showCommentBox: false, comments: []})
        })
    }
  }

  postComment() {
    const { selectedTask, newComment, comments } = this.state
    if (newComment.trim() === '') return
    const { currentUser } = this.props
    const url = '/comments.json'
    const payload = {
      commentable_type: 'Task',
      commentable_id: selectedTask.id,
      comment: {
        content: newComment.trim()
      },
      email: currentUser.email
    }
    requestsManager.submitEntity(url, payload)
      .then((res) => {
        res.data.created_at = moment(res.data.created_at).format(`${this.dateFormat}, h:mm A`)
        this.setState({ comments: [res.data, ...comments], newComment: '', enableCommentSave: false })
      })
  }

  handleWriteComment(newComment) {
    this.setState({
      newComment: newComment,
      enableCommentSave: newComment.trim() !== ''
    })
  }

  handleCommentChange(editedComment) {
    this.setState({
      editedCommentContent: editedComment,
      enableCommentEdit: editedComment.trim() !== ''
    })
  }

  handleSelectComment(id) {
    const { comments } = this.state
    const comment = comments.find(c => c.id === id)
    this.setState({
      selectedComment: id,
      editedCommentContent: comment.content,
      enableCommentEdit: true
    })
  }

  handleWriteCommentFocus = () => {
    const { selectedComment } = this.state
    if (selectedComment) this.setState({ selectedComment: null, editedCommentContent: '' })
  }

  handleEditComment() {
    const { selectedComment, editedCommentContent, comments } = this.state
    if (editedCommentContent.trim() === '') return
    const url = `/comments/${selectedComment}.json`
    const payload = {
      comment: { content: editedCommentContent.trim() }
    }
    requestsManager.updateEntity(url, payload)
      .then(res => {
        res.data.created_at = moment(res.data.created_at).format(`${this.dateFormat}, h:mm A`)
        const newComments = JSON.parse(JSON.stringify(comments))
        const index = newComments.findIndex((comment => comment.id === selectedComment))
        newComments[index] = res.data
        this.setState({ comments: newComments, selectedComment: null, editedCommentContent: '' })
      })
  }

  handleDeleteComment(selectedComment) {
    const url = `/comments/${selectedComment}.json`
    const comments = JSON.parse(JSON.stringify(this.state.comments))
    requestsManager.deleteEntity(url)
      .then(() => {
        this.setState({ comments: comments.filter(comment => comment.id !== selectedComment) })
      })
  }

  handleCancelEditing() {
    this.setState({ selectedComment: null, editedCommentContent: '' })
  }

  handleOptionsClick(e) {
    const inViewData = this.inViewData(e.target)
    if (!inViewData.inView) {
      const { topOffset, bottomOffset } = inViewData
      this.commentList.scrollTop += topOffset < 0 ? bottomOffset : topOffset
    }
  }

  // TODO: Move this out of this component and make it reusable for the framework
  inViewData(elem) {
    const container = this.commentList
    const contHeight = container.clientHeight
    const contTop = container.scrollTop
    const contBottom = contTop + contHeight

    const elemTop = elem.getBoundingClientRect().top - container.getBoundingClientRect().top
    const elemBottom = elemTop + 106

    return {
      inView: elemTop >= 0 && elemBottom <= contHeight,
      isPartialFromTop: elemTop < 0,
      isPartialFromBottom: elemBottom <= contHeight,
      bottomOffset: elemTop,
      topOffset: elemBottom - contHeight
    }
  }


  setSelectedNav(selectedNav) {
    this.setState({ selectedNav }, () => {
      this.getTasks()
      this.resetCommentAttributes()
    })
  }

  getAction() {
    const { selectedNav, filters } = this.state
    if (this.userContext === 'customer' && filters.role) {
      return filters.role.id
    }
    return navItems[this.userContext].find(item => item.display === selectedNav).action
  }

  getTaskUrl() {
    const { selectedNav, selectedFilters, entityTypeList } = this.state
    let url = this.props.api
    const { startDate, endDate, client, entity, status, entityName } = selectedFilters
    const params = {
      filter: {
        client_id: client ? client.id : null,
        action: this.getAction(),
        taskable_type: entityTypeList[entity],
        taskable_id: entityName ? entityName.id : null,
        due_date_from: startDate && endDate ? moment(startDate).format('YYYY-MM-DD') : '',
        due_date_to: startDate && endDate ? moment(endDate).format('YYYY-MM-DD') : '',
        status
      }
    }
    cleanObject(params)
    if (Object.keys(params).length > 0) {
      url += `${url.indexOf('?') >= 0 ? '&' : '?'}${$.param(params)}`
    }
    return url
  }

  showResolvedTasks() {
    const filters = { ...this.state.filters, status: 'Resolved' }
    this.setState({ filters, selectedFilters: filters, resolvedTasksShown: true }, () => {
      this.getTasks()
      this.resetCommentAttributes()
    })
  }

  //Checks if the filter is applied or not
  checkFilter() {
    const { filters } = this.state
    return filters.client !== null || filters.entity !== '' || moment(filters.endDate).isValid() || filters.role
  }

  hideResolvedTasks() {
    let filters = { ...this.state.filters }
    filters.status = ['Open', 'Reopened']
    this.setState({ filters, selectedFilters: filters, resolvedTasksShown: false, filterApplied: this.checkFilter() }, () => {
      this.getTasks()
      this.resetCommentAttributes()
    })
  }

  selectTask(task) {
    const { selectedTask } = this.state
    if (task.id !== selectedTask.id) {
      this.setState({ selectedTask: task }, () => {
        this.getTaskDetails()
        this.resetCommentAttributes()
        setUrlParam({li_id: task.id})
      })
    }
  }

  applyTaskAction(action, url, successMsg) {
    const requestsManagerFn = action === 'DELETE' ? requestsManager.deleteEntity : requestsManager.submitEntity
    requestsManagerFn.call({}, url)
      .then(() => {
        this.props.addNotification({
          title: successMsg,
          level: 'success'
        })
        this.getTasks()
      })
      .catch((error) => {
        this.props.addNotification({
          title: error.response.data.base.join(', '),
          level: 'error'
        })
      })
  }

  showFilters() {
    this.setState({ showFilters: true }, () => { $('#filtersModal').modal('show') })
  }

  hideFilters(updateTasks = false) {
    const { filters, selectedFilters } = this.state
    if (updateTasks) {
      this.setState({
        selectedFilters: filters,
        showFilters: false,
        filterApplied: true,
        resolvedTasksShown: filters.status === 'Resolved'
      }, this.getTasks)
    } else {
      // reset filters
      this.setState({ filters: selectedFilters, showFilters: false })
    }
  }

  removeFilters() {
    const filters = { startDate: moment(), endDate: null, client: null, entity: '', status: 'Open' }
    this.setState({ filters, selectedFilters: filters, filterApplied: false }, this.getTasks)
  }

  get formattedDateRange() {
    const { startDate, endDate } = this.state.filters
    return (startDate && endDate) ? `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}` : ''
  }

  editTask() {
    const { taskDetails, selectedTask } = this.state
    const modalTask = {
      id: taskDetails.id,
      name: taskDetails.name,
      description: taskDetails.description,
      dueDate: taskDetails.due_date,
      watchersAttributes: taskDetails.watchers_attributes,
      assigneeId: taskDetails.assignee_id,
      assigneeName: taskDetails.assignee_name,
      assigneeEmail: taskDetails.assignee_email,
      actionUrl: selectedTask.actions.show.url,
      clientId: selectedTask.client_id,
      slug: selectedTask.client_slug,
      visibility: taskDetails.visibility
    }
    this.setState({ modalTask, taskModalShown: true }, this.showTaskModal)
  }

  createTask() {
    const { client } = this.state
    this.setState({ modalTask: { clientId: client.id, slug: client.slug }, taskModalShown: true }, this.showTaskModal)
  }

  showTaskModal() {
    $('#taskModal').modal('show')
  }

  emptyTasksPlaceholder() {
    const { resolveCount, filterApplied } = this.state
    if (filterApplied) {
      return resolveCount > 0 ? 'There are no open tasks matching the filter criteria.' : 'There are no tasks matching the filter criteria.'
    }
    return resolveCount > 0 ? 'You don\'t have any open tasks.' : 'You don\'t have any tasks.'
  }

  hideTaskModal(taskUpdated = false) {
    if (taskUpdated) {
      this.setState({ taskModalShown: false }, () => this.getTasks(false))
    } else {
      this.setState({ taskModalShown: false })
    }
  }

  render() {
    const {
      clientId,
      tasks,
      tasksLoading,
      taskDetailsLoading,
      selectedTask,
      taskDetails,
      resolveCount,
      navItems,
      selectedNav,
      filters,
      filterApplied,
      showFilters,
      client,
      taskModalShown,
      resolvedTasksShown,
      modalTask,
      comments,
      newComment,
      selectedComment,
      editedCommentContent,
      showCommentBox,
      enableCommentEdit,
      enableCommentSave,
      entityTypeList
    } = this.state
    const entityTypes = Object.keys(entityTypeList)
    const { addNotification, currentUser, assigneeUrl } = this.props
    const isFilterApplied = filters.client !== null || filters.entity !== '' || filters.status !== '' || moment(filters.endDate).isValid() || filters.role
    let leftPanelHeading = filterApplied ? 'Filter' : null
    leftPanelHeading = resolvedTasksShown ? 'Resolved' : leftPanelHeading
    const filterClass = filterApplied ? 'filter-notes-applied' : 'filter-notes'
    const removeAction = resolvedTasksShown ? this.hideResolvedTasks : this.removeFilters
    const removeActionTitle = resolvedTasksShown ? 'Hide Resolved Tasks' : 'Remove Filters'

    const actions = selectedTask ? selectedTask.actions : {}
    const showCreateTaskBtn = client && (['Assigned', 'Watcher'].indexOf(selectedNav) === -1) && !filterApplied && !resolvedTasksShown
    return (
      <div className="content-main pb-0">
        <div className="row">
          <Header
            clientSwitcher={
              clientId && currentUser.internal ? (
                <Switcher
                  schema={{
                    selectedItem: {
                     id: this.props.client.id,
                     label: this.props.client.name
                    },
                    redirectUrl: '/clients',
                    switcherApi: '/clients.json',
                    dropdownLabel: 'Switch Customers',
                  }}
                />
              ) : null
            }
            title="Tasks"
          />
        </div>
        <div className="row px-md-none">
          <div className="col-lg-4 section-cntr">
            <div className="content-main-sec sec-large p-0">
              <div className="tasks">
                <div className="header">
                  {(filterApplied || resolvedTasksShown) ? (
                    <p> {leftPanelHeading} </p>
                  ) : (
                    <TabList items={navItems} selectedItem={selectedNav} onItemSelect={this.setSelectedNav} />
                  )}
                  <div className="float-right notes-controls">
                    {!resolvedTasksShown &&
                      <span className={`icons ${filterClass}`} title="Apply Filters" role="presentation" onClick={this.showFilters} />
                    }
                    {showCreateTaskBtn &&
                      <span className="icons create-new-task" role="presentation" onClick={this.createTask} />
                    }
                    {(filterApplied || resolvedTasksShown) &&
                      <span className="icons close" role="presentation" title={removeActionTitle} onClick={removeAction} />
                    }
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active">
                    {tasksLoading ? (
                      <Loader />
                    ) : (
                      <React.Fragment>
                        {resolveCount > 0 && !resolvedTasksShown &&
                          <div className="p-3 text-gray">
                            <span>{resolveCount} Resolved</span>
                            <span className="float-right text-btn" role="presentation" onClick={this.showResolvedTasks}> Show </span>
                          </div>
                        }
                        <ul className="notes">
                          {tasks.length > 0 &&
                            <React.Fragment>
                              {tasks.map((task) => {
                                const isSelected = task.id === selectedTask.id
                                return (
                                  <EntityItem title={task.name} isSelected={isSelected} clientName={task.client_name} date={task.due_date} key={shortid.generate()} onClickHandler={() => this.selectTask(task)} />
                                )
                              })
                              }
                            </React.Fragment>
                          }
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 section-cntr">
            <div className="content-main-sec sec-large p-0">
              <div className="notes">
                <div className="header">
                  <div className={`float-right notes-controls${tasks.length === 0 ? ' disabled' : ''}`}>
                    {!resolvedTasksShown ? (
                    (selectedTask) && (
                      <React.Fragment>
                        <span
                          className="icons check-task mr-0"
                          title="Complete Task"
                          role="presentation"
                          onClick={() => this.applyTaskAction(actions.resolve.action, actions.resolve.url, 'Task Resolved Successfully.')}
                        />
                        <span className="icons attachment disabled d-none" title="Attach Files" />
                        <div className="btn-group option-dropdown notes-options ml-3">
                          <button type="button" className="dropdown-toggle dropdown-regular" id="dropdownMenuButton" data-toggle="dropdown">
                            <i className="fa fa-ellipsis-v" aria-hidden="true" />
                          </button>
                          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a
                              rel="nofollow"
                              className="dropdown-item"
                              role="presentation"
                              onClick={this.editTask}
                            > Edit
                            </a>
                            <a
                              rel="nofollow"
                              className="dropdown-item"
                              role="presentation"
                              onClick={() => this.applyTaskAction(actions.destroy.action, actions.destroy.url, 'Task Deleted Successfully.')}
                            > Delete
                            </a>
                          </div>
                        </div>
                      </React.Fragment>)
                    ) : (
                      <span
                        className="icons trash"
                        title="Delete Task"
                        role="presentation"
                        onClick={() => this.applyTaskAction(actions.destroy.action, actions.destroy.url, 'Task Deleted Successfully.')}
                      />
                    )}
                  </div>
                </div>
                <React.Fragment>
                  {(tasksLoading || taskDetailsLoading) ? (
                    <Loader />
                  ) : (
                    <React.Fragment>
                      {tasks.length === 0 || !taskDetails ? (
                        <div className="empty-notes">
                          <p className="text-center text-gray"> {this.emptyTasksPlaceholder()} </p>
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className="p-3">
                            <TaskDetail
                              title={taskDetails.name}
                              description={taskDetails.description}
                              creator={taskDetails.creator_name}
                              createdAt={taskDetails.created_at}
                              assigneeName={taskDetails.assignee_name}
                              assigneePhoto={taskDetails.assignee_photo}
                              watchers={taskDetails.watchers_attributes}
                              type={taskDetails.type}
                              link={taskDetails.link}
                              parentLink={taskDetails.parent_link}
                              parentLinkTitle={taskDetails.parent_link_title}
                            />
                          </div>
                          <div
                            className="task-comment-list"
                            ref={(comp) => { this.commentList = comp }}
                          >
                            <CommentList
                              userId={currentUser.id}
                              enableCommentEdit={enableCommentEdit}
                              editedCommentContent={editedCommentContent}
                              selectedComment={selectedComment}
                              comments={comments}
                              commentEditActions={this.commentEditActions}
                              containerClass="mb-3"
                              onSelectComment={this.handleSelectComment}
                              onCommentChange={this.handleCommentChange}
                              onDeleteComment={this.handleDeleteComment}
                              onEditComment={this.handleEditComment}
                              onCancelEditing={this.handleCancelEditing}
                              onOptionsClick={this.handleOptionsClick}
                            />
                          </div>
                          <div className="px-3 pt-3">
                            {showCommentBox &&
                              <CommentInput
                                value={newComment}
                                enableSave={enableCommentSave}
                                placeholder="Write a comment"
                                onChange={this.handleWriteComment}
                                onFocus={this.handleWriteCommentFocus}
                              >
                                <button
                                  className={classNames('cvc-btn-primary mr-3', !enableCommentSave && 'disabled')}
                                  onClick={this.postComment}
                                >
                                  Save
                                </button>
                              </CommentInput>
                            }
                          </div>
                          <div className="comment-input">
                            {resolvedTasksShown ? (
                              <Button
                                className="btn btn-reopen"
                                onClick={() => this.applyTaskAction(actions.reopen.action, actions.reopen.url, 'Task Reopened Successfully.')}
                              > Reopen Task
                              </Button>
                            ) : (
                              <div className="comment-outer d-none">
                                <div className="input-group">
                                  <input type="text" className="form-control" placeholder="Write a comment" />
                                </div>
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
        {showFilters &&
          <FilterPanel onFilterClose={this.hideFilters} disableFilterBtn={!isFilterApplied} >
            <div className="form-group field field-object">
              {!client &&
                <div className="form-group field">
                  <div className="btn-group h-100">
                    <label>Customer Name</label>
                    <AutoComplete
                      api="/clients.json"
                      queryParamKey="name"
                      respDataFormatter={data => data.results}
                      className="form-control input-normal input-invite"
                      placeholder="Select..."
                      valueKey="label"
                      onSuggestionSelected={(item) => { this.setState({ filters: { ...filters, client: item } }) }}
                      selectedValue={filters.client ? filters.client.label : ''}
                    />
                  </div>
                </div>
              }
              <FilterItem
                title={'Entity'}
                items={entityTypes}
                selectedItems={filters.entity}
                selectedValue={filters.entity}
                onItemSelect={(item) => { this.setState({ filters: { ...filters, entity: item } }) }}
              />
              {filters.entity &&
                <div className="form-group field">
                  <div className="btn-group h-100">
                    <label>Entity Name</label>
                    <ApiAutocomplete
                      defaultOptions
                      autocompleteUrl={`/tasks/entities.json`}
                      additionalQueryParams={{
                        'filter[taskable_type]': filters.entity,
                        'filter[action]': this.userContext === 'customer' ? filters.role : this.getAction(),
                        'filter[client_id]': client ? client.id : filters.client ? filters.client.id : null
                      }}
                      queryKey="entity_name"
                      placeholder="Select..."
                      respDataFormatter={data => data.entities}
                      onItemSelect={(item) => { this.setState({ filters: { ...filters, entityName: item } }) }}
                      value={filters.entityName}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                    />
                  </div>
                </div>
              }
              {this.userContext === 'customer' && selectedNav === 'My Tasks' &&
                <FilterItem
                  title={'Role'}
                  items={roleValues}
                  selectedItems={filters.role}
                  selectedValue={filters.role ? filters.role.label : null}
                  onItemSelect={(item) => { this.setState({ filters: { ...filters, role: item } }) }}
                />
              }
              <div className="form-group field">
                <div className="cvc-daterange-picker">
                  <label>Date</label>
                  <div className="btn-group h-100">
                    <button type="button" className={classNames("dropdown-toggle dropdown-regular", { 'text-gray': !this.formattedDateRange })} id="dropdownMenuButton" data-toggle="dropdown">
                      {this.formattedDateRange || 'Select...'}
                    </button>
                  </div>
                  <DateRangePicker
                    startDateId={'startDate'}
                    startDate={filters.startDate}
                    endDateId={'endDate'}
                    endDate={filters.endDate}
                    numberOfMonths={1}
                    daySize={28}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    onDatesChange={({ startDate, endDate }) => this.setState({ filters: { ...filters, startDate, endDate } })}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                  />
                </div>
              </div>
              <FilterItem
                title={'Status'}
                items={STATUS_TYPES}
                selectedItems={filters.status}
                selectedValue={filters.status[0]}
                onItemSelect={(item) => { this.setState({ filters: { ...filters, status: item } }) }}
              />
            </div>
          </FilterPanel>
        }
        {taskModalShown &&
          <TaskModal
            taskId={modalTask.id}
            name={modalTask.name}
            description={modalTask.description}
            dueDate={modalTask.dueDate}
            watchersAttributes={modalTask.watchersAttributes}
            assigneeId={modalTask.assigneeId}
            assigneeName={modalTask.assigneeName}
            assigneeEmail={modalTask.assigneeEmail}
            assigneeUrl={assigneeUrl}
            clientId={modalTask.clientId}
            taskUrl={modalTask.actionUrl}
            onModalHide={this.hideTaskModal}
            addNotification={addNotification}
            visibility={modalTask.visibility}
            hideVisibility={this.props.hideVisibility}
          />
        }
        <ReactTooltip
          effect="solid"
          multiline={true}
          className="cvc-tooltip"
          place="bottom"
        />
      </div>
    )
  }
}

Index.propTypes = {
  addNotification: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  api: PropTypes.string.isRequired,
  assigneeUrl: PropTypes.string.isRequired
}

export default NotificationComponentHoc(Index)