import omit from 'lodash.omit'
import { SET_WIDGET_GROUPS, SHOW_WIDGET_GROUP } from '../constants/widgetGroupConstants'
import { HIDE_MODAL } from '../constants/modalConstants'
import { RESET_POPOVER_SCHEMA } from '../constants/popoverConstants'
import { RESET_TABLE_ROW_SCHEMA } from '../constants/tableRowConstants'

import { convertArrayToHash } from '../../helpers/utils'

export const $$initialState = {}

export default function widgetGroupReducer($$state = $$initialState, action) {
  const {
    type,
    parentId,
    widgetGroupId,
    modalId,
    popoverId,
    rowId,
    show,
    widgetGroups
  } = action

  let widgetGroup
  switch (type) {
    case SET_WIDGET_GROUPS:
      return {
        ...$$state, [parentId]: convertArrayToHash(widgetGroups)
      }
    case SHOW_WIDGET_GROUP:
      widgetGroup = $$state[parentId] || {}
      return {
        ...$$state,
        [parentId]: {
          ...widgetGroup,
          [widgetGroupId]: { ...widgetGroup[widgetGroupId], show }
        }
      }
    case HIDE_MODAL:
      return omit($$state, modalId)
    case RESET_POPOVER_SCHEMA:
      return omit($$state, popoverId)
    case RESET_TABLE_ROW_SCHEMA:
      return omit($$state, rowId)
    default:
    {
      return $$state
    }
  }
}
