import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { handleAction } from 'core/redux/actions/appActionCreators'
import { renderData } from '../helpers/utils'

const Footer = (props) => {
  const { schema, data = {}, options } = props
  const { properties = [], style = {}, heading = {}, actions } = schema

  function onclickHandler(e) {
    const { events = {} } = schema
    const { onClick: eventSchema } = events

    if (!eventSchema) return

    const eventsData = data.events
    const action = { schema: eventSchema, data: eventsData[eventSchema.id] }
    props.dispatch(handleAction(null, action))
  }

  function renderTabs() {
    const navItemStyles =
      style.navItem && style.navItem.styles ? style.navItem.styles : {}
    return properties.map((property, index) => {
      const activeClassName = classNames('nav-link d-inline-block px-2', {
        active: property.selected,
        'primary-color': property.selected,
      })

      const badgeStyles =
        property.style && property.style.badge && property.style.badge.styles

      return (
        <li
          className={classNames(
            'nav-item d-flex align-items-center font-24 px-3'
          )}
          style={navItemStyles}
          id={property.id}
          key={property.id}
          role="presentation"
        >
          <div className={activeClassName}>{property.title}</div>
          {property.badge && (
            <div className="d-inline-block">
              <span className={classNames('mb-1')} style={badgeStyles}>
                {property.badge}
              </span>
            </div>
          )}
        </li>
      )
    })
  }

  function renderHeading() {
    return (
      <div
        role="presentation"
        className={classNames(
          'heading-container d-inline-block',
          heading.containerClassName
        )}
        {...heading.tooltip}
      >
        {heading && heading.title && (
          <div
            className={classNames(
              'd-inline-block align-middle',
              heading.className
            )}
          >
            {renderData(
              heading.title,
              heading.title.type === 'COMPONENT'
                ? data.heading.title
                : heading.title
            )}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          {heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames('cvc-w-footer2', style.containerClassName, {
        'cursor-pointer': data.events,
      })}
      onClick={onclickHandler}
    >
      <div
        className={classNames(
          'd-flex justify-content-between',
          heading ? heading.containerClassName : ''
        )}
      >
        {heading && renderHeading()}
        <div className={classNames('d-flex align-items-center')}>
          <ul
            className={classNames('nav tab-nav')}
            id="pills-tab"
            role="tablist"
          >
            {renderTabs()}
          </ul>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.object,
}

export default Footer
