import React from 'react'
import PropTypes from 'prop-types'

export const showLoginDialog = (schema) => {
  $('#auth-dialog').modal('show')
};

const LoginDialog = ({ schema, data }) => {
  return (
    <div className="modal-container">
      <div className="modal auth-modal" id="auth-dialog" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title text-center text-white" id="auth-dialog-title">You have limited access without login. Please login to get full access.</span>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <a
                className="cvc-btn-primary"
                href={'/login?redirect=' + window.location.href}
                onClick={(e) => {
                  $('body').removeClass('modal-open')
                  $('.modal-backdrop').remove()
                }}
              >Login</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginDialog.propTypes = {
  schema: PropTypes.shape({
    className: PropTypes.string
  }),
  data: PropTypes.shape({
    text: PropTypes.string.isRequired
  })
}

export default LoginDialog
