import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'
import { renderData } from 'core/helpers/utils'
import Figure from '../components/Figure'

const Figures = ({ data, schema, options }) => {
  const { header, style = {} } = schema
  return (
    <div className={classNames('cvc-w', 'cvc-w-figures', style.className)}>
      {header && (
          <div className={classNames('heading-container', header.containerClassName)}>
            <div className={classNames('d-inline-block align-middle', header.className)}>
              {renderData(header, header.title.type === 'COMPONENT' ? data.header : header.title)}
            </div>
            <div className="clearfix ml-auto float-right">
              {
                header.actions && (
                  <React.Fragment>
                    {renderData(header.actions, data.actions, options)}
                  </React.Fragment>
                )
              }
            </div>
          </div>
        )
      }
      <div className={classNames("figures-container", style.containerClassName)}>
        {data.map((d, index) => {
          return (
              <React.Fragment key={shortid.generate()}>
                <Figure data={d} schema={schema} />
                {
                  (index !== data.length - 1  && style.divider) && <div style={style.divider.styles}></div>
                }
              </React.Fragment>
            )
          }
         )}
      </div>
    </div>
  )
}

Figures.propTypes = {
  schema: PropTypes.shape({
    header: PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string
    }),
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.array.isRequired
}

export default Figures
