import DateRangeField from './DateRangeField'
import Autocomplete from './Autocomplete'
import ApiDropdown from './ApiDropdown'
import ApiAutocomplete from './ApiAutocomplete'
import CustomSchemaField from './CustomSchemaField'

const fields = {
  'dateRange': DateRangeField,
  'Autocomplete': Autocomplete,
  'ApiDropdown': ApiDropdown,
  'ApiAutocomplete': ApiAutocomplete,
  'SchemaField': CustomSchemaField
}

export default fields
