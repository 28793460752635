import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import shortid from 'shortid'
import classNames from 'classnames'

import { renderData } from '../helpers/utils'

const ProgressBar = ({ schema, data }) => {
  const defaultData = {
    value: 3,
    label: '',
    innerlabel: '',
    title: '',
    barSize: 20
  }

  const { showValue, barSize, minimumValue, className, containerClassName, vertical } = schema
  const { title, label, primaryValue, bars, innerLabel } = data

  const tooltip = data.tooltip || schema.tooltip
  const titleSchema = schema.title
  const labelSchema = schema.label
  const innerLabelSchema = schema.innerLabel
  const defaultMinimumValue = minimumValue != null ? minimumValue : defaultData.value
  const titleToRender = title || defaultData.title // render at top
  const labelToRender = label || defaultData.label // render at bottom
  const innerLabelToRender = innerLabel || defaultData.innerLabel // render inside progressbar

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  if (vertical) {
    const { labels } = schema

    return (
      <div className={classNames("cvc-c-progress h-100 position-relative vertical", className)}>
        { bars.map((bar) => {
            const style = {
              height: `${bar.value > defaultMinimumValue ? bar.value : defaultMinimumValue}%`,
              width: `${barSize || '100%'}`,
              backgroundColor: `${bar.background}`
            }

            return (
              <div className="progress-bar" style={style} key={shortid.generate()} {...bar.tooltip}>
                {
                  bar.labels && (
                    <div
                      className="d-flex justify-content-between align-items-center px-3"
                      style={{...labels}}
                    >
                      {
                        bar.labels && bar.labels.map((barLabel) => {
                          return (<span key={shortid.generate()}>{barLabel}</span>)
                        })
                      }
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div title={tooltip}>
      { title && (
        <div className="text-center">
          {titleSchema ? renderData(titleSchema, titleToRender) : titleToRender}
        </div>
      )}
      <div className={classNames(containerClassName ? containerClassName : 'd-flex flex-row align-items-center')}>
        <div className={classNames("cvc-c-progress w-100 position-relative", className)}>
          { bars.map((bar) => {
            const style = {
              width: `${bar.value > defaultMinimumValue ? bar.value : defaultMinimumValue}%`,
              height: `${barSize || defaultData.barSize}px`,
              backgroundColor: `${bar.background}`
            }

            return <div className="progress-bar" style={style} key={shortid.generate()} />
          })
        }
        {innerLabel && (
          <div className="inner-label">
            {innerLabelSchema ? renderData(innerLabelSchema, innerLabelToRender) : innerLabelToRender}
          </div>
        )}
        </div>
        { (showValue && primaryValue !== undefined && primaryValue !== null) ? (
            <span className="ml-2 d-inline-block progress-value">
              {primaryValue}%
            </span>
          ) : (
            <span></span>
          )
        }
      </div>
      { label && (
        <div className="mt-1 text-gray">
          {labelSchema ? renderData(labelSchema, labelToRender) : labelToRender}
        </div>
      )}
    </div>
  )
}

ProgressBar.propTypes = {
  schema: PropTypes.shape({
    showValue: PropTypes.bool,
    barSize: PropTypes.number,
    tooltip: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    primaryValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bars: PropTypes.array,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltip: PropTypes.string
  }).isRequired
}

export default ProgressBar
