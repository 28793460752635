import React, { useState } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import bindAll from 'lodash.bindall'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames'
import { success, error } from 'react-notification-system-redux'
import { useDispatch } from 'react-redux'
import { handleAction } from 'core/redux/actions/appActionCreators'
import { renderData } from '../helpers/utils'

const AnimatedTile = (props) => {
  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(null)

  const { schema, data, options } = props
  const { heading, style = {} } = schema
  const { tile = {} } = style
  const notificationOptions = {
    title: '',
    message: '',
    position: 'tc',
    autoDismiss: 3,
  }

  const handleMouseEnter = (index) => {
    setIsHover(true)
    setHoverIndex(index)
  }
  const handleMouseLeave = (index) => {
    setIsHover(false)
    setHoverIndex(null)
    ReactTooltip.hide()
  }

  function renderHeading() {
    return (
      <div
        role="presentation"
        className={classNames('heading-container', heading.containerClassName)}
        {...heading.tooltip}
      >
        {heading && heading.title && (
          <div
            className={classNames(
              'd-inline-block align-middle',
              heading.className
            )}
          >
            {renderData(
              heading.title,
              heading.title.type === 'COMPONENT'
                ? data.heading.title
                : heading.title
            )}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          {heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  function onclickHandler(e, itemData) {
    const { events = {} } = schema
    const { onClick: eventSchema } = events

    if (!eventSchema) return

    const eventsData = itemData.events
    const action = { schema: eventSchema, data: eventsData[eventSchema.id] }
    dispatch(
      handleAction(null, action, (response) => {
        const { notifications } = eventSchema

        // if no notification key passed from schema
        if (Object.keys(notifications).length === 0) {
          return false
        }

        if (response.status === 'success') {
          dispatch(
            success({
              ...notificationOptions,
              title: notifications.success.title,
            })
          )
        } else {
          dispatch(
            error({
              ...notificationOptions,
              title: notifications.error.title,
              message: response.error,
            })
          )
        }
      })
    )
  }

  function renderTiles() {
    const { placeholder, style = {}, items = [] } = schema
    const { tiles = [] } = data

    const { tile: tileSchemaStyle = {}, hoverTile: hoverTileStyle = {}, container = {} } = style

    if (tiles.length <= 0) {
      return (
        <div className="p-3 text-gray text-center">
          {placeholder || 'no items found.'}
        </div>
      )
    }

    return (
      <ul className={classNames('tiles-container', tile.containerClassName)} style={{...container.styles}}>
        {tiles.map((tile, index) => {
          const tileDataStyle =
            tile.style && tile.style.tile ? tile.style.tile : {}
          const hoverTileDataStyle =
            tile.style && tile.style.hoverTile ? tile.style.hoverTile : {}

          return (
            <div
              className={classNames(
                'tile',
                tileDataStyle.className || tileSchemaStyle.className,
                {
                  'cursor-pointer': tile.events,
                }
              )}
              style={{ ...tileSchemaStyle.styles, ...tileDataStyle.styles }}
              onClick={(e) => onclickHandler(e, tile)}
              onMouseEnter={() => tile.hoverItems && handleMouseEnter(index)}
              onMouseLeave={() => tile.hoverItems && handleMouseLeave(index)}
              key={index}
            >
              {tile.items && tile.items.map((item) => {
                const tileItemSchema = schema.items[item.type]

                if (!tileItemSchema) throw `${item.type} schema missing in items or hoverItems`
                const itemStyle = {
                  ...(tileItemSchema.style && tileItemSchema.style.styles
                    ? tileItemSchema.style.styles
                    : {}),
                  ...item.styles,
                }

                return (
                  <div
                    className={classNames('tile-inner-item')}
                    style={itemStyle}
                    key={shortid.generate()}
                  >
                    {renderData(tileItemSchema, item.data)}
                  </div>
                )
              })}
              <div
                className={classNames(
                  'hover-tile',
                  hoverTileDataStyle.className || hoverTileStyle.className,
                  (isHover && hoverIndex == index) ? 'default-hover' : 'default',
                  {
                    'd-none':
                      !schema.hoverItems ||
                      !tile.hoverItems ||
                      tile.hoverItems.length <= 0,
                  }
                )}
                style={{
                  ...hoverTileStyle.styles,
                  ...hoverTileDataStyle.styles,
                }}
              >
                {tile.hoverItems &&
                  schema.hoverItems &&
                  tile.hoverItems.map((hoverItem) => {
                    const tileItemSchema = schema.hoverItems[hoverItem.type]
                    const itemStyle = {
                      ...(tileItemSchema.style && tileItemSchema.style.styles
                        ? tileItemSchema.style.styles
                        : {}),
                      ...hoverItem.styles,
                    }

                    return (
                      <div
                        className="hover-tile-inner-item"
                        style={itemStyle}
                        key={shortid.generate()}
                      >
                        {renderData(tileItemSchema, hoverItem.data)}
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
      </ul>
    )
  }

  return (
    <div
      className={classNames(
        'cvc-w-animated-tile',
        style.containerClassName
      )}
      style={style.styles}
    >
      {heading && renderHeading()}
      {schema.actions && (
        <div className="more-option">
          {renderData(schema.actions, data.actions)}
        </div>
      )}
      {renderTiles()}
    </div>
  )
}

export default AnimatedTile
