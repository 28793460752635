import React from 'react'

import Loader from 'libs/components/Loader'

const FullScreenLoader = () => {
  return (
    <div className="fullscreen-loader-wrapper">
      <Loader className="fullscreen-overlay-loader loader" />
    </div>
  )
}

export default FullScreenLoader
