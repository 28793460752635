import tinymce from 'tinymce'
import 'tinymce/skins/lightgray/skin.min.css'
import 'tinymce/themes/modern/theme'
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import "tinymce/plugins/fullpage"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/autolink"
import "tinymce/plugins/paste"
import "tinymce/plugins/directionality"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/visualchars"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/image"
import "tinymce/plugins/link"
import "tinymce/plugins/media"
import "tinymce/plugins/template"
import "tinymce/plugins/table"
import "tinymce/plugins/codesample"
import "tinymce/plugins/charmap"
import "tinymce/plugins/pagebreak"
import "tinymce/plugins/hr"
import "tinymce/plugins/nonbreaking"
import "tinymce/plugins/anchor"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/toc"
import "tinymce/plugins/lists"
import "tinymce/plugins/advlist"
import "tinymce/plugins/textcolor"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/imagetools"
import "tinymce/plugins/contextmenu"
import "tinymce/plugins/colorpicker"
import "tinymce/plugins/textpattern"
import "tinymce/plugins/help"
import "tinymce/plugins/code"
import "tinymce/plugins/autoresize"
import 'plugins/tinymce/relink/plugin'
import 'plugins/tinymce/placeholder/plugin'
import 'plugins/tinymce/mention/plugin'

export default tinymce