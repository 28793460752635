/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import { cloneDeepWith, get, isEqual } from 'lodash/fp'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { populateJSON } from 'libs/utils'
import { default as JsonSchemaForm } from 'react-jsonschema-form'
import { handleAction } from 'core/redux/actions/appActionCreators'
import Heading from 'core/components/Heading'
import { success, error } from 'react-notification-system-redux'
import shortid from 'shortid'
/* eslint-disable import/no-unresolved */
import * as formWidgets from '../components/form/widgets'
import formFields from '../components/form/fields'
import { renderData } from '../helpers/utils'

class StepForm2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: props.data.formData,
      isDataValid: false,
      hasDataRetained: true,
      defaultSchema: {
        liveValidate: false,
        showErrorList: false
      },
      initialData: null
    }
    this.formRef = null

    bindAll(
      this,
      'handleOnChange',
      'transformErrors',
      'checkValidation'
    )
  }

  get activeStepIndex() {
    const { schema } = this.props
    return schema.activeStepIndex
  }

  getChildContext() {
    const { formData } = this.state
    return { formData }
  }

  componentDidMount() {
    if (this.formRef && this.formRef.state) {
      const { formData } = this.formRef.state
      this.setState({
        initialData: formData,
        formData
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.data.formData, this.props.data.formData)) {
      this.state.formData = this.props.data.formData
      this.setState({
        refresh: true
      })
    }
  }

  handleOnChange({ formData, errors }) {
    const { schema, data = {} } = this.props
    const { initialData } = this.state
    const filteredErrors = errors.filter((error) => {
      const errorList = ['minItems']
      return !errorList.includes(error.name)
    })

    if (initialData) {
      this.setState({
        formData,
        isDataValid: filteredErrors.length === 0,
        hasDataRetained: JSON.stringify(formData) === JSON.stringify(initialData)
      })
    }
  }

  transformErrors(errors) {
    const ignoredErrors = ['should match exactly one schema in oneOf']
    const transformedErrors = []
    errors.forEach((error) => {
      if (error.name === 'required') {
        error.message = 'is a required field'
      }
      if (!ignoredErrors.includes(error.message) && error.message !== 'should be equal to one of the allowed values' && error.name !== 'enum') {
        transformedErrors.push(error)
      }
    })
    return transformedErrors
  }

  checkValidation(validationScheme) {
    const validationStatus = {
      default: !this.state.isDataValid,
      refurbished: !(!this.state.hasDataRetained && this.state.isDataValid),
      retained: !this.state.hasDataRetained,
      none: false // Always enabled
    }
    // If validation scheme is invalid, let the button be active
    return validationStatus[validationScheme] || false
  }

  onFieldChange = (name, value, schema) => {
    // copies value from one field to other on change
    if (schema && schema.copyTo) {
      const keys = schema.copyTo.split('.')
      // simplest solution for now.
      switch (keys.length) {
        case 1:
          this.state.formData[keys[0]] = value
          break;
        case 2:
          this.state.formData[keys[0]][keys[1]] = value
          break;
      }
    }
  }

  markLabelAsFalse = (uiSchema) => {
    return cloneDeepWith((value, key) => {
      if (get('ui:widget', value) || get('ui:field', value)) {
        if (!get('ui:options', value)) {
          return {
            ...value,
            'ui:options': {
              label: false
            }
          }
        }
      }
      // mark default labels from uiSchema as false
      if (key === 'ui:options' && value.label !== true) {
        return { ...value, label: false }
      }
    }, uiSchema);
  }

  renderHeading() {
    const { heading } = this.props.schema
    const { data, options } = this.props

    if (!heading) {
      return null;
    }

    return (
      <div role="presentation" className={classNames('heading-container', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  handleStepClick = (e, step, stepIndex) => {
    if (this.activeStepIndex == stepIndex) return

  }

  renderSteps() {
    const { schema } = this.props
    const { steps = [] } = schema

    return steps.map((step, index) => {
      return (
        <React.Fragment key={shortid.generate()}>
          <div
            className={classNames("form-step", {
              'active': index <= this.activeStepIndex
            })}
            onClick={(e) => this.handleStepClick(e, step, index)}
          >{renderData(step.action)}</div>
          { (index != steps.length - 1) && <div className="form-step-separator"></div> }
        </React.Fragment>
      )
    })
  }

  renderActiveStep = () => {
    const { steps = [] } = this.props.schema
    const currentStep = steps[this.activeStepIndex]

    return (
      <React.Fragment key={shortid.generate()}>
        <div className="step-form-title">{currentStep.title}</div>
        <div className="step-form-subtitle">{currentStep.subtitle}</div>
      </React.Fragment>
    )
  }

  renderEndMessage = () => {
    const { endMessage } = this.props.schema
    return (
      <div className="end-msg-container d-flex justify-content-center align-items-center">
        <div className="end-msg d-flex justify-content-center flex-column align-items-center">
          <div className="end-msg-icon"><i className={endMessage.icon}></i></div>
          <div className="end-msg-title">{endMessage.title}</div>
          <div className="end-msg-description">{endMessage.description}</div>
        </div>
      </div>
    )
  }

  render() {
    const {
      uiSchema,
      jsonSchema,
      actions,
      liveValidate,
      style = {},
      showErrorList,
      useFormContextData,
      heading,
      endMessage
    } = this.props.schema
    const { defaultSchema, formData, isDataValid } = this.state

    const { data, options } = this.props
    let actionData = {
      ...data.actions,
      apiPayload: data.jsonTemplate ? populateJSON(data.template, formData) : formData,
      disabled: !isDataValid,
      checkActionValidation: this.checkValidation
    }
    // TODO: ApiDropdown.jsx cleanup to remove hardcoded document context
    // Once that is done we can remove useFormContextData
    const formContextData = useFormContextData ? formData : {}
    const formContext = {
      onFieldChange: this.onFieldChange,
      ...formContextData
    };

    return (
      <div className={classNames('cvc-w-step-form', style ? style.containerClassName : '')}>
        {heading && <Heading
          schema={heading}
          data={data}
          options={options}
        />}
        <div className="form-steps-container">
          {this.renderSteps()}
        </div>
        <div className="active-step-container">
          {endMessage ? this.renderEndMessage() : this.renderActiveStep()}
        </div>
        {(!endMessage && jsonSchema) && <JsonSchemaForm
          id={this.props.id}
          className={style.className}
          ref={(instance) => this.formRef = instance}
          fields={formFields}
          uiSchema={this.markLabelAsFalse(uiSchema)}
          schema={jsonSchema}
          formData={formData}
          formContext={formContext}
          widgets={formWidgets}
          liveValidate={liveValidate || defaultSchema.liveValidate}
          showErrorList={showErrorList || defaultSchema.showErrorList}
          onError={(errors) => { console.info(errors) }}
          onChange={this.handleOnChange}
          transformErrors={this.transformErrors}
        >
          {actions
            ? (
            <React.Fragment>
              {renderData(actions, actionData, options)}
            </React.Fragment>
            )
            : <div />
          }
        </JsonSchemaForm>}
      </div>
    )
  }
}

StepForm2.propTypes = {
  schema: PropTypes.shape({
    uiSchema: PropTypes.object,
    jsonSchema: PropTypes.object,
    fields: PropTypes.object,
    liveValidate: PropTypes.bool,
    showErrorList: PropTypes.bool,
    actions: PropTypes.object
  }).isRequired,
  data: PropTypes.object
}

StepForm2.defaultProps = {
  data: {}
}

StepForm2.childContextTypes = {
  formData: PropTypes.object,
}

export default StepForm2
