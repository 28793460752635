import React from 'react'
import { Provider } from 'react-redux'
import Loader from 'libs/components/Loader'
import configureStore from './redux/store/appStore'
import PageContainer from './redux/containers/PageContainer'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
// railsContext provides contextual information especially useful for server rendering, such as
// knowing the locale. See the React on Rails documentation for more info on the railsContext
const App = (props) => {
  const store = configureStore(props)
  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <PageContainer/>
      </PersistGate>
    </Provider>
  )
}

export default App
