import React from 'react'
import shortid from 'shortid'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { renderData } from '../helpers/utils'

/*
  Renders an untitled list of text. Usage of render data ensures that it can render
  another component/formatted data.

  To render a component, the schema should have the property like this:

  json.property do
    json.type 'COMPONENT'
    json.component do
      json.name 'ProgressBar'
      json.schema do ....

  To render formatted data, the schema should have the property like this:

  json.property do
    json.dataType 'CURRENCY'
  end
*/


const TextList = (props) => {
  const { schema, data } = props
  const { style = {} } = schema
  return (
    <div className={classNames('cvc-c-text-list', style.className)}>
      {
        data.map(listItem => (
          <div key={shortid.generate()} className="list-item">
            <span className={classNames('text-icon', style.icon || listItem.icon)} />
            <p
              className="text"
              dangerouslySetInnerHTML={{__html: renderData(schema.property, listItem.text)}}
            />
          </div>
        ))
      }
    </div>
  )
}

TextList.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.array.isRequired
}
export default TextList
