import React from "react"
import classNames from 'classnames'
import { DragHandle } from "core/components/table/DragHandle";

export const StaticTableRow = ({ row }) => {
  return (
    <tr {...row.getRowProps()} className={classNames("tr dragable")}>
      {row.cells.map((cell, i) => {
        const rowData = row.original
        const cellStyle = rowData.style && rowData.style[cell.column.id] ? rowData.style[cell.column.id] : {}

        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={classNames("td", cellStyle.className)}
              style={cellStyle.styles}
            >
              <DragHandle isDragging />
              <span>{cell.render("Cell")}</span>
            </td>
          )
        }
        return (
          <td
            {...cell.getCellProps()}
            className={classNames("td", cellStyle.className)}
            style={cellStyle.styles}
          >
            {cell.render("Cell")}
          </td>
        )
      })}
    </tr>
  )
}