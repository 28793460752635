/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import bindAll from 'lodash.bindall'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import requestsManager from 'libs/api/requestsManager'
import { formatError } from 'libs/utils'
import { success, error } from 'react-notification-system-redux'
import ReactTooltip from 'react-tooltip'

import Icon from 'core/components/Icon'
import Error from 'core/components/Error'
import { handleAction, markWidgetsDirty } from 'core/redux/actions/appActionCreators'
import { FilterPills } from 'core/common/filters'
import ErrorMap from '../helpers/errors'
import {
  renderData,
  dragDropDisabler,
  getWidget,
  sortTableRows
} from '../helpers/utils'
import InlineEdit from 'core/widgets/InlineEdit'
import PureLayout from 'core/layouts/PureLayout'
import TableRowContainer from '../redux/containers/TableRowContainer'

class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultSchema: {
        heading: null,
        placeholder: 'No data found.',
        enableDragDrop: false,
        style: {
          className: '',
          row: {
            alternateClassName: '',
            className: ''
          },
          column: []
        },
        show: []
      },
      notificationOptions: {
        title: '',
        message: '',
        position: 'tc',
        autoDismiss: 3
      },
      colIds: [],
      popover: false,
      expanded: null,
      collapsedRows: {}, // used in expand mode when data is preloaded
      expandedRows: {} // used in collapsed mode when data is preloaded
    }
    bindAll(
      this,
      'getColWidth',
      'printTableHeader',
      'printTableRows',
      'sort',
      'handleRowClick',
      'onSortEnd',
      'tableTruncationToggler',
      'printTableSuperHeader',
      'handleHeaderClick'
    )
  }

  // persist this data on refresh
  static getPersistedProps(prevProps = {}) {
    const { viewAll } = prevProps
    return { viewAll, stamp: Math.random() }
  }

  static inflateData({ data = {}, widgetGroupId, widgetId, appState }) {
    const { widgetData = {} } = data
    const storedWidget = getWidget({ widgetGroupId, widgetId, appState })
    const storedSortOrder = storedWidget && storedWidget.data && storedWidget.data.widgetData.sortOrder
    const storedSortBy = storedWidget && storedWidget.data && storedWidget.data.widgetData.sortBy
    const sortOrder = widgetData.sortOrder || storedSortOrder
    const sortBy = widgetData.sortBy || storedSortBy

    const deletedTableRows = []
    if (storedWidget && storedWidget.data && storedWidget.data.widgetData) {
      const prevRows = storedWidget.data.widgetData.table
      prevRows.forEach((row) => {
        if (row.deleted) {
          deletedTableRows.push(row.id)
        }
      })
    }

    const isSortRequired = (sortBy && sortOrder)
    const isDeletedRowsPresent = deletedTableRows.length > 0
    if (isSortRequired || isDeletedRowsPresent) {
      let rows = widgetData.table || []

      // preprocess rows to handle deleted rows
      if (isDeletedRowsPresent) {
        rows = rows.map((row) => {
          if (deletedTableRows.includes(row.id)) {
            return {
              ...row,
              deleted: true
            }
          }

          return row
        })
      }

      // preprocess rows to handle sortBy and sortOrder
      if (storedWidget && storedWidget.schema && storedWidget.schema.properties && isSortRequired) {
        const properties = storedWidget.schema.properties
        const column = properties.find((property) => property.id === sortBy)

        if (!column) {
          throw `Invalid sort: missing column with id '${sortBy}'`
        }

        rows = sortTableRows(rows, column, sortOrder)
      }

      return {
        ...data,
        widgetData: {
          ...data.widgetData,
          sortOrder,
          sortBy,
          table: rows
        }
      }
    }

    return data;
  }

  // This defines if the expanded table row is rendered using the preloaded schema
  // provided through the table row event data
  get isRowPreloaded() {
    const { schema } = this.props
    return schema.events && schema.events.onExpand && !schema.events.onExpand.schemaUrl
  }

  // This defines if the table rows can be expanded or collapsed
  get allowRowExpansion() {
    const { schema } = this.props
    return schema.events && schema.events.onExpand && schema.events.onExpand.id
  }

  // schemaUrls is required for container based row expansion
  get rowSchemaUrl() {
    const { schema } = this.props
    return schema.events && schema.events.onExpand && schema.events.onExpand.schemaUrl
  }
  // If expand is true all table rows will be shows in expanded mode.
  // Default is false.
  get isModeExpand() {
    const { schema } = this.props;
    return schema.expand
  }

  componentDidMount() {
    const { schema, data, isCollapsed } = this.props
    // check for props validations
    const { show, properties, heading } = schema
    const { defaultSchema } = this.state
    const colnToShow = show || defaultSchema.show
    this.setState({ colIds: (colnToShow.length > 0 ? colnToShow : properties.map(property => property.id)) })
    this.checkTableSchema()

    // only set the collapsible state when rendering first time.
    if (heading && heading.collapsible && (typeof isCollapsed === 'undefined')) {
      const defaultState = typeof heading.collapsed === 'boolean' ? heading.collapsed : true
      this.setCollapsedStatus(defaultState)
    }
    if (schema.expand) {
      if (this.rowSchemaUrl) {
        this.setState({
          error: '`events.onExpand.schemaUrl` can not be used when `expand` is `true`.'
        })
      }

      if (!this.allowRowExpansion) {
        this.setState({ error: '`events.onExpand` is required when `expand` is `true`.' })
      }
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps
    if (Array.isArray(data)) {
      return { error: ErrorMap.multiDataTableError }
    }

    return null
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { schema, widgetGroupId, widgetId, dataUrl, data, fetchWidgetData, fetchWidgetDataSuccess } = this.props
    const url = data.table[oldIndex].sortableUrl
    const tableData = arrayMove(data.table, oldIndex, newIndex)
    const widgetData = { widgetData: { ...data, table: tableData }}
    const widget = {
      dataUrl,
      id: widgetId
    }
    fetchWidgetDataSuccess(widgetGroupId, widgetId, widgetData)
    if (oldIndex !== newIndex) {
      const prevObj = newIndex !== 0 ? tableData[newIndex - 1] : {}
      const nextObj = newIndex !== (tableData.length - 1) ? tableData[newIndex + 1] : {}
      const apiData = {
        prevObjId: prevObj.id,
        prevObjPosition: prevObj.position,
        nextObjId: nextObj.id,
        nextObjPosition: nextObj.position
      }
      requestsManager.updateEntity(url, apiData)
        .then(() => {
          fetchWidgetData({ widgetGroupId, widget, force: true })
          this.props.dispatch(success({
            ...this.state.notificationOptions,
            title: schema.dragdrop.notifications ? schema.dragdrop.notifications.success.title : 'Order Changed.'
          }))
        })
        .catch((errorResponse) => {
          const { data } = errorResponse.response
          const errorText = formatError(data)

          this.props.dispatch(error({
            ...this.state.notificationOptions,
            title: schema.dragdrop.notifications ? schema.dragdrop.notifications.error.title : 'Order Change Failed.',
            message: errorText
          }))
          fetchWidgetDataSuccess(widgetGroupId, widgetId, widgetData)
        })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.state.expanded && nextState.expanded)) {
      // close accordion when either widget data updated
      // or parent schema/data is updated
      if (
        (nextProps.loadingData && !this.props.loadingData) ||
        (nextProps.stamp !== this.props.stamp)
      ) {
        this.setState({ expanded: null })
      };
      return false
    }
    return !window.isPopoverVisible
  }

  getColWidth(columnId) {
    const { schema } = this.props
    const { defaultSchema, colIds } = this.state
    const tableColns = (schema.style && schema.style.column) ? schema.style.column : defaultSchema.style.column
    const colStyle = tableColns.find(styleObj => styleObj.id === columnId)
    return colStyle ? colStyle.width : (100 / colIds.length)
  }

  tableTruncationToggler() {
    const { widgetId, widgetGroupId, updateWidget, viewAll } = this.props
    updateWidget(widgetGroupId, widgetId, { viewAll: !viewAll })
  }

  checkTableSchema() {
    const { schema } = this.props
    const { defaultSchema } = this.state
    const tableColns = (schema.style && schema.style.column) ? schema.style.column : defaultSchema.style.column
    const columnWidthSpecified = tableColns.filter(column => column.width !== undefined)
    if (columnWidthSpecified.length > 0 && columnWidthSpecified.length !== tableColns.length) {
      this.setState({ error: ErrorMap.tableSchemaError })
    }
  }

  sort(column) {
    if (this.state.expanded) {
      this.setState({
        expanded: null
      })
    }
    const { widgetGroupId, widgetId, data = {}, fetchWidgetDataSuccess } = this.props
    const { sortBy, sortOrder } = data
    let updatedSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    let updatedSortBy = column.id

    if (!column) {
      throw `Invalid sort: missing column with id '${widgetData.sortBy}'`
    }

    const rows = sortTableRows(data.table, column, updatedSortOrder)

    const updatedWidgetData = {
      widgetData: {
        ...data,
        table: rows,
        sortBy: updatedSortBy,
        sortOrder: updatedSortOrder
      }
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, updatedWidgetData)
    this.props.dispatch(markWidgetsDirty(column.markDirty))
  }

  printTableSuperHeader() {
    const { schema } = this.props
    const { superHeader } = schema
    return (
      <div className="tr table-header page-break-inside-avoid">
        {superHeader.map((col) => {
          const { width } = col
          const colClassName = classNames('th', col.className)
          return (
            <div
              key={shortid.generate()}
              className={colClassName}
              style={{ width: `${width}%` }}
            >
              <span>{col.title}</span>
            </div>
          )
        })}
      </div>
    )
  }

  printTableHeader() {
    const { schema, data = {} } = this.props
    const { sortBy, sortOrder } = data
    const { defaultSchema, colIds } = this.state
    const tableColns = (schema.style && schema.style.column) ? schema.style.column : defaultSchema.style.column
    const columnClass = schema.style.columnClassName
    return (
      <div className="tr table-header">
        {schema.properties.map((column, index) => {
          const colnWidth = this.getColWidth(column.id)
          const colProps = tableColns[index] || {}
          const colBackColor = colProps.backgroundColor && {backgroundColor: colProps.backgroundColor}
          // If this is a stad alone widget with no header, add padding to th
          const needThTopPadding = !schema.heading
          const colnClassName = classNames('th', columnClass, colProps.headerClassName, {'d-none': colProps.header === false, 'pt-3': needThTopPadding, 'd-flex': colProps.header !== false })
          if (colIds.indexOf(column.id) > -1) {
            const isSortedByColn = sortBy === column.id
            return (
              <div
                key={shortid.generate()}
                className={colnClassName}
                style={{ width: `${colnWidth}%`, ...colBackColor}}
                title={column.hoverText}
              >
                <span>{column.title}</span>
                {column.sortable && (
                  <span className="sort" role="presentation" onClick={() => { this.sort(column) }}>
                    <Icon schema={{ className: classNames('fa fa-sort-up', { 'active': (sortOrder === 'asc' && isSortedByColn) }) }} />
                    <Icon schema={{ className: classNames('fa fa-sort-down', { 'active': (sortOrder === 'desc' && isSortedByColn) }) }} />
                  </span>
                )}
              </div>
            )
          }
        })
        }
      </div>
    )
  }

  handleRowClick(eventSchema, rowsData = {}) {
    const eventsData = rowsData.events
    const action = { schema: eventSchema, data: eventsData[eventSchema.id] }
    this.props.dispatch(handleAction(null, action, (response) => {
      const { notifications } = eventSchema

      // if no notification key passed from schema
      if (Object.keys(notifications).length === 0) {
        return false
      }

      if (response.status === 'success') {
        this.props.dispatch(success({
          ...this.state.notificationOptions,
          title: notifications.success.title
        }))
      } else {
        this.props.dispatch(error({
          ...this.state.notificationOptions,
          title: notifications.error.title,
          message: response.error
        }))
      }
    }))
  }

  setCollapsedStatus(isCollapsed) {
    const { schema, widgetId, widgetGroupId, updateWidget } = this.props
    const collapsible = schema.heading && schema.heading.collapsible

    if (collapsible) {
      updateWidget(widgetGroupId, widgetId, { isCollapsed })
    }
  }

  handleHeaderClick() {
    const { isCollapsed } = this.props
    this.setCollapsedStatus(!isCollapsed)
  }

  renderCellData = (property, rowData, rowIndex) => {
    const inlineEditProps = property.inlineEdit
    const options = { deleted: rowData.deleted }

    if (inlineEditProps) {
      const schemaUrl = inlineEditProps.schemaUrl || (rowData.popover[property.id] ? rowData.popover[property.id].schemaUrl : null)
      return (
        <InlineEdit
          data={property.inlineEdit && rowData.popover ? rowData.popover[property.id] : {}}
          schemaUrl={schemaUrl}
          onClick={(status) => {
            window.isPopoverVisible = status
          }}
          {...inlineEditProps}
        >
          {renderData(property, rowData[property.id], options)}
        </InlineEdit>
      )
    }

    return renderData(property, rowData[property.id], options)
  }

  renderCell(property, rowData, rowIndex) {
    const { schema } = this.props
    const colnWidth = this.getColWidth(property.id)
    const columnStyles =
      (schema.style && schema.style.column) ?
        schema.style.column :
        defaultSchema.style.column

    const columnStyle =
      columnStyles.length > 0 &&
      columnStyles.find(styleObj => styleObj.id === property.id)

    if (!columnStyle) {
      throw `Column styles missing for property '${property.id}'`
    }
    const columnClassName = columnStyle.className
    const rowDataStyle = rowData.style && rowData.style[property.id] ? rowData.style[property.id] : {}

    const cellClassName = classNames('td', columnClassName, rowDataStyle.className)

    return (
      <div key={shortid.generate()} className={cellClassName} style={{ width: `${colnWidth}%`, ...rowDataStyle.styles }}>
        <div
          className="data-wrapper"
          title={schema.style.className && schema.style.className.includes('table-truncated-data') ? rowData[property.id] : ''}
          style={{backgroundColor: property.fill}}
        >
          {this.renderCellData(property, rowData, rowIndex)}
        </div>
      </div>
    )
  }

  toggleRowContainer = (rowIndex) => {
    $(`.collapse-${rowIndex}`).collapse('toggle')
  }

  handleRowClickAndExpand = (rowData, rowIndex) => {
    const { schema } = this.props

    if (window.isPopoverVisible) return undefined

    const self = this
    if (this.allowRowExpansion && schema.events.onExpand.schemaUrl) {
      // This code defines the container based expansion where row
      // schema is fetched from the server everytime row is expanded.
      const eventSchema = schema.events.onExpand
      const data = rowData.events ? rowData.events[eventSchema.id] : {}

      if (self.state.expanded && self.state.expanded.rowIndex === rowIndex) {
        // collapse expanded row
        self.toggleRowContainer(self.state.expanded.rowIndex)
        setTimeout(() => {
          self.setState({ expanded: null })
        }, 100)
      } else if (self.state.expanded) {
        // collapse previously expanded row
        self.toggleRowContainer(self.state.expanded.rowIndex)
        self.setState({
          expanded: null
        }, () => {
          setTimeout(() => {
            self.setState({
              expanded: {
                rowIndex,
                ...eventSchema,
                data
              }
            })
            // expand new row
            self.toggleRowContainer(rowIndex)
          }, 0)
        })
      } else {
        self.setState({
          expanded: {
            rowIndex,
            ...eventSchema,
            data
          }
        })
        setTimeout(() => {
          // expand new row
          self.toggleRowContainer(rowIndex)
        }, 0)
      }

      return
    } else if (this.allowRowExpansion) {
      // This code defines the container based expansion where row
      // schema is provided through the data.
      // Any number of rows can be expanded/collapsed
      if (this.isModeExpand) {
        self.setState({
          collapsedRows: {
            ...self.state.collapsedRows,
            [rowIndex.toString()]: !self.state.collapsedRows[rowIndex]
          }
        })
      } else {
        self.setState({
          expandedRows: {
            ...self.state.expandedRows,
            [rowIndex.toString()]: !self.state.expandedRows[rowIndex]
          }
        })
      }
      return
    }

    return schema.events ? this.handleRowClick(schema.events.onClick, rowData) : undefined
  }

  renderExpandedRowWithPreloadedData(rowData, rowIndex) {
    if (!this.allowRowExpansion) {
      return null
    }

    const { schema, widgetGroupId, widgetId } = this.props;
    const row = rowData.events ? rowData.events[schema.events.onExpand.id] : {}

    const isRowExpanded = this.isModeExpand ? !this.state.collapsedRows[rowIndex] : this.state.expandedRows[rowIndex]

    if (!isRowExpanded || !row.schema) {
      return null
    }

    return (
      <PureLayout
        {...this.props}
        schema={row.schema}
        parentWidgetGroupId={widgetGroupId}
        parentId={widgetId}
      />
    )
  }

  printTableRows(rowsData) {
    const { schema, widgetId, viewAll, widgetGroupId } = this.props
    const { defaultSchema, colIds, expanded } = this.state

    const tableColns = (schema.style && schema.style.column) ? schema.style.column : defaultSchema.style.column
    const tableRow = (schema.style && schema.style.row) ? schema.style.row : defaultSchema.style.row
    const enableDragDrop = (schema.dragdrop !== undefined) ? true : defaultSchema.enableDragDrop
    const { initialRowDisplay } = schema.style

    const rows = initialRowDisplay && !viewAll ? rowsData.slice(0, initialRowDisplay) : rowsData
    const rowsEl = (
      <div key={shortid.generate()}>
        {rows.map((rowData, rowIndex) => {
          const rowClassName = classNames(
            'tr',
            'page-break-inside-avoid',
            {
              [tableRow.className]: tableRow.className !== '',
              [tableRow.alternateClassName]: rowIndex % 2 === 0,
            },
            {
              link: schema.events !== undefined,
              deleted: rowData.deleted,
              restored: rowData.restored,
              added: rowData.added,
              selected: rowData.selected,
            },
          )

          const eTableRowContainer = (
            <div className={classNames(`collapse-${rowIndex}`, 'row-container collapse')}>
              {
                expanded && expanded.rowIndex === rowIndex && <TableRowContainer
                  row={expanded}
                />
              }
            </div>
          );

          const rowEl = (
            <div key={shortid.generate()}>
              <div
                role="presentation"
                className={classNames(rowClassName, { 'dragable': enableDragDrop })}
                onClick={(e) => {
                  // skip anchor tags
                  if (e.target.tagName == 'A') {
                    return;
                  }
                  this.handleRowClickAndExpand(rowData, rowIndex)
                }}
              >
                {
                  schema.properties.map((property) => {
                    if (colIds.indexOf(property.id) < 0) {
                      return null
                    }

                    return this.renderCell(property, rowData, rowIndex)
                  })
                }
              </div>
              {this.isRowPreloaded && this.renderExpandedRowWithPreloadedData(rowData, rowIndex)}
              {eTableRowContainer}
            </div>
          )

          const SortableItem = SortableElement(() => rowEl)

          return (
            <SortableItem
              key={`item-${rowIndex}`}
              disabled={!enableDragDrop}
              index={rowIndex}
              value={rowsData}
            />
          )
        })
      }
      </div>
    )

    // to prevent rerender when row is expanded avoid rendering sortablecontainer
    if (!expanded) {
      const SortableTableRows = SortableContainer(({ items }) => rowsEl)
      return <SortableTableRows items={rowsData} onSortEnd={this.onSortEnd} shouldCancelStart={dragDropDisabler} pressDelay={200} />
    }

    return rowsEl
  }

  printTableActions(actionData) {
    const { schema, data, viewAll } = this.props
    const { initialRowDisplay } = schema.style
    return (
      <React.Fragment>
        {(initialRowDisplay && data.table.length > initialRowDisplay)
          && (
          <a className="btn p-3">
            <button type="button" className="cvc-btn-secondary x-small" onClick={this.tableTruncationToggler}>
              {viewAll ? 'View Less' : 'View More'}
            </button>
          </a>
          )
        }
        {schema.actions
          && (
            <React.Fragment>
              {renderData(schema.actions, actionData)}
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  renderExportItems(items, itemData) {
    return (
      <React.Fragment>
        {
          items.map((element) => {
            const action = {
              type: 'COMPONENT',
              component: {
                name: 'Action',
                schema: {
                  style: {
                    className: 'd-inline-block text-gray text-btn',
                    iconClassName: 'fa fa-file-export'
                  },
                  type: 'LINK',
                  linkUrl: element.linkUrl,
                  title: element.title
                }
              }
            }
            return (
              <React.Fragment key={shortid.generate()}>
                {renderData(action, itemData[element.id])}
              </React.Fragment>
            )
          })
        }
      </React.Fragment>
    )
  }

  handleOnRemove = (item) => {
    const {
      widgetFilter,
      removeFilterItem
    } = this.props

    removeFilterItem({
      filter: widgetFilter,
      item
    })
  }

  render() {
    const { schema, data, children, isCollapsed, options, hideFilters } = this.props
    const { defaultSchema, error } = this.state
    const { heading, placeholder, superHeader, filters = {} } = schema
    const style = schema.style || {}
    const tableClass = classNames('cvc-w', 'cvc-w-table', style.className || defaultSchema.style.className)
    const tableData = data ? data.table : []
    const exportData = data ? data.exports : null
    const tableActionData = data ? data.actions : []
    const collapsible = heading && heading.collapsible
    if (error) { return <Error error={error} /> }

    const tableRows = tableData ? tableData
      .filter((row) => {
        return !row.hide
      }) : []

    return (
      <div className={tableClass} key={shortid.generate()}>
        {heading
        && (
          <div
            role="presentation"
            className={classNames('heading-container', heading.containerClassName, { 'action': collapsible })}
            onClick={() => this.handleHeaderClick()}
            {...heading.tooltip}
          >
            {collapsible && (
              <div className={classNames(isCollapsed ? 'fa fa-angle-right' : 'fa fa-angle-down', 'mr-2 pr-2')} />
            )}
            <div className={classNames('d-inline-block align-middle', heading.className)}>
              {(heading.title && heading.title.type === 'COMPONENT') ? renderData(heading.title, data && data.heading ? data.heading.title : '') : heading.title}
            </div>
            <div className="clearfix ml-auto float-right">
              { heading.exports && (
                <React.Fragment>
                  { this.renderExportItems(heading.exports, exportData) }
                </React.Fragment>)
              }
            </div>
            <div className="clearfix ml-auto float-right">
              { heading.actions && (
                <React.Fragment>
                  {renderData(heading.actions, tableActionData, options)}
                </React.Fragment>)
              }
            </div>
          </div>
        )
        }
        {!hideFilters && this.props.widgetFilter &&
          <FilterPills
            onRemove={this.handleOnRemove}
            filters={this.props.widgetFilter}
          />
        }
        {!(collapsible && isCollapsed) && (
          <React.Fragment>
            <div className="tcontainer">
              {superHeader
              && (
                <React.Fragment>
                  {this.printTableSuperHeader()}
                </React.Fragment>
              )
              }
              {this.printTableHeader()}
              {tableRows.length === 0 ? (
                  <div
                    className="text-center p-3 text-gray page-break-inside-avoid"
                    dangerouslySetInnerHTML={{
                      __html: schema.placeholder || defaultSchema.placeholder,
                    }}
                  />
              ) : (
                <div className="tbody">
                  {this.printTableRows(tableRows)}
                </div>
              )}
              {children}
            </div>
            {this.printTableActions(tableActionData)}
          </React.Fragment>
        )}
      </div>
    )
  }
}

Table.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]).isRequired,
  schema: PropTypes.shape({
    heading: PropTypes.object,
    superHeader: PropTypes.array,
    placeholder: PropTypes.string,
    properties: PropTypes.array.isRequired,
    show: PropTypes.array,
    style: PropTypes.object,
    dragdrop: PropTypes.object,
    actions: PropTypes.object
  }).isRequired,
  widgetId: PropTypes.string.isRequired,
  widgetGroupId: PropTypes.string.isRequired,
  dataUrl: PropTypes.string.isRequired,
  fetchWidgetDataSuccess: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  fetchWidgetSchemaSuccess: PropTypes.func,
  children: PropTypes.node
}

export default Table
