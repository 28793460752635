import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Notifications from 'react-notification-system-redux'
import ReactTooltip from 'react-tooltip'
import { addAuthInterceptor } from 'libs/api/requestsManager'

import { handleAction } from '../redux/actions/appActionCreators'
import Loader from 'libs/components/Loader'
import WidgetGroupContainer from '../redux/containers/WidgetGroupContainer'
import ModalContainer from '../redux/containers/ModalContainer'
import Error from '../components/Error'
import ErrorMap from '../helpers/errors'
import Header from '../components/Header'
import Header2 from '../components/Header2'
import FullScreenLoader from '../components/FullScreenLoader'
import ConfirmDialog from '../components/ConfirmDialog'
import LoginDialog from '../components/LoginDialog'
import LayoutRenderer from './LayoutRenderer'
import { FilterPills } from 'core/common/filters'

class PageRenderer extends React.Component {
  constructor() {
    super()
    this.state = {
      error: null
    }
    addAuthInterceptor();
  }

  getChildContext() {
    const { page, pageId, pageFilter } = this.props
    return {
      pageId: page.schema.id,
      pageFilter: pageFilter,
    }
  }

  componentDidMount() {
    const { page } = this.props

    if (!page.schema && !page.schemaUrl) {
      this.setState({ error: ErrorMap.schemaError })
    } else {
      this.props.fetchPageSchema(page)
    }

    // to make tinymce link modal work in boostrap modal we need to handle
    // the focusin event like below
    // https://www.tiny.cloud/docs-4x/integrations/bootstrap/#usingtinymceinabootstrapdialog
    $(document).on('focusin', function(e) {
      if ($(e.target).closest('.mce-window').length) {
        e.stopImmediatePropagation();
      }
    });
    $(window).on('beforeunload', function(e){
      if (window.requestInProgress) {
        return 'Are you sure you want to leave?';
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { modal, query, page, handleAction } = this.props

    try {
      // only show the modal if its not visible and dismissed once
      // make sure to have schemaURL encoded with encodeURIComponent
      if (query.modal && !modal.show && !modal.dismissed) {
        const modalData = JSON.parse(query.modal)

        handleAction(
          null,
          {
            schema: {
              type: 'MODAL'
            },
            data: {
              modalSchemaUrl: modalData.schemaUrl,
              modalParams: modalData.schemaParams
            }
          }
        )
      }
    } catch(error) {
      // skip
    }

    if (prevProps.page.loadingSchema && !this.props.page.loadingSchema) {
      const { schema: { defaultActions = []} } = page
      defaultActions.forEach((defaultAction) => {
        handleAction(
          null,
          defaultAction
        )
      })
    }
  }

  handleOnRemove = (item) => {
    const {
      page,
      pageFilter,
      removeFilterItem
    } = this.props

    removeFilterItem({
      filter: pageFilter,
      item
    })
  }

  render() {
    const { error } = this.state
    const {
      page,
      widgetGroupIds,
      notifications,
      lodingModalSchema,
      loadingOverlay,
      pageFilter,
      widgetFilter
    } = this.props

    const { schema, loadingSchema } = page
    if (error) {
      return <Error error={error} />
    }
    if (loadingSchema) {
      return <Loader />
    }
    const { header } = schema

    return (
      <div className={classNames('content-main', schema.className)}>
        {/* Next line shows a full screen loader whenever a modal in the app is loading it's schema */}
        { (lodingModalSchema || loadingOverlay) && <FullScreenLoader /> }
        <React.Fragment>
          {header &&
            (header.type === 'v2' ? <Header2 {...header} /> : <Header {...header} />)
          }
          <FilterPills
            onRemove={this.handleOnRemove}
            filters={{...widgetFilter, ...pageFilter}}
            type='page'
          />
          <div className="px-md-none">
            <LayoutRenderer
              widgetGroups={schema.widgetGroups}
              parentId={schema.id}
            />
          </div>
          <ModalContainer />
          <Notifications
            notifications={notifications}
          />
          <ConfirmDialog />
          <LoginDialog />
          <ReactTooltip
            effect="solid"
            multiline={true}
            className="cvc-tooltip"
            place="bottom"
          />
        </React.Fragment>
      </div>
    )
  }

  componentWillUnMount() {
    // tinymce
    $(document).off('focusin')
    $(window).off('beforeunload')
  }
}

PageRenderer.propTypes = {
  page: PropTypes.object.isRequired,
  fetchPageSchema: PropTypes.func,
  widgetGroupIds: PropTypes.array.isRequired,
  fetchPageSchemaSuccess: PropTypes.func,
  notifications: PropTypes.array,
  lodingModalSchema: PropTypes.bool
}

PageRenderer.defaultProps = {
  notifications: []
}

PageRenderer.childContextTypes = {
  pageId: PropTypes.string,
  pageFilter: PropTypes.object,
}

export default PageRenderer
