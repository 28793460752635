import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { range } from 'lodash/fp'

import { renderData, getPropertySchema } from '../helpers/utils'

class Html extends Component {
  renderHeading() {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div role="presentation" className={classNames('heading-container', heading.containerClassName)}  {...heading.tooltip}>
        {(heading && heading.title) && (
          <div className={classNames('d-inline-block align-middle', heading.className)}>
            {renderData(heading.title, heading.title.type === 'COMPONENT' ? data.heading.title : heading.title)}
          </div>
        )}
        <div className="clearfix ml-auto float-right">
          { heading.actions && (
            <React.Fragment>
              {renderData(heading.actions, data.actions, options)}
            </React.Fragment>)
          }
        </div>
      </div>
    )
  }

  render() {
    const { schema = {}, data = {} } = this.props
    const style = schema.style || {}

    const defaultEmptyTextLines = schema.defaultEmptyTextLines
    let emptyHTML = ''
    if (defaultEmptyTextLines) {
      const emptyLines = range(0, defaultEmptyTextLines)
      emptyHTML = emptyLines.map((index) => '<br/>').join('')
    }

    const htmlToRender = {
      __html: data && data.html ? data.html : emptyHTML
    }

    return (
      <div className={classNames('cvc-w-html', style.containerClassName)}>
        {schema.heading && this.renderHeading()}
        <div className={classNames(style.className)} dangerouslySetInnerHTML={htmlToRender} />
      </div>
    )
  }
}

Html.propTypes = {
  schema: PropTypes.shape({
    style: PropTypes.object
  }),
  data: PropTypes.object
}

export default Html
