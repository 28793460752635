import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { renderData } from '../helpers/utils'

const Header2 = (props) => {
  const { schema = {}, data = {} } = props
  const { left, right, middle } = schema
  const style = schema.style || {}
  const hideLeft = data.hideLeft || schema.hideLeft
  const hideRight = data.hideRight || schema.hideRight
  const hideMiddle = data.hideMiddle || schema.hideMiddle

  return (
    <div className={classNames('cvc-c-header2')} style={style.styles}>
      <div className="d-flex justify-content-between w-100">
        {!hideLeft && <div className="left w-100 d-flex justify-content-start">
          {left && renderData(left, data.left)}
        </div>}
        {!hideMiddle && <div className="middle w-100 d-flex justify-content-center">
          {middle && renderData(middle, data.middle)}
        </div>}
        {!hideRight &&<div className="right w-100 d-flex justify-content-end">
          {right && renderData(right, data.right)}
        </div>}
      </div>
    </div>
  )
}

Header2.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.object
}

export default Header2
