import React from 'react'
import PropTypes from 'prop-types'

import { renderData } from '../../../../helpers/utils'

const RADIAN = Math.PI / 180

const FormattedValueLabel = (props) => {
  const {
    value,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    dataType,
    percent,
    color = '#000',
    fontSize = 10,
    labelProps = {}
  } = props
  const radius = innerRadius + (outerRadius - innerRadius) * 1
  const x = cx + radius * Math.cos(-midAngle * RADIAN) * 1.2
  const y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.2

  return (
    <React.Fragment>
      <text
        x={x}
        y={y}
        fill={color}
        fontSize={fontSize}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        <tspan x={x}>{renderData({ ...labelProps, dataType }, value)}</tspan>
      </text>
    </React.Fragment>
  )
}

FormattedValueLabel.propTypes = {
  value: PropTypes.number,
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
  name: PropTypes.string,
  dataType: PropTypes.string
}

export default FormattedValueLabel