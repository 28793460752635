import React from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import requestsManager from 'libs/api/requestsManager'
import { formatError } from 'libs/utils'
import NotificationComponentHoc from 'libs/components/NotificationComponentHoc'

class ImagePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetchedImageUrl: null,
      uploadedImage: null,
      imageUrl: null,
      actions: null
    }
    bindAll(
      this,
      'onChangeHandler',
      'updateImage',
      'deleteImage',
      'refreshPage'
    )
  }

  componentDidMount() {
    const { imageUrl, actions } = this.props.data

    if (imageUrl) {
      this.setState({ fetchedImageUrl: imageUrl })
    }
    this.setState({ imageUrl: '/images.json', actions })
  }

  onChangeHandler(e) {
    const { uploadedImage } = this.state
    this.setState({
      uploadedImage: e.target.files[0] || uploadedImage
    }, this.updateImage)
    e.target.value = null
  }

  updateImage() {
    const { id } = this.props.data
    const {
      uploadedImage,
      fetchedImageUrl,
      imageUrl,
      actions
    } = this.state
    const requestsManagerFn =
      fetchedImageUrl ?
        requestsManager.updateEntity :
        requestsManager.submitEntity

    const url = fetchedImageUrl ? actions.Edit.url : imageUrl
    const formData = new FormData()

    if (uploadedImage) {
      formData.append('image[image]', uploadedImage)
      formData.append('image[imageable_id]', id)
      formData.append('image[imageable_type]', 'User')

      requestsManagerFn.call({}, url, formData, true)
        .then((res) => {
          this.props.addNotification({
            title: 'Profile Picture uploaded successfully',
            level: 'success'
          })
          this.setState({
            fetchedImageUrl: res.data.imageUrl,
            uploadedImage: null
          }, this.refreshPage)
        })
        .catch((error) => {
          const { data } = error.response
          const errorText = formatError(data) || 'Invalid file format. Valid file formats are - JPEG, GIF, PNG';

          this.props.addNotification({
            title: 'Image upload failed!',
            message: errorText,
            level: 'error'
          })
          this.setState({ uploadedImage: null })
        })
    }
  }

  refreshPage() {
    window.location.reload()
  }

  deleteImage() {
    const { actions } = this.state
    const { url } = actions.Delete
    requestsManager.deleteEntity(url)
      .then(() => {
        this.props.addNotification({
          title: 'Profile Picture deleted successfully',
          level: 'success'
        })
        this.setState({ fetchedImageUrl: null, uploadedImage: null }, this.refreshPage)
      })
      .catch((error) => {
        this.props.addNotification({
          title: 'Image deletion failed!',
          message: error.response.data.errors,
          level: 'error'
        })
      })
  }

  render() {
    const { fetchedImageUrl } = this.state

    return (
      <div className="cvc-c-image-picker">
        <div className="set-user-image">
          {
            fetchedImageUrl &&
              <img src={fetchedImageUrl} className="rounded img-fluid" alt="profile" />
          }
          {
            !fetchedImageUrl &&
              <div className="icon-camera-small" >
                <input type="file" className="custom-file-input" name="profile_pic" title="Upload JPEG/GIF/PNG image of size less than 1 MB" onChange={this.onChangeHandler} />
              </div>
          }
          {
            fetchedImageUrl &&
              <div className="option-dropdown icon-camera-small" >
                <button type="button" className="dropdown-toggle dropdown-regular" id="dropdownMenuButton" data-toggle="dropdown" />
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a rel="nofollow noreferer" className="dropdown-item">
                    <label htmlFor="profile_pic">Change Image</label>
                    <input type="file" className="custom-file-input d-none" id="profile_pic" title="Upload JPEG/GIF/PNG image of size less than 1 MB" onChange={this.onChangeHandler} />
                  </a>
                  <a
                    rel="nofollow noreferer"
                    className="dropdown-item"
                    role="presentation"
                    onClick={this.deleteImage}
                  >Delete Image</a>
                </div>
              </div>
          }
        </div>
      </div>
    )
  }
}

ImagePicker.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    emailId: PropTypes.string,
    imageUrl: PropTypes.string,
    actions: PropTypes.object,
    addNotification: PropTypes.func
  }).isRequired,
  schema: PropTypes.object.isRequired
}
export default NotificationComponentHoc(ImagePicker)