import { connect } from 'react-redux'
import WidgetGroupRenderer from '../../renderers/WidgetGroupRenderer'
import * as actions from '../actions/widgetActionCreators'

const mapStateToProps = (state, ownProps) => {
  const { widgetGroupId, parentId } = ownProps
  const widgetGroups = state.widgetGroup[parentId] || {}
  return { widgetGroup: widgetGroups[widgetGroupId] }
}

export default connect(mapStateToProps, actions)(WidgetGroupRenderer)
