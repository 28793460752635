import React from "react"
import classNames from 'classnames'
import { CSS } from "@dnd-kit/utilities"
import {
  useSortable,
} from "@dnd-kit/sortable"
import { DragHandle } from "core/components/table/DragHandle";

export const DraggableTableRow = ({
  row,
  handleRowClick,
  enableDragDrop,
  enableRowClick,
  onMouseOver = () => {},
  onMouseLeave = () => {},
  children,
  showActions
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.id,
    disabled: !enableDragDrop,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }

  return (
    <tr
      ref={setNodeRef}
      style={style}
      className={classNames("tr", "page-break-inside-avoid position-relative", {
        deleted: row.original.deleted,
        restored: row.original.restored,
        added: row.original.added,
        selected: row.original.selected,
        dragable: enableDragDrop,
        link: enableRowClick,
      })}
      {...row.getRowProps()}
      onClick={(e) => {
        handleRowClick(row)
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {row.cells.map((cell, i) => {
        const rowData = row.original
        const cellStyle = rowData.style && rowData.style[cell.column.id] ? rowData.style[cell.column.id] : {}

        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={classNames("td", { invisible: isDragging }, cellStyle.className)}
              style={cellStyle.styles}
            >
              {enableDragDrop && <DragHandle {...attributes} {...listeners} />}
              <span>{cell.render("Cell")}</span>
            </td>
          )
        }

        return (
          <td
            {...cell.getCellProps()}
            className={classNames("td", { invisible: isDragging }, cellStyle.className)}
            style={cellStyle.styles}
          >
            {cell.render("Cell")}
          </td>
        )
      })}
      <td className="td">
        {showActions && children}
      </td>
    </tr>
  )
}
