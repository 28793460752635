import requestsManager from 'libs/api/requestsManager'
import {
  updateQueryStringParams,
  inflateWidgetGroups,
  inflateData,
  inflateSchema,
  getFilteredAt
} from 'core/helpers/utils'

import {
  FETCHING_WIDGET_SCHEMA,
  FETCH_WIDGET_SCHEMA_SUCCESS,
  FETCHING_WIDGET_DATA,
  FETCH_WIDGET_DATA_SUCCESS,
  RELOAD_WIDGETS,
  SET_WIDGET_FILTERS,
  RESET_WIDGET_FILTERS,
  SET_ENCAPSULATOR_SCHEMA_URL,
  UPDATE_WIDGET,
  DELETE_WIDGET,
  MARK_WIDGET_DIRTY,
  RESET_WIDGET,
} from '../constants/widgetConstants'
import {
  UPDATE_SPREAD
} from '../constants/spreadConstants'
import { setWidgetGroups } from './widgetGroupActionCreators'

export const schemaUrlSetter = (id, schemaUrl) => {
  return {
    type: SET_ENCAPSULATOR_SCHEMA_URL,
    payload: {
      id,
      schemaUrl
    }
  }
}

export const updateSpread = payload => (dispatch) => {
  dispatch({
    type: UPDATE_SPREAD,
    payload
  })
}

export const fetchingWidgetSchema = (widgetGroupId, widgetId, loadingSchema = true, actionPayload) => {
  return {
    type: FETCHING_WIDGET_SCHEMA,
    widgetGroupId,
    widgetId,
    loadingSchema,
    actionPayload,
  }
}

export const fetchWidgetSchemaSuccess = (widgetGroupId, widgetId, widgetSchema, widgetType) => {
  return (dispatch, getState) => {
    const appState = getState()
    const inflatedSchema = inflateSchema({ schema: widgetSchema, widgetGroupId, widgetId, widgetType, appState })

    return dispatch({
      type: FETCH_WIDGET_SCHEMA_SUCCESS,
      widgetGroupId,
      widgetId,
      widgetSchema: inflatedSchema
    })
  }
}

export const setWidgetSchemaAndWidgetGroups = (widgetGroupId, widgetId, schema, widgetType) => {
  return (dispatch, getState) => {
    if (schema.widgetGroups) {
      const appState = getState()
      const widgetGroups = inflateWidgetGroups({
        schema,
        widgetGroupId,
        widgetId,
        widgetType,
        appState
      })
      dispatch(setWidgetGroups(widgetId, widgetGroups, widgetGroupId, widgetType))
    }
    dispatch(fetchWidgetSchemaSuccess(widgetGroupId, widgetId, schema, widgetType))
  }
}

export const setWidgetLoadingOverlay = (widgetGroupId, widgetId, loadingOverlay = false) => {
  return (dispatch) => {
    dispatch(updateWidget(widgetGroupId, widgetId, { loadingOverlay }))
  }
}

export const fetchWidgetSchema = ({ widgetGroupId, widget, pageFilter, widgetFilter, force = false, spreads }) => {
  return (dispatch) => {
    if ((!widget.schema || force) && widget.schemaUrl) {
      // This is intermittent payload which is only available between
      // FETCHING_WIDGET_DATA and FETCH_WIDGET_DATA_SUCCESS events.
      // Which we can use to form a query params in the url based
      // on the action schema/data
      const pageFilterData = pageFilter ? pageFilter.data : {};
      const widgetFilterData = widgetFilter ? widgetFilter.data : {};
      const actionPayload = widget.actionPayload || {}

      dispatch(fetchingWidgetSchema(widgetGroupId, widget.id))
      let filterParam = getMergedFilters(pageFilterData, widgetFilterData);

      const request = requestsManager.fetchEntities(
        updateQueryStringParams(
          widget.schemaUrl, {
            spreads,
            filter: filterParam,
            widget_group_id: widgetGroupId,
            widget_id: widget.id,
            ...actionPayload
          })
      )

      return (
        request.then((res) => {
          return dispatch(setWidgetSchemaAndWidgetGroups(widgetGroupId, widget.id, res.data, widget.type))
        })
          .catch(error => console.info(error))
      )
    }

    dispatch(setWidgetLoadingOverlay(widgetGroupId, widget.id, true))
    dispatch(setWidgetSchemaAndWidgetGroups(widgetGroupId, widget.id, widget.schema, widget.type))
  }
}

export const fetchingWidgetData = (widgetGroupId, widgetId, loadingData = true, actionPayload) => {
  return {
    type: FETCHING_WIDGET_DATA,
    widgetGroupId,
    widgetId,
    loadingData,
    actionPayload
  }
}

export const fetchWidgetDataSuccess = (widgetGroupId, widgetId, data, widgetType, filteredAt) => {
  return (dispatch, getState) => {
    const appState = getState()
    const inflatedData = inflateData({ data, widgetGroupId, widgetId, widgetType, appState })

    return dispatch({
      type: FETCH_WIDGET_DATA_SUCCESS,
      widgetGroupId,
      widgetId,
      widgetData: inflatedData,
      filteredAt
    })
  }
}

export const fetchWidgetData = ({widgetGroupId, widget, pageFilter, widgetFilter, spreads, force = false, skipLoadingOverlay = false}) => {
  const pageFilterData = pageFilter ? pageFilter.data : {};
  const widgetFilterData = widgetFilter ? widgetFilter.data : {};
  const filteredAt = getFilteredAt(pageFilter, widgetFilter)

  return (dispatch) => {
    if ((!widget.data || force) && widget.dataUrl) {
      // This is intermittent payload which is only available between
      // FETCHING_WIDGET_DATA and FETCH_WIDGET_DATA_SUCCESS events.
      // Which we can use to form a query params in the url based
      // on the action schema/data
      const actionPayload = widget.actionPayload || {}

      !skipLoadingOverlay && dispatch(fetchingWidgetData(widgetGroupId, widget.id))

      let filterParam = getMergedFilters(pageFilterData, widgetFilterData);

      // if widget specified to not use page filter
      if (widget.schema && widget.ignorePageFilter) {
        filterParam = widgetFilterData
      }
      const dataUrl = updateQueryStringParams(
        widget.dataUrl,
        {
          spreads,
          filter: filterParam,
          widget_group_id: widgetGroupId,
          widget_id: widget.id,
          ...actionPayload
        }
      )
      const request = requestsManager.fetchEntities(dataUrl)
      const filteredAt = getFilteredAt(pageFilter, widgetFilter)

      return (
        request.then((res) => {
          return dispatch(fetchWidgetDataSuccess(widgetGroupId, widget.id, res.data, widget.type, filteredAt))
        })
      )
    }

    dispatch(setWidgetLoadingOverlay(widgetGroupId, widget.id, true))
    dispatch(fetchWidgetDataSuccess(widgetGroupId, widget.id, widget.data, widget.type, filteredAt))
  }
}

function getMergedFilters(pageFilterData, widgetFilterData) {
  if (!widgetFilterData || !pageFilterData) {
    return pageFilterData || widgetFilterData
  }
  let mergedFilters = JSON.parse(JSON.stringify(pageFilterData))
  Object.keys(widgetFilterData).forEach((key) => {
    if (Array.isArray(widgetFilterData[key]) && widgetFilterData[key].length > 0) {
      if (!pageFilterData[key]) {
        mergedFilters[key] = []
      }
      mergedFilters[key] = widgetFilterData[key].concat(mergedFilters[key])
    } else if (!Array.isArray(widgetFilterData[key]) && widgetFilterData[key] && !pageFilterData[key]) {
      mergedFilters[key] = widgetFilterData[key]
    }
  })
  return mergedFilters
}

export const reloadWidgets = (widgetGroupId) => {
  return {
    type: RELOAD_WIDGETS,
    widgetGroupId
  }
}

export const updateWidget = (widgetGroupId, widgetId, widgetStatus) => {
  return {
    type: UPDATE_WIDGET,
    widgetGroupId,
    widgetId,
    widgetStatus
  }
}

export const deleteWidget = (widgetGroupId, widgetId, deleted) => {
  return {
    type: DELETE_WIDGET,
    data: {
      deleted,
    },
    widgetGroupId,
    widgetId
  }
}

export const markWidgetDirty = (widgetGroupId, widgetId, dirty = true) => {
  return {
    type: MARK_WIDGET_DIRTY,
    data: {
      dirty,
    },
    widgetGroupId,
    widgetId
  }
}

export const resetWidget = (widgetGroupId, widgetId, reset = true) => {
  return {
    type: RESET_WIDGET,
    data: {
      reset,
    },
    widgetGroupId,
    widgetId
  }
}
