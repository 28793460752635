import React, { Component } from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'
import classNames from 'classnames'
import shortid from 'shortid'
import moment from 'moment'
import { map } from 'lodash'
import ReactTooltip from 'react-tooltip'
import Loader from 'libs/components/Loader'
import { getErrorText } from 'libs/utils'
import requestsManager from 'libs/api/requestsManager'
import Image from './Image'
import RichTextEditor from './RichTextEditor'
import { handleAction } from '../redux/actions/appActionCreators'
import { getNoteVisibility } from 'libs/components/EnumValuesHelper'
import Select from 'core/components/Select'

class NoteForm extends Component {
  constructor(props) {
    super(props)

    const { defaultVisibility } = getNoteVisibility()
    const { schema = {}, data } = props

    this.state = {
      loading: false,
      error: null,
      description: data.description || null,
      visibility: data.visibility || schema.defaultVisibility || defaultVisibility,
    }
  }

  get saveBtnText() {
    const { loading } = this.state
    const { data } = this.props

    if (data.id) {
      if (loading) {
        return 'Saving...'
      }
      return 'SAVE'
    }

    if (loading) {
      return 'Posting...'
    }

    return 'POST'
  }

  updateVisibility = (e) => {
    const { visibility } = this.state
    const { value } = e.target

    this.setState({ visibility: value })
  }

  renderVisibility = () => {
    const { visibility, loading } = this.state
    const { visibilities } = getNoteVisibility()
    const name = shortid.generate()

    return (
      <div className="px-3 small-grey">
        <span className="pr-1 align-middle">Visibility:</span>
        {map(visibilities, (value, key) => {
          return (
            <div key={key} className="form-check d-inline-block m-1">
              <input
                className="form-check-input"
                type="radio"
                name={name}
                disabled={loading}
                id={key}
                value={key}
                onChange={this.updateVisibility}
                checked={visibility === key}
              />
              <label
                className="form-check-label align-middle text-gray"
                htmlFor={key}
              >
                {value}
              </label>
            </div>
          )
        })}
      </div>
    )
  }

  handleChange = (name, value) => {
    this.setState({ description: value })
  }

  handleSave = (e) => {
    const { data, onSuccess, onFail } = this.props

    let url = '/notes.json'
    const actions = data.actions || {}
    if (data.id) {
      // Use UPDATE url
      const update = actions.update || null
      url = update && update.url ? update.url : null
    } else {
      // Use GET url
      const show = actions.show || null
      url = show && show.url ? show.url : null
    }

    this.setState({ loading: true })

    const note = {
      user_id: data.user_id,
      noteable_id: data.noteable_id,
      noteable_type: data.noteable_type,
      client_id: data.client_id,
      visibility: this.state.visibility,
      description: this.state.description,
    }

    const requestsManagerFn = data.id
      ? requestsManager.updateEntity
      : requestsManager.submitEntity
    requestsManagerFn
      .call({}, url, note)
      .then((res) => {
        onSuccess && onSuccess(e, res)
        this.setState({ loading: false, error: null })
        if (this.editor) {
          this.editor.setContent('')
        }
      })
      .catch((error) => {
        const errorText = getErrorText(error)

        this.setState({ loading: false, error: errorText })
        onFail && onFail(error)
      })
  }

  handleCancel = () => {
    const { onCancel } = this.props
    onCancel && onCancel()
  }

  handleDelete = () => {
    const { data } = this.props
    const { onDelete } = this.props

    const actions = data.actions || {}
    const destroy = actions.destroy || null

    const url = destroy && destroy.url ? destroy.url : null

    if (!url) {
      this.setState({ error: 'Delete url is unavailable.' })
    }

    requestsManager
      .deleteEntity(url)
      .then((response) => {
        this.setState({ error: null })
        onDelete && onDelete(response)
      })
      .catch((error) => {
        const errorText = getErrorText(error)
        this.setState({ error: errorText })
      })
  }

  render() {
    const { data, schema, name, showCancel, showDelete, tinyOptions } = this.props
    const { description, loading, error } = this.state

    return (
      <div className="note-form w-100">
        {error && <p className="text-danger mt-1">{error}</p>}
        <RichTextEditor
          type="text"
          ref={(editor) => { this.editor = editor }}
          name={`description-${shortid.generate()}`}
          value={description}
          placeholder="Write a note... Use @ to tag people"
          className="textarea-autosize create-note"
          handleChange={this.handleChange}
          mentionsUrl={data.mentions_url}
          relink={schema.relink}
          tinyOptions={{
            toolbar: 'bold italic underline numlist bullist link | removeformat',
            ...tinyOptions,
          }}
        />
        <div className="note-actions d-flex align-items-center justify-content-between mt-2">
          <div className="d-flex align-items-center">
            <a
              className={classNames('cvc-btn-primary small mr-2', {
                disabled: !this.state.description || this.state.loading,
              })}
              onClick={(e) => this.handleSave(e)}
            >
              {this.saveBtnText}
            </a>
            {showCancel && (
              <a
                className={classNames('cvc-btn-primary small')}
                onClick={(e) => this.handleCancel(e)}
              >
                CANCEL
              </a>
            )}
            {!schema.hideVisibility && this.renderVisibility()}
          </div>
          <div>
            {showDelete && data.id && (
              <span
                className="icon-trash cursor-pointer"
                onClick={this.handleDelete}
                title="Delete"
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

class EditableNote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      error: null,
      expanded: false,
      description: ''
    }
  }

  get createdAt() {
    const { data } = this.props
    const dateFormat = window.CC_MOMENT_JS_DATE_FORMAT
    return moment(data.created_at).format(`${dateFormat}, h:mm A`)
  }

  handleDelete = () => {
    const { data } = this.props
    const { onSuccess } = this.props

    const actions = data.actions || {}
    const destroy = actions.destroy || null

    const url = destroy && destroy.url ? destroy.url : null

    if (!url) {
      this.setState({ error: 'Delete url is unavailable.' })
    }

    requestsManager
      .deleteEntity(url)
      .then((response) => {
        this.setState({ error: null })
        onSuccess && onSuccess(response)
      })
      .catch((error) => {
        const errorText = getErrorText(error)
        this.setState({ error: errorText })
      })
  }

  handleEdit = () => {
    this.setState({ editing: true })
  }

  renderUserImage = (note) => {
    return (
      <Image
        schema={{
          style: {
            imageClassName: 'user-image mr-0',
            textClassName: 'user-text-image'
          }}
        }
        data={{
          altTitle: note.user_name,
          styles: {
            width: 40,
            height: 40,
            background: note.user_photo ? `url(${note.user_photo})` : null ,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
          }
        }}
      />
    )
  }

  // feed types: note, post, email
  renderFeedType = (type = 'note') => {
    // icon-post, icon-email, icon-note
    return <span
      className={classNames("cursor-pointer mx-1", `${'icon-' + type}`)}
      title={type}
    />
  }

  handleExpand = () => {
    const { data } = this.props

    if (data.description || this.state.description) {
      this.setState({ expanded: true })
      return
    }

    this.setState({
      expanded: true,
      loadingDescription: true
    })

    const actions = data.actions || {}
    const show = actions.show || null

    const url = show && show.url ? show.url : null

    if (!url) {
      this.setState({ error: 'Show url is unavailable.' })
    }

    requestsManager
      .fetchEntities(url)
      .then((response) => {
        this.setState({
          error: null,
          description: response.data.description || data.description,
          loadingDescription: false,
        })
      })
      .catch((error) => {
        const errorText = getErrorText(error)
        this.setState({
          error: errorText,
          loadingDescription: false
        })
      })
  }

  renderNote = (note) => {
    const { schema = {} } = this.props
    const { expanded, loadingDescription, error } = this.state

    const contentToShow = expanded ? note.description : note.description_preview

    return (
      <div className="row d-flex border-bottom py-2 px-3">
        <div className="col-9 col-sm-9">
          <div className="d-flex">
            <div className="pr-3 mt-2">
              {this.renderFeedType(note.content_category)}
            </div>
            <div className="pr-3">
              {this.renderUserImage(note)}
            </div>
            <div className="note-content w-100">
              { expanded || !note.description_preview ?
                (
                  <div>
                    <div
                      className="position-sticky sticky-top d-flex justify-content-between bg-pale-grey"
                    >
                      {loadingDescription && <span className="px-2 py-1">Loading...</span>}
                      <span/>
                      <span
                        className="cursor-pointer mt-2 mr-2 icon-collapse"
                        onClick={() => this.setState({ expanded: false })}
                      />
                    </div>
                    <p
                      className="cvc-c-richtext description bg-pale-grey px-3 pt-0 pb-3"
                      dangerouslySetInnerHTML={{ __html: note.description || this.state.description }}
                    />
                  </div>
                ) :
                <p
                  className="cvc-c-richtext description"
                  dangerouslySetInnerHTML={{ __html: note.description_preview }}
                  onClick={this.handleExpand}
                  style={{ cursor: 'pointer' }}
                />
              }
              {error && <p className="text-danger mt-1">{error}</p>}
              <div className="d-flex justify-content-between">
                <span className="small-grey text-left">{ note.user_name ? `By ${note.user_name}` : ' '}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 col-sm-3 d-flex justify-content-between flex-column">
          <p className="mb-0 small-grey text-right">{this.createdAt}</p>
          <div className="note-actions d-flex align-items-center justify-content-end">
            {note.deletable && <span
              className="icon-trash cursor-pointer mx-1"
              onClick={this.handleDelete}
              title="Delete"
            />}
            {note.editable && <span
              className="icon-edit cursor-pointer mx-1"
              onClick={this.handleEdit}
              title="Edit"
            />}
            {!schema.hideVisibility && <span
              className={classNames("ml-1", {
                'icon-eye-slash': note.visibility === 'internal',
                'icon-eye': note.visibility !== 'internal'
              })}
              style={{ cursor: 'default' }}
              data-tip={ note.visibility === 'internal' ? 'Visible internal only' : 'Visible to All' }
              data-place="top"
            />}
          </div>
        </div>
      </div>
    )
  }

  renderNoteForm = () => {
    const { data, schema } = this.props

    return (
      <div className="d-flex row py-2 border-bottom">
        <div className="col-10 d-flex">
          <div className="d-flex justify-content-end">
            <div className="px-3 mt-2">
              {this.renderFeedType(data.content_category)}
            </div>
            <div className="pr-3">
              {this.renderUserImage(data)}
            </div>
          </div>
          <NoteForm
            data={data}
            schema={schema}
            onSuccess={() => {
              ReactTooltip.rebuild()
              this.setState({ editing: false })
              this.props.onSuccess()
            }}
            showCancel
            onCancel={() => {
              ReactTooltip.rebuild()
              this.setState({ editing: false })
            }}
            showDelete={data.deletable}
            onDelete={() => {
              this.setState({ editing: false })
              this.props.onSuccess()
            }}
            tinyOptions={{
              height: 80,
            }}
          />
        </div>
        <div className="col-2 small-grey text-right pr-3">{this.createdAt}</div>
      </div>
    )
  }

  render() {
    const { data = {} } = this.props
    if (this.state.editing) {
      return this.renderNoteForm(data)
    }

    return this.renderNote(data)
  }
}

class NoteList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: null,
      notes: null,
    }
  }

  get noteCount() {
    if (this.state.notes) {
      return this.state.notes.length
    }

    return 0
  }

  componentDidUpdate(prevProps) {
    const { data: prevData } = prevProps
    const { data = {}, refresh } = this.props

    const prevFilters = prevData.filters || {}
    const filters = data.filters || {}

    if (
      prevFilters.noteable_type !== filters.noteable_type ||
      prevFilters.noteable_id !== filters.noteable_id ||
      prevFilters.id !== filters.id ||
      prevProps.refresh !== refresh
    ) {
      this.fetchNotes()
    }
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    this.fetchNotes()
  }

  handleSuccess = () => {
    this.fetchNotes(true)
  }

  fetchNotes = (refreshing) => {
    const { data, onCountUpdate } = this.props

    if (!refreshing) {
      this.setState({ loading: true })
    }

    const {
      filters
    } = data

    const url = `/notes.json?${$.param({ filter: filters })}`
    requestsManager
      .fetchEntities(url)
      .then((res) => {
        if (!refreshing) {
          this.setState({ loading: false })
        }
        this.setState({ notes: res.data.notes, error: null })
        onCountUpdate && onCountUpdate(res.data.notes.length)
      })
      .catch((error) => {
        const errorText = getErrorText(error)
        this.setState({ error: errorText })
      })
  }

  render() {
    const { error, notes = [], loading } = this.state
    const { schema } = this.props;

    return (
      <div className="note-list col-12">
        {error && <p className="text-danger mt-1">{error}</p>}
        {loading && <Loader className="inline-loader" />}
        {notes && !loading &&
          notes.map((note) => {
            return (
              <EditableNote
                data={note}
                schema={schema}
                key={note.id}
                onSuccess={this.handleSuccess}
              />
            )
          })}
      </div>
    )
  }
}

class Notes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notesCount: 0,
      refresh: Math.random()
    }
  }

  handleClose = (e) => {
    const { schema } = this.props
    $('.notes-modal').modal('hide')
    if (this.props.dispatch) {
      const onCloseSchema =
        schema.actions && schema.actions.onClose ? schema.actions.onClose : {}
      this.props.dispatch(handleAction(e, onCloseSchema, null))
    }
  }

  renderHeader = () => {
    const { watchers } = this.props.data;

    return (
      <div className="d-flex align-items-center justify-content-between p-3 heading-shadow">
        <div className="small-dark">{this.state.notesCount} Communications</div>
        <div className="d-flex align-items-center">
          {watchers && <Select
            schema={{
              dropdownIcon: {
                left: 'icon-watch'
              },
              searchIcon: 'icon-search',
              style: {
                containerClassName: 'mr-3'
              }
            }}
            data={watchers}
          />}
          <span
            className="icon-close cursor-pointer"
            onClick={this.handleClose}
          />
        </div>
      </div>
    )
  }

  handleSuccess = (e) => {
    const { schema } = this.props
    $('.notes-modal').modal('hide')

    if (this.props.dispatch) {
      this.handleClose(e)
    } else {
      // This is used to refresh the note list when note is
      // created in the Notes component
      this.setState({ refresh: Math.random() })
    }
  }

  renderNoteForm = () => {
    const { data = {}, schema } = this.props

    return (
      <div className="note-form-container p-3">
        <NoteForm
          schema={schema}
          data={data.form}
          onSuccess={this.handleSuccess}
          tinyOptions={{
            height: 80,
          }}
        />
      </div>
    )
  }

  handleCountUpdate = (count) => {
    this.setState({ notesCount: count })
  }

  renderNoteList = () => {
    const { data = {}, schema } = this.props

    return (
      <div className="note-list-container col">
        <NoteList
          ref={(noteList) => {
            this.noteList
          }}
          schema={schema}
          data={data.list}
          onCountUpdate={this.handleCountUpdate}
          refresh={this.state.refresh}
        />
      </div>
    )
  }

  render() {
    const containerClassName = classNames('cvc-c-notes')
    const { schema } = this.props

    const { hideHeader = false, hideForm = false, hideList = false } = schema

    return (
      <div className={containerClassName}>
        {!hideHeader && this.renderHeader()}
        <div className="notes-container">
          {!hideForm && this.renderNoteForm()}
          {!hideList && this.renderNoteList()}
        </div>
      </div>
    )
  }
}

export default Notes
