/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Popup = ({ schema, data }) => {
  const { style = {}, btnText } = schema
  return (
    <div className={classNames('p-5 text-center', style.className)}>
      <p>{data.message}</p>
      <button className={classNames('cvc-btn-primary', style.button)} data-dismiss="modal">
        {btnText || 'Close'}
      </button>
    </div>
  )
}

Popup.propTypes = {
  schema: PropTypes.shape({
    btnText: PropTypes.string,
    style: PropTypes.object
  }).isRequired,
  data: PropTypes.shape({
    message: PropTypes.string.isRequired
  }).isRequired
}

export default Popup
