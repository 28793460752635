import React, { Component, useEffect, useState, useRef, useLayoutEffect, useMemo } from "react"
import { useTable, useColumnOrder, useExpanded, usePagination } from "react-table"

import classNames from "classnames"
import { pick, isEmpty, isEqual } from "lodash"
import { CSS } from "@dnd-kit/utilities"
import { success, error } from "react-notification-system-redux"
import requestsManager from "libs/api/requestsManager"
import { formatError, updateOrAddQueryString } from "libs/utils"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable"
import Popover, { ArrowContainer } from "react-tiny-popover"
import PureLayout from "core/layouts/PureLayout"
import Icon from "core/components/Icon"
import {
  handleAction,
  markWidgetsDirty,
} from "core/redux/actions/appActionCreators"
import {
  renderData,
  getWidget,
  sortTableRows,
  getWidgetPageIdKey,
} from 'core/helpers/utils'
import TableRowContainer from "../redux/containers/TableRowContainer"
import { showConfirmModal } from "core/components/ConfirmDialog";

const defaultCacheKeys = ['hiddenColumns', 'columnOrder']

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}
const DragHandle = (props) => {
  const { isDragging, ...rest } = props
  return (
    <div
      {...rest}
      className="data-wrapper d-inline-block"
      style={{
        height: "1rem",
        cursor: isDragging ? "grabbing" : "grab",
      }}
    />
  )
}

const StaticTableRow = ({ row }) => {
  return (
    <tr {...row.getRowProps()} className={classNames("tr dragable")}>
      {row.cells.map((cell, i) => {
        const rowData = row.original
        const cellStyle = rowData.style && rowData.style[cell.column.id] ? rowData.style[cell.column.id] : {}

        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={classNames("td", cellStyle.className)}
              style={cellStyle.styles}
            >
              <DragHandle isDragging />
              <span>{cell.render("Cell")}</span>
            </td>
          )
        }
        return (
          <td
            {...cell.getCellProps()}
            className={classNames("td", cellStyle.className)}
            style={cellStyle.styles}
          >
            {cell.render("Cell")}
          </td>
        )
      })}
    </tr>
  )
}

const DraggableTableRow = ({
  row,
  handleRowClick,
  enableDragDrop,
  enableRowClick,
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.id,
    disabled: !enableDragDrop,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }

  return (
    <tr
      ref={setNodeRef}
      style={style}
      className={classNames("tr", "page-break-inside-avoid", {
        deleted: row.original.deleted,
        restored: row.original.restored,
        added: row.original.added,
        selected: row.original.selected,
        dragable: enableDragDrop,
        link: enableRowClick,
      })}
      {...row.getRowProps()}
      onClick={(e) => {
        handleRowClick(row)
      }}
    >
      {row.cells.map((cell, i) => {
        const rowData = row.original
        const cellStyle = rowData.style && rowData.style[cell.column.id] ? rowData.style[cell.column.id] : {}

        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={classNames("td", { invisible: isDragging }, cellStyle.className)}
              style={cellStyle.styles}
            >
              {enableDragDrop && <DragHandle {...attributes} {...listeners} />}
              <span>{cell.render("Cell")}</span>
            </td>
          )
        }

        return (
          <td
            {...cell.getCellProps()}
            className={classNames("td", { invisible: isDragging }, cellStyle.className)}
            style={cellStyle.styles}
          >
            {cell.render("Cell")}
          </td>
        )
      })}
    </tr>
  )
}

function Table({
  columns,
  data,
  expandedRows,
  columnOrder,
  hiddenColumns,
  updateRowOrder,
  enableDragDrop,
  handleRowClick,
  currentPage,
  pageCount: controlledPageCount,
  initialState,
  schema,
  renderRowSubComponent,
  isPaginationEnabled,
  fetchPaginatedData,
}) {
  const [activeId, setActiveId] = useState()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { expanded, pageIndex, pageSize },
    toggleAllRowsExpanded
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState,
      pageCount: controlledPageCount,
    },
    useColumnOrder,
    useExpanded,
    usePagination
  )
  const prevIsPaginationEnabled = usePrevious(isPaginationEnabled)
  const prevCurrentPage = usePrevious(prevCurrentPage)

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  function isDragDropDisabled() {
    return (
      schema.dragdrop &&
      schema.dragdrop.confirmDrop &&
      schema.dragdrop.confirmDrop.sortBy &&
      (
        schema.dragdrop.confirmDrop.sortBy !== initialState.sortBy ||
        schema.dragdrop.confirmDrop.sortOrder !== initialState.sortOrder
      )
    )

  }
  function handleDragStart(event) {
    if (isDragDropDisabled()) {
      showConfirmModal(schema.dragdrop).then((confirm) => {
        const columnToSort = columns.find((column) => column.accessor === schema.dragdrop.confirmDrop.sortBy)
        if (confirm && columnToSort) {
          return columnToSort.sort(schema.dragdrop.confirmDrop.sortOrder)
        }
        return null;
      });
      return;
    }

    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    if (isDragDropDisabled()) {
      return;
    }
    // add check here to see if the data.sortOrder === stack rank else show alert
    const { active, over } = event
    if (active.id !== over.id) {
      updateRowOrder(+active.id, +over.id)
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  useEffect(() => {
    setColumnOrder(columnOrder)
    setHiddenColumns(hiddenColumns)
    if (schema.expand) {
      toggleAllRowsExpanded(true)
    }
  }, [columnOrder, hiddenColumns, columns])

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({ id }) => id === activeId)

    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  React.useEffect(() => {
    if (!isEqual(prevIsPaginationEnabled, isPaginationEnabled)) {
      gotoPage(currentPage)
    }

    if (isPaginationEnabled && prevIsPaginationEnabled) {
      fetchPaginatedData({ pageIndex, pageSize })
    }
  }, [isPaginationEnabled, pageIndex, pageSize, currentPage])

  React.useEffect(() => {
    if (isPaginationEnabled) {
      gotoPage(currentPage)
    }
  }, [currentPage])

  const expandRowSchema = schema.events && schema.events.onExpand
  const onClickSchema = schema.events && schema.events.onClick

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <table className="table transparent-background">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      className={classNames('th', {
                        'pt-3': !schema.heading
                      })}
                      style={{ width: column.columnWidth + "%" }}
                    >
                      {
                        // Render the header
                        column.render("Header")
                      }
                      {column.sortable && (
                        <span
                          className="sort"
                          role="presentation"
                          onClick={() => {
                            column.sort()
                          }}
                        >
                          <Icon
                            schema={{
                              className: classNames("fa fa-sort-up", {
                                active:
                                  column.isSorted && column.sortOrder === "asc",
                              }),
                            }}
                          />
                          <Icon
                            schema={{
                              className: classNames("fa fa-sort-down", {
                                active:
                                  column.isSorted &&
                                  column.sortOrder === "desc",
                              }),
                            }}
                          />
                        </span>
                      )}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            {rows.map((row, i) => {
              prepareRow(row)
              const rowProps = row.getRowProps()
              return (
                <React.Fragment key={rowProps.key}>
                  <DraggableTableRow
                    key={row.id}
                    row={row}
                    handleRowClick={() => {
                      onClickSchema &&
                        handleRowClick(onClickSchema, row.original)
                    }}
                    enableDragDrop={enableDragDrop}
                    enableRowClick={onClickSchema}
                  />
                  {row.isExpanded &&
                    expandRowSchema &&
                    renderRowSubComponent({
                      data: row,
                      schema: expandRowSchema,
                    })}
                </React.Fragment>
              )
            })}
          </SortableContext>
        </tbody>
      </table>
      {enableDragDrop && (
        <DragOverlay dropAnimation={null}>
          {activeId && (
            <table style={{ width: "100%" }} className="table">
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <th
                            {...column.getHeaderProps()}
                            className="th"
                            style={{ width: column.columnWidth + "%" }}
                          />
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              <tbody>
                <StaticTableRow row={selectedRow} />
              </tbody>
            </table>
          )}
        </DragOverlay>
      )}
      {isPaginationEnabled && (
        <div className="m-2 d-flex align-items-center justify-content-end float-right">
          <div className="mx-2 small-grey d-inline-block">
            Page{' '}<strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </div>
          <ul class="pagination justify-content-end">
            <li class={classNames("page-item", { disabled: !canPreviousPage})} onClick={() => gotoPage(0)}>
              <span class="page-link">First</span>
            </li>
            <li class={classNames("page-item", { disabled: !canPreviousPage})} onClick={() => previousPage()}>
              <span class="page-link">Previous</span>
            </li>
            <li class={classNames("page-item", { disabled: !canNextPage})} onClick={() => nextPage()}>
              <span class="page-link">Next</span>
            </li>
            <li class={classNames("page-item", { disabled: !canNextPage})} onClick={() => gotoPage(pageCount - 1)}>
              <span class="page-link">Last</span>
            </li>
          </ul>
          <span className="small-grey pl-2">
            Go to page:{' '}
            <input
              type="number"
              defaultValue={currentPage + 1}
              className="pagination-input"
              onKeyUp={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>
        </div>
      )}
    </DndContext>
  )
}

function AdvancedTable2(props) {
  const [isEditViewVisible, setIsEditViewVisible] = useState(false)
  const [expandedRows, setExpandedRows] = useState({})
  const {
    widgetGroupId,
    widgetId,
    data = {},
    updateWidget,
    schema,
    viewAll,
    dataUrl,
    fetchWidgetData,
    fetchWidgetDataSuccess,
    setWidgetCache,
    widgetFilter,
    pageFilter,
    ignorePageFilter
  } = props
  const notificationOptions = {
    title: "",
    message: "",
    position: "tc",
    autoDismiss: 3,
  }
  const { sortBy, sortOrder } = data
  const { heading, style } = schema

  if (data.paginated && (isNaN(data.pageSize) || isNaN(data.page) || isNaN(data.totalPages))) {
    throw 'Table pagination requires: pageSize, page and totalPages.'
  }

  const tableColns =
    schema.style && schema.style.column ? schema.style.column : []

  function sort(property, forcedSortOrder) {
    let updatedSortOrder = forcedSortOrder ? forcedSortOrder : (sortOrder === "asc" ? "desc" : "asc")
    let updatedSortBy = property.id

    if (!property) {
      throw `Invalid sort: missing column with id '${widgetData.sortBy}'`
    }
    const rows = sortTableRows(data.table, property, updatedSortOrder)
    const updatedWidgetData = {
      widgetData: {
        ...data,
        table: rows,
        sortBy: updatedSortBy,
        sortOrder: updatedSortOrder,
      },
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, updatedWidgetData, AdvancedTable2.displayName)
    props.dispatch(markWidgetsDirty(property.markDirty || schema.markDirty))
  }

  function getColWidth(columnId) {
    const colStyle = tableColns.find((styleObj) => styleObj.id === columnId)
    return colStyle ? colStyle.width : 100 / schema.properties.length
  }

  function updateCache(cacheData) {
    const cacheKeys =
      data && data.browserCache
        ? data.browserCache
        : defaultCacheKeys
    const dataToCache = pick(cacheData, cacheKeys)
    if (!isEmpty(dataToCache)) {
      setWidgetCache({
        widgetGroupId,
        widgetId,
        data: { data: dataToCache }
      })
    }
  }

  function updateColumnOrder(updatedColumnOrder) {
    const columnOrder = updatedColumnOrder || []
    updateCache({ columnOrder })
    const updatedWidgetData = {
      widgetData: {
        ...data,
        columnOrder
      },
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, updatedWidgetData, AdvancedTable2.displayName)
    props.dispatch(markWidgetsDirty(schema.markDirty))
  }

  function updateHiddenColumn(column) {
    const schemaHiddenColumns = schema.hiddenColumns || []
    let updatedHiddenColumns = data.hiddenColumns && data.hiddenColumns.length ? [...data.hiddenColumns] : schemaHiddenColumns
    const columnIndex = updatedHiddenColumns.indexOf(column.id)
    if (columnIndex >= 0) {
      updatedHiddenColumns.splice(columnIndex, 1)
    } else {
      updatedHiddenColumns.push(column.id)
    }
    const hiddenColumns = updatedHiddenColumns
    updateCache({ hiddenColumns })
    const updatedWidgetData = {
      widgetData: {
        ...data,
        hiddenColumns
      },
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, updatedWidgetData, AdvancedTable2.displayName)
    props.dispatch(markWidgetsDirty(column.markDirty || schema.markDirty))
  }

  function handleRowClick(eventSchema, rowsData = {}) {
    const eventsData = rowsData.events
    const action = { schema: eventSchema, data: eventsData[eventSchema.id] }
    props.dispatch(
      handleAction(null, action, (response) => {
        const { notifications } = eventSchema

        // if no notification key passed from schema
        if (Object.keys(notifications).length === 0) {
          return false
        }

        if (response.status === "success") {
          props.dispatch(
            success({
              ...notificationOptions,
              title: notifications.success.title,
            })
          )
        } else {
          props.dispatch(
            error({
              ...notificationOptions,
              title: notifications.error.title,
              message: response.error,
            })
          )
        }
      })
    )
  }

  function renderExportItems(items, itemData) {
    return (
      <React.Fragment>
        {items.map((element) => {
          const action = {
            type: "COMPONENT",
            component: {
              name: "Action",
              schema: {
                style: {
                  className: "d-inline-block text-gray text-btn",
                  iconClassName: "fa fa-file-export",
                },
                type: "LINK",
                linkUrl: element.linkUrl,
                title: element.title,
              },
            },
          }
          return (
            <React.Fragment>
              {renderData(action, itemData[element.id])}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }

  function renderColumnDropdown(columns) {
    const { columnOrder = [] } = data
    const hiddenColumns = data.hiddenColumns || schema.hiddenColumns || []
    const SortableItem = SortableElement(({ item }) => {
      return (
        <div role="presentation" className={"tr dragable"} key={`item-${item.id}`}>
          <div className="td w-100">
            <div className="data-wrapper">
              <label
                className="d-flex align-items-center mb-0"
                htmlFor={item.id}
              >
                <input
                  type="checkbox"
                  defaultChecked={
                    hiddenColumns
                      ? hiddenColumns.indexOf(item.id) < 0
                      : true
                  }
                  id={item.id}
                  name={item.id}
                  value={item.id}
                  onChange={() => updateHiddenColumn(item)}
                />
                <span className="px-2">{item.title}</span>
              </label>
            </div>
          </div>
        </div>
      )
    })

    const containerRef = useRef(null);
    const SortableList = SortableContainer(({ items }) => {

      const scrollTo = useMemo(() => {
        return containerRef.current?.scrollTop;
      }, []);

      useLayoutEffect(() => {
        if (scrollTo && containerRef.current) {
          containerRef.current.scrollTop = scrollTo
        }
      }, [scrollTo, containerRef]);

      return (
        <div ref={containerRef} className="column-dropdown">
          {items.map((item, index) => (
            <SortableItem key={`item-${item.id}`} index={index} item={item} />
          ))}
        </div>
      )
    })


    const items = columnOrder.length
      ? schema.properties
          .filter((property) => {
            return !property.preventReorder
          })
          .sort((propertyA, propertyB) => {
            return columnOrder.indexOf(propertyA.id) - columnOrder.indexOf(propertyB.id)
          })
      : schema.properties.filter((property) => !property.preventReorder)

    return (
      <Popover
        isOpen={isEditViewVisible}
        position={"left"}
        onClickOutside={() => {
          // setIsEditViewVisible(false)
        }}
        containerClassName={classNames("react-tiny-popover-container")}
        content={({ position, targetRect, popoverRect }) => {
          return (
            <ArrowContainer
              position={position}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowSize={0}
            >
              <div
                className={classNames("cvc-w cvc-w-table border")}
                style={{ backgroundColor: "white", width: 300 }}
              >
                <div className="text-right px-3 pt-3 pb-2">
                  <span
                    onClick={() => setIsEditViewVisible(false)}
                    className="cursor-pointer"
                  >
                    <i className="fa fa-times"></i>
                    <span> Close</span>
                  </span>
                </div>
                <div className="tbody">
                  <SortableList
                    items={items}
                    onSortEnd={({ oldIndex, newIndex }) => {
                      const order = items.map((item) => item.id)
                      const newOrderedColumns = arrayMove(
                        order || [],
                        oldIndex,
                        newIndex
                      )

                      updateColumnOrder(newOrderedColumns)
                    }}
                  />
                </div>
              </div>
            </ArrowContainer>
          )
        }}
      >
        <div className="d-inline-block">
          <div>{props.children}</div>
        </div>
      </Popover>
    )
  }

  function toggleViewAll() {
    updateWidget(widgetGroupId, widgetId, { viewAll: !viewAll })
  }

  function renderTableActions(actionData) {
    const { schema, data, viewAll } = props
    const { initialRowDisplay } = schema.style
    return (
      <React.Fragment>
        {initialRowDisplay && data.table.length > initialRowDisplay && (
          <a className="btn p-3">
            <button
              type="button"
              className="cvc-btn-secondary x-small"
              onClick={toggleViewAll}
            >
              {viewAll ? "View Less" : "View More"}
            </button>
          </a>
        )}
        {schema.actions && (
          <React.Fragment>
            {renderData(schema.actions, actionData)}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  function updateRowOrder(oldIndex, newIndex) {
    const url = data.table[oldIndex] ? data.table[oldIndex].sortableUrl : null
    const tableData = arrayMove(data.table, oldIndex, newIndex)
    const widgetData = { widgetData: { ...data, table: tableData } }
    const widget = {
      dataUrl,
      id: widgetId,
      type: 'AdvancedTable'
    }

    fetchWidgetDataSuccess(widgetGroupId, widgetId, widgetData, AdvancedTable2.displayName)
    if (oldIndex !== newIndex && url) {
      const prevObj = newIndex !== 0 ? tableData[newIndex - 1] : {}
      const nextObj =
        newIndex !== tableData.length - 1 ? tableData[newIndex + 1] : {}
      const apiData = {
        prevObjId: prevObj.id,
        prevObjPosition: prevObj.position,
        nextObjId: nextObj.id,
        nextObjPosition: nextObj.position,
      }
      requestsManager
        .updateEntity(url, apiData)
        .then(() => {
          fetchWidgetData({ widgetGroupId, widget, force: true })
          props.dispatch(
            success({
              ...notificationOptions,
              title: schema.dragdrop.notifications
                ? schema.dragdrop.notifications.success.title
                : "Order Changed.",
            })
          )
        })
        .catch((errorResponse) => {
          const { data: errorData } = errorResponse.response
          const errorText = formatError(errorData)

          props.dispatch(
            error({
              ...notificationOptions,
              title: schema.dragdrop.notifications
                ? schema.dragdrop.notifications.error.title
                : "Order Change Failed.",
              message: errorText,
            })
          )
          fetchWidgetDataSuccess(widgetGroupId, widgetId, {
            widgetData: { ...data },
          }, AdvancedTable2.displayName)
        })
    }
  }

  function paginatedSort(property, forcedSortOrder, pageIndex) {
    let updatedSortOrder = forcedSortOrder ? forcedSortOrder : (sortOrder === "asc" ? "desc" : "asc")
    let updatedSortBy = property.id

    if (!property) {
      throw `Invalid sort: missing column with id '${widgetData.sortBy}'`
    }

    let paginatedUrl = updateOrAddQueryString(dataUrl, 'page', pageIndex)
    paginatedUrl = updateOrAddQueryString(paginatedUrl, 'sortOrder', updatedSortOrder)
    paginatedUrl = updateOrAddQueryString(paginatedUrl, 'sortBy', updatedSortBy)

    const widget = {
      dataUrl: paginatedUrl,
      id: widgetId,
      type: 'AdvancedTable',
      ignorePageFilter
    }
    fetchWidgetData({ widgetGroupId, widget, force: true, skipLoadingOverlay: true, widgetFilter, pageFilter })
  }

  function fetchPaginatedData({ pageIndex, pageSize }) {
    let paginatedUrl = updateOrAddQueryString(dataUrl, 'page', pageIndex)
    paginatedUrl = updateOrAddQueryString(paginatedUrl, 'pageSize', pageSize)
    paginatedUrl = updateOrAddQueryString(paginatedUrl, 'sortOrder', sortOrder)
    paginatedUrl = updateOrAddQueryString(paginatedUrl, 'sortBy', sortBy)

    const widget = {
      dataUrl: paginatedUrl,
      id: widgetId,
      type: 'AdvancedTable',
      ignorePageFilter
    }
    fetchWidgetData({ widgetGroupId, widget, force: true, skipLoadingOverlay: true, widgetFilter, pageFilter })
  }

  const columns = useMemo(
    () =>
      schema.properties.map((property, index) => {
        if (!property.title && property.title !== '') {
          throw `missing title key for property ${property.id} in widget ${widgetId}`
        }

        return {
          Header: tableColns[index].header === false ? "" : property.title,
          accessor: property.id,
          sortable: property.sortable,
          isSorted: sortBy === property.id,
          sortOrder: property.sortOrder || sortOrder,
          columnWidth: getColWidth(property.id),
          sort: (sortOrder) => data.paginated ? paginatedSort(property, sortOrder, data.page) : sort(property, sortOrder),
          Cell: ({ row, toggleAllRowsExpanded }) => {
            const rowData = row.original
            const options = { deleted: rowData.deleted }
            const onExpandSchema = schema.events && schema.events.onExpand
            const onRowClickSchema = schema.events && schema.events.onClick
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            return (
              <React.Fragment>
                {onExpandSchema && property.canExpand && (
                  <a
                    {...row.getToggleRowExpandedProps()}
                    onClick={(e) => {
                      onRowClickSchema && e.stopPropagation()
                      if (
                        onExpandSchema &&
                        onExpandSchema.schemaUrl &&
                        !row.isExpanded
                      ) {
                        toggleAllRowsExpanded(false)
                        setTimeout(() => {
                          row.toggleRowExpanded()
                        }, 100)
                      } else {
                        row.toggleRowExpanded()

                        if (!onExpandSchema.schemaUrl) {
                          setExpandedRows({
                            ...expandedRows,
                            [row.id]: !expandedRows[row.id],
                          })
                        }
                      }
                    }}
                    className="d-inline-block align-top"
                  >
                    <span
                      className={classNames(
                        !row.isExpanded
                          ? 'arrow-right-grey ml-2'
                          : 'arrow-down-circle light-grey'
                      )}
                    />
                  </a>
                )}
                {renderData(property, rowData[property.id], options)}
              </React.Fragment>
            )
          },
        }
      }),
    [schema]
  )

  const renderRowSubComponent = React.useCallback(({ data: row, schema }) => {
    const expandData = row.original.events ? row.original.events[schema.id] : {}
    return (
      <tr className="tr">
        <td colSpan={row.cells.length} className="p-0">
          {schema.schemaUrl ? (
            <TableRowContainer
              row={{
                rowIndex: row.id,
                ...schema,
                data: expandData,
              }}
            />
          ) : (
            <PureLayout
              {...props}
              schema={expandData.schema}
              parentWidgetGroupId={props.widgetGroupId}
              parentId={props.widgetId}
            />
          )}
        </td>
      </tr>
    )
  }, [])

  const { initialRowDisplay } = schema.style
  const dataRows =
    initialRowDisplay && !viewAll
      ? data.table.slice(0, initialRowDisplay)
      : data.table
  const rows = useMemo(() => dataRows, [data, viewAll])

  const enableDragDrop = (schema.dragdrop !== undefined && !schema.dragdrop.disabled) ? true : false
  const tableActionData = data ? data.actions : []

  function onPillClick(pill) {
    const { heading } = schema
    const { pills: actionSchema } = heading

    if (!actionSchema || !actionSchema.type) return
    const action = { schema: actionSchema, data: pill }
    props.dispatch(handleAction(null, action))
  }

  function renderPills() {
    const { data } = props
    const { heading: headingData = {} } = data
    const { pills = [] } = headingData

    return pills.map((pill, index) => {
      const badgeStyles =
        pill.style && pill.style.badge && pill.style.badge.styles

      return (
        <li
          className={classNames(
            'nav-item d-flex align-items-center font-24 px-3 cursor-pointer',
            {
              'pill-active': pill.selected
            }
          )}
          id={pill.id}
          key={pill.id}
          role="presentation"
          onClick={() => onPillClick(pill)}
        >
          <div className={classNames("nav-link d-inline-block px-2")}>{pill.title}</div>
          {pill.badge && (
            <div className="d-inline-block">
              <span className={classNames('mb-1')} style={badgeStyles}>
                {pill.badge}
              </span>
            </div>
          )}
        </li>
      )
    })
  }

  function renderHeading() {
    const tableActionData = data ? data.actions : []

    return (
      <div
        role="presentation"
        className={classNames(
          'd-flex justify-content-between heading-container',
          heading.containerClassName
        )}
        {...heading.tooltip}
      >
        <div
          className={classNames(
            "d-inline-block",
            heading.className
          )}
        >
          {heading.title && heading.title.type === "COMPONENT"
            ? renderData(
                heading.title,
                data && data.heading ? data.heading.title : ""
              )
            : heading.title}
        </div>
        <div className="d-flex justify-content-end">
          {(data.heading && data.heading.pills) && <div className={classNames('d-flex align-items-end')}>
              <ul
                className={classNames('nav tab-nav font-24')}
                id="quick-pills"
                role="tablist"
              >
                {renderPills()}
              </ul>
            </div>
          }
          {!heading.disableColumnDragDrop && <div className="ml-auto d-flex align-items-center font-24">
            {
              <i
                onClick={() => setIsEditViewVisible(!isEditViewVisible)}
                className="fa fa-sliders-h cursor-pointer"
              />
            }
            {renderColumnDropdown(columns)}
          </div>}
          <div className="clearfix ml-auto float-right">
            { heading.actions && (
              <React.Fragment>
                {renderData(heading.actions, tableActionData, {})}
              </React.Fragment>)
            }
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={classNames("cvc-w-advanced-table2", style.className)}>
      {heading && renderHeading()}
      <div className="tcontainer">
        <Table
          columns={columns}
          data={rows || []}
          schema={schema}
          hiddenColumns={data.hiddenColumns || schema.hiddenColumns || []}
          columnOrder={data.columnOrder || []}
          initialState={{
            expanded: expandedRows,
            sortBy: data.sortBy,
            sortOrder: data.sortOrder,
            pageSize: parseInt(data.pageSize, 10),
            pageIndex: data.page
          }}
          currentPage={data.page}
          pageCount={data.totalPages || 1}
          isPaginationEnabled={data.paginated}
          updateRowOrder={updateRowOrder}
          enableDragDrop={enableDragDrop}
          handleRowClick={handleRowClick}
          renderRowSubComponent={renderRowSubComponent}
          fetchPaginatedData={fetchPaginatedData}
        />
        {(rows && rows.length === 0) && (
          <div
            className="text-center p-3 text-gray page-break-inside-avoid"
            dangerouslySetInnerHTML={{
              __html: schema.placeholder || "No data found.",
            }}
          />
        )}
      </div>
      {renderTableActions(data.actions || [])}
    </div>
  )
}
AdvancedTable2.displayName = 'AdvancedTable2'

class AdvancedTableComponent extends Component {
  // persist this data on refresh
  static getPersistedProps(prevProps = {}) {
    const { viewAll } = prevProps
    return { viewAll, stamp: Math.random() }
  }

  static inflateData({ data = {}, widgetGroupId, widgetId, appState }) {
    const { widgetData = {} } = data
    const storedWidget = getWidget({ widgetGroupId, widgetId, appState })
    const storedSortOrder =
      storedWidget && storedWidget.data && storedWidget.data.widgetData.sortOrder
    const storedSortBy =
      storedWidget && storedWidget.data && storedWidget.data.widgetData.sortBy
    const storedHiddenColumns =
      storedWidget &&
      storedWidget.data &&
      storedWidget.data.widgetData.hiddenColumns
    const storedColumnOrder =
      storedWidget &&
      storedWidget.data &&
      storedWidget.data.widgetData.columnOrder
    const sortOrder = widgetData.sortOrder || storedSortOrder
    const sortBy = widgetData.sortBy || storedSortBy
    const hiddenColumns = widgetData.hiddenColumns || storedHiddenColumns
    const columnOrder = widgetData.columnOrder || storedColumnOrder

    const deletedTableRows = []
    if (storedWidget && storedWidget.data && storedWidget.data.widgetData) {
      const prevRows = storedWidget.data.widgetData.table
      prevRows.forEach((row) => {
        if (row.deleted) {
          deletedTableRows.push(row.id)
        }
      })
    }

    let cachedProperties = {}
    const cachedWidgetId = getWidgetPageIdKey(widgetGroupId, widgetId)
    // retrieve cached properties from the store and inject it at run time.
    if (appState.widgetCache && appState.widgetCache[cachedWidgetId]) {
      const widgetCache = appState.widgetCache[cachedWidgetId]
      if (widgetCache && widgetCache.data) {
        const cacheKeys =
          data.widgetData && data.widgetData.browserCache
            ? data.widgetData.browserCache
            : defaultCacheKeys
        cachedProperties = pick(widgetCache.data, cacheKeys)
      }
    }

    cachedProperties = !storedWidget || !storedWidget.data ? cachedProperties : {}

    const isSortRequired = sortBy && sortOrder
    const isDeletedRowsPresent = deletedTableRows.length > 0
    if (isSortRequired || isDeletedRowsPresent || columnOrder || hiddenColumns) {
      let rows = widgetData.table || []

      // preprocess rows to handle deleted rows
      if (isDeletedRowsPresent) {
        rows = rows.map((row) => {
          if (deletedTableRows.includes(row.id)) {
            return {
              ...row,
              deleted: true,
            }
          }

          return row
        })
      }

      // preprocess rows to handle sortBy and sortOrder
      if (
        storedWidget &&
        storedWidget.schema &&
        storedWidget.schema.properties &&
        isSortRequired
      ) {
        const properties = storedWidget.schema.properties
        const column = properties.find((property) => property.id === sortBy)

        if (!column) {
          throw `Invalid sort: missing column with id '${sortBy}'`
        }

        rows = sortTableRows(rows, column, sortOrder)
      }

      return {
        ...data,
        widgetData: {
          ...data.widgetData,
          sortOrder,
          sortBy,
          columnOrder,
          hiddenColumns,
          table: rows,
          ...cachedProperties,
        },
      }
    }

    if (!isEmpty(cachedProperties)) {
      return {
        ...data,
        widgetData: {
          ...data.widgetData,
          ...cachedProperties,
        },
      }
    }

    return data
  }


  render() {
    return <AdvancedTable2 {...this.props} />
  }
}
export default AdvancedTableComponent
