import { isEmpty, omit } from 'lodash/fp'
import { clone } from 'libs/utils'
import { cleanObject } from 'libs/utils'
import {
  SET_FILTER,
  UPDATE_FILTER,
  REMOVE_FILTER_ITEM,
  REMOVE_FILTER,
  REMOVE_ALL_WIDGET_FILTERS
} from '../constants/filtersConstants'
import { FILTER_TYPE_PAGE } from '../../helpers/constants';
import { getFilterPageIdKey } from 'core/helpers/utils'

export const $$initialState = {}

function filtersReducer($$state = $$initialState, action = null) {
  const { type } = action

  switch (type) {
    case SET_FILTER: {
      const { data } = action
      const { filter } = data

      // Remove the filter if all data values are empty
      const clone = { ...filter.data }
      cleanObject(clone)
      const filterId = getFilterPageIdKey(filter.id)

      if (isEmpty(clone)) {
        const { [filterId]: deleted, ...newState } = $$state
        return newState;
      }


      return {
        ...$$state,
        [filterId]: {
          updatedAt: Date.now(),
          ...filter
        }
      }
    }

    case UPDATE_FILTER: {
      const { filter: { id, data, type, refresh, cache } } = action.data

      if (!id || !data) {
        console.warn('Missing filter id or data in filter payload')
        return
      }

      const filterId = getFilterPageIdKey(id)
      const previousData = $$state[filterId] ? $$state[filterId] : { id }

      return {
        ...$$state,
        [filterId]: {
          id,
          updatedAt: Date.now(),
          data: {
            ...previousData.data,
            ...data
          },
          type: type ? type : previousData.type,
          refresh: refresh ? refresh : previousData.refresh,
          cache
        }
      }
    }

    case REMOVE_FILTER_ITEM: {
      const { data } = action
      const { filter: { id }, item } = data
      const filterId = getFilterPageIdKey(id)

      const previousData = $$state[filterId] ? $$state[filterId].data : {}
      const updatedData = removeItem(previousData, item.key, item.value)

      const previousDisplayData = $$state[filterId] ? $$state[filterId].displayFilters : {}
      const updatedDisplayFilters = removeItem(previousDisplayData, item.key, item.displayFilterValue)

      // if data is empty remove the filter
      if (isEmpty(updatedData)) {
        const { [filterId]: deleted, ...newState } = $$state

        return newState
      }

      // else update the filter
      return {
        ...$$state,
        [filterId]: {
          ...$$state[filterId],
          updatedAt: Date.now(),
          data: { ...updatedData },
          displayFilters: { ...updatedDisplayFilters }
        }
      }
    }

    case REMOVE_FILTER: {
      const { data: { filter: { id } } } = action

      const filterId = getFilterPageIdKey(id)

      // if data is empty remove the filter
      const { [filterId]: deleted, ...newState } = $$state

      return newState
    }

    case REMOVE_ALL_WIDGET_FILTERS: {
      const { data: { cached } } = action
      const newState = clone($$state)

      const filters = Object.values(newState)
      const wigetFilterIds = filters
        .filter((filter) => {
          const isWidgetFilter = filter.type !== FILTER_TYPE_PAGE
          if (!cached) {
            // remove only non cacheable filters
            return isWidgetFilter && !filter.cache
          }

          // remove all filters including cached filters
          return isWidgetFilter
        })
        .map((filter) => getFilterPageIdKey(filter.id))

      const remainingFilters = omit(wigetFilterIds, newState)

      return {
        ...remainingFilters
      }
    }

    default:
      return $$state
  }
}

function removeItem(filterData, key, value) {
  if (filterData == null) {
    return null
  }

  let newFilters = clone(filterData)
  const filterValue = newFilters[key]

  if (Array.isArray(filterValue)) {
    newFilters[key] = filterValue.filter(f => f !== value)
  } else {
    delete newFilters[key]
  }

  let isEmpty = true
  Object.keys(newFilters).forEach((key) => {
    const valueData = newFilters[key]
    const isValueArray = Array.isArray(valueData)
    if (isValueArray && valueData.length > 0) {
      isEmpty = false
    } else if (!isValueArray && valueData) {
      isEmpty = false
    }
  })

  return isEmpty ? null : newFilters
}

export default filtersReducer