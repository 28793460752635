/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import shortid from 'shortid'
import RichTextEditor from '../../RichTextEditor'

class RichText extends React.PureComponent {
  handleChange = (name, value) => {
    this.props.onChange(value)
  }

  render() {
    const { schema, placeholder, required, options = {}, value } = this.props
    const { mentionsUrl, relink, tinyOptions={}, readonly, imageUpload } = options
    const { title, name } = schema
    return (
      <React.Fragment>
        {
          title && (
            <label className="mb-2">
              {title}
              {required && options.astrick ? '*' : null}
            </label>
          )
        }
        {readonly ? <p
            className={classNames("cvc-c-richtext", options.className)}
            dangerouslySetInnerHTML={{ __html: value }}
          /> : <RichTextEditor
            name={ name ? name : shortid.generate() }
            placeholder={placeholder}
            className={classNames(options.classnames)}
            value={value}
            style={options.style}
            mentionsUrl={mentionsUrl}
            relink={relink}
            handleChange={this.handleChange}
            tinyOptions={tinyOptions}
            imageUpload={imageUpload}
          />
       }
      </React.Fragment>
    )
  }
}

RichText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

RichText.defaultProps = {
  value: ''
}

export default RichText
