import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Loader from 'libs/components/Loader'
import LayoutRenderer from './LayoutRenderer'

import isEqual from 'lodash/isEqual'

class EncapsulatorRenderer extends PureComponent {
  componentDidMount() {
    const { id, schemaUrl, schema, spreads } = this.props
    const unformattedSpreads = spreads ? this.formatSpreadParams(spreads) : {}

    if (!schemaUrl) {
      this.props.fetchEncapsulatorSchema(id, schema.initialSchemaUrl, unformattedSpreads, true)
    } else {
      this.props.fetchEncapsulatorSchema(id, schemaUrl, unformattedSpreads)
    }
  }

  formatSpreadParams = (unFormattedspreads) => {
    const { spreads: secondLevelSpreads, widgetId, widget } = unFormattedspreads
    const spreads = secondLevelSpreads && secondLevelSpreads.map((spread) => {
      const { label, drillDownWidget, drillDownWidgetId } = spread
      return drillDownWidget ? { label, drillDownWidget, drillDownWidgetId } : { label }
    })

    return (secondLevelSpreads ? { widget, widgetId, spreads } : { widget, widgetId })
  }

  componentDidUpdate(prevProps) {
    const { id, schemaUrl, spreads } = this.props
    const unformattedSpreads = spreads ? this.formatSpreadParams(spreads) : {}

    if ((prevProps.schemaUrl !== schemaUrl || !isEqual(spreads, prevProps.spreads)) && schemaUrl) {
      this.props.fetchEncapsulatorSchema(id, schemaUrl, unformattedSpreads)
    }
  }

  render() {
    const {
      loadingSchema,
      id,
      schema
    } = this.props
    if (loadingSchema) {
      return <Loader />
    }
    const { widgetGroups } = schema

    return (
      <div className={classNames('cvc-w', 'cvc-w-encapsulator', 'hide-widget-border')}>
        <LayoutRenderer
          widgetGroups={widgetGroups}
          parentId={id}
        />
      </div>
    )
  }
}

EncapsulatorRenderer.propTypes = {
  id: PropTypes.any,
  schema: PropTypes.object,
  schemaUrl: PropTypes.string,
  loadingSchema: PropTypes.bool.isRequired
}

export default EncapsulatorRenderer
