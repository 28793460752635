/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'
import bindAll from 'lodash.bindall'

import Grid from './Grid'
import { renderData, getPropertySchema } from '../helpers/utils'

class ExpandableGrid extends React.Component {
  constructor() {
    super()
    this.state = {
      defaultSchema: {
        heading: null,
        style: {
          className: '',
          grid: {}
        },
        summary: {}
      },
      expandedGrid: null
    }
    bindAll(
      this,
      'renderSummary'
    )
  }

  renderSummary(gridData, hideDetails = false) {
    const { summary, properties } = this.props.schema
    const title = summary.title ? getPropertySchema(properties, summary.title.property_id) : {}
    const detail = summary.detail ? getPropertySchema(properties, summary.detail.property_id) : {}
    const subdetail = summary.subdetail ? getPropertySchema(properties, summary.subdetail.property_id) : {}
    const actions = summary.actions ? getPropertySchema(properties, summary.actions.property_id) : null
    return (
      <div className="row">
        <div className="col-md-4">
          <p className="exp-grid-title pb-3">{renderData(title, gridData[title.id])}</p>
        </div>
        <div className="col-md-4">
          {!hideDetails
            && (
            <p className="text-gray">
                {renderData(detail, gridData[detail.id])}
              <br />
                {renderData(subdetail, gridData[subdetail.id])}
            </p>
            )
          }
        </div>
        <div className="col-md-4 pr-5 clearfix">
          {actions
            && <div className="float-right cursor-auto" onClick={e => e.stopPropagation()}>{renderData(actions, gridData[actions.id])}</div>
          }
        </div>
      </div>
    )
  }

  render() {
    const { data, schema, ...otherGridProps } = this.props
    const { defaultSchema, expandedGrid } = this.state
    const { heading, ...others } = schema
    const style = schema.style || {}
    const gridClass = classNames('cvc-w', 'cvc-w-expandable-grid p-0 pt-3', style.className || defaultSchema.style.className)
    const grids = Array.isArray(data) ? data : [data]
    return (
      <React.Fragment>
        {heading
          && <p className={heading.className}>{heading.title}</p>
        }
        {grids.map((dataObj, index) => {
          const gridSchema = { ...others, style: (style.grid || defaultSchema.style.grid) }
          return (
            <div className="mb-3" key={shortid.generate()}>
              <div className={gridClass}>
                <div className="exp-grid-expandable" role="presentation" onClick={() => { this.setState({ expandedGrid: expandedGrid === index ? null : index }) }} key={shortid.generate()}>
                  {this.renderSummary(dataObj.grid, expandedGrid === index)}
                </div>
                {expandedGrid === index
                  && <Grid schema={gridSchema} data={dataObj} {...otherGridProps} />
                }
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

ExpandableGrid.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  schema: PropTypes.shape({
    heading: PropTypes.object,
    properties: PropTypes.array.isRequired,
    summary: PropTypes.object.isRequired,
    show: PropTypes.array,
    style: PropTypes.object,
    actions: PropTypes.object
  }).isRequired
}

export default ExpandableGrid
