import React, { Children } from 'react'
import PropTypes from 'prop-types'
import bindAll from 'lodash.bindall'

class FilterPanel extends React.Component {
  constructor() {
    super()
    this.state = {
      updateNoteables: false
    }
    bindAll(this, 'applyFilters')
  }

  componentDidMount() {
    $('#filtersModal').on('hide.bs.modal', () => { this.props.onFilterClose(this.state.updateNoteables) })
  }

  applyFilters() {
    this.setState({ updateNoteables: true }, () => { $('#filtersModal').modal('hide') })
  }

  render() {
    const { children, disableFilterBtn } = this.props
    return (
      <div className="modal-container">
        <div className="modal fade filter-modal" id="filtersModal" role="dialog" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title center-title">Filter</div>
              </div>
              <div className="modal-body">
                <div className="cvc-w cvc-w-filter-form overflow-auto shadow-none h-100">
                  {Children.toArray(children)}
                </div>
              </div>
              <div className="modal-footer clearfix text-center p-0" style={{marginTop: 70}}>
                <button type="button" className="filter-btn" data-dismiss="modal">Cancel</button>
                <button type="button" className={`filter-btn-green${disableFilterBtn ? ' disabled' : ''}`} onClick={this.applyFilters}>Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FilterPanel.propTypes = {
  children: PropTypes.node.isRequired,
  onFilterClose: PropTypes.func,
  disableFilterBtn: PropTypes.bool.isRequired
}

export default FilterPanel
