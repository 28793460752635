import moment from 'moment'
import showdown from 'showdown';

const converter = new showdown.Converter();
const showdownDefaultOptions = showdown.getDefaultOptions();

const defaultFormatter = (property, data) => data

const stringFormatter = (property, data) => {
  return data.replace('_', ' ').toUpperCase()
}

const dateTimeFormatter = (property, data) => {
  const momentjsDateTimeFormat = `${window.CC_MOMENT_JS_DATE_FORMAT} HH:mm:ss:SS Z`
  const datetimeFormat = property.dateTimeFormat || momentjsDateTimeFormat
  try {
    return moment(data).format(datetimeFormat)
  } catch (err) {
    throw `DateTimeFormatter: Unable to parse date ${data} to ${datetimeFormat} format.`
  }
}

const dateFormatter = (property, data) => {
  if (property.dateFormat === 'RELATIVE_TIME') {
    if (moment().diff(data, 'days') > 0) {
      return moment(data).from(moment().startOf('day'))
    }
    return 'today'
  }

  try {
    const dateFormat = property.dateFormat || window.CC_MOMENT_JS_DATE_FORMAT
    return moment(data).format(dateFormat)
  } catch (error) {
    throw `DateFormatter: Unable to parse date ${data} to ${dateFormat} format.`
  }
}

const enumFormatter = (property, data) => {
  const enumObj = window.CC_ENUMS[property.enumName] ? window.CC_ENUMS[property.enumName].entries : null
  return enumObj ? enumObj[data] : 'Enum mapping not found'
}

const percentageFormatter = (property, data) => {
  return `${Number(parseFloat(data).toFixed(2))}%`
}

const numeralFormatter = (property, data, fractionDigits = 2) => {
  const num = Math.abs(data)
  let formattedNum = ''
  if (num === 0) return '0'
  else if (num < 1000) formattedNum = num.toFixed(fractionDigits).toString()
  else if (num < 1000000) formattedNum = `${Number((num / 1000).toFixed(fractionDigits))}K`
  else if (num < 1000000000) formattedNum = `${Number((num / 1000000).toFixed(fractionDigits))}M`
  else formattedNum = `${Number((num / 1000000000).toFixed(fractionDigits))}B`
  if (data < 0) formattedNum = `-${formattedNum}`
  return formattedNum.replace(/\.00$/, '')
}

const currencyFormatter = (property, data, currency = '$') => {
  const num = Math.abs(data)
  let formattedCurrency = ''
  let currencySymbol = property.currencyCode ? '' : currency
  let currencyCode = property.currencyCode ? ' ' + property.currencyCode : ''
  formattedCurrency = `${currencySymbol}${numeralFormatter(property, num, property.fractionDigits || 2)}${currencyCode}`
  if (data < 0) formattedCurrency = `-${formattedCurrency}`
  return formattedCurrency
}

const markdownFormatter = (property, data) => {
  const options = property.options ? property.options : showdownDefaultOptions

  Object.keys(options).forEach((key) => {
    converter.setOption(key, options[key])
  })

  let output = converter.makeHtml(data);
  if (property.classNames) {
    Object.keys(property.classNames).forEach((key, index) => {
      const re = new RegExp(`<${key}([^>]*)>`, 'gm')
      output = output.replace(re, `<${key} class="${property.classNames[key]}" $1>`)
    })
  }

  return output
}

const incrementFormatter = (property, data) => {
  const {showPositiveSign, prefix, postfix} = property
  let positiveSign = showPositiveSign || true ? "+" : "";
  let sign = Math.sign(data) >= 0 ? positiveSign : "-";
  let value = Math.abs(data);
  if(property.formatter) {
    const valueProperty = { "dataType": property.formatter };
    const formatter = getFormatter(valueProperty);
    value = formatter(valueProperty, value);
  }
  return `${sign}${prefix || ''}${value}${postfix || ''}`
}

const formatters = {
  'DATETIME': dateTimeFormatter,
  'DATE': dateFormatter,
  'INCREMENT': incrementFormatter,
  'ENUM': enumFormatter,
  'CURRENCY': currencyFormatter,
  'PERCENTAGE': percentageFormatter,
  'NUMERAL': numeralFormatter,
  'STRING': stringFormatter,
  'MARKDOWN': markdownFormatter,
}

const getFormatter = (property, data) => {
  const formatter = formatters[property.dataType]
  return formatter ? formatter : defaultFormatter
}

export default getFormatter
