import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

const Render = (props) => {
  const { items, component, ...others } = props
  const ComponentToRender = component
  let content = (<div />)

  // If we have items, render them
  if (Array.isArray(items)) {
    content = items.map((item) => {
      return <ComponentToRender key={shortid.generate()} {...others} item={item} />
    })
  } else {
    // Otherwise render a single component
    content = (<ComponentToRender {...props} />)
  }

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

Render.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  component: PropTypes.func.isRequired
}

export default Render
