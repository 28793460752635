import React from 'react'
import PropTypes from 'prop-types'
import TextEditor from './form/widgets/TextEditor'

const RichTextView = ({ schema, data }) => {
  const defaultSchema = {
    readOnly: true
  }
  const editorProps = { ...data, readonly: typeof schema.readOnly === 'boolean' ? schema.readOnly : defaultSchema.readOnly }
  return <TextEditor {...editorProps} />
}

RichTextView.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export default RichTextView
