import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import classNames from 'classnames'

const Stepper = ({ schema, data }) => {
  const { style } = schema
  return (
    <div className={classNames('cvc-c-comp', style.containerClassName)}>
      <div className="horizontal-line" />
      <div className="stepper-content">
        {data.map(d => (
          <div key={shortid.generate()} className="data-wrapper">
            <div className="circle" />
            <div className="stepper-data">
              {d.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Stepper.propTypes = {
  schema: PropTypes.shape(),
  data: PropTypes.array
}

export default Stepper
