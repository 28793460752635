import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { renderData } from '../helpers/utils'

import { Link, animateScroll as scroll, scroller } from 'react-scroll'

class ScrollTab2 extends React.Component {
  componentDidMount() {
    const { schema, widgetId } = this.props
    const { properties } = this.props.schema
    const selectedTab = properties.find((property) => {
      return property.selected
    })
    if (selectedTab) {
      setTimeout(() => {
        scroller.scrollTo(selectedTab.containerId, {
          smooth: true,
          containerId: widgetId,
          offset: 1,
        })
      }, 100)
    }
  }

  renderTabs() {
    const { properties, style = {} } = this.props.schema
    const { data, widgetId } = this.props
    const navItemStyles =
      style.navItem && style.navItem.styles ? style.navItem.styles : {}

    return properties.map((property, index) => {
      const badgeStyles =
        property.style && property.style.badge && property.style.badge.styles

      return (
        <li
          className={classNames(
            'nav-item d-flex align-items-center font-24 px-3 cursor-pointer'
          )}
          style={navItemStyles}
          id={property.id}
          key={property.id}
          role="presentation"
        >
          <Link
            activeClass="tab-active"
            to={property.containerId}
            containerId={widgetId}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            ignoreCancelEvents
          >
            <div className="nav-link d-inline-block px-2">{property.title}</div>
            {property.badge && (
              <div className="d-inline-block">
                <span className={classNames('mb-1')} style={badgeStyles}>
                  {property.badge}
                </span>
              </div>
            )}
          </Link>
        </li>
      )
    })
  }

  renderHeading = () => {
    const { heading = {} } = this.props.schema
    const { data, options } = this.props

    return (
      <div
        role="presentation"
        className={classNames(
          'd-flex justify-content-between heading-container',
          heading.containerClassName
        )}
        {...heading.tooltip}
      >
        <div
          className={classNames(
            "d-inline-block align-middle",
            heading.className
          )}
        >
          {heading && heading.title && (
            <div
              className={classNames(
                'd-inline-block align-middle',
                heading.className
              )}
            >
              {renderData(
                heading.title,
                heading.title.type === 'COMPONENT'
                  ? data.heading.title
                  : heading.title
              )}
            </div>
          )}
        </div>
        <div className={classNames('d-flex align-items-end')}>
          <ul
            className={classNames('nav tab-nav')}
            id="pills-tab"
            role="tablist"
          >
            {this.renderTabs()}
          </ul>
        </div>
      </div>
    )
  }

  render() {
    const { schema, data = {} } = this.props
    const { style = {}, heading, actions } = schema
    const { children, widgetGroups, widgetId } = this.props

    return (
      <div
        className={classNames(
          'cvc-w-scrolltab',
          style.containerClassName
        )}
      >
        {heading && this.renderHeading()}
        <div
          className="scroll-container"
          id={widgetId}
          style={{
            height: 300,
            overflowY: 'auto',
            overflowX: 'hidden',
            ...style.styles,
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

ScrollTab2.propTypes = {
  schema: PropTypes.object,
  data: PropTypes.object,
}

export default ScrollTab2
