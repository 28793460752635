import React from 'react'
import PropTypes from 'prop-types'
import DropdownList from 'libs/components/DropdownList'

const FormDropdown = (props) => {
  const { value, btnClass, placeholderText, isDisabled, showLabel, ...others } = props
  const dropdownClass = `dropdown-toggle ellipsis ${btnClass}${isDisabled ? ' disabled' : ''}`
  const btnValidClass = value ? ' valid-status' : ''
  const labelClass = showLabel ? ' dropdown-label' : ''
  return (
    <div className="btn-group h-100">
      <label>{placeholderText}</label>
      <button type="button" className={dropdownClass} id="dropdownMenuButton" data-toggle="dropdown">
        <p className="ellipsis m-0 pr-3"> {value} </p>
      </button>
      <DropdownList {...others} />
    </div>
  )
}

FormDropdown.propTypes = {
  value: PropTypes.string,
  btnClass: PropTypes.string,
  placeholderText: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  isDisabled: PropTypes.bool
}

FormDropdown.defaultProps = {
  isDisabled: false,
  showLabel: false
}

export default FormDropdown
