import omit from 'lodash.omit'
import { clone } from 'libs/utils'
import { find } from 'lodash/fp'
import {
  FETCHING_WIDGET_SCHEMA,
  FETCH_WIDGET_SCHEMA_SUCCESS,
  FETCHING_WIDGET_DATA,
  FETCH_WIDGET_DATA_SUCCESS,
  RELOAD_WIDGETS,
  UPDATE_WIDGET,
  DELETE_WIDGET_TAB_ITEM,
  DELETE_WIDGET,
  DELETE_WIDGET_TABLE_ROW,
  RESTORE_WIDGET_TABLE_ROW,
  ADD_WIDGET_TABLE_ROW,
  MARK_WIDGET_DIRTY,
  RESET_WIDGET,
} from '../constants/widgetConstants'
import {
  SET_FILTER,
  UPDATE_FILTER,
  REMOVE_FILTER_ITEM,
  REMOVE_FILTER
} from '../constants/filtersConstants'
import { FILTER_TYPE_PAGE } from '../../helpers/constants';

import { SET_WIDGET_GROUPS } from '../constants/widgetGroupConstants'
import { HIDE_MODAL } from '../constants/modalConstants'
import { RESET_POPOVER_SCHEMA } from '../constants/popoverConstants'
import { RESET_TABLE_ROW_SCHEMA } from '../constants/tableRowConstants'

export const $$initialState = {}

export default function widgetReducer($$state = $$initialState, action) {
  const {
    type,
    widgetId,
    widgets,
    widgetGroupId,
    loadingSchema,
    widgetSchema,
    loadingData,
    widgetData,
    widgetGroupIds,
    widgetsToRefresh = [],
    filterId,
    pageId,
    widgetStatus,
    actionPayload,
    filteredAt
  } = action

  const groupWidgets = $$state[widgetGroupId]
  switch (type) {
    case SET_WIDGET_GROUPS:
      return {
        ...$$state, ...widgets
      }

    case UPDATE_WIDGET:
      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            ...widgetStatus,
            stamp: Math.random()
          }
        }
      }

    case MARK_WIDGET_DIRTY: {
      const { data: { dirty } } = action;

      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            dirty,
            stamp: Math.random()
          }
        }
      }
     }
    case RESET_WIDGET: {
      const { data: { reset } } = action;

      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            reset: true,
            stamp: Math.random()
          }
        }
      }
    }
    case DELETE_WIDGET: {
      const { data: { deleted } } = action;

      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            deleted,
            stamp: Math.random()
          }
        }
      }
    }
    case DELETE_WIDGET_TAB_ITEM: {
      const {
        data: {
          widgetId,
          widgetGroupId,
          itemId
        }
      } = action

      const newState = clone($$state)

      if (newState[widgetGroupId] && newState[widgetGroupId][widgetId]) {
        const widget = newState[widgetGroupId][widgetId]
        if (widget.schema) {
          widget.stamp = Math.random()
          widget.schema.properties.forEach((tab, index) => {
            if (tab.id === itemId) {
              tab.deleted = !tab.deleted
            }
          })
        }
      }

      return newState
    }
    case DELETE_WIDGET_TABLE_ROW: {
      const {
        data: {
          widgetId,
          widgetGroupId,
          actionsId,
          actionId,
          rowKey,
          rowValue,
          childRow
        }
      } = action

      const newState = clone($$state)

      if (newState[widgetGroupId] && newState[widgetGroupId][widgetId]) {
        const widget = newState[widgetGroupId][widgetId]
        if (widget.data && widget.data.widgetData) {
          const rowIndexesToRemove = [];
          widget.stamp = Math.random()
          widget.data.widgetData.table.forEach((row, index) => {
            if (row[rowKey] === rowValue) {
              if (row.added) {
                return rowIndexesToRemove.push(index);
              }
              if (!childRow) {
                if (row[actionsId] && row[actionsId][actionId]) {
                  row[actionsId][actionId].deleted = !row.deleted
                }

                row.deleted = !row.deleted
              } else {
                // add eventId
                row.events[childRow.eventId].schema.widgetGroups.forEach((childWidgetGroup) => {
                  if (childWidgetGroup.id !== childRow.widget_group_id) return;

                  childWidgetGroup.widgets.forEach((childWidget) => {
                    if (childWidget.id !== childRow.widget_id) return;

                    childWidget.data.widgetData.table.forEach((r, childRowIndex) => {
                      if (r[childRow.row_key] === childRow.row_value) {
                        r.deleted = !r.deleted
                      }
                    })
                  })
                })
              }
            }
          })

          while(rowIndexesToRemove.length) {
            widget.data.widgetData.table.splice(rowIndexesToRemove.pop(), 1);
          }
        }
      }

      return newState
    }
    case RESTORE_WIDGET_TABLE_ROW: {
      const {
        data: {
          widgetId,
          widgetGroupId,
          actionsId,
          actionId,
          rowKey,
          rowValue
        }
      } = action
      const newState = clone($$state)

      if (newState[widgetGroupId] && newState[widgetGroupId][widgetId]) {
        const widget = newState[widgetGroupId][widgetId]
        if (widget.data && widget.data.widgetData) {
          widget.stamp = Math.random()
          widget.data.widgetData.table.forEach((row, index) => {
            if (row[rowKey] === rowValue) {
              if (row[actionsId] && row[actionsId][actionId]) {
                row[actionsId][actionId].restored = !row.restored
              }
              row.restored = !row.restored
            }
          })
        }
      }

      return newState
    }
    case ADD_WIDGET_TABLE_ROW: {
      const { data: { widgetId, widgetGroupId, tableRow } } = action

      const newState = clone($$state)

      if (newState[widgetGroupId] && newState[widgetGroupId][widgetId]) {
        const widget = newState[widgetGroupId][widgetId]
        if (widget.data && widget.data.widgetData && tableRow) {
          widget.stamp = Math.random()
          widget.data.widgetData.table.push(tableRow)
        }
      }

      return newState
    }

    case FETCHING_WIDGET_SCHEMA:
      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            loadingSchema,
            actionPayload,
            loadingOverlay: true,
            stamp: Math.random()
          }
        }
      }
    case FETCH_WIDGET_SCHEMA_SUCCESS: {
      const { actionPayload, ...restWidget } = groupWidgets[widgetId]

      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...restWidget,
            schema: widgetSchema,
            loadingSchema: false,
            loadingOverlay: false,
            stamp: Math.random()
          }
        }
      }
    }
    case FETCHING_WIDGET_DATA:
    {
      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...groupWidgets[widgetId],
            loadingData,
            actionPayload,
            loadingOverlay: true,
            stamp: Math.random()
          }
        }
      }
    }
    case FETCH_WIDGET_DATA_SUCCESS:
    {
      const { actionPayload, ...restWidget } = groupWidgets[widgetId]

      return {
        ...$$state,
        [widgetGroupId]: {
          ...groupWidgets,
          [widgetId]: {
            ...restWidget,
            data: widgetData,
            filteredAt,
            loadingData: false,
            loadingOverlay: false,
            stamp: Math.random()
          }
        }
      }
    }
    case HIDE_MODAL:
    {
      return omit($$state, widgetGroupIds)
    }
    case RESET_POPOVER_SCHEMA:
    {
      return omit($$state, widgetGroupIds)
    }
    case RESET_TABLE_ROW_SCHEMA:
    {
      return omit($$state, widgetGroupIds)
    }
    case RELOAD_WIDGETS: {
      const widgetIds = Object.keys($$state[widgetGroupId])
      let newWidgetGroupObj = groupWidgets
      widgetIds.forEach((widgetIdName) => {
        newWidgetGroupObj = {
          ...newWidgetGroupObj,
          [widgetIdName]: {
            ...newWidgetGroupObj[widgetIdName],
            loadingData: true,
            loadingOverlay: true,
            stamp: Math.random()
          }
        }
      })

      return {
        ...$$state,
        [widgetGroupId]: { ...newWidgetGroupObj }
      }
    }
    case SET_FILTER:
    case UPDATE_FILTER:
    case REMOVE_FILTER_ITEM:
    case REMOVE_FILTER: {
      const { data: { filter, silent } } = action

      const newState = clone($$state)

      // @TODO remove this refresh stuff after
      // spread pages are migrated
      if (filter.refresh && filter.refresh.widgets) {
        filter.refresh.widgets.forEach((itemToRefresh) => {
          const widgetGroupId = itemToRefresh.widgetGroupId
          // Refresh specific widgets for widget group
          if (itemToRefresh.widgetIds) {
            if (newState[widgetGroupId]) {
              itemToRefresh.widgetIds.forEach((widgetId) => {
                if (newState[widgetGroupId][widgetId]) {
                  if (itemToRefresh.schema) {
                    newState[widgetGroupId][widgetId].loadingSchema = true
                  }
                  newState[widgetGroupId][widgetId].loadingData = true
                  newState[widgetGroupId][widgetId].loadingOverlay = true
                  newState[widgetGroupId][widgetId].stamp = Math.random()
                  newState[widgetGroupId][widgetId].filterStamp = Math.random()
                }
              })
            }
          } else {
            // Refresh all widgets for widget group
            const widgets = Object.values(newState[widgetGroupId])
            widgets.forEach((widget) => {
              widget.loadingData = true
              widget.loadingOverlay = true
              widget.stamp = Math.random()
              widget.filterStamp = Math.random()
            })
          }
        })

        return newState
      }

      // modify values inplace
      const widgetGroups = Object.values(newState)

      widgetGroups.forEach((widgetGroup) => {
        const widgets = Object.values(widgetGroup)
        widgets.forEach((widget) => {
          // filter.silent is used on componentDidmount to only update the store
          // without making widget refresh requests
          if (!silent && (filter.type === FILTER_TYPE_PAGE || widget.subscribedFilterId === filter.id)) {
            if (widget.ignorePageFilter && filter.type === FILTER_TYPE_PAGE) {
              return;
            }

            if (filter.refresh && filter.refresh.schema) {
              widget.loadingSchema = true
            }
            widget.loadingData = true
            widget.loadingOverlay = true
            widget.stamp = Math.random()
            widget.filterStamp = Math.random()
          }
        })
      })

      return newState
    }
    default:
    {
      return $$state
    }
  }
}
