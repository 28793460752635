import React from 'react'
import PropTypes from 'prop-types'


const HeaderLegend = (props) => {
  const { payload, title, fill } = props

  return (
    <React.Fragment>
      {/* TODO: Need to include the title styling in the schema  */}
      <span className="font-14">{title}</span>
      <ul className="header-legend-wrapper recharts-default-legend">
        {
          payload.map(data => (
            <li key={data.value} className="recharts-legend-item legend-item-0">
              <svg className="recharts-surface" width="8" height="8" version="1.1">
                <circle
                  className="recharts-symbols"
                  cx="4"
                  cy="4"
                  r={fill ? 4 : 3}
                  stroke={fill ? 'none' : data.color}
                  strokeWidth={fill ? 0 : 2}
                  fill={fill ? data.color : 'none'}
                />
              </svg>
              <span className="recharts-legend-item-text">{data.value}</span>
            </li>))
        }
      </ul>
    </React.Fragment>
  )
}

HeaderLegend.propTypes = {
  payload: PropTypes.array,
  title: PropTypes.string
}

export default HeaderLegend
