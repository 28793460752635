import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash/fp'

const FormInput = ({ placeholder, type, value, isDisabled, validateField, isValid, errorMsg, ...others }) => {
  const fieldEmptyClass = !isEmpty(value) ? ' valid-input' : ''
  const disabledClass = isDisabled ? ' disabled' : ''
  const validationClass = !isValid ? ' error' : ''
  const inputClass = `mat-form-group${fieldEmptyClass}${disabledClass}${validationClass}`
  const fieldVal = value !== null ? value : undefined
  return (
    <div className={inputClass}>
      <input {...others} value={(type === 'number') && fieldVal ? parseFloat(fieldVal) : fieldVal} type={type} />
      <label>
        {placeholder}
      </label>
      {(validateField && !isValid)
        && (
        <span className="field-error">
          {errorMsg}
        </span>
        )
      }
    </div>
  )
}

FormInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  validateField: PropTypes.bool,
  isValid: PropTypes.bool,
  errorMsg: PropTypes.string,
  isDisabled: PropTypes.bool
}

FormInput.defaultProps = {
  validateField: false,
  type: 'text',
  isValid: true,
  errorMsg: '',
  isDisabled: false
}

export default FormInput
