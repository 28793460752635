import React from 'react';
import classNames from 'classnames'
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';

const CustomSchemaField = function(props) {

  const customProps = {};

  //Only process if we are dealing with a field, not the parent object
  if (props.name) {

    const formContext = props.registry.formContext;

    //Store the original onChange event provided to the SchemaField
    //as well as the name of the field
    const { onChange, name, uiSchema } = props;
    //Provide a new onChange event for the SchemaField
    customProps.onChange = function(formData) {
      let emptyValue = null
      const isUndefined = (formData == undefined)

      if (isUndefined && props.uiSchema && props.uiSchema.hasOwnProperty('ui:emptyValue')) {
        emptyValue = props.uiSchema['ui:emptyValue']
      }

      const fieldData = isUndefined ? emptyValue : formData

      //Call the custom handler provided in the formContext, if it exists,
      //with the field name and new value
      if (formContext && formContext.onFieldChange &&
        typeof formContext.onFieldChange === 'function') {
        formContext.onFieldChange(name, fieldData, uiSchema);
      }

      //Call the original onChange handler
      onChange(fieldData);
    };

  }

  if (props.uiSchema && props.uiSchema.hide) {
    return null
  }
  const eSchemaField = (<SchemaField {...props} {...customProps} />)

  if (props.name && props.schema && props.schema.canHide) {
    return (
      <div className={classNames(props.uiSchema ? props.uiSchema.containerClassName : '', "position-relative")} >
        {eSchemaField}
        <span
          className="position-absolute icon-close-small"
          style={{top: 10, right: 10}}
          onClick={() => {
            if (props.registry.formContext && props.registry.formContext.onFieldRemove) {
              props.registry.formContext.onFieldRemove(props.name)
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className={classNames(props.uiSchema ? props.uiSchema.containerClassName : '')} >
      {eSchemaField}
    </div>
  )
};

export default CustomSchemaField;