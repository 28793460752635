/* eslint-disable react/button-has-type */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from 'libs/components/Loader'
import { WidgetEventContext } from 'core/context'
import LayoutRenderer from './LayoutRenderer'
import WidgetGroupContainer from '../redux/containers/WidgetGroupContainer'

class PopoverRenderer extends Component {
  componentDidMount() {
    const { popover } = this.props;
    this.props.fetchPopoverSchema(popover)
  }

  render() {
    const {
      popover,
      schema,
      widgetGroupIds,
    } = this.props

    if (!schema) return <Loader className="inline-loader text-gray" />;

    return (
      <LayoutRenderer
        widgetGroups={schema.widgetGroups}
        parentId={schema.id}
      />
    )
  }

  componentWillUnmount() {
    const { schema, widgetGroupIds } = this.props;
    const schemaId = schema ? schema.id : null;
    this.props.resetPopoverSchema(schemaId, widgetGroupIds);
  }
}

PopoverRenderer.propTypes = {
  popover: PropTypes.shape({
    popoverSchemaUrl: PropTypes.string,
    schema: PropTypes.object
  }),
  schema: PropTypes.object,
}

export default PopoverRenderer
