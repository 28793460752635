import Immutable from 'seamless-immutable'

import {
  FETCH_ENCAPSULATOR_SCHEMA_REQUEST,
  FETCH_ENCAPSULATOR_SCHEMA_SUCCESS
} from '../constants/encapsulatorConstants'
import { SET_ENCAPSULATOR_SCHEMA_URL } from '../constants/widgetConstants'


export const $$initialState = Immutable({
})

export default function encapsulatorReducer($$state = $$initialState, action) {
  const { type, payload = {} } = action
  const { schemaUrl, id, schema } = payload
  switch (type) {
    case SET_ENCAPSULATOR_SCHEMA_URL: {
      return $$state.merge({
        [id]: {
          ...$$state[id],
          schemaUrl
        }
      })
    }
    case FETCH_ENCAPSULATOR_SCHEMA_REQUEST: {
      return $$state.merge({
        [id]: {
          ...$$state[id],
          loadingSchema: true
        }
      })
    }
    case FETCH_ENCAPSULATOR_SCHEMA_SUCCESS: {
      return $$state.merge({
        [id]: {
          ...$$state[id],
          loadingSchema: false,
          schema
        }
      })
    }
    default:
      return $$state
  }
}
